import { MediaIODevice } from '../../shared/types/Video';

export const getAvailableMediaDevices = async () => {
  const mediaDevices = await navigator.mediaDevices.enumerateDevices();
  return mediaDevices as MediaIODevice[];
};

export const detachTracks = (tracks: any) => {
  tracks.forEach((track: any) => {
    if (track) {
      track.detach().forEach((detachedElement: any) => {
        detachedElement.remove();
      });
    }
  });
};

export const attachTracks = (tracks: any[], container: any) => {
  tracks.forEach((track: any) => {
    if (track) {
      container.appendChild(track.attach());
    }
  });
};
