/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import {
  useAudioTrack,
  useDaily,
  useLocalSessionId,
  useScreenShare,
  useVideoTrack,
} from '@daily-co/daily-react';

import './Tray.css';
import callIcon from '../../../../../../assets/call-end.svg';
import mutedAudioIcon from '../../../../../../assets/micOff.svg';
import mutedVideoIcon from '../../../../../../assets/videoOff.svg';
import messageIcon from '../../../../../../assets/message.svg';
import audioIcon from '../../../../../../assets/micOn.svg';
import videoIcon from '../../../../../../assets/videoOn.svg';
import VideoRoomControl from '../../../../VideoRoomControl';
import useLayout from '../../../../../../utilities/hooks/useLayout';
import screenSharingIcon from '../../../../../../assets/screen-share.svg';
import stopScreenSharingIcon from '../../../../../../assets/stop-screen-share.svg';
import { EventActions, EventCategories, EventNames } from '../../../../../../shared/constant/Analytics';
import useTracking from '../../../../../../utilities/hooks/useTracking';
import useToast from '../../../../../../utilities/hooks/useToast';

export default function Tray({ leaveCall, meeting }) {
  const { track } = useTracking();
  const toast = useToast();
  const callObject = useDaily();
  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare({
    onError:(error)=>{
      toast.show({ message:error.errorMsg, toastVariant:'error' });
    },
  },
  );

  const { updateChatWindowSettings } = useLayout();
  const sessionId = useLocalSessionId();
  const localVideo = useVideoTrack(sessionId);
  const localAudio = useAudioTrack(sessionId);
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const toggleVideo = useCallback(() => {
    track(EventNames.inSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.meeting,
      eventValue: mutedVideo ? 'on' : 'off',
      eventLabel: 'toggle_camera',
      featureVersion: 'v1',
    });
    callObject.setLocalVideo(mutedVideo);
  }, [callObject, mutedVideo]);

  const toggleAudio = useCallback(() => {
    track(EventNames.inSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.meeting,
      eventValue: mutedAudio ? 'on' : 'off',
      eventLabel: 'toggle_mic',
      featureVersion: 'v1',
    });
    callObject.setLocalAudio(mutedAudio);
  }, [callObject, mutedAudio]);

  const toggleScreenShare = () => {
    if (isSharingScreen) {
      stopScreenShare();
    } else {
      startScreenShare();
    }
    track(EventNames.inSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.meeting,
      eventValue: isSharingScreen ? 'off' : 'on',
      eventLabel: 'screen_share',
      featureVersion: 'v1',
    });
  };
    
  return (
    <div className='tray'>
      <div className='tray-buttons-container'>
        <div className='actions'>
          <VideoRoomControl
            color="primary"
            altText={mutedAudio ? 'Unmute mic' : 'Mute mic'}
            icon={mutedAudio ? mutedAudioIcon : audioIcon}
            onClick={toggleAudio}
          />
          <VideoRoomControl
            color="primary"
            altText={mutedVideo ? 'Turn camera on' : 'Turn camera off'}
            icon={mutedVideo ? mutedVideoIcon : videoIcon}
            onClick={toggleVideo}
          />
          <VideoRoomControl
            color="primary"
            altText={isSharingScreen ? 'Share screen' : 'Stop sharing'}
            icon={isSharingScreen ? stopScreenSharingIcon : screenSharingIcon}
            onClick={toggleScreenShare}
          />
          <VideoRoomControl
            color="primary"
            altText="chat"
            icon={messageIcon}
            onClick={() =>
              updateChatWindowSettings({
                show: true,
                viewMode: 'chat-view',
                initialUser: Number(meeting.data.clientId),
              })
            }
          />
          <VideoRoomControl
            color="error"
            altText="Leave call"
            icon={callIcon}
            onClick={() => {
              leaveCall();
              track(EventNames.inSession, {
                eventAction: EventActions.click,
                eventCategory: EventCategories.meeting,
                eventLabel: 'leave_call',
                featureVersion: 'v1',
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}
