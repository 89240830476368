import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import useAuth from '../../../utilities/hooks/useAuth';
import './Header.scss';
import profileAvatarIcon from '../../../assets/profile-avatar.svg';
import intellectLogo from '../../../assets/IntellectLogo.svg';
import hamburgerIcon from '../../../assets/hamburger.svg';
import Typography from '../../components/Typography';
import Popup from '../../components/Popup';
import ProfilePopup from '../ProfilePopup';
import chevronLeftIcon from '../../../assets/chevron-left.svg';
import Button from '../../components/Button';
import SosClientList from '../SosClientList';
import { useFetch } from '../../../utilities/hooks/useFetch';
import { IClientSummary } from '../../../shared/types/response/provider';
import { getClientsForAssignment } from '../../../shared/redux/actions/provider';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import timezoneIcon from '../../../assets/Timezone.svg';


interface HeaderProps {
  showPageNavigation: boolean;
  handleBackNavigation: () => void;
  handleOpenSidebar: () => void;
  currentTimeZone: string;
  isConfigLoading: boolean;
}

function Header({
  showPageNavigation,
  handleBackNavigation,
  handleOpenSidebar,
  currentTimeZone,
  isConfigLoading,
}: HeaderProps) {
  // Custom Hooks
  const { user } = useAuth();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { track } = useTracking();

  const { data: clientsData, isLoading: clientListLoading } = useFetch<
  IClientSummary[]
  >(['clients'], () => dispatch(getClientsForAssignment()));
  
  // const navigate = useNavigate();
  // Local State
  const [showProfilePopup, setShowProfilePopup] =
    React.useState<boolean>(false);
  const [clientListStatus, setClientListStatus] =
    React.useState<boolean>(false);
  const { name } = user;
  return (
    <div className="header-container">
      <AnimatePresence>
        <div className="logo-container">
          <div role="button" tabIndex={0} onClick={handleOpenSidebar}>
            <img src={hamburgerIcon} alt="open sidebar" />
          </div>
          <img src={intellectLogo} className="logo" alt="intellect logo" />
        </div>
        {showPageNavigation && (
          <motion.div
            className="back-nav-container"
            onClick={handleBackNavigation}
            initial={{ x: '100px', opacity: 0 }}
            animate={{ x: '0', opacity: 1 }}
            exit={{ x: '100px', opacity: 0 }}
            whileHover={{ x: '-10px' }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <img src={chevronLeftIcon} alt="go back" />
            <Typography size={12} weight="400" color="secondary">
              Back
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!!showProfilePopup && (
          <Popup withBackdrop onClose={() => setShowProfilePopup(false)}>
            <ProfilePopup setShowProfilePopup={setShowProfilePopup} />
          </Popup>
        )}
      </AnimatePresence>
      <div className="right-section">
        {!isConfigLoading && (
          <div className="timezone-wrapper">
            <img src={timezoneIcon} alt="timezone-icon" />
            <Typography size={12} withColor="#1F384C">
              {currentTimeZone}
            </Typography>
          </div>
        )}
        <Button
          label={t('SOS')}
          variant="sos"
          minWidth="90px"
          width="90px"
          onClick={() => {
            setClientListStatus(true);
            track(EventNames.sosHelpLine, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.sosHelpLine,
              featureVersion: 'v1',
              eventLabel: 'sos_helpline',
              eventSource: EventSource.quickTab,
            });
          }}
        />
        <div
          className="userinfo-container"
          role="button"
          tabIndex={0}
          onClick={() => setShowProfilePopup(true)}
        >
          <div className="header-profile-image-container">
            <img src={profileAvatarIcon} alt="profile" />
            <Typography
              weight="600"
              size={16}
              color="solarized"
              breakWord="keep-all"
            >
              {name}
            </Typography>
          </div>
        </div>
      </div>
      {clientListStatus && (
        <SosClientList
          show={clientListStatus}
          clientListLoading={clientListLoading}
          onClose={() => {
            setClientListStatus(false);
            track(EventNames.sosHelpLine, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.close,
              featureVersion: 'v1',
              eventLabel: 'close',
              eventSource: EventSource.quickTab,
            });
          }}
          clientList={clientsData}
        />
      )}
    </div>
  );
}

export default Header;
