/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import {
  useLocalSessionId,
  useParticipantProperty,
  useVideoTrack,
  useDevices,
  useDaily,
  useDailyEvent,
  DailyVideo,
} from '@daily-co/daily-react';
import DailyIframe from '@daily-co/daily-js';
import UserMediaError from '../UserMediaError/UserMediaError';
import { EventActions, EventCategories, EventNames } from '../../../../../../shared/constant/Analytics';
import useTracking from '../../../../../../utilities/hooks/useTracking';
import './HairCheck.css';
import { BlurOnSmall } from '../Tray/Icons';
import VideoEffects from '../VideoEffects';
import SideDrawer from '../../../../../components/SideDrawer';

export default function HairCheck({ joinCall, cancelCall }) {
  const { track } = useTracking();
  const sessionId = useLocalSessionId();
  const userName = useParticipantProperty(sessionId, 'user_name');
  const videoTrack = useVideoTrack(sessionId);
  const {
    microphones,
    speakers,
    cameras,
    setMicrophone,
    setCamera,
    setSpeaker,
    // micState,
    // camState,
    refreshDevices,
  } = useDevices();
  const callObject = useDaily();
  const videoElement = useRef();

  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const [showEffects, setShowEffects] = useState(false);

  useDailyEvent(
    'camera-error',
    useCallback((e) => {
      Sentry.captureException(e.error.msg);
      setGetUserMediaError(true);
    }, []),
  );

  const onChange = (e) => {
    callObject.setUserName(e.target.value);
  };

  const join = (e) => {
    e.preventDefault();
    joinCall();
  };

  useEffect(() => {
    callObject.startCamera();
    refreshDevices();
    if (!videoTrack.persistentTrack) return;
    if (videoElement?.current) {
      videoElement.current.srcObject =
        videoTrack.persistentTrack &&
        new MediaStream([videoTrack?.persistentTrack]);
    }
  }, [videoTrack.persistentTrack]);

  const updateMicrophone = (e) => {
    track(EventNames.inSession, {
      eventAction: EventActions.load,
      eventCategory: EventCategories.hardwareSetup,
      eventLabel: 'microphone',
      eventValue: e.target.value,
      featureVersion: 'v1',
    });
    setMicrophone(e.target.value);
  };

  const updateSpeakers = (e) => {
    track(EventNames.inSession, {
      eventAction: EventActions.load,
      eventCategory: EventCategories.hardwareSetup,
      eventLabel: 'speakers',
      eventValue: e.target.value,
      featureVersion: 'v1',
    });
    setSpeaker(e.target.value);
  };

  const updateCamera = (e) => {
    track(EventNames.inSession, {
      eventAction: EventActions.load,
      eventCategory: EventCategories.hardwareSetup,
      eventLabel: 'camera',
      eventValue: e.target.value,
      featureVersion: 'v1',
    });
    setCamera(e.target.value);
  };

  const toggleEffects = () => {
    setShowEffects((prev) => !prev);
  };

  return getUserMediaError ? (
    <UserMediaError />
  ) : (
    <form className="hair-check" onSubmit={join}>
      <SideDrawer show={showEffects} hideHandler={() => setShowEffects(false)}>
        <VideoEffects callObject={callObject} />
      </SideDrawer>
      <h1>Setup your hardware</h1>
      {/* Video preview */}
      {sessionId && (
        <DailyVideo sessionId={sessionId} mirror />
      )}
      {!!videoTrack.persistentTrack && DailyIframe.supportedBrowser().supportsVideoProcessing &&  (
        <div className="hair-check-effects" onClick={toggleEffects} tabIndex={0} role='button'>
          <BlurOnSmall />
          Effects
        </div>
      )}
      {/* Username */}
      <div>
        <label htmlFor="username">Your name:</label>
        <input
          name="username"
          type="text"
          placeholder="Enter username"
          onChange={(e) => onChange(e)}
          value={ userName || ' '}
          style={{ width: '96.7%' }}
        />
      </div>

      {/* Microphone select */}
      <div>
        <label htmlFor="micOptions">Microphone:</label>
        <select name="micOptions" id="micSelect" onChange={updateMicrophone}>
          {microphones?.map((mic) => (
            <option
              key={`mic-${mic.device.deviceId}`}
              value={mic.device.deviceId}
            >
              {mic.device.label}
            </option>
          ))}
        </select>
      </div>

      {/* Speakers select */}
      <div>
        <label htmlFor="speakersOptions">Speakers:</label>
        <select
          name="speakersOptions"
          id="speakersSelect"
          onChange={updateSpeakers}
        >
          {!speakers.length && (
            <option key='speaker-default' value={0}>
              Default speaker
            </option>
          )}
          {speakers?.map((speaker) => (
            <option
              key={`speaker-${speaker.device.deviceId}`}
              value={speaker.device.deviceId}
            >
              {speaker.device.label}
            </option>
          ))}
        </select>
      </div>

      {/* Camera select */}
      <div>
        <label htmlFor="cameraOptions">Camera:</label>
        <select name="cameraOptions" id="cameraSelect" onChange={updateCamera}>
          {cameras?.map((camera) => (
            <option
              key={`cam-${camera.device.deviceId}`}
              value={camera.device.deviceId}
            >
              {camera.device.label}
            </option>
          ))}
        </select>
      </div>

      <button
        onClick={join}
        type="submit"
        id="room-button"
        // disabled={micState !== 'granted' && camState !== 'granted'}
      >
        Join call
      </button>
      <button
        onClick={() => {
          cancelCall();
          track(EventNames.inSession, {
            eventAction: EventActions.click,
            eventCategory: EventCategories.backButton,
            eventLabel: 'back_Button',
            featureVersion: 'v1',
          });
        }}
        className="cancel-call"
        type="button"
      >
        Back to start
      </button>
    </form>
  );
}
