import * as React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getTruncatedMeetingId } from '../../../utilities/common/Display';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import './CaseNotesListItem.scss';
import { APIDateFormat } from '../../../utilities/common/Date';

interface ICaseNotesListItemProps {
  meetingId: string;
  scheduledDate: string;
  onViewCaseNote: ( meetingId: string, scheduledDate: string) => void;
  isDisable?: boolean;
}

function CaseNotesListItem({ meetingId, scheduledDate, onViewCaseNote, isDisable = false }: ICaseNotesListItemProps) {
  const { t } = useTranslation();
  return (
    <div className="case-note-detail-item" key={meetingId}>
      <div className="case-note-left-pane">
        <Typography size={14} weight="400">{getTruncatedMeetingId(meetingId)}</Typography>
        <Typography size={12} weight="400" color="solarized">{moment(scheduledDate, APIDateFormat).format('MMM D, YYYY')}</Typography>
      </div>
      <Button label={t('view')} variant="secondary" onClick={() => onViewCaseNote(meetingId, scheduledDate)} disabled={isDisable} disableAnimation={isDisable}/>
    </div>
  );
}

export default CaseNotesListItem;