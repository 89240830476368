import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IClientSummary } from '../../../shared/types/response/provider';
import CheckBox from '../../components/CheckBox';
import Typography from '../../components/Typography';
import './AssignClientListItem.scss';

interface AssignClientListItemProps {
  clientDetails: IClientSummary;
  onClientCheckBoxChange: (client: IClientSummary, isChecked: boolean) => void;
  isChecked: boolean;
  index: number;
  isCheckBoxDisable: boolean;
}

const profilePlaceholderColors = [
  '#4C8387',
  '#955D29',
  '#DB6421',
  '#252525',
  '#A00000',
];

function AssignClientListItem({
  clientDetails,
  onClientCheckBoxChange,
  index,
  isChecked,
  isCheckBoxDisable,
}: AssignClientListItemProps) {
  const { t } = useTranslation();
  const { user, language } = clientDetails;
  const { friendlyName, companyName } = user;
  const profilePlaceholderColor =
    profilePlaceholderColors[index % profilePlaceholderColors.length];
  return (
    <div className="assign-item">
      <div className="assign-client-details">
        <div
          className="avatar"
          style={{ backgroundColor: profilePlaceholderColor }}
        >
          <Typography size={10} weight="500" color="light">
            {friendlyName?.charAt(0)}
          </Typography>
        </div>
        <div className="assign-client-info">
          <Typography size={14} weight="500" withColor='#1F384C'>
            {friendlyName}
          </Typography>
          <Typography size={12} weight="400">
            {`${companyName} ${language ? ` | ${t(language)}` : ''}`}
          </Typography>
        </div>
      </div>
      <CheckBox
        isChecked={isChecked}
        onChange={(checkStatus) =>
          onClientCheckBoxChange(clientDetails, checkStatus)
        }
        isDisabled={isCheckBoxDisable}
      />
    </div>
  );
}

export default AssignClientListItem;
