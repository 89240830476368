import { beautifyUrl } from '../../../utilities/common/Path';
import { getInternalAssignmentReferenceType } from '../../../utilities/common/Provider';
import Config from '../../Config';
import NetworkClient from '../../service/Network';
import { Assignment, AssignmentPage, CustomAssignment, FilterValues } from '../../types/response/assignment';

const client = new NetworkClient();

export const getAssignments = (pageNumber: number, filters: FilterValues, searchText: string) => async () => {
  const { categories, languages, topics } = filters;
  const url = new URL(Config.api.assignments.getAssignments);
  url.searchParams.append('page', pageNumber.toString());

  if (categories.length) url.searchParams.append('categories', categories.join(','));
  if (languages.length) url.searchParams.append('languages', languages.join(','));
  if (topics.length) url.searchParams.append('topics', topics.join(','));
  if (searchText) url.searchParams.append('query', searchText);

  const response = await client.doGet(url.toString());
  if (response.data && response.data.hits && response.data.hits.length) {
    const { hits, page, nbPages } = response.data;
    const assignmentPage: AssignmentPage<Assignment> = {
      assignments: hits,
      nbPages,
      page,
    };

    return assignmentPage;
  }

  return [];
};

export const getCustomAssignments = (pageNumber: number, searchText: string) => async () => {
  const url = new URL(Config.api.assignments.getCustomAssignments);
  url.searchParams.append('page', pageNumber.toString());
  if (searchText) url.searchParams.append('query', searchText);
  const response = await client.doGet(url.toString());

  const { assignments, page, totalPages } = response.data;
  const assignmentPage: AssignmentPage<CustomAssignment> = {
    page,
    nbPages: totalPages, 
    assignments: assignments || [],
  };

  return assignmentPage;
};

export const assignToClients = (assignmentType: 'internal' | 'custom', assignment: { entityId: string, type: string }, clientIds: number[], note?: string) => async () => {
  let requestBody: Record<string, any> = {};
  const { entityId, type } = assignment;
  if (assignmentType === 'internal') {    
    requestBody = {
      referenceId: entityId,
      referenceType: getInternalAssignmentReferenceType(type),
      userIds: clientIds,
    };
  } else if (assignmentType === 'custom') {
    requestBody = {
      referenceId: entityId,
      referenceType: type,
      userIds: clientIds,
    };
  }
  if (note) {
    requestBody.notes = note;
  }
  const response = await client.doPost(Config.api.assignments.assignToClients, requestBody);
  return response.data;
};

export const getAssignmentById = (assignmentId: string) => async () => {
  const response = await client.doGet(beautifyUrl(Config.api.assignments.getAssignmentById, [assignmentId]));
  return response.data;
};

export const getAssignmentConfig = () => async () => {
  const response = await client.doGet(Config.api.assignments.getAssignmentConfigs);
  return response.data;
};

export const getClientAssignments = (clientId: string) => async () => {
  const response = await client.doGet(beautifyUrl(Config.api.assignments.getClientAssignments, [clientId]));
  return response.data;
};

export const sendAssignmentReminder = (clientId: string, assignmentId: string) => async () => {
  const response = await client.doPost(beautifyUrl(Config.api.assignments.sendReminder, [assignmentId, clientId]), {});
  if (response && response.success) {
    return 'success';
  }
  console.log('res', response);
  throw new Error(response.error?.message, {
    cause: response.error?.statusCode,
  });
};

export const createCustomAssignment = (
  type: 'file' | 'url',
  title: string,
  file?: Blob,
  url?: string,
) => async () => {
  const body = new FormData();
  body.append('title', title);
  if (type === 'file' && file) {
    body.append('file', file);
  } else if (type === 'url' && url) {
    body.append('fileURL', url);
  }

  const response = await client.doPost(Config.api.assignments.createCustomAssignment, body, { 'Content-Type': 'form-data/multipart' });
  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message);
};

export const getInsightsReport = (clientId: string) => async () => {
  const response = await client.doGet(beautifyUrl(Config.api.assignments.getClientReports, [clientId]));
  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message);
};