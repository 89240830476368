import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePagination, DOTS } from './hooks/usePagination';
import './ClientPagination.scss';
import { IClientPagination } from './types';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';

function Pagination({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}: IClientPagination) {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const { t } = useTranslation();
  const { track } = useTracking();

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.page,
      eventLabel: 'next',
      eventValue: currentPage + 1,
    });
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.page,
      eventLabel: 'previous',
      eventValue: currentPage - 1,
    });
  };

  const onClickPageNumber = (newPage: number) => {
    onPageChange(newPage);
    track(EventNames.providerWebClients, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.page,
      eventLabel: 'page',
      eventValue: newPage,
    });
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`pagination-container ${className}`}>
      <li
        className={`pagination-item arrow-button ${
          currentPage === 1 ? 'disabled' : ''
        }`}
        onClick={onPrevious}
      >
        <div className="arrow left" />
        {t('prev')}
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS || typeof pageNumber === 'string') {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
            className={`pagination-item ${
              pageNumber === currentPage ? 'selected' : ''
            }`}
            onClick={() => onClickPageNumber(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={`pagination-item arrow-button ${
          currentPage === lastPage ? 'disabled' : ''
        }`}
        onClick={onNext}
      >
        {t('next')}
        <div className="arrow right" />
      </li>
    </ul>
  );
}

export default Pagination;
