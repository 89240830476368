import moment from 'moment';
import { getMonthlyDailyOverrides } from '../../../shared/redux/actions/dailyOverrides';
import { useFetch } from '../useFetch';

export default function useMonthlyDailyOverrides(currentMonth: string) {
  return useFetch<[string, string][]>(
    ['overridden-dates', currentMonth],
    () => getMonthlyDailyOverrides(moment(currentMonth).format('YYYY/MM')),

    {
      initialData: [],
      refetchOnWindowFocus: false,
    },
  );
}
