import { motion } from 'framer-motion';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import './DailyOverrideCalenderView.scss';
import rightIcon from '../../../assets/right.svg';
import leftIcon from '../../../assets/left.svg';
import { CalenderDay, CalenderIndicator } from '../../../shared/types/calender';
import { APIDateFormat } from '../../../utilities/common/Date';
import useMonthlyDailyOverrides from '../../../utilities/hooks/useMonthlyDailyOverride/useMonthlyDailyOverrides';
import { OverrideVariant } from '../../../shared/constant/DailyOverideVariant';

interface IDailyOverrideCalenderViewProps {
  onMonthChange?: (month: string) => void;
  onCalenderDayClick?: (day: string) => void;
}

function generateContinuousDaysForCalenderView(
  noOfDays: number,
  indicator: CalenderIndicator,
): Array<CalenderDay> {
  const days: CalenderDay[] = [];
  for (let day = 1; day <= noOfDays; day += 1) {
    days.push({ day, indicator });
  }

  return days;
}

function overrideIndicators(
  calenderDays: CalenderDay[],
  daysToOverride: number[],
  overridingIndicator: CalenderIndicator,
): CalenderDay[] {
  const newCalenderDays: CalenderDay[] = [];
  calenderDays.forEach((calenderDay) => {
    if (daysToOverride.includes(calenderDay.day)) {
      const newCalenderDay = {
        day: calenderDay.day,
        indicator: overridingIndicator,
      };
      newCalenderDays.push(newCalenderDay);
    } else {
      newCalenderDays.push(calenderDay);
    }
  });

  return newCalenderDays;
}

function getDaysByVariant(
  dateVariantList: [string, string][],
  variant: string,
): number[] {
  return dateVariantList
    ?.filter(([, variantVal]) => variantVal === variant)
    .map((day) => moment(day, APIDateFormat).date());
}

function getCalenderValues(
  calenderViewDate: moment.Moment,
  overriddenDays: [string, string][],
): Array<CalenderDay> {
  let calenderValues = [];
  const noOfWeekdays = 7;
  const todayDate = moment();
  // ! need to confirm date.startOf('month').isoWeekday(); is working as expected for edge cases.
  // TODO can improve this flow by having a pipeline for indicator flow
  const weekDay = calenderViewDate.startOf('month').isoWeekday();
  const noOfDaysCurrentMonth = calenderViewDate.daysInMonth();
  let currentMonthDays = generateContinuousDaysForCalenderView(
    noOfDaysCurrentMonth,
    'default',
  );

  // Virtual only, In-Person only and Both will be shown as Scheduled.
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysByVariant(overriddenDays, OverrideVariant.BothOverride),
    'overridden',
  );
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysByVariant(overriddenDays, OverrideVariant.Virtual),
    'overridden',
  );
  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysByVariant(overriddenDays, OverrideVariant.InPerson),
    'overridden',
  );

  currentMonthDays = overrideIndicators(
    currentMonthDays,
    getDaysByVariant(overriddenDays, OverrideVariant.Leave),
    'on-leave',
  );
  if (todayDate.month() === calenderViewDate.month()) {
    currentMonthDays = overrideIndicators(
      currentMonthDays,
      [todayDate.date()],
      'today',
    ); // * applying 'today' indication for calender days
  }

  if (weekDay === 7) {
    //  * No need to add top padding if the weekday starts from sunday.
    calenderValues = [...currentMonthDays];
  } else {
    const noOfDaysInPreviousMonth = calenderViewDate
      .subtract(1, 'month')
      .daysInMonth();
    const previousMonthDays = generateContinuousDaysForCalenderView(
      noOfDaysInPreviousMonth,
      'disabled',
    );

    const topPaddingLength = noOfWeekdays - (noOfWeekdays - weekDay);
    const topPadding = previousMonthDays.splice(-topPaddingLength);

    calenderValues = [...topPadding, ...currentMonthDays];
  }

  const bottomPaddingLength =
    noOfWeekdays - (calenderValues.length % noOfWeekdays);
  if (bottomPaddingLength !== noOfWeekdays) {
    // * No need to add bottom padding if the month ending day is on saturday
    const bottomPadding = generateContinuousDaysForCalenderView(
      bottomPaddingLength,
      'disabled',
    );
    calenderValues = [...calenderValues, ...bottomPadding];
  }

  return calenderValues;
}

function DailyOverrideCalenderView({
  onCalenderDayClick,
  onMonthChange,
}: IDailyOverrideCalenderViewProps) {
  const [currentMonth, setCurrentMonth] = React.useState(() =>
    moment().toISOString(),
  );
  const [calenderValues, setCalenderValues] = React.useState<CalenderDay[]>([]);
  const [selectedDay, setSelectedDay] = React.useState<number>(() =>
    moment().date(),
  );
  const maximumPossibleUpcomingSessionsMonthsLookup = 12;
  const { data: overriddenDates } = useMonthlyDailyOverrides(
    moment(currentMonth).format(APIDateFormat),
  );

  const { t } = useTranslation();

  const calendarIndicators = [
    {
      label: t('today'),
      style: { backgroundColor: '#1c4679' },
    },
    {
      label: t('scheduled'),
      style: { backgroundColor: '#ccccff' },
    },
    {
      label: t('ON_LEAVE'),
      style: { backgroundColor: '#FFD9CF' },
    },
  ];

  React.useEffect(() => {
    onMonthChange?.(currentMonth);
    // ? can memoization applied for getCalenderValues()?
    setCalenderValues(
      getCalenderValues(moment(currentMonth), overriddenDates || []),
    );
  }, [currentMonth, overriddenDates]);

  const onPreviousMonthClick = () => {
    const today = moment();
    const selectedMonth = moment(currentMonth);

    // disabling showing slots history
    if (!today.isSame(selectedMonth, 'months')) {
      setCurrentMonth((currentMonthState) =>
        moment(currentMonthState).subtract(1, 'month').toISOString(),
      );
    }
  };

  const onNextMonthClick = () => {
    const today = moment();
    const selectedMonth = moment(currentMonth);
    const maximumSelectableMonth = today.add(
      maximumPossibleUpcomingSessionsMonthsLookup - 1,
      'months',
    );
    if (selectedMonth.isBefore(maximumSelectableMonth)) {
      setCurrentMonth((currentMonthState) =>
        moment(currentMonthState).add(1, 'month').toISOString(),
      );
    }
  };

  const onCalenderViewDayClick = (
    event: React.MouseEvent<Element, MouseEvent>,
    calenderDay: CalenderDay,
  ): void => {
    const { day, indicator } = calenderDay;
    if (indicator !== 'disabled') {
      setSelectedDay(day);
      onCalenderDayClick?.(moment(currentMonth).set('date', day).toISOString());
    }
  };

  const getOldDatesStripConfig = () => {
    const calenderViewNoOfColumns = 7;
    const indexOfToday = calenderValues.findIndex(
      (v) => v.indicator === 'today',
    );
    if (indexOfToday === -1) {
      //* no need to generate strip config if it's not current month
      return [];
    }
    const fullRowStripsCount = Math.floor(
      (indexOfToday + 1) / calenderViewNoOfColumns,
    );
    const partialRowStripIndex = (indexOfToday + 1) % calenderViewNoOfColumns;
    const stripConfig = [
      ...Array(fullRowStripsCount).fill(calenderViewNoOfColumns),
      partialRowStripIndex,
    ];

    return stripConfig;
  };

  const stripConfig = getOldDatesStripConfig();

  return (
    <div className="daily-override-calenderview-container">
      <div className="controls">
        <motion.div
          className="control-item"
          whileHover={{ scale: 1.5 }}
          whileTap={{ scale: 0.9 }}
          onClick={onPreviousMonthClick}
        >
          <img src={leftIcon} alt="previous month" />
        </motion.div>
        <Typography withColor="#344054" weight="600" size={20}>
          {moment(currentMonth).format('MMMM YYYY')}
        </Typography>
        <motion.div
          className="control-item"
          whileHover={{ scale: 1.5 }}
          whileTap={{ scale: 0.9 }}
          onClick={onNextMonthClick}
        >
          <img src={rightIcon} alt="next month" />
        </motion.div>
      </div>
      <div className="calenderview">
        {moment.weekdaysShort().map((day) => (
          <Typography
            withColor="#344054"
            size={14}
            weight="600"
            color="primary"
            key={day}
          >
            {day}
          </Typography>
        ))}
        {calenderValues.map((item, index) => (
          <div
            className={`day ${
              item.day === selectedDay && item.indicator !== 'disabled'
                ? 'selected'
                : ''
            }`}
            key={`${item.day}-${item.indicator}`}
            onClick={(event) => onCalenderViewDayClick(event, item)}
            role="button"
            tabIndex={index}
          >
            <div className={`item ${item.indicator}`}>
              <Typography
                weight="600"
                size={16}
                color={item.indicator === 'today' ? 'light' : 'primary'}
              >
                {item.day}
              </Typography>
            </div>
          </div>
        ))}
        {stripConfig.map((extendStripTill, index) => (
          <div
            className="strip"
            style={{
              gridColumn: `1 / ${extendStripTill + 1}`,
              gridRow: `${index + 2} / ${index + 3}`,
            }}
          />
        ))}
      </div>
      <div className="indicators">
        {calendarIndicators.map((indicator) => (
          <div
            className="indicator"
            key={indicator.label + JSON.stringify(indicator.style)}
          >
            <div className="color-circle" style={indicator.style} />
            <Typography size={12} weight="400" color="primary">
              {indicator.label}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default DailyOverrideCalenderView;
