import * as React from 'react';
import './VideoRoomParticipant.scss';
import { RemoteTrack } from 'twilio-video';
import VideoRoomParticipantTrack from '../VideoRoomParticipantTrack';

interface IVideoRoomParticipantProps {
  participant: any;
  localParticipant?: boolean;
  previewMode?: boolean;
}

function VideoRoomParticipant({
  participant,
  localParticipant = false,
  previewMode = false,
}: IVideoRoomParticipantProps) {
  const [tracks, setTracks] = React.useState<any>([]);

  const addTrack = (track: RemoteTrack) => {
    setTracks((prev: any) => [track, ...prev]);
  };

  React.useEffect(() => {
    if (localParticipant) {
      const existingPublications = Array.from(participant.tracks.values());
      const existingTracks = existingPublications.map(
        (publication: any) => publication.track,
      );
      const nonNullTracks = existingTracks.filter((track) => track !== null);
      if (nonNullTracks && nonNullTracks.length) {
        setTracks(nonNullTracks);
      }
    } else {
      participant.tracks.forEach((publication: any) => {
        if (publication.isSubscribed) {
          const { track } = publication.track;
          addTrack(track);
        }
      });
    }

    if (!localParticipant) {
      participant.on('trackSubscribed', (track: any) => addTrack(track));
    }
  }, []);

  return (
    <div className="participant">
      {tracks.map((track: any) => (
        <VideoRoomParticipantTrack
          key={track}
          track={track}
          isLocal={localParticipant}
          previewMode={previewMode}
        />
      ))}
    </div>
  );
}

export default VideoRoomParticipant;
