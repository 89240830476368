import * as React from 'react';
import './CheckBox.scss';

interface CheckBoxProps {
  isChecked?: boolean;
  id?: string;
  onChange?: (isChecked: boolean) => void;
  isDisabled?: boolean;
}

function CheckBox({
  isChecked = false,
  onChange,
  isDisabled = false,
  id,
}: CheckBoxProps) {
  const onCheckBoxChange = () => {
    onChange?.(!isChecked);
  };

  return (
    <input
      type="checkbox"
      checked={isChecked}
      onChange={onCheckBoxChange}
      disabled={isDisabled}
      id={id}
    />
  );
}

export default CheckBox;
