import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Typography from '../../components/Typography';
import creditLogo from '../../../assets/credit-logo.svg';
import { displayDateFormat } from '../../../utilities/common/Display';
import './ClientDetailsCard.scss';
import Button from '../../components/Button';
import useToast from '../../../utilities/hooks/useToast';
import { useRequestCreditsQuery } from '../../../utilities/hooks/fetchHooks/provider';

export const availableLanguages = {
  value: 'unlimited',
  label: 'Unlimited',
};

interface IClientDataprops {
  userInfo: { friendlyName: string; userId: string };
  credits: { tier: string; credit: number; canBook: boolean };
  subscription: Array<{ expiresOn: string }>;
  providerMapping: { createdAt: string; scheduledDate: string };
  recentMeeting: { scheduledDate: string; displayDate: string };
}

function ClientDetailsCard({ clientData }: { clientData: IClientDataprops }) {
  // Custom Hook
  const { t } = useTranslation();
  const toast = useToast();

  // Query
  // * Query to request for credits
  const requestCreditsDetails = {
    userId: clientData?.userInfo?.userId,
    creditType: 0,
  };
  const { mutate: requestCredits } = useRequestCreditsQuery(
    clientData?.userInfo?.userId.toString(),
    requestCreditsDetails,
    {
      onSuccess: () => {
        toast.show({
          message: `${t('CREDIT_REQUESTED_NUDGE', {
            clientName: clientData?.userInfo?.friendlyName,
          })}`,
        });
      },
      onError: (error: any) => {
        toast.show({ message: error.message });
        // if (setIsPopUp) {
        //   setIsPopUp(false);
        // }
      },
    },
  );

  // Functions
  // * Function to get Available credits
  const getAvailableCredits = () => {
    const { credits } = clientData;
    const { credit, canBook } = credits;
    const iscreditExhausted = credit === 0;
    const isPoolUser = iscreditExhausted && canBook;
    const isLimitedCreditUser = iscreditExhausted && !canBook;
    if (isPoolUser || !isLimitedCreditUser) {
      return true;
    }
    return false;
  };
  // Component Life Cycle
  React.useEffect(() => {
    if (clientData) {
      const { credits } = clientData;
      const availableCredits = credits.credit;
      if (credits?.tier === '1') {
        availableLanguages.label = t('unlimited');
      } else if (credits?.credit === 0 && credits?.canBook === true) {
        availableLanguages.label = t('creditPool');
      } else if (credits?.credit === 1 && credits?.canBook === true) {
        availableLanguages.label = t('creditWithValue', {
          credit: availableCredits,
        });
      } else if (credits?.credit > 1 && credits?.canBook === true) {
        availableLanguages.label = t('creditsWithValue', {
          credits: availableCredits,
        });
      } else if (credits?.credit === 0 && credits?.canBook === false) {
        availableLanguages.label = t('creditWithValue', {
          credit: availableCredits,
        });
      }
    }
  }, [clientData]);

  return (
    <div className="client-card-container">
      <div className="client-details-container">
        <Typography size={24} weight="500" color="secondary" textAlign="left">
          {clientData?.userInfo?.friendlyName}
        </Typography>
        <Typography size={12} weight="400" color="secondary" textAlign="left">
          {clientData?.recentMeeting && (
            <span>
              {t('lastSession', {
                date: moment(clientData?.recentMeeting?.displayDate).format(
                  displayDateFormat,
                ),
              })}
            </span>
          )}
          &nbsp;&nbsp;&nbsp;
          {t('expiry', {
            date: moment(clientData?.subscription[0]?.expiresOn).format(
              displayDateFormat,
            ),
          })}
        </Typography>
        {!getAvailableCredits() && (
          <Button
            label={t('requestCredits')}
            variant="blue"
            onClick={() => requestCredits()}
          />
        )}
      </div>
      <div className="credits-container">
        <img src={creditLogo} alt="credit-logo" />
        <Typography size={12} weight="500" withColor="#ffffff" textAlign="left">
          {availableLanguages.label}
        </Typography>
      </div>
    </div>
  );
}

export default ClientDetailsCard;
