import { beautifyUrl } from '../../../utilities/common/Path';
import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

const errorMessages = {
  generic: 'Unable to perform helpline action, please try again!',
};

export const getHelplineRecords = async (clientId: string) => {
  const response = await client.doGet(beautifyUrl(Config.api.helpline.getHelplineRecords, [clientId]));
  if (response && response.data && response.data.result) {
    const result = response.data.result[0];
    if (result?.sessions) {
      return result?.sessions;
    }

    return [];
  }

  throw new Error(errorMessages.generic);
};

export const getHelplineDetails = async (sessionId: string) => {
  const response = await client.doGet(beautifyUrl(Config.api.helpline.getHelplineRecordDetails, [sessionId]));
  if (response && response.success) {
    return response.data;
  }

  throw new Error(errorMessages.generic);
};

export const addHelplineRecordComment = async (sessionId: string, comment: string) => {
  const body = { note: comment };
  const response = await client.doPost(beautifyUrl(Config.api.helpline.addHelplineNote, [sessionId]), body);
  if (response && response.success) {
    return true;
  }

  throw new Error(errorMessages.generic);
};