import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import passIcon from '../../../assets/pass.svg';
import failIcon from '../../../assets/fail.svg';
import { passwordValidators } from '../../../shared/constant/password';
import Typography from '../Typography';
import './PasswordStrengthValidator.scss';

interface PasswordStrengthValidatorProps {
  password: string;
  onChange: (isValid: boolean) => void;
  className?: string;
}

type ValidationStage = 'notValidated' | 'failed' | 'passed';

const validate = (
  value: string,
  pattern: RegExp,
  validateWhen: number,
): ValidationStage => {
  if (value?.length > validateWhen) {
    const isPassed = pattern.test(value);
    if (isPassed) {
      return 'passed';
    }

    return 'failed';
  }

  return 'notValidated';
};

function PasswordStrengthValidator({
  password,
  onChange,
  className,
}: PasswordStrengthValidatorProps) {
  const validatorStyles: Record<ValidationStage, string> = {
    passed: 'text-green-700',
    failed: 'text-red-500',
    notValidated: 'ml-4 text-gray',
  };

  const { t } = useTranslation();

  React.useEffect(() => {
    const isValid = passwordValidators.every(({ pattern }) =>
      pattern.test(password),
    );
    onChange(isValid);
  }, [onChange, password]);

  const validatorIcons: Record<ValidationStage, string> = {
    passed: passIcon,
    failed: failIcon,
    notValidated: '',
  };

  return (
    <section className={`validation-strength-wrapper ${className}`}>
      <Typography size={12}>{t('PWD_CONDITION_SPLIT1')}</Typography>
      {passwordValidators.map(
        ({ label, pattern, noOfCharactersRequiredForValidation }) => {
          const validationStatus = validate(
            password,
            pattern,
            noOfCharactersRequiredForValidation,
          );
          return (
            <motion.section
              layout
              className="validation-content-wrapper"
            >
              {validatorIcons[validationStatus] && (
                <img
                  src={validatorIcons[validationStatus]}
                  alt={validationStatus}
                  className="validation-icon"
                />
              )}
              <span
                key={label}
                className={`validation-text ${validatorStyles[validationStatus]}`}
              >
                {t(label)}
              </span>
            </motion.section>
          );
        },
      )}
    </section>
  );
}

export default PasswordStrengthValidator;
