/* eslint-disable react/prop-types */
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { CssSize } from '../BaseCard/BaseCard.d';

interface LineChartProps {
  data?: any;
  props?: any;
  width?: CssSize;
  height?: CssSize;
}

export default function LineChart({
  data,
  props,
  width,
  height,
}: LineChartProps) {
  const options = {
    maintainAspectRatio: false,
    borderColor: '#33BB7C',
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: true,
        yAlign: 'bottom',
        callbacks: {
          title: (()=> ''),
          label: (tooltipItem: any) => (tooltipItem[tooltipItem.datasetIndex]),
        },
      },
    },
    scales: {
      x: { grid: { display: false, drawBorder: false } },
      y: {
        grid: { drawBorder: false, borderDash: [8, 4], color: '#F1F1F5' },
        beginAtZero: true,
        min: 0,
        max: props?.max,
        ticks: {
          stepSize: props?.stepSize,
        },
      },
    },
    elements: {
      line: { tension: 0.4 },
    },
    ...(props || {}),
  };
  return <Line data={data} options={options} width={width} height={height} />;
}
