import React from 'react';
import RadioGroup from '../../../../components/RadioGroup';
import Typography from '../../../../components/Typography';
import { IFollowUpSessionsFormSections } from '../../FollowUpSession.type';
import './RiskAssessments.scss';
import FollowUpSessionCaseNotesControls from '../../FollowUpSessionCaseNotes.controls';
import { IOptions } from '../../../../../shared/types/CaseNotes';
import Heading from '../../Heading';

function RiskAssessment({
  watch,
  setValue,
  errorMessage,
  trackOnBlur,
  trackOnFocus,
  riskAssessmentOptions,
  question,
  hideTag = false,
}: IFollowUpSessionsFormSections & { riskAssessmentOptions: IOptions[], hideTag?: boolean }) {
  const mappedOpt = React.useMemo(
    () =>
      FollowUpSessionCaseNotesControls.getMappedOptionsWithId(
        riskAssessmentOptions,
      ),
    [riskAssessmentOptions],
  );
  const handleChange = (value: string) => {
    if (setValue) {
      setValue('riskAssessment', mappedOpt[value]);
    }
  };
  return (
    <>
      <Heading toolTipID="1" headerText={question?.question} hideTooltipChips={hideTag} />
      <div className="risk-assessments-field">
        <RadioGroup
          name="riskAssessment"
          onChange={handleChange}
          values={riskAssessmentOptions}
          selectedValue={watch('riskAssessment')?.id?.toString()}
          customCss
          highlightColor="rgba(208, 213, 221, 0.17)"
          highlightOnSelect
          onBlur={() => trackOnBlur?.(question?.id)}
          onFocus={trackOnFocus}
        />
        {errorMessage && (
          <Typography size={14} color="error">
            {errorMessage}
          </Typography>
        )}
      </div>
    </>
  );
}

export default RiskAssessment;
