import * as React from 'react';
import { scrollToBottom } from '../../../../utilities/common/UserExperience';


function useInfiniteScroll(onIntersect: () => void, callBackDep:Array<any>, observerDep:Array<any>) {
  const chatContainerRef = React.useRef<HTMLDivElement>(null);
  const topMessageElementRef = React.useRef<HTMLDivElement>(null);
  const loadMoreChats = React.useCallback(
    (entries) => {
      const lastEntry = entries[0];
      if (lastEntry.isIntersecting ) {
        onIntersect();
      }
    },
    [onIntersect, ...callBackDep],
  );

  React.useEffect(() => {
    let observer:IntersectionObserver;
    if (chatContainerRef.current) {
      scrollToBottom(chatContainerRef);
      observer = new IntersectionObserver(loadMoreChats, {
        root: chatContainerRef.current,
        threshold: 1.0,
      });

      if (topMessageElementRef.current) {
        observer.observe(topMessageElementRef.current);
      }
    }
    return ()=>{
      if (topMessageElementRef.current) {
        observer?.unobserve(topMessageElementRef.current);
      }
    };
  }, [chatContainerRef.current, topMessageElementRef.current, ...observerDep]);

  return { chatContainerRef, topMessageElementRef };
}

export default useInfiniteScroll;
