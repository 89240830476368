import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFetch, useMutation } from '../useFetch';
import {
  getClientTraitDetails,
  getPHQCardDetails,
} from '../../../shared/service/API/personalInsights';
import useToast from '../useToast';
import {
  IGetTraitReq,
  IGetTraitRes,
  IPHQRes,
  ISendAssignmentToClients,
} from './types';
import {
  assignToClients,
  sendAssignmentReminder,
} from '../../../shared/redux/actions/assignment';

function usePersonalInsights(uniqueClientId?:string) {
  const { t } = useTranslation();
  const toast = useToast();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [traitData, setTraitData] = React.useState<IGetTraitRes>(
    {} as IGetTraitRes,
  );
  const [disableEmptyStateAction, setDisableEmptyStateAction] =
    React.useState<boolean>(false);

  const { mutate: getTraits, isLoading: isTraitsDataLoading } = useMutation(
    ({ payload, userId }: { payload: IGetTraitReq; userId: string }) =>
      getClientTraitDetails(payload, userId),
    {
      onSuccess: (data) => {
        setTraitData(data?.data);
      },
      onError: (errorData) => {
        toast.show({ message: errorData.message });
      },
    },
  );

  const { mutate: sendRecommendedAssignment, isLoading: isAssignmentSent } =
    useMutation(
      ({
        assignmentType,
        assignment,
        clientId,
        note,
      }: ISendAssignmentToClients) =>
        dispatch(assignToClients(assignmentType, assignment, [clientId], note)),
      {
        onSuccess: (data) => {
          toast.show({
            message: data?.assigned
              ? t('SUCCESSFUL_PROMPT', { user: state?.user?.friendlyName })
              : t('ALREADY_ASSIGNED'),
          });
        },
        onError: () =>
          toast.show({
            message: t('FAILED_PROMPT', { user: state?.user?.friendlyName }),
          }),
      },
    );

  const { mutate: sendReminder } = useMutation(
    ({ assignmentId, clientId }: { assignmentId: string; clientId: string }) =>
      dispatch(
        sendAssignmentReminder(clientId?.toString() || '', assignmentId),
      ),
    {
      onSuccess: (response: string) => {
        if (response === 'success') {
          toast.show({ message: t('sentReminder') });
          setDisableEmptyStateAction(true);
        }
      },
      onError: (error: Error) => {
        toast.show({ message: error.message });
      },
    },
  );

  const { data: phqData, isLoading: isPHQDataLoading } = useFetch<IPHQRes>(
    ['phqRes', uniqueClientId || state?.userId],
    () => getPHQCardDetails(uniqueClientId || state?.userId ),
    {
      refetchOnWindowFocus: false,
      enabled:!!(uniqueClientId || state?.userId),
    },
  );

  return {
    getTraits,
    isTraitsDataLoading,
    traitData,
    sendRecommendedAssignment,
    isAssignmentSent,
    sendReminder,
    disableEmptyStateAction,
    phqData,
    isPHQDataLoading,
  };
}
export default usePersonalInsights;
