import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import './CancellationReasons.scss';
import { motion } from 'framer-motion';
import Typography from '../../components/Typography';
import { ICancellationReasons, ISelectedReason } from './types';
import { ProviderCancellationReasons } from '../../../shared/constant/CancellationReasons';
import ClickableCard from '../../components/ClickableCard';
import Button from '../../components/Button';
import Banner from '../../components/Banner';
import { useproviderClientDataQuery } from '../../../utilities/hooks/fetchHooks/provider';

function CancellationReasons({
  header,
  subHeader,
  handleContinueClick,
  onOtherReason,
  sessionData,
}: ICancellationReasons) {
  const { t } = useTranslation();
  const { userId, providerRole } = sessionData;
  const { data: clientDetails } = useproviderClientDataQuery(
    Number(userId),
    providerRole,
  );
  const [selectedReason, setSelectedReason] = React.useState<ISelectedReason>({
    reasonCode: '',
    reasonDesc: '',
  });
  const [otherReasonValue, setOtherReasonValue] = React.useState<string>('');
  const handleReasonCLick = (data: ISelectedReason) =>
    setSelectedReason({
      reasonCode: data.reasonCode,
      reasonDesc: data.reasonDesc,
    });
  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onOtherReason(e.target.value);
    setOtherReasonValue(e.target.value);
  };
  const isSelectedReasonEmpty = Object.values(selectedReason).every(
    (item) => item === '',
  );

  const availableDuration = clientDetails?.duration
    ?.map((time: string) => parseInt(time, 10))
    .sort((a: number, b: number) => b - a);

  const currentMeetingDuration = moment
    .unix(Number(sessionData.scheduledEndTime))
    .diff(moment.unix(Number(sessionData.scheduledStartTime)), 'second');

  const durationExtensionError = React.useMemo(() => {
    if (
      selectedReason.reasonCode ===
        ProviderCancellationReasons.CANCEL_REASON_PROVIDER_CHANGE_DURATION &&
      currentMeetingDuration === availableDuration?.[0]
    ) {
      return true;
    }
    return false;
  }, [selectedReason, availableDuration, currentMeetingDuration]);
  
  return (
    <article className="cancellation-wrappper">
      <section className="cancellation-header">
        <Typography size={22} weight="600" withColor="#101828">
          {header}
        </Typography>
        <Typography size={16} color="secondary">
          {subHeader}
        </Typography>
      </section>
      <section className="cancellation-body">
        {Object.keys(ProviderCancellationReasons).map((reason) => (
          <ClickableCard
            content={t(reason)}
            selected={
              selectedReason.reasonCode === ProviderCancellationReasons[reason]
            }
            cardContentWidth="100%"
            onClick={() =>
              handleReasonCLick({
                reasonCode: ProviderCancellationReasons?.[reason],
                reasonDesc: reason,
              })
            }
            uniqueKey={reason}
            borderRadius="48px"
            height="48px"
            fontSize={16}
            key={ProviderCancellationReasons?.[reason]}
          />
        ))}
        {selectedReason.reasonCode ===
          ProviderCancellationReasons.CANCEL_REASON_PROVIDER_OTHERS && (
          <motion.textarea
            onChange={onInputChange}
            placeholder={t('OTHERS_REASON_SUBTEXT')}
            className="other-reason-input"
            initial={{ y: -20, opacity: 0 }}
            value={otherReasonValue}
            animate={{
              y: 0,
              opacity: 1,
              transition: { duration: 0.4 },
            }}
            exit={{
              y: -20,
              opacity: 0,
              transition: { duration: 0.6 },
            }}
          />
        )}
        {durationExtensionError && <Banner
          primaryContent={t('DURATION_EXTENSION_ERROR')}
          onClick={()=>{}}
          buttonLabel=''
          bannerVariant='error'
        />}
        <Button
          label={t('continue')}
          variant="blue"
          onClick={() => handleContinueClick(selectedReason?.reasonCode)}
          width="100%"
          height="48px"
          fontSize={14}
          disabled={
            isSelectedReasonEmpty ||
            (selectedReason.reasonCode ===
              ProviderCancellationReasons.CANCEL_REASON_PROVIDER_OTHERS &&
              !otherReasonValue.replace(/\s/g, '').length)
              || durationExtensionError
          }
        />
      </section>
    </article>
  );
}

export default CancellationReasons;
