import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import './ChangeLanguagePopup.scss';

interface ChangeLanguagePopupProps {
  onClose: () => void;
  onChangeLanguage: () => void;
  selectedLang: string;
}

function ChangeLanguagePopup({ onClose, selectedLang, onChangeLanguage }: ChangeLanguagePopupProps) {
  const { t } = useTranslation();
  return (
    <Popup withBackdrop onClose={onClose}>
      <div className="change-lang-popup">
        <div className="contents">
          <Typography size={20} weight="600">
            {t('changeLanguage')}
          </Typography>
          <Typography color="secondary" size={14} weight="400">
            {t('changeLangConfirmation', { lang: selectedLang })}
          </Typography>
        </div>
        <div className="actions">
          <Button variant="secondary" label={t('no')} onClick={onClose} />
          <Button variant="primary" label={t('yes')} onClick={onChangeLanguage} />
        </div>
      </div>
    </Popup>
  );
}

export default ChangeLanguagePopup;