import * as React from 'react';
import Popup from '../Popup';
import './SuccessPopup.scss';
import greenTickIcon from '../../../assets/green-tick.png';
import Typography from '../Typography';

interface SuccessPopupProps {
  heading: string;
  description: string;
  onClose: () => void;
}

function SuccessPopup({ heading, description, onClose }: SuccessPopupProps) {
  return (
    <Popup withBackdrop useDefaultPadding={false} onClose={onClose}>
      <div className="operation-success">
        <img src={greenTickIcon} alt="success" />
        <Typography size={24} weight="600">
          {heading}
        </Typography>
        <Typography color="secondary" size={16} weight="400">
          {description}
        </Typography>
      </div>
    </Popup>
  );
}

export default SuccessPopup;
