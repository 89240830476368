// TODO need to be removed in future
import moment from 'moment';
import {
  getBillingDetails,
  getBillingListing,
  getBillingSummary,
} from '../../../shared/service/API/billing';
import {
  BillingSummaries,
  IBills,
} from '../../../shared/types/response/provider';
import { useFetch } from '../useFetch';
import { IBillingRes } from '../../../view/screens/BillingDetails/types';
import { BillingMonthFormat, NewBillingMonthFormat } from '../../common/Date';

export const useBillingDetails = (day: string) =>
  useFetch<IBills>(['bills', 'day', day], () => getBillingDetails(day), {});

export const useBillingListing = (month: string) => {
  const formattedMonth = moment(month, BillingMonthFormat).format(NewBillingMonthFormat);
  return useFetch<BillingSummaries>(['bills', 'month', month], () =>
    getBillingListing(formattedMonth),
  );
};

export const useBillingInfo = (month: string, sessionType: number[]) => {
  const formattedMonth = moment(month, 'MMMM YYYY').format('YYYY/MM');
  return useFetch<IBillingRes>(
    ['billSummary', sessionType, month],
    () => getBillingSummary(formattedMonth, sessionType),
    {
      refetchOnWindowFocus: false,
    },
  );
};
