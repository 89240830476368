import moment from 'moment';
import { MessageContentTypes } from '../../shared/constant/Chat';
import { IChatItem, IMessageItem } from '../../shared/types/Chat';

export const getLastMessagedTime = (chatItem: IChatItem) => {
  if (
    !(
      chatItem.messages &&
      chatItem.messages.items &&
      chatItem.messages.items.length
    )
  )
    return moment().format();
  const { timestamp } =
    chatItem.messages.items[chatItem.messages.items.length - 1].state;

  return timestamp;
};

export const getLatestMessage = (chatItem: IChatItem | undefined) => {
  if (
    chatItem &&
    chatItem.messages &&
    chatItem.messages.items &&
    chatItem.messages.items.length
  ) {
    return chatItem.messages.items[chatItem.messages.items.length - 1].state
      .sid;
  }
  return '';
};

export const getLastMessageIndex = (chatItem: IChatItem) => {
  const messageItems = chatItem.messages.items;
  if (!messageItems.length) return 0;
  const lastMessage = messageItems[messageItems.length - 1];
  return lastMessage.state.index;
};

export const getMessageContentType = (message: IMessageItem) => {
  const { state } = message;
  const { type, media, attributes } = state;

  if (attributes?.messageType === 'assignment' && attributes.assignmentId) {
    return MessageContentTypes.assignment;
  }
  if (type === 'text') {
    return MessageContentTypes.text;
  }
  if (media && type === 'media') {
    const { contentType } = media;
    if (contentType.includes('image')) {
      return MessageContentTypes.image;
    }
    if (contentType.includes('audio')) {
      return MessageContentTypes.audio;
    }
    if (contentType.includes('assignment')) {
      return MessageContentTypes.assignment;
    }
    if (contentType.includes('cta')) {
      return MessageContentTypes.cta;
    }
  }

  return null;
};
