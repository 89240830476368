import * as React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';

//* RQ devtools are only included in dev bundles,
//* so no need to worry about excluding RQ devtools in production build
function FetchDevtools() {
  return (
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  );
}

export default FetchDevtools;
