import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../../../i18n/utilities';
import {
  getAssignmentById,
  sendAssignmentReminder,
} from '../../../../shared/redux/actions/assignment';
import { ClientAssignment } from '../../../../shared/types/response/assignment';
import { getImageUri } from '../../../../utilities/common/Display';
import { getAssignmentProgressData } from '../../../../utilities/common/Provider';
import { useFetch, useMutation } from '../../../../utilities/hooks/useFetch';
import useToast from '../../../../utilities/hooks/useToast';
import Button from '../../../../view/components/Button';
import Loader from '../../../../view/components/Loader';
import Typography from '../../../../view/components/Typography';
import Tags from '../../../../view/app-components/Tags';
import './AssignmentMessageItem.scss';

interface IAssignmentMessageItemProps {
  assignmentContent: string;
  clientId: number;
}

function AssignmentMessageItem({
  assignmentContent,
  clientId,
}: IAssignmentMessageItemProps) {
  const { assignmentId = '' } = JSON.parse(assignmentContent);
  const { t } = useTranslation();
  const toast = useToast();
  const [reminderSent, setReminderSent] = React.useState<boolean>(false);

  const { isLoading, data } = useFetch<ClientAssignment>(
    ['get-assignment', assignmentId],
    getAssignmentById(assignmentId),
    {
      initialData: {
        title: '',
        mainImage: '',
        translationKeys: {},
      } as ClientAssignment,
    },
  );

  const { mutate: sendReminder } = useMutation(
    sendAssignmentReminder(clientId?.toString(), assignmentId),
    {
      onSuccess: (response: string) => {
        if (response === 'success') {
          toast.show({ message: t('sentReminder') });
          setReminderSent(true);
        }
      },
      onError: (error: Error) => {
        let errMsg = error.message;
        if (error.cause === 422) errMsg = t('alreadySentReminder');
        toast.show({ message: errMsg });
      },
    },
  );

  const {
    title,
    mainImage,
    translationKeys,
    assignmentCategory,
    referenceType,
    notes,
    status,
  } = data as ClientAssignment;

  const assignmentProgress = getAssignmentProgressData(status) || {};

  return (
    <div className="assignment-message">
      {isLoading && <Loader useFullPage={false} withBackdrop={false} />}
      <div className="assignment-image-container">
        <img src={getImageUri(mainImage)} alt="assignment" />
      </div>
      {assignmentCategory && (
        <Tags variant="pill" textSize={10} tags={assignmentCategory} />
      )}
      <div className="assignment-msg-contents">
        <Typography size={14} weight="600">
          {referenceType === 'custom'
            ? title
            : getTranslation(title, translationKeys)}
        </Typography>
        {notes && (
          <div className="msg-notes">
            <Typography weight="400" size={12} withItalics>
              Notes:
            </Typography>
            <Typography weight="400" size={12} withItalics>
              {notes}
            </Typography>
          </div>
        )}
        {assignmentProgress.cta && (
          <Button
            width="100%"
            variant="blue"
            label={t(assignmentProgress.cta)}
            onClick={() => sendReminder()}
            disabled={reminderSent}
          />
        )}
      </div>
    </div>
  );
}

export default AssignmentMessageItem;
