import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import Popup from '../../components/Popup';
import TextBox from '../../components/TextBox';
import Typography from '../../components/Typography';
import './CreateCustomAssignmentPopup.scss';
import selectFileIcon from '../../../assets/select-file.svg';
import ToggleSwitch from '../../components/ToggleSwitch';
import Button from '../../components/Button';
import { isValidCustomAssignmentFile, isValidURL } from '../../../utilities/common/Validators';
import { useFetch, useMutation } from '../../../utilities/hooks/useFetch';
import { createCustomAssignment } from '../../../shared/redux/actions/assignment';
import CircularProgressBar from '../../components/CircularProgressBar';
import Loader from '../../components/Loader';
import AssignToClientsPopup from '../AssignToClientsPopup';
import { getClientsForAssignment } from '../../../shared/redux/actions/provider';
import useTracking from '../../../utilities/hooks/useTracking';
import { EventNames, EventActions, EventCategories } from '../../../shared/constant/Analytics';
import useToast from '../../../utilities/hooks/useToast';
import { IClientSummary } from '../../../shared/types/response/provider';

interface CreateCustomAssignmentPopupProps {
  onClose: () => void;
  existingData?: {
    id: string;
    title: string;
    filename: string;
    fileURL: string;
  };
}

export type CustomAssignmentType = 'file' | 'url';

function CreateCustomAssignmentPopup({ onClose, existingData = { id: '', title: '', filename: '', fileURL: '' } }: CreateCustomAssignmentPopupProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { track } = useTracking();
  const toast = useToast();
  const [assignmentTitle, setAssignmentTitle] = React.useState<string>('');
  const [fileUploadProgress, setFileUploadProgress] = React.useState<number>(0);
  const [selectedFile, setSelectedFile] = React.useState<File | undefined>(undefined);
  const [linkUrl, setLinkUrl] = React.useState<string>('');
  const [assignmentType, setAssignmentType] = React.useState<CustomAssignmentType>('file');
  const [assignmentData, setAssignmentData] = React.useState<string>('');
  const [showAssignPopup, setShowAssignPopup] = React.useState<boolean>(false);
  const fileDropZoneRef = React.useRef<HTMLDivElement>(null);
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const assignmentTitleRef = React.useRef<string>('');
  const fileUploadProgressRef = React.useRef<number>(fileUploadProgress); // to counter stale-closure
  const isReadyOnly = Boolean(existingData?.title);
  let uploadProgressInterval: ReturnType<typeof setInterval> | null = null;
  let isFileFullyUploaded = fileUploadProgress === 100;

  const onProceedToAssignClients = () => {
    setShowAssignPopup(true);
  };

  // logic for next popup - store assignment data in state, in the button validation, add validation check for assignment data
  const { isLoading, mutate: createNewCustomAssignment } = useMutation(
    () => dispatch(createCustomAssignment(assignmentType, assignmentTitle, selectedFile, linkUrl)),
    {
      onSuccess: (customAssignmentId: string) => {
        setFileUploadProgress(100);
        setAssignmentData(customAssignmentId);
        if (uploadProgressInterval) {
          clearInterval(uploadProgressInterval);
        }

        if (assignmentType === 'file') {
          track(EventNames.providerWebCustomAssignment, {
            eventAction: EventActions.click,
            eventCategory: EventCategories.uploadFile,
            eventLabel: 'upload_a_file',
          });
        }

        if (assignmentType === 'url') {
          onProceedToAssignClients();
        }
      },
    },
  );

  const fillExistingAssignmentData = () => {
    if (isReadyOnly) {
      const { id, title, filename, fileURL } = existingData || {};
      setAssignmentData(id);
      setAssignmentTitle(title || '');
      if (filename) {
        const dummyFile = new File([''], filename || '');
        setAssignmentType('file');
        isFileFullyUploaded = true;
        setSelectedFile(dummyFile);
      } else {
        setAssignmentType('url');
        setLinkUrl(fileURL);
      }
    }
  };


  const { data: clientsData } = useFetch<IClientSummary[]>(['clients'], () => dispatch(getClientsForAssignment()), { initialData: [] });

  const setFile = (files: FileList) => {
    if (files.length === 1) {
      const isValidFile = isValidCustomAssignmentFile(files[0]);
      if (isValidFile) {
        setSelectedFile(files[0]);
      }
    }
  };

  const handleFileDragOver = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleFileDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!assignmentTitleRef.current) {
      toast.show({ message: t('ASSIGNMENT_NAME_ERROR') });
    } else if (e.dataTransfer) {
      const { files } = e.dataTransfer;
      setFile(files);
    }
  };

  const handleDropZoneClick = (assignmentName: string) => {
    if (!assignmentName) {
      toast.show({ message: t('ASSIGNMENT_NAME_ERROR') });
    } else {
      inputFileRef.current?.click();
    }
  };

  const onInputFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setFile(event.target.files);
    }
  };

  const onLinkToggle = (isChecked: boolean) => {
    if (isChecked) {
      setAssignmentType('url');
    } else {
      setAssignmentType('file');
    }
  };

  const onContinueSelectClientsClick = () => {
    if (assignmentType === 'url') {
      if (!isReadyOnly) {
        createNewCustomAssignment();
      } else {
        onProceedToAssignClients();
      }
    } else if (assignmentType === 'file') {
      onProceedToAssignClients();
    }

    track(EventNames.providerWebCustomAssignment, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.continueToSelectClients,
      eventLabel: 'continue_to_select_clients',
    });
  };

  const isValidCustomAssignment = () => {
    if (!assignmentTitle) return false;
    if (assignmentType === 'file' && !isReadyOnly && !isFileFullyUploaded) return false;
    if (assignmentType === 'url' && !isValidURL(linkUrl)) return false;

    return true;
  };


  const handleAssignmentTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    assignmentTitleRef.current = e.target.value.trimStart();
    const regex = /^[^a-zA-Z0-9-]/;
    if (
      regex.test(assignmentTitleRef.current) &&
      assignmentTitleRef.current.length === 1
    ) {
      e.preventDefault();
      return;
    }
    setAssignmentTitle(e.target.value.trimStart());
  };

  React.useEffect(() => {
    fileDropZoneRef.current?.addEventListener('dragover', handleFileDragOver);
    fileDropZoneRef.current?.addEventListener('drop', handleFileDrop);

    fillExistingAssignmentData();

    return () => {
      fileDropZoneRef.current?.removeEventListener('dragover', handleFileDragOver);
      fileDropZoneRef.current?.removeEventListener('drop', handleFileDrop);
    };
  }, []);

  React.useEffect(() => {
    if (selectedFile && !isReadyOnly) {
      // * progress logic -> increments 15% for every 1sec,
      // * will make the progress 100% on API success callback
      // TODO extract faking logic to custom hook
      uploadProgressInterval = setInterval(() => {
        if (fileUploadProgressRef.current < 90) {
          const newProgress = fileUploadProgressRef.current + 15;
          fileUploadProgressRef.current = newProgress;
          setFileUploadProgress(newProgress);
        }
      }, 1000);
      createNewCustomAssignment();
    }

    return () => {
      if (uploadProgressInterval) {
        clearInterval(uploadProgressInterval);
      }
    };
  }, [selectedFile]);

  const renderFileDropZone = () => {
    if (isFileFullyUploaded || isReadyOnly) {
      return (
        <motion.div
          className="file-upload-drop-zone uploaded-file"
          ref={fileDropZoneRef}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
          }}
        >
          <Typography size={12} weight="400">
            {selectedFile?.name}
          </Typography>
        </motion.div>
      );
    }

    if (selectedFile) {
      return (
        <div
          className="file-upload-drop-zone"
          role="button"
          tabIndex={0}
          ref={fileDropZoneRef}
          onClick={() => handleDropZoneClick(assignmentTitle)}
        >
          <CircularProgressBar progressValue={fileUploadProgress} />
          <Typography size={12} weight="500">
           {t('UPLOADING_TEXT')}
          </Typography>
        </div>
      );
    }

    return (
      <div
        className="file-upload-drop-zone"
        role="button"
        tabIndex={0}
        ref={fileDropZoneRef}
        onClick={() => handleDropZoneClick(assignmentTitle)}
        >
        <input
          type="file"
          ref={inputFileRef}
          onChange={onInputFileChange}
        />
        <img src={selectFileIcon} alt="select file" />
        <Typography size={12} weight="500" color="secondary" textAlign="center">
          {t('PLACEHOLDER_UPLOAD_FILE')}
        </Typography>
      </div>
    );
  };

  const renderAssignmentForm = () => {
    if (assignmentType === 'url') {
      return (
        <TextBox
          value={linkUrl}
          variant="box-border"
          animate={false}
          placeholder={t('PLACEHOLDER_LINK')}
          onChange={(e) => setLinkUrl(e.target.value)}
          disabled={isReadyOnly}
          showValidationMark={Boolean(linkUrl)}
          isValidInput={Boolean(linkUrl) && isValidURL(linkUrl)}
          errorMessage={Boolean(linkUrl) && !isValidURL(linkUrl) ? t('MISSING_HTTPS') : ''}
        />
      );
    }

    return renderFileDropZone();
  };

  return (
    <Popup
      onClose={onClose}
      withBackdrop
      useDefaultPadding={false}
      backdropMode="glass"
      show={!showAssignPopup}
    >
      <div className="create-ca-container">
        {isLoading
          && assignmentType === 'url'
          && (
            <Loader useFullPage />
          )}
        {showAssignPopup && assignmentData && (
          <AssignToClientsPopup
            assignmentData={{ entityId: assignmentData, type: 'custom' }}
            assignmentType="custom"
            clientsData={clientsData || []}
            onClose={() => onClose()}
          />
        )}
        <div className="create-ca-configs">
          <div className="ca-header">
            <Typography size={18} weight="500">
              {t('CUSTOM_ASSIGNMENT')}
            </Typography>
          </div>
          <div className="ca-form">
            <div className="ca-form-field">
              <div className="field-header">
                <div className="field-header-item label">
                  <Typography size={12} weight="400">
                    {t('ASSIGNMENT_NAME')}
                  </Typography>
                  <Typography size={12} weight="400" color="error">
                    &nbsp;*
                  </Typography>
                </div>
              </div>
              <TextBox
                value={assignmentTitle}
                variant="box-border"
                animate={false}
                placeholder={t('PLACEHOLDER_ASSIGNMENT_NAME')}
                onChange={handleAssignmentTitle}
                disabled={isReadyOnly || isFileFullyUploaded}
              />
            </div>
            <div className="ca-form-field">
              <div className="field-header">
                <div className="field-header-item label">
                  <Typography size={12} weight="400">
                    {t('UPLOAD_FILE_LINK')}
                  </Typography>
                  <Typography size={12} weight="400" color="error">
                    &nbsp;*
                  </Typography>
                </div>
                <div className="field-header-item">
                  <Typography size={12} weight="400" color={isReadyOnly ? 'disabled' : 'primary'}>
                    {t('LINK_TOGGLE')}
                  </Typography>
                  <ToggleSwitch
                    id="assignment-type"
                    initialValue={assignmentType === 'url'}
                    onChange={onLinkToggle}
                    disabled={isReadyOnly}
                  />
                </div>
              </div>
              {renderAssignmentForm()}
            </div>
          </div>
        </div>
        <div className="ca-action-container">
          <Button
            width="100%"
            height="40px"
            label={t('CONTINUE_CLIENT_CTA')}
            variant="primary"
            disabled={!isValidCustomAssignment()}
            onClick={() => onContinueSelectClientsClick()}
          />
        </div>
      </div>
    </Popup>
  );
}

export default CreateCustomAssignmentPopup;

