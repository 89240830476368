export const passwordValidators = [
  {
    label: 'PWD_CONDITION_SPLIT7',
    pattern: /^\S+$/,
    noOfCharactersRequiredForValidation: 0,
  },
  {
    label: 'PWD_CONDITION_SPLIT2',
    pattern: /^.{10,}$/,
    noOfCharactersRequiredForValidation: 0,
  },
  {
    label: 'PWD_CONDITION_SPLIT6',
    pattern: /[0-9]/,
    noOfCharactersRequiredForValidation: 0,
  },
  {
    label: 'PWD_CONDITION_SPLIT4',
    pattern: /[a-z]/,
    noOfCharactersRequiredForValidation: 0,
  },
  {
    label: 'PWD_CONDITION_SPLIT5',
    pattern: /[A-Z]/,
    noOfCharactersRequiredForValidation: 0,
  },
  {
    label: 'PWD_CONDITION_SPLIT3',
    pattern: /[!@#$%^&*()_+[\]{};':"\\|,.<>?/~`-]/,
    noOfCharactersRequiredForValidation: 0,
  },
];
