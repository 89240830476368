import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import { getCurrentLocale } from '../../../i18n';
import './Settings.scss';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';
import useTriggerSettings from '../../../utilities/hooks/useTriggerSettings/useTriggerSettings';
import { IMessageTemplateProps, TriggerType } from './types';
import Card from '../../components/ClickableCard';
import Loader from '../../components/Loader';
import MessageTemplate from '../../components/MessageTemplate';
import Banner from '../../components/Banner';
import CustomTranslationEditor from '../../components/CustomTranslationEditor';
import eyeOff from '../../../assets/eye-off.svg';
import alertCircleIcon from '../../../assets/alert-circle.svg';

function Settings() {
  const { t } = useTranslation();
  const { track } = useTracking();
  const currentLanguage = getCurrentLocale();
  const loaderParentRef = React.useRef<HTMLElement>(null);
  const [selectedTrigger, setSelectedTrigger] = React.useState<TriggerType>(
    {} as TriggerType,
  );
  const [isBannerVisible, setIsBannerVisible] = React.useState<boolean>(true);
  const customMsgSave = () => {
    setIsBannerVisible(true);
  };
  const {
    triggersData,
    isLoading,
    updateTriggerStatus,
    updateDefaultMsgTemplate,
    customMsgList,
    getCustomMsgList,
    isCustomMsgListLoading,
  } = useTriggerSettings(customMsgSave);

  function handleTrigger(trigger: TriggerType) {
    setSelectedTrigger(trigger);
    getCustomMsgList(trigger.id);
    track(EventNames.automated_messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.trigger,
      eventLabel: 'trigger',
      featureVersion: 'v1',
    });
  }

  React.useEffect(() => {
    if (triggersData && Object.keys(selectedTrigger)?.length > 0) {
      const [newSelectedTriggerData] = triggersData.triggers.filter(
        (trigger) => trigger.id === selectedTrigger.id,
      );
      setSelectedTrigger((prev) => ({
        ...prev,
        ...newSelectedTriggerData,
      }));
    } else if (triggersData) {
      setSelectedTrigger(triggersData?.triggers?.[0]);
      getCustomMsgList(triggersData?.triggers?.[0]?.id);
    }
  }, [triggersData?.triggers, isLoading]);
  React.useEffect(() => {
    // For instrumentation only
    if (!isLoading) {
      track(EventNames.settings, {
        eventAction: EventActions.load,
        eventCategory: EventCategories.settings,
        eventLabel: 'settings',
        featureVersion: 'v1',
      });
    }
  }, [isLoading]);

  const handleToggle = (
    selectedData: IMessageTemplateProps['selectedTrigger'],
  ) => {
    updateTriggerStatus({
      triggerId: selectedData?.id,
      // isActive: selectedData?.isActive,
      customEnabled: selectedData?.customEnabled,
    });
    track(EventNames.automated_messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.message_template,
      eventLabel: selectedData?.customEnabled ? 'toggle_on' : 'toggle_off',
      featureVersion: 'v1',
    });
  };

  const handleSaveBtnClick = (
    selectedData: IMessageTemplateProps['selectedTrigger'],
    templateData: string,
  ) => {
    updateDefaultMsgTemplate({
      payload: {
        template: encodeURI(templateData),
        languageId: selectedData?.languageId,
      },
      triggerId: selectedData?.id,
    });
    track(EventNames.automated_messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.message_template,
      eventLabel: 'save',
      featureVersion: 'v1',
    });
  };

  const otherLanguageMsgs = React.useMemo(() => {
    const filtered =
      customMsgList?.filter((item) => item.languageId !== currentLanguage) ||
      [];
    setIsBannerVisible(!filtered.length);
    return filtered;
  }, [currentLanguage, customMsgList]);

  const supportedLanguages = React.useMemo(
    () =>
      triggersData?.languages.filter((lang) => lang !== currentLanguage) || [],
    [currentLanguage, triggersData?.languages],
  );

  if (isLoading) {
    return <Loader withBackdrop={false} useFullPage />;
  }

  return (
    <article className="settings-container">
      <section>
        <Typography weight="500" color="primary" size={24}>
          {t('AUTOMATED_MSG')}
        </Typography>
        <Typography weight="400" color="grey" size={16}>
          {t('AUTOMATED_MSG_DESCRIPTION')}
        </Typography>
      </section>
      <section className="settings-body">
        <section className="settings-header">
          <section className="trigger-section-header">
            <Typography weight="500" color="rhino" size={12}>
              {t('PROMPT')}
            </Typography>
          </section>
          <section className="template-section-header">
            <Typography weight="500" color="rhino" size={12}>
              {t('MESSAGE_TEMPLATE_HEADER')}
            </Typography>
          </section>
        </section>
        <section className="trigger-template-section">
          <section className="trigger-section">
            {triggersData?.triggers?.map((trigger) => (
              <Card
                key={trigger.id}
                content={t(`${trigger.description}`)}
                onClick={() => handleTrigger(trigger)}
                selected={selectedTrigger.id === trigger.id}
                width="96%"
                uniqueKey={trigger.id}
                icon={
                  (trigger.customizationToDo && alertCircleIcon) ||
                  (!trigger.customEnabled && eyeOff) ||
                  undefined
                }
                toolTipText={
                  trigger.customizationToDo ? t('LISTING_INDICATOR') : undefined
                }
              />
            ))}
          </section>
          <section className="template-section" ref={loaderParentRef}>
            <MessageTemplate
              key={selectedTrigger?.id}
              selectedTrigger={selectedTrigger}
              handleToggle={handleToggle}
              handleSaveBtnClick={handleSaveBtnClick}
            />
            {isCustomMsgListLoading ? (
              <Loader
                useFullPage={false}
                dimension={loaderParentRef.current?.getBoundingClientRect()}
              />
            ) : (
              <>
                {isBannerVisible &&
                  selectedTrigger.customizationToDo &&
                  !!supportedLanguages?.length && (
                    <Banner
                      primaryIcon={alertCircleIcon}
                      primaryContent={t('MAKE_CHANGES_HEADER')}
                      secondaryContent={t('MAKE_CHANGES_DESC')}
                      onClick={() => {
                        setIsBannerVisible(false);
                        track(EventNames.automated_messages, {
                          eventAction: EventActions.click,
                          eventCategory: EventCategories.message_template,
                          eventLabel: 'translate_message',
                          featureVersion: 'v1',
                        });
                      }}
                      buttonLabel={t('MAKE_CHANGES_CTA')}
                      bannerVariant='automated-msg'
                      uniqueKey="automated-msg"
                      toolTipText=''
                    />
                )}
                <CustomTranslationEditor
                  displayTranslationEditor={
                    (selectedTrigger.customEnabled && !isBannerVisible) ||
                    (selectedTrigger.customEnabled &&
                      !supportedLanguages.length)
                  }
                  supportedLanguages={supportedLanguages}
                  selectedTrigger={selectedTrigger}
                  translatedMessages={otherLanguageMsgs}
                />
              </>
            )}
          </section>
        </section>
      </section>
    </article>
  );
}

export default Settings;
