import styled from 'styled-components';
import { CssSize } from '../BaseCard/BaseCard.d';

interface StyledTableWrapperProps {
  width: CssSize;
  height: CssSize;
  background: string;
}

export const StyledTableWrapper = styled.section<StyledTableWrapperProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 12px;
  background: ${(props) => props.background};
  padding: 10px;
`;

export const StyledTable = styled.table`
  width: 100%;
  padding: 10px;
  padding-top: 0;
  border-collapse: separate;
  border-spacing: 0 10px;
  tbody {
    display: block;
    height: 95px;
    tr {
      td:first-child {
        width: 90%;
      }
      td {
        padding: 0 6px; 
        
      }
    }
  }
`;