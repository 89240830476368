export enum MessageConsumptionStatus {
  CONSUMED = 'consumed',
  NOT_CONSUMED = 'not-consumed',
}

export enum MessageContentTypes {
  text = -1,
  image = 0,
  audio = 2,
  assignment = 4,
  cta = 5,
  pdf = 3,
}

export enum MessageChannelTypes {
  IRIS = '1',
  TWILIO = '0',
}

export const MessageContentTypeCssMap = {
  '-1': 'text',
  '0': 'image',
  '2': 'audio',
  '4': 'assignment',
  '5': 'cta',
  '3': 'pdf',
};

export enum TwilioMessageType {
  TEXT = 'text',
  MEDIA = 'media',
}

export enum MessageSendType {
  TEXT = 'TEXT',
  AUDIO = 'AUDIO',
  IMAGE = 'IMAGE',
  DEEPLINK = 'DEEPLINK',
  PDF = 'PDF',
}
