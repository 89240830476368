import * as React from 'react';
import './CareReferral.scss';
import { ICareReferral } from './types';
import Typography from '../../components/Typography';
import Button from '../../components/Button';

function CareReferral({
  icon,
  desc,
  iconHeight,
  iconWidth,
  fontSize,
  fontWeight,
  contentWidth,
  withColor,
  btnLabelIcon,
  btnLabel = '',
  btnVariant = 'raised',
  onClick = () => {},
}: ICareReferral) {
  return (
    <article className={`care-referral-wrapper ${btnVariant}`}>
      <section className="care-referral-info">
        {icon && (
          <section className={`care-referral-icon ${btnVariant}`}>
            <img
              height={iconHeight}
              width={iconWidth}
              src={icon}
              className="referral-icon"
              alt="information-icon"
            />
          </section>
        )}
        <Typography
          size={fontSize}
          weight={fontWeight}
          width={contentWidth}
          withColor={withColor}
        >
          {desc}
        </Typography>
      </section>
      <Button
        label={btnLabel}
        variant={btnVariant}
        onClick={onClick}
        minHeight="40px"
        minWidth="131px"
        fontWeight="700"
        width='161px'
        icon={btnLabelIcon}
        style={btnVariant === 'raised' ? { backgroundColor:'#263e58', borderRadius:'100px', flexDirection:'row-reverse' } : {} }
      />
    </article>
  );
}

export default CareReferral;
