import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ISlotTime } from '../../../shared/types/Time';
import { generate30MinTimeIntervals } from '../../../utilities/common/Date';
import SelectBoxV2 from '../SelectBoxV2';
import Typography from '../Typography';
import './TimeRangePicker.scss';
import upDownArrowIcon from '../../../assets/up-down-arrow.svg';
import { getCurrentLocale } from '../../../i18n';

export interface ITimeRange {
  startTime: string;
  endTime: string;
}

interface ITimeRangePicker {
  onChange?: (time: ITimeRange) => void;
  initialValue?: ITimeRange;
  disabled?: boolean;
  withLabels?: boolean;
}

function TimeRangePicker({ onChange, initialValue, disabled = false, withLabels = false }: ITimeRangePicker) {
  const { t } = useTranslation();
  const locale = getCurrentLocale();
  const intervals: ISlotTime[] = [
    ...generate30MinTimeIntervals({ is12HrFormat: locale.includes('en'), totalSlot:disabled ? 49 : 48 }),
  ];
  const [selectedTime, setSelectedTime] = React.useState<ITimeRange>(() => initialValue || { startTime: '', endTime: '' });
  const [startTimeIndex, setStartTimeIndex] = React.useState<number>(0);

  // sub-range of end time values, we don't want duplicate keys for empty option so we conditionally builds endTimeOptions array
  const endTimeOptions: ISlotTime[] = startTimeIndex ? [...intervals.slice(startTimeIndex)] : [...intervals.slice(startTimeIndex)];

  const updateTime = (newValue: string, source: 'start' | 'end') => {
    if (source === 'start') {
      setSelectedTime((prev) => ({ ...prev, startTime: newValue, endTime: '' }));
      const selectedTimeIndex = intervals.findIndex(interval => interval.slot === Number(newValue));
      setStartTimeIndex(selectedTimeIndex + 1);
    }
    if (source === 'end') setSelectedTime((prev) => ({ ...prev, endTime: newValue }));
  };

  React.useEffect(() => {
    onChange?.(selectedTime);
  }, [selectedTime]);

  React.useEffect(() => {
    if (initialValue) {
      setSelectedTime(initialValue);
    }
  }, [initialValue]);

  return (
    <div
      className="time-range-picker-container"
      style={{ gap: withLabels ? '48px' : '100px' }}
    >
      <div className="time-range-picker">
        {withLabels && (
          <Typography weight="500" size={12} withColor="rgba(86, 95, 112, 0.53)">
            {t('from')}
          </Typography>
        )}
        <SelectBoxV2
          customIcon={upDownArrowIcon}
          onChange={(newValue) => updateTime(newValue, 'start')}
          selectedValue={selectedTime.startTime}
          disabled={disabled}
          values={intervals.slice(0, intervals.length - 1).map(({ slot, time }) => ({ value: slot.toString(), label: time }))}
          placeHolderText={t('SELECT')}
        />
      </div>
      <div className="time-range-picker">
        {withLabels && (
          <Typography weight="500" size={12} withColor="rgba(86, 95, 112, 0.53)">
            {t('to')}
          </Typography>
        )}
        <SelectBoxV2
          customIcon={upDownArrowIcon}
          onChange={(newValue) => updateTime((Number(newValue) - 1).toString(), 'end')}
          selectedValue={selectedTime.endTime ? (Number(selectedTime.endTime) + 1).toString() : ''}
          disabled={disabled}
          values={endTimeOptions.map(({ slot, time }) => ({ value: slot.toString(), label: time }))}
          placeHolderText={t('SELECT')}
        />
      </div>
    </div>
  );
}

export default TimeRangePicker;
