import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './LeaveDateMarker.scss';
import Popup from '../../components/Popup';
import filledAlertCircleIcon from '../../../assets/filledAlert.svg';
import Card from '../../components/ClickableCard';
import Typography from '../../components/Typography';
import DatePicker from '../../components/DatePickerV2';
import Button from '../../components/Button';
import useOutOfOffice, {
  addOutOfOfficeDates,
} from '../../../utilities/hooks/useOutOfOffice/useOutOfOffice';
import { IActiveSessionPayload } from '../../../utilities/hooks/useOutOfOffice/types';
import {
  OldChatContactFormat,
  getDateRange,
} from '../../../utilities/common/Date';
import { isArrayEmpty } from '../../../utilities/common/Array';
import useToast from '../../../utilities/hooks/useToast';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';

interface ILeaveDateMarker {
  closeHandler: () => void;
  show: boolean;
  onSucessClick: (tabName: string) => void;
}

function LeaveDateMarker({
  show,
  closeHandler = () => {},
  onSucessClick,
}: ILeaveDateMarker) {
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const { track } = useTracking();
  const { fetchActiveSessions, isFetchSessionLoading, activeSessionDetails } =
    useOutOfOffice();

  const [outOfOfficeDates, setOutOfOfficeDates] =
    React.useState<IActiveSessionPayload>({
      fromDate: '',
      toDate: '',
    });

  const handleStartDate = (date: string) => {
    setOutOfOfficeDates({
      ...outOfOfficeDates,
      fromDate: date,
    });
  };
  const handleEndDate = (date: string) => {
    setOutOfOfficeDates((prev) => ({
      ...prev,
      toDate: date,
    }));
  };

  const checkScheduledSessions = () => {
    if (outOfOfficeDates.fromDate.length && outOfOfficeDates.toDate.length) {
      fetchActiveSessions(outOfOfficeDates);
    }
  };

  React.useEffect(() => {
    checkScheduledSessions();
  }, [outOfOfficeDates]);

  const getEndDaysToDisable = React.useMemo(() => {
    if (outOfOfficeDates.fromDate.length) {
      return moment(outOfOfficeDates.fromDate).diff(moment(), 'day') + 1;
    }
    return 7;
  }, [outOfOfficeDates.fromDate]);

  const getSessionRelatedText = React.useMemo(() => {
    if (!!activeSessionDetails && isArrayEmpty(activeSessionDetails?.sessions))
      return 'SCHEDULED_SESSIONS_DO_NO_EXIST';
    if (!!activeSessionDetails && !isArrayEmpty(activeSessionDetails?.sessions))
      return 'SCHEDULED_SESSIONS_EXIST';
    return 'CHECK_SUBTEXT';
  }, [isFetchSessionLoading, activeSessionDetails]);

  React.useEffect(() => {
    if (getSessionRelatedText !== 'CHECK_SUBTEXT') {
      track(EventNames.workingHours, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.checkScheduledSessions,
        eventLabel:
        !isFetchSessionLoading &&
        getSessionRelatedText === 'SCHEDULED_SESSIONS_DO_NO_EXIST'
          ? 'dates_available'
          : 'dates_unavailable',
        featureVersion: 'v1',
      });
    }
  }, [getSessionRelatedText]);

  const leaveAdditionSuccess = () => {
    toast.show({ message: t('OOO_SUCCESS_PROMPT_1') });
    closeHandler();
    onSucessClick?.('MONTHLY_SCHEDULE');
  };

  const { addLeaveDates } = addOutOfOfficeDates(leaveAdditionSuccess);

  const handleConfirmLeave = () => {
    if (
      moment(outOfOfficeDates.fromDate).isAfter(moment(outOfOfficeDates.toDate))
    ) {
      toast.show({ message: t('INVALID_DATES') });
      return;
    }
    const dateRange = getDateRange(
      outOfOfficeDates.fromDate,
      outOfOfficeDates.toDate,
    );
    const body = {
      dates: dateRange,
    };
    addLeaveDates(body);
    track(EventNames.workingHours, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.confirmLeave,
      eventLabel: 'confirm_leave',
      featureVersion: 'v1',
      eventValue: dateRange,
    });
  };

  return (
    <Popup
      withBackdrop
      onClose={() => {
        closeHandler();
        track(EventNames.workingHours, {
          eventAction: EventActions.click,
          eventCategory: EventCategories.onLeave,
          eventLabel: 'close',
          featureVersion: 'v1',
        });
      }}
      useDefaultPadding={false}
      show={show}
    >
      <article className="leave-marker-parent">
        <Card
          content={t('MARK_AS_ON_LEAVE')}
          selected={false}
          onClick={() => {}}
          uniqueKey="ooo-header"
          icon={filledAlertCircleIcon}
          borderStyle="none"
          fontSize={24}
          variant="ooo-header"
          fontWeight="500"
          toolTipText={t('COACHMARK')}
          toolTipClass="marker-tooltip"
        />
        <section className="marker-content-wrapper">
          <section className="leave-marker-body">
            <Typography size={18} weight="500" color="swamp" textAlign="left">
              {t('SELECT_DATES')}
            </Typography>
            <section className="date-selection-group">
              <section className="date-selection">
                <Typography
                  size={14}
                  weight="500"
                  withColor="#7E818C"
                  textAlign="left"
                >
                  {t('from').toUpperCase()}
                </Typography>
                <DatePicker
                  onSelect={(date) => handleStartDate(date)}
                  customClass="date-picker-style"
                  inputDateFormat={OldChatContactFormat}
                  daysToDisable={7}
                />
              </section>
              <section className="date-selection">
                <Typography
                  size={14}
                  weight="500"
                  withColor="#7E818C"
                  textAlign="left"
                >
                  {t('to').toUpperCase()}
                </Typography>
                <DatePicker
                  onSelect={(date) => handleEndDate(date)}
                  customClass="date-picker-style"
                  inputDateFormat={OldChatContactFormat}
                  daysToDisable={getEndDaysToDisable}
                  disableInput={!outOfOfficeDates.fromDate.length}
                />
              </section>
            </section>
          </section>
          <section className="leave-marker-footer">
            <section className="leave-marker-footer-content">
              <Typography size={18} weight="500" color="swamp" textAlign="left">
                {t('YOUR_SCHEDULED_SESSIONS')}
              </Typography>
              <Typography
                size={14}
                weight="400"
                withColor="#767575"
                textAlign="left"
              >
                {t(getSessionRelatedText)}
                {getSessionRelatedText === 'SCHEDULED_SESSIONS_EXIST' && (
                  <Button
                    label={t('RESCHEDULE_SESSION')}
                    variant="hyperlink"
                    onClick={() => {
                      navigate('/sessions');
                      track(EventNames.upcomingSessions, {
                        eventAction: EventActions.click,
                        eventCategory: EventCategories.upcomingSessions,
                        eventLabel: 'upcoming_sessions',
                        featureVersion: 'v1',
                        eventSource: EventSource.onLeave,
                      });
                    }}
                    fontSize={14}
                    minWidth="140px"
                  />
                )}
              </Typography>
              {getSessionRelatedText === 'SCHEDULED_SESSIONS_DO_NO_EXIST' && (
                <Button
                  label={t('CONFIRM_LEAVE')}
                  variant="blue"
                  onClick={handleConfirmLeave}
                  fontSize={14}
                  fontWeight="600"
                  width="100%"
                  height="48px"
                />
              )}
            </section>
          </section>
        </section>
      </article>
    </Popup>
  );
}

export default LeaveDateMarker;
