import { beautifyUrl } from '../../../utilities/common/Path';
import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

export const errorMessage = {
  generic: 'Unable to load the traits data, please try again!',
};

export const getClientTraitDetails = async (body: any, userId: string) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.analytics.getClientTraits, [userId]),
    body,
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};

export const getPHQCardDetails = async (userId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.analytics.getPHQDetails, [userId]),
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};
