/* eslint-disable */
import * as React from 'react';
import Typography from '../../../../view/components/Typography';
import './TextMessageItem.scss';

interface ITextMessageItemProps {
  message: string;
  source: string;
}

function TextMessageItem({ message, source }: ITextMessageItemProps) {
  function linkify(text: string) {
    let urlRegex =
    /\b((https?|ftp|file):\/\/|(www|ftp)\.)[-A-Z0-9+&@#\/%?=~_|$!:,.;]*[A-Z0-9+&@#\/%=~_|$]/ig;
    return text.replace(urlRegex, function (url) {
      return `<a href='${url}' target='_blank'> ${url} </a> `;
    });
  }
  return (
    <Typography
      whiteSpace="pre-line"
      weight="400"
      size={12}
      withColor={source === 'outbound' ? '#fff' : '#48515A'}
    >
      <div
        dangerouslySetInnerHTML={{ __html: linkify(message) }}
        className={source === 'outbound' ? 'outbound-links' : 'inbound-links'}
      />
    </Typography>
  );
}

export default TextMessageItem;

