import moment from 'moment';
import { CalenderIndicator, CalenderDay } from '../types';

function generateContinuousDaysForCalenderView(
  noOfDays: number,
  indicator: CalenderIndicator,
): Array<CalenderDay> {
  const days: CalenderDay[] = [];
  for (let day = 1; day <= noOfDays; day += 1) {
    days.push({ day, indicator });
  }

  return days;
}

function overrideIndicators(
  calenderDays: CalenderDay[],
  daysToOverride: number[],
  overridingIndicator: CalenderIndicator,
): CalenderDay[] {
  const newCalenderDays: CalenderDay[] = [];
  calenderDays.forEach((calenderDay) => {
    if (daysToOverride.includes(calenderDay.day)) {
      const newCalenderDay = {
        day: calenderDay.day,
        indicator: overridingIndicator,
      };
      newCalenderDays.push(newCalenderDay);
    } else {
      newCalenderDays.push(calenderDay);
    }
  });

  return newCalenderDays;
}

function getCalenderValues(
  calenderViewDate: moment.Moment,
): Array<CalenderDay> {
  let calenderValues = [];
  const noOfWeekdays = 7;
  const todayDate = moment();
  const weekDay = calenderViewDate.startOf('month').isoWeekday();
  const noOfDaysCurrentMonth = calenderViewDate.daysInMonth();
  let currentMonthDays = generateContinuousDaysForCalenderView(
    noOfDaysCurrentMonth,
    'default',
  );

  if (todayDate.month() === calenderViewDate.month()) {
    currentMonthDays = overrideIndicators(
      currentMonthDays,
      [todayDate.date()],
      'today',
    ); // * applying 'today' indication for calender days
  }

  if (weekDay === 7) {
    //  * No need to add top padding if the weekday starts from sunday.
    calenderValues = [...currentMonthDays];
  } else {
    const noOfDaysInPreviousMonth = calenderViewDate
      .subtract(1, 'month')
      .daysInMonth();
    const previousMonthDays = generateContinuousDaysForCalenderView(
      noOfDaysInPreviousMonth,
      'disabled',
    );

    const topPaddingLength = noOfWeekdays - (noOfWeekdays - weekDay);
    const topPadding = previousMonthDays.splice(-topPaddingLength);

    calenderValues = [...topPadding, ...currentMonthDays];
  }

  const bottomPaddingLength =
    noOfWeekdays - (calenderValues.length % noOfWeekdays);
  if (bottomPaddingLength !== noOfWeekdays) {
    // * No need to add bottom padding if the month ending day is on saturday
    const bottomPadding = generateContinuousDaysForCalenderView(
      bottomPaddingLength,
      'disabled',
    );
    calenderValues = [...calenderValues, ...bottomPadding];
  }

  return calenderValues;
}

function useCalender(currentMonth: string) {
  return {
    values: getCalenderValues(moment(currentMonth, 'YYYY-MM-DD')),
  };
}

export default useCalender;
