import { IAddOOODates } from '../../../utilities/hooks/useOutOfOffice/types';
import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

export const errorMessage = {
  generic: 'something went wrong, please try again!',
};

export const markOutOfOffice = async (body: IAddOOODates) => {
  const response = await client.doPost(Config.api.outOfOffice.addLeave, body);
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.error?.message || errorMessage.generic);
};

export const fetchActiveSessionDuringOutOfOffice = async (
  fromDate: string,
  toDate: string,
) => {
  const response = await client.doGet(
    Config.api.outOfOffice.activeSession,
    {},
    {
      fromDate,
      toDate,
    },
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};

export const fetchLeaveStatus = async (date?: string) => {
  const response = await client.doGet(Config.api.outOfOffice.isOnLeave, {}, { date });
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};

export const removeOutOfOffice = async (body: IAddOOODates) => {
  const response = await client.doPut(Config.api.outOfOffice.removeLeave, body);
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};
