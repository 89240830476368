import { useMutation } from 'react-query';
import {
  addHelplineRecordComment,
  getHelplineDetails,
  getHelplineRecords,
} from '../../../shared/service/API/helpline';
import {
  HelplineRecord,
  HelplineRecordDetails,
} from '../../../shared/types/helpline';
import { useFetch } from '../useFetch';

export const useHelplineRecords = (clientId: string) =>
  useFetch<HelplineRecord[]>(['helpline-records', clientId], () =>
    getHelplineRecords(clientId),
  );

export const useHelplineRecordDetails = (sessionId: string) =>
  useFetch<HelplineRecordDetails>(
    ['helpline-record', sessionId],
    () => getHelplineDetails(sessionId),
    {
      refetchOnWindowFocus: false,
    },
  );

export const useHelplineNotes = (options: {
  sessionId: string;
  onSuccess: () => void;
}) => {
  const { sessionId, onSuccess } = options;
  return useMutation(
    (comment: string) => addHelplineRecordComment(sessionId, comment),
    {
      onSuccess,
    },
  );
};
