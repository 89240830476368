/* eslint-disable */
import * as React from 'react';
import _ from 'lodash';
import { motion } from 'framer-motion';
import tickIcon from "../../../assets/tick.svg";
import './MultilevelDropdown.scss';
import { IMultiDropdown, IMultiOption, IMultiOptionsData } from './types';
import Typography from '../Typography';
import chevronDownIcon from '../../../assets/chevron-down.svg';

function instanceOfIMultiOptionsData(data: any): data is IMultiOptionsData {
  return 'label' in data;
}
function instanceOfIMultiOptionsDataList(
  data: any,
): data is IMultiOptionsData[] {
  return data?.length;
}
function MultilevelDropdown({
  placeholderText = 'Select',
  multiDropValues,
  onChange,
  onFocus,
  onBlur,
  menuFontSize = 16,
  menuFontWeight = '600',
  menuWithColor,
  subMenuFontSize = 14,
  subMenuFontWeight = '400',
  subMenuWithColor,
  isMultiSelect = false,
  defaultSelected,
  limit = 3,
}: IMultiDropdown) {
  const [menuVisible, setMenuVisible] = React.useState(false);
  const [baseMenuData, setBaseMenuData] = React.useState<IMultiOptionsData>(
    {} as IMultiOptionsData,
  );

  const [selectedMenuItem, setSelectedMenuItem] = React.useState<
    IMultiOption[] | IMultiOption | undefined
  >(undefined);

  React.useEffect(() => {
    const allChildren = _.flatten(
      multiDropValues.map((item) => item?.children),
    );
    if (!defaultSelected) return;
    if (!isMultiSelect && instanceOfIMultiOptionsData(defaultSelected)) {
      setSelectedMenuItem(
        allChildren?.filter(
          (item) => item?.value === defaultSelected.value,
        )?.[0],
      );
    } else if (instanceOfIMultiOptionsDataList(defaultSelected)) {
      const defaultSelectedValue = defaultSelected.map((item) => item.value);
      setSelectedMenuItem(
        allChildren?.filter(item=>!!item && defaultSelectedValue?.includes(item?.value))
      );
    }
  }, [defaultSelected]);

  const onSelect = (child: IMultiOption) => {
    if (isMultiSelect && instanceOfIMultiOptionsDataList(selectedMenuItem)) {
      const selectedValues = selectedMenuItem?.filter(
        (item) => item.value !== child.value,
      );
      if (selectedMenuItem?.length !== selectedValues?.length) {
        setSelectedMenuItem(selectedValues);
        onChange(selectedValues);
      } else {
        if(selectedMenuItem.length >= limit){
          return
        }
        setSelectedMenuItem([...selectedValues, child]);
        onChange([...selectedValues, child]);
      }
    } else if (isMultiSelect) {
      setSelectedMenuItem([child]);
      onChange([child]);
    } else {
      setSelectedMenuItem(child);
      onChange(child);
      setMenuVisible(false);
      setBaseMenuData({} as IMultiOptionsData);
    }
  };

  const onCollapse = (val:IMultiOptionsData)=>{
    if(baseMenuData.value=== val.value){
      setBaseMenuData({} as IMultiOptionsData)
      return
    }
    setBaseMenuData(val)
  }
  const lableValue = React.useMemo(() => {
    if (!selectedMenuItem) {
      return placeholderText;
    }
    if (!isMultiSelect && instanceOfIMultiOptionsData(selectedMenuItem)) {
      return selectedMenuItem.label.replace(/\([^)]*\)/g, '');
    }
    if (isMultiSelect && instanceOfIMultiOptionsDataList(selectedMenuItem)) {
      return selectedMenuItem.length > 1
        ? `${selectedMenuItem?.[0].label.replace(/\([^)]*\)/g, '')} +${selectedMenuItem.length - 1}`
        : selectedMenuItem?.[0]?.label.replace(/\([^)]*\)/g, '');
    }
    return placeholderText
  }, [selectedMenuItem]);


  const showIcon = (child:IMultiOption)=>{
    if(isMultiSelect && instanceOfIMultiOptionsDataList(selectedMenuItem)){
      return selectedMenuItem.filter((item)=>item.value===child.value).length>0
    }
    return false
  }
  return (
    <article
      className="multi-dropdown-wrapper"
      role="button"
      tabIndex={0}
      onBlur={() => {
        setMenuVisible(false);
        setBaseMenuData({} as IMultiOptionsData);
        onBlur?.();
      }}
      onFocus={()=>onFocus?.()}
    >
      <section
        className="placeholder-container"
        onClick={() => setMenuVisible(true)}
      >
        <Typography withColor="#667085" size={14}>
          {lableValue}
        </Typography>
        <img src={chevronDownIcon} alt="select" />
      </section>

      {menuVisible && (
        <motion.section
          className="dropdown-base"
          initial={{ opacity: 0, top: '-70px' }}
          animate={{ opacity: 1, top: '50px' }}
          exit={{ opacity: 0, top: '-70px' }}
          transition={{ duration: 0.4 }}
        >
          {multiDropValues.map((val) => (
            <section key={val.value}>
              <section
                onClick={() =>onCollapse(val)}
                className="menu-item"
              >
                <Typography size={menuFontSize} weight={menuFontWeight}>
                  {val.label}
                </Typography>
                <img src={chevronDownIcon} alt="select" />
              </section>
              {val.value == baseMenuData.value &&
                baseMenuData?.children?.map((child) => (
                  <motion.section
                    key={child.value}
                    className="submenu-item"
                    initial={{ opacity: 0, top: '-70px' }}
                    animate={{ opacity: 1, top: '50px' }}
                    exit={{ opacity: 0, top: '-70px' }}
                    transition={{ duration: 0.4 }}
                    onClick={() => onSelect(child)}
                  >
                    <Typography
                      size={subMenuFontSize}
                      weight={subMenuFontWeight}
                    >
                      {child.label.replace(/\([^)]*\)/g, '')}
                      <Typography size={12} withColor="#b3b3b3">
                        {child.label.match(/\(([^)]+)\)/g)}
                      </Typography>
                    </Typography>
                    {showIcon(child) && <img src={tickIcon} alt='check mark'/>}
                  </motion.section>
                ))}
            </section>
          ))}
        </motion.section>
      )}
    </article>
  );
}

export default MultilevelDropdown;
