import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import './UnableToJoinPopup.scss';

interface IUnableToJoinPopupProps {
  onLeaveClick: () => void;
}

function UnableToJoinPopup({ onLeaveClick }: IUnableToJoinPopupProps) {
  const { t } = useTranslation();
  return (
    <Popup withBackdrop showCloseIcon={false} onClose={onLeaveClick}>
      <div className="unable-to-join-popup-container">
        <Typography weight="400" size={16}>{t('unableToJoinVideoRoom')}</Typography>
        <ul>
          <li>
            <Typography weight="400" size={12}>{t('pleaseNoteMeetingTiming')}</Typography>
          </li>
        </ul>
        <div className="actions">
          <Button width="180px" label='Leave' variant="primary" onClick={onLeaveClick} />
        </div>
      </div>
    </Popup>
  );
}

export default UnableToJoinPopup;