/* eslint-disable */
import moment, { Moment } from 'moment';
import lodash from 'lodash';
import {
  APIDateFormat,
  getSlotsByDate,
  getSlotsByWeekDay,
  getSlotTz,
  getTimeZone,
  slotToTime,
  transformSlotsToWeekDay,
} from '../../../utilities/common/Date';
import { beautifyUrl } from '../../../utilities/common/Path';
import { addItemToLocalStorage } from '../../../utilities/common/Storage';
import Config from '../../Config';
import NetworkClient from '../../service/Network';
import {
  ICaseNoteAnswer,
  ICaseNoteQuestion,
  INewCasNote,
} from '../../types/response/CaseNote';
import {
  ProviderSessions,
  SessionData,
  IHoliday,
  IProviderClient,
  IMeetingInfo,
  IClientSummary,
  IMeeting,
  IProviderSlot,
  IProviderDaySlot,
  InPersonClinic,
  ISlots,
} from '../../types/response/provider';
import ActionTypes from '../../constant/ActionTypes';
import { clientsListingComparator } from '../../../utilities/common/Comparator';

const client = new NetworkClient();

export const getProviderSessions = (month?: string) => (dispatch: Function) => {
  const monthMoment = moment(month);
  const params = {
    fromDate: monthMoment.startOf('month').format(APIDateFormat),
    toDate: monthMoment.endOf('month').format(APIDateFormat),
  };
  dispatch({
    type: 'ADD_SESSIONS',
    payload: [],
  });
  return client
    .doGet(Config.api.sessions.allotedSessions, {}, params)
    .then((response) => {
      const sessionsData: SessionData[] = [];
      const { profile, sessions, leaves } = response.data;

      dispatch({
        type: ActionTypes.APP.SET_PROVIDER_DATA,
        payload: profile,
      });

      if (sessions && sessions.length) {
        sessions.forEach((session: ProviderSessions) => {
          const {
            participants,
            displayDate,
            displayTime,
            displayTimeEnd,
            id,
            status,
            providerRole,
            type,
            scheduledStartTime,
            scheduledEndTime,
            providerId,
            meetingTimeZone,
            locationRef,
            roomId,
            room,
          } = session;
          const { friendlyName, userId, createdAt, coachingTier, tags } =
            participants[0];
          // TODO push session number
          sessionsData.push({
            displayDate,
            displayTime,
            displayTimeEnd,
            friendlyName,
            userId: userId.toString(),
            meetingId: id,
            status,
            userCreatedAt: createdAt,
            coachingTier,
            tags,
            providerRole,
            type,
            scheduledStartTime,
            scheduledEndTime,
            providerId,
            meetingTimeZone,
            locationRef,
            roomId,
            room,
          });
        });
      }
      dispatch({
        type: 'ADD_SESSIONS',
        payload: sessionsData,
      });
      dispatch({
        type: 'ADD_HOLIDAYS',
        payload: leaves,
      });
    })
    .catch((error) => {
      console.error(
        'Error occurred while fetching provider sessions - ',
        error,
      );
      throw new Error(error);
    });
};

export const getProviderSlots = () => () =>
  client
    .doGet(Config.api.slots.getSlots)
    .then((response) => {
      if (response && response.data) {
        const slotsByWeekDay = getSlotsByWeekDay(response.data);
        return slotsByWeekDay;
      }
      return {};
    })
    .catch((error) => {
      console.error('Error occurred while fetching provider slots - ', error);
    });

export const updateProviderSlots = () => () => {
  const requestBody = {};
  return client
    .doPost(Config.api.slots.getSlots, requestBody)
    .then((response) => {
      if (response && response.data) {
        const slotsByWeekDay = getSlotsByWeekDay(response.data);

        return slotsByWeekDay;
      }
      return {};
    })
    .catch((error) => {
      console.error('Error occurred while fetching provider slots - ', error);
    });
};

/** V2 Slots */

export const getProviderSlotsV2 = () => () =>
  client
    .doGet(Config.api.slots.getSlotsV2)
    .then((response) => {
      if (response && response.data) {
        const slotsByWeekDay = getSlotsByWeekDay(response.data);

        return slotsByWeekDay;
      }
      return {};
    })
    .catch((error) => {
      console.error('Error occurred while fetching provider slots - ', error);
    });

export const getProviderHolidays = () => (dispatch: Function) => {
  let holidays = []; // TODO
  client
    .doGet(Config.api.slots.getHolidays)
    .then((response) => {
      if (response && response.data && response.data.length) {
        holidays = Object.values(getSlotsByDate(response.data));

        // dispatch({
        //   type: 'ADD_HOLIDAYS',
        //   payload: holidays,
        // });
      }
    })
    .catch((error) => {
      console.error(
        'Error occurred while fetching provider holidays - ',
        error,
      );
    });
};

export const saveHoliday =
  (date: string, leaveSlots: number[][]) => async () => {
    if (moment(date).isSameOrAfter(moment(new Date()).format('YYYY/MM/DD'))) {
      const offset = moment.tz(getTimeZone()).utcOffset();
      const slotsArray = (leaveSlots.length ? leaveSlots : [[0, 47]]).reduce(
        (res: Moment[], cur: number[]) => {
          const dates = lodash
            .range(cur[0], cur[cur.length - 1] + 1)
            .map((slot: number) =>
              moment(
                `${date} ${slotToTime(+slot)}`,
                'YYYY/MM/DD HH:mm',
              ).subtract(offset, 'minutes'),
            );
          res.push(...dates);
          return res;
        },
        [],
      );

      const slotsMap = slotsArray.reduce((res: any, dateSlot: any) => {
        if (!res[dateSlot.format('YYYY/MM/DD')]) {
          res[dateSlot.format('YYYY/MM/DD')] = [];
        }
        res[dateSlot.format('YYYY/MM/DD')].push(
          Math.floor(+dateSlot.format('HH') * 2 + +dateSlot.format('mm') / 30),
        );
        return res;
      }, {});

      // eslint-disable-next-line no-restricted-syntax,no-unreachable-loop
      for await (const key of Object.keys(slotsMap)) {
        await client.doPost(Config.api.slots.getHolidays, {
          date: key,
          slots: slotsMap[key].join(','),
        });
      }
      return true;
    }
    return false;
  };

export const saveSlots = async (body: ISlots[]) =>
  client.doPost(Config.api.slots.getSlots, body);

export const getCaseNoteQuestions = () => () => {
  client
    .doGet(Config.api.caseNotes.questions)
    .then((response) => {
      if (response && response.data) {
        const responseCaseNoteQuestions: ICaseNoteQuestion[] = response.data;
        const caseNoteQns: ICaseNoteQuestion[] = responseCaseNoteQuestions.map(
          (caseNote: ICaseNoteQuestion) => ({
            id: caseNote.id,
            question: caseNote.question,
          }),
        );

        addItemToLocalStorage('caseNotQuestions', caseNoteQns);
      }
    })
    .catch((error) => {
      console.error(
        'Error occurred while fetching provider case note questions - ',
        error,
      );
    });
};

export const getCaseNoteData = (meetingId: string) => () =>
  client
    .doGet(beautifyUrl(Config.api.caseNotes.answers, [meetingId]))
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.caseNotes &&
        response.data.caseNotes.length
      ) {
        const answers: ICaseNoteAnswer[] = response.data.caseNotes;
        return answers;
      }
      return [];
    })
    .catch((error) => {
      console.error(
        'Error occurred while fetching provider case note answers - ',
        error,
      );
    });

export const saveCaseNoteData =
  (meetingId: string, userId: number, caseNotes: INewCasNote[]) => () => {
    const requestBody = { meetingId, userId, caseNotes };
    return client
      .doPost(Config.api.caseNotes.save, requestBody)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.caseNotes &&
          response.data.caseNotes.length
        ) {
          const answers: ICaseNoteAnswer[] = response.data.caseNotes;
          return answers;
        }
        return [];
      })
      .catch((error) => {
        console.error(
          'Error occurred while saving provider case note answers - ',
          error,
        );
      });
  };

export const getClientData =
  (clientId: string, page: number, providerRole: string) =>
  (dispatch: Function) =>
    client
      .doGet(
        beautifyUrl(Config.api.clients.clientData, [clientId, page.toString()]),
        {},
        {
          role: providerRole,
        },
      )
      .then((response) => {
        const responseData: IProviderClient = response.data;
        const pageData = {
          hasNextPage: Boolean(
            responseData &&
              responseData.caseNotes &&
              responseData.caseNotes &&
              responseData.caseNotes.length,
          ),
          nextPage: page + 1,
        };

        responseData.page = pageData;

        // for first page, we need to fill the whole clients data, and from onward pages, we only just need to fill caseNotes.
        if (page === 1) {
          dispatch({
            type: 'SET_SELECTED_CLIENT',
            payload: responseData,
          });
        } else {
          dispatch({
            type: 'UPDATE_CASE_NOTES',
            payload: responseData,
          });
        }
      })
      .catch((error) => {
        console.error(
          'Error occurred while fetching provider clients - ',
          error,
        );
      });

export const getMeetingInfo =
  (meetingId: string, body: { platform: string }) => () =>
    client
      .doPost(
        beautifyUrl(Config.api.meeting.getMeetingDetails, [meetingId]),
        body,
      )
      .then((response) => {
        const responseData: IMeetingInfo = response.data;
        return responseData;
      })
      .catch((error) => {
        console.error(
          'Error occurred while fetching provider meeting info - ',
          error,
        );
      });

export const getClients = () => (dispatch: Function) => {
  dispatch({
    type: 'ADD_CLIENTS',
    payload: { isLoading: true, responseData: [] },
  });
  client
    .doPost(Config.api.chat.conversations, {})
    .then((response) => {
      if (
        response &&
        response.data &&
        response.data.conversations &&
        response.data.conversations
      ) {
        const responseData: IClientSummary[] = response.data.conversations;
        {/* //! intentionally removing the sorting since going forward default sorting will be based on matched on date */}
        // const sortedClients = responseData.sort(clientsListingComparator); 
        dispatch({
          type: 'ADD_CLIENTS',
          payload:  { isLoading: false, responseData },
        });

        return responseData;
      }

      return [];
    })
    .catch((error) => {
      console.error('Error occurred while fetching provider clients - ', error);
    });
};

export const getClientsForAssignment = () => async () => {
  try {
    const response = await client.doPost(Config.api.chat.conversations, {});
    if (
      response &&
      response.data &&
      response.data.conversations &&
      response.data.conversations
    ) {
      const responseData: IClientSummary[] = response.data.conversations;
      const sortedClients = responseData.sort(clientsListingComparator);
      return sortedClients;
    }
    return [];
  } catch (error) {
    throw new Error('Unable to load details');
  }
};

export const getProviderBilling = (month: string) => (dispatch: Function) => {
  const formattedDate = moment(month, 'MMMM YYYY').format('YYYY/MM');
  client
    .doGet(
      beautifyUrl(Config.api.billing.getBillingDetails, [
        encodeURIComponent(formattedDate),
      ]),
      {},
    )
    .then((response) => {
      if (response && response.data) {
        const result = response.data.sessions.reduce(
          (res: { [date: string]: IMeeting[] }, session: IMeeting) => {
            const slotTime = getSlotTz(
              session.scheduledDate,
              session.scheduledSlot,
            );
            if (!res[slotTime.displayDate]) {
              res[slotTime.displayDate] = [];
            }
            res[slotTime.displayDate].push({ ...session, ...slotTime });
            return res;
          },
          {},
        );
        dispatch({
          type: 'ADD_BILLING_INFO',
          payload: result,
        });
      }
    })
    .catch((error) => {
      console.error('Error occurred while fetching provider clients - ', error);
    });
};

export const getProviderProfileData = (providerId: string) => async () => {
  const response = await client.doGet(
    beautifyUrl(Config.api.profile.getProviderProfile, [providerId]),
  );
  if (response && response.success) {
    return response.data;
  }

  throw new Error(response.error?.message);
};
