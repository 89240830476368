import { getSlotTz } from '../../../utilities/common/Date';
import { beautifyUrl } from '../../../utilities/common/Path';
import Config from '../../Config';
import { IMeeting } from '../../types/response/provider';
import NetworkClient from '../Network';

const ErrorMessages = {
  generic: 'Unable to load the billing data, please try again!',
};

const client = new NetworkClient();

export const getBillingDetails = async (billingDay: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.billing.getBillingDetails, [
      encodeURIComponent(billingDay),
    ]),
  );
  if (response && response.data) {
    const billingDetails = response.data.sessions.reduce(
      (res: { [date: string]: IMeeting[] }, session: IMeeting) => {
        const { displayTime, displayTimeEnd } = session;
        const slotTime = getSlotTz(
          session.scheduledDate,
          session.scheduledSlot,
        );
        if (!res[slotTime.displayDate]) {
          res[slotTime.displayDate] = [];
        }
        res[slotTime.displayDate].push({
          ...session,
          displayTime,
          displayTimeEnd,
        });
        return res;
      },
      {},
    );

    return { billingDetails, summary: response.data?.summary || {} };
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};

export const getBillingListing = async (billingMonth: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.billing.getBillingListing, [
      encodeURIComponent(billingMonth),
    ]),
  );
  if (response && response.data && response.data.sessions) {
    return response.data.sessions;
  }

  throw new Error(response.error?.message || ErrorMessages.generic);
};

export const getBillingSummary = async (
  billingMonth: string,
  sessionType: number[],
) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.billing.getBillingSummary, [
      encodeURIComponent(billingMonth),
    ]),
    undefined,
    {
      sessionType,
    },
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response.data.error?.message || ErrorMessages.generic);
};
