import * as React from 'react';
import { connect } from 'react-redux';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import useAuth from '../../../utilities/hooks/useAuth';
import MeetingClientSelection from '../../app-components/MeetingClientSelection';
import MeetingSlotSelection from '../../app-components/MeetingSlotSelection';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import { useProviderOpenSlots } from '../../../utilities/hooks/fetchHooks/provider';
import './GetProviderWizard.scss';
import { getClients as _getClients } from '../../../shared/redux/actions/provider';
import { PageAnimationDuration } from '../../../configs/animations/constants';
// import { IClientSummary } from '../../../shared/types/response/provider';

interface LocationState {
  isPopUp: boolean;
  clientId: number;
}

export interface IClientsProps {
  getClients: () => Promise<Array<{ providerRole: string }>>;
  provider: {
    clients: any;
  };
}

function GetProviderWizard({ getClients, provider }: IClientsProps) {
  // const  { clients } = provider;
  // Custom Hooks
  const { user } = useAuth();
  const location = useLocation();
  const { isPopUp, clientId } = location.state as LocationState;
  // Redux State
  const { clients } = provider;
  // Local State
  const totalSteps = 2;
  const [currentStep, setCurrentStep] = React.useState(isPopUp ? 2 : 1);
  const [selectedClientId, setSelectedClientId] = React.useState(0);
  const [isSelectionPopUp, setIsSelectionPopUp] = React.useState(isPopUp);
  const [selectedProviderRole, setSelectedProviderRole] = React.useState('');
  const [selectedClientName, setSelectedClientName] = React.useState('');

  //* Query to get selected client slot
  const { data: providerOpenSlots } = useProviderOpenSlots(
    user.id,
    selectedProviderRole,
    clientId.toString(),
  );

  React.useEffect(() => {
    getClients();
  }, []);

  return (
    <motion.div
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -100, opacity: 0 }}
      transition={{ duration: PageAnimationDuration }}
    >
      <Typography size={12} weight="400" color="secondary" textAlign="center">
        Step {currentStep} of {totalSteps}
      </Typography>
      {currentStep === 1 && (
        <MeetingClientSelection
          setCurrentStep={setCurrentStep}
          clientsList={clients}
          // isLoading={clientIsLoading}
          setSelectedClientId={setSelectedClientId}
          selectedClientId={selectedClientId}
          setSelectedProviderRole={setSelectedProviderRole}
          setSelectedClientName={setSelectedClientName}
        />
      )}
      {!isSelectionPopUp && currentStep === 2 && (
        <MeetingSlotSelection
          selectedClientId={isSelectionPopUp ? clientId : selectedClientId}
          setCurrentStep={setCurrentStep}
          isPopUp={isSelectionPopUp}
          providerOpenSlots={providerOpenSlots}
          name={selectedClientName}
        />
      )}
      {isSelectionPopUp && currentStep === 2 && (
        <Popup
          withBackdrop
          useDefaultPadding={false}
          backdropMode="glass"
          onClose={() => setIsSelectionPopUp(false)}
        >
          <MeetingSlotSelection
            selectedClientId={isSelectionPopUp ? clientId : selectedClientId}
            setCurrentStep={setCurrentStep}
            isPopUp={isSelectionPopUp}
            providerOpenSlots={providerOpenSlots}
            name={selectedClientName}
          />
        </Popup>
      )}
    </motion.div>
  );
}

const mapStateToProps = (state: any) => ({ provider: state.provider });

const mapDispatchToProps = (dispatch: Function) => ({
  getClients: () => dispatch(_getClients()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetProviderWizard);
