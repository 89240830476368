import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTableWrapper, StyledTable } from './BaseTable.styled';
import Typography from '../Typography';
import { BaseTableProps } from './BaseTable.d';

function BaseTable({
  tableData,
  tableHeader,
  width = '100%',
  height = 'auto',
  backgroundColor = '#D9D9D9',
}: BaseTableProps) {
  const { t } = useTranslation();
  return (
    <StyledTableWrapper
      width={width}
      height={height}
      background={backgroundColor}
    >
      {tableHeader && (
        <Typography size={12} weight="400" textAlign="center">
          {t(`${tableHeader}`)}
        </Typography>
      )}
      <StyledTable>
        <tbody>
          {tableData?.map((data) => (
            <tr key={t(`${data.id}`)}>
              <td>{t(`${data.description}`)}</td>
              <td>{t(`${data.count}`)}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </StyledTableWrapper>
  );
}
export default BaseTable;
