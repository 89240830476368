import * as React from 'react';
import { IFloatingChatWindowSettings } from '../types/Chat';
import { IHeaderSettings } from '../../view/app-components/Layout/types';

interface ILayoutContext {
  layoutRef: React.RefObject<HTMLDivElement>;
  updateChatWindowSettings: (chatSettings: IFloatingChatWindowSettings) => void;
  chatWindowVisible: boolean;
  updateHeaderSettings: (headerSetting: Partial<IHeaderSettings>) => void;
  resetNavigation: () => void;
}

const LayoutContext = React.createContext<ILayoutContext>({} as ILayoutContext);

export default LayoutContext;
