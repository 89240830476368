import * as React from 'react';
import './ConsentBanner.scss';
import { IConsentBanner } from './types';

function ConsentBanner({
  label,
  onChange,
  defaultValue = false,
  width = '100%',
  height = '50px',
  fontSize = 12,
  background = 'linear-gradient(200deg, #F3F8FF 0%, rgba(197, 217, 248, 0.00) 100%)',
  borderColor = 'rgba(197, 217, 248, 0.44)',
  borderWidth = '1px',
  borderStyle = 'solid',
  borderRadius = '8px',
  fontWeight = '500',
  labelColor = '#667085',
  isConsentDisabled,
}: IConsentBanner) {
  const [value, setValue] = React.useState<boolean>(defaultValue);
  const getStyles = () => {
    const styles: React.CSSProperties = {
      height,
      width,
      background,
      borderColor,
      borderWidth,
      borderStyle,
      borderRadius,
      fontSize,
      fontWeight,
      color: labelColor,
    };
    return styles;
  };
  React.useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
    setValue(e.target.checked);
  };
  return (
    <article style={getStyles()} className="consent-banner-container">
      <input
        checked={value}
        type="checkbox"
        id="consent-chkbox"
        onChange={onCheck}
        disabled={isConsentDisabled}
      />
      <label htmlFor="consent-chkbox">{label}</label>
    </article>
  );
}

export default ConsentBanner;
