import * as React from 'react';

function useOutsideClickHandler(ref: React.RefObject<HTMLElement>, callback: () => void) {
  React.useEffect(() => {

    function handleClickOutside(event: TouchEvent | MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideClickHandler;


