import * as React from 'react';
import './Header.css';
import Button from '../../../../../components/Button';
import ClientDetail from '../../../../ClientDetails';
import SideDrawer from '../../../../../components/SideDrawer';
import { ClientMeetingData, IClientSummary } from '../../../../../../shared/types/response/provider';
import intellectLogo from '../../../../../../assets/IntellectLogo.svg';

export default function Header({ meetingData }: { meetingData: ClientMeetingData }) {
  const [showClientDetails, setShowClientDetails] = React.useState(false);

  const getClientSummary = () => {
    const { clientId, friendlyName, providerRole, tags, userCreatedAt } = meetingData;
    const summary: IClientSummary = {
      providerRole,
      createdAt: userCreatedAt,
      userId: Number(clientId),
      user: {
        friendlyName,
        tags,
        createdAt: userCreatedAt,
      },
    };

    return summary;
  };

  return (
    <header>
      <SideDrawer
        show={showClientDetails}
        hideHandler={() => setShowClientDetails(false)}
      >
        {!!showClientDetails && (
          <ClientDetail clientSummary={getClientSummary()} isInSession/>
        )}
      </SideDrawer>
      <div className='header-section'>
        <img src={intellectLogo} className="logo" alt="intellect logo" />
        <Button onClick={() => setShowClientDetails(true)} label='Client details' variant='primary' />
      </div>
    </header>
  );
}
