import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './Card.scss';
import Typography from '../Typography';
import closeIcon from '../../../assets/close.svg';
import chevronRightIcon from '../../../assets/chevron-right.svg';
import circularInfo from '../../../assets/filledAlert.svg';
import { CssSize } from '../BaseCard/BaseCard.d';

export interface ICard {
  onClose?: () => void;
  controlClick?: () => void;
  cardHeadingContent?: string;
  cardDescContent?: string;
  cardActionLabelContent?: string;
  cardActionIcon?: string;
  show: boolean;
  showControls?: boolean;
  startingLeaveDate?: string;
  enditingLeaveDate?: string;
  cardWidth?: CssSize;
}

const containerAnimationVariants = {
  hover: {},
};

const imageAnimationVariants = {
  hover: {
    x: '10px',
  },
};
function Card({
  show,
  onClose,
  controlClick,
  cardHeadingContent = 'OOO_HEADING',
  cardDescContent = 'OOO_SUBHEADING',
  cardActionLabelContent = 'MARK_AS_AVAILABLE',
  cardActionIcon = chevronRightIcon,
  showControls = false,
  startingLeaveDate = '24/02/2024 - 12:00 am (IST)', // todo add dynamic date
  enditingLeaveDate = '24 Jan, 2024 - 11:59 pm (IST)', // todo add dynamic date
  cardWidth = '100%',
}: ICard) {
  const { t } = useTranslation();

  return (
    <article
      className={`parent-section ${show ? 'show' : ''}`}
      style={{ width: cardWidth }}
    >
      <section className="card-header">
        {onClose && (
          <section
            className="card-close-control"
            role="button"
            onClick={onClose}
            tabIndex={0}
          >
            <img src={closeIcon} alt="close-icon" />
          </section>
        )}
      </section>
      <section className="card-body">
        <section className="heading-section">
          <img src={circularInfo} alt="information-logo" />
          <Typography size={14} weight="600">
            {t(cardHeadingContent)}
          </Typography>
        </section>
        <section className="leave-date-section">
          <section className="date-item-wrapper">
            <Typography size={10} withColor="#9CAFC0">
              {' '}
              {t('from')}
            </Typography>
            <Typography size={12} withColor="#404755">
              {' '}
              {startingLeaveDate}
            </Typography>
          </section>
          <section className="date-item-wrapper">
            <Typography size={10} withColor="#9CAFC0">
              {' '}
              {t('to')}
            </Typography>
            <Typography size={12} withColor="#404755">
              {' '}
              {enditingLeaveDate}
            </Typography>
          </section>
        </section>
        <section className="description-section">
          <Typography withColor="#404755" size={12} weight="400">
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: t(cardDescContent) }} />
          </Typography>
        </section>
        {showControls && (
          <motion.section
            className="card-action"
            variants={containerAnimationVariants}
            whileHover="hover"
            onClick={() => controlClick?.()}
          >
            <Typography size={12} weight="500" withColor="#1477F8">
              {t(cardActionLabelContent)}
            </Typography>
            <motion.img
              src={cardActionIcon}
              alt="arrow-icon"
              variants={imageAnimationVariants}
            />
          </motion.section>
        )}
      </section>
    </article>
  );
}

export default Card;
