import { beautifyUrl } from '../../../utilities/common/Path';
import {
  UpdateTemplateRequestType,
  UpdateTriggerRequestType,
} from '../../../view/screens/Settings/types';
import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

export const errorMessage = {
  generic: 'something went wrong, please try again!',
};

export const getAutomatedMessageTriggers = async () => {
  const response = await client.doGet(Config.api.automatedMessages.getTriggers);
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};

export const updateAutomatedMessageTrigger = async (
  body: UpdateTriggerRequestType,
) => {
  const response = await client.doPost(
    Config.api.automatedMessages.overrideTriggers,
    body,
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.error?.message || errorMessage.generic);
};

export const updateAutomatedMessageTemplate = async (
  body: UpdateTemplateRequestType,
  triggerId: string,
) => {
  const response = await client.doPost(
    beautifyUrl(Config.api.automatedMessages.addCustomMessage, [triggerId]),
    body,
  );
  if (response && response.success) {
    return response;
  }
  throw new Error(response?.error?.message || errorMessage.generic);
};

export const getCustomMessagesList = async (triggerId: string) => {
  const response = await client.doGet(
    Config.api.automatedMessages.listCustomMessages,
    undefined,
    {
      triggerId,
    },
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};

export const enableCustomMessages = async (body: {
  isActive: boolean;
  triggerId: string;
}) => {
  const response = await client.doPut(
    Config.api.automatedMessages.activateCustomMessages,
    body,
  );
  if (response && response.success) {
    return response.data;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};
