import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './SelectTimeSlotLocationPopup.scss';
import Popup from '../../components/Popup';
import Typography from '../../components/Typography';
import CheckboxGroup from '../../components/CheckboxGroup';
import Button from '../../components/Button';
import { ICheckboxOptions } from '../../components/CheckboxGroup/types';
import { InPersonClinic } from '../../../shared/types/response/provider';

interface ISelectTimeSlotLocationPopup {
  locations: InPersonClinic[];
  closeHandler: () => void;
  addSlotForLocation: (locationId: string[]) => void;
}

function SelectTimeSlotLocationPopup({
  locations,
  closeHandler,
  addSlotForLocation,
}: ISelectTimeSlotLocationPopup) {
  const { t } = useTranslation();

  const [locationsList, setLocationsList] = React.useState<ICheckboxOptions[]>(
    [],
  );

  const handleAddClick = () => {
    addSlotForLocation(locationsList.map((loc) => loc.value as string));

    closeHandler();
  };

  return (
    <Popup useDefaultPadding={false} withBackdrop onClose={closeHandler}>
      <div className="select-location-popup">
        <div className="popup-header">
          <Typography size={24} weight="600">
            {t('SELECT_LOCATION', { count: locations.length })}
          </Typography>
        </div>
        <div className="location-select">
          <CheckboxGroup
            options={locations.map((loc) => ({
              label: loc.name,
              value: loc.id,
            }))}
            onChange={(selected) => setLocationsList(selected)}
          />
        </div>

        <div className="confirm-locations-container">
          <Button
            label={t('ADD')}
            variant="primary"
            minHeight="2.5rem"
            minWidth="50%"
            fontSize={14}
            onClick={() => handleAddClick()}
            disabled={locationsList.length < 1}
          />
        </div>
      </div>
    </Popup>
  );
}

export default SelectTimeSlotLocationPopup;
