export default {
  APP: {
    INITIALIZE_MEETING: 'INITIALIZE_MEETING',
    COMPLETE_MEETING: 'COMPLETE_MEETING',
    RESET_MEETING: 'RESET_MEETING',
    SET_PROVIDER_DATA: 'SET_PROVIDER_DATA',
    SET_CHAT_TOKEN: 'SET_CHAT_TOKEN',
  },

  CHAT: {
    SET_CHANNEL_MESSAGES: 'SET_CHANNEL_MESSAGES',
    ADD_NEW_MESSAGE: 'ADD_NEW_MESSAGE',
    PREPEND_MESSAGES: 'PREPEND_MESSAGES',
    PREPEND_SOCKET_MESSAGES: 'PREPEND_SOCKET_MESSAGES',
    UPDATE_MESSAGE_CONSUMPTION_STATUS: 'UPDATE_MESSAGE_CONSUMPTION_STATUS',
    SET_TOTAL_CONVERSATIONS: 'SET_TOTAL_CONVERSATIONS',
    INCREMENT_PAGE_COUNT: 'INCREMENT_PAGE_COUNT',
  },
};
