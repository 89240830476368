import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import englishTranslations from './locales/en.json';
import japaneseTranslations from './locales/ja.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: englishTranslations,
    },
    ja: {
      translations: japaneseTranslations,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'ja'];

export default i18n;


