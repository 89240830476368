import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './view/app-components/Layout/Layout';
import ErrorBoundary from './view/app-components/ErrorBoundary';
import { FetchDevtools } from './utilities/hooks/useFetch';
import { ToastProvider } from './utilities/hooks/useToast';
import AppRoutes from './AppRoutes';

// TODO lazy load screens + suspense fallback

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <ToastProvider>
          <Layout>
            <AppRoutes />
            <FetchDevtools />
          </Layout>
        </ToastProvider>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
