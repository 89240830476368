import * as React from 'react';

export interface TabItem { key: string; label: string }

interface ITabsContext {
  selectedTab: TabItem;
}

const TabsContext = React.createContext<ITabsContext>({} as ITabsContext);

export default TabsContext;

