import * as React from 'react';
import { useTranslation } from 'react-i18next';
import noInsights from '../../../assets/no-insights.png';
import Typography from '../Typography';
import { StyledNewProviderContainer, StyledImageSection, StyledImg } from './NewProviderInsights.styled';

function NewProviderInsights() {
  const { t } = useTranslation();
  return (
    <StyledNewProviderContainer>
      <StyledImageSection>
      <StyledImg src={noInsights} alt="empty insights icon" />
      <Typography weight="700" color="primary" size={20}>
        {t('NO_INSIGHTS')}
      </Typography>
      </StyledImageSection>
    </StyledNewProviderContainer>
  );
}

export default NewProviderInsights;
