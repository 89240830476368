export const GoalsTraitMapping: {
  [key: string]: string[];
} = {
  'Anxiety & worry': [
    '638848c8a83e3f183fc48b7b',
    '6388493da83e3f183fc48d10',
    '638849b5a83e3f183fc48e45',
  ],
  'Career & work ': [
    '63884844a83e3f183fc48a18',
    '63884a81a83e3f183fc48e9d',
    '63884a4ea83e3f183fc48e85',
  ],
  'Emotion regulation': [
    '6388493da83e3f183fc48d10',
    '638849b5a83e3f183fc48e45',
    '63884a4ea83e3f183fc48e85',
  ],
  'Health & lifestyle': [
    '63884785a83e3f183fc48988',
    '63884803a83e3f183fc489e5',
    '63884706a83e3f183fc48975',
  ],
  'Low mood': [
    '63884844a83e3f183fc48a18',
    '63884803a83e3f183fc489e5',
    '63884885a83e3f183fc48b42',
  ],
  Relationships: [
    '6388493da83e3f183fc48d10',
    '638849b5a83e3f183fc48e45',
    '63884abda83e3f183fc48eab',
  ],
  'Self-confidence': [
    '63884785a83e3f183fc48988',
    '638848c8a83e3f183fc48b7b',
    '63884901a83e3f183fc48d02',
  ],
  'Stress & burnout': [
    '6388493da83e3f183fc48d10',
    '63884abda83e3f183fc48eab',
    '63884971a83e3f183fc48d1e',
  ],
};

export const GoalDimesionRelevencyMapping: {
  [key: string]: { [key: string]: string };
} = {
  'Anxiety & worry': {
    '638848c8a83e3f183fc48b7b': 'ANXIETY_AND_WORRY_OPTIMISM',
    '6388493da83e3f183fc48d10': 'ANXIETY_AND_WORRY_EMOTION_REGULATION',
    '638849b5a83e3f183fc48e45': 'ANXIETY_AND_WORRY_RELATIONSHIP_BUILDING',
  },
  'Career & work ': {
    '63884844a83e3f183fc48a18': 'CAREER_AND_WORK_GOAL_ORIENTATION',
    '63884a81a83e3f183fc48e9d': 'CAREER_AND_WORK_WORK_LIFE_BALANCE',
    '63884a4ea83e3f183fc48e85': 'CAREER_AND_WORK_ENCOURAGING_PARTICIPATION',
  },
  'Emotion regulation': {
    '6388493da83e3f183fc48d10': 'EMOTION_REGULATION_EMOTION_REGULATION',
    '638849b5a83e3f183fc48e45': 'EMOTION_REGULATION_BUILDING',
    '63884a4ea83e3f183fc48e85': 'EMOTION_REGULATION_ENCOURAGING_PARTICIPATION',
  },
  'Health & lifestyle': {
    '63884785a83e3f183fc48988': 'HEALTH_AND_LIFESTYLE_SELF_AWARENESS',
    '63884803a83e3f183fc489e5': 'HEALTH_AND_LIFESTYLE_SELF_EFFICACY',
    '63884706a83e3f183fc48975': 'HEALTH_AND_LIFESTYLE_GROWTH_MINDSET',
  },
  'Low mood': {
    '63884844a83e3f183fc48a18': 'LOW_MOOD_GOAL_ORIENTATION',
    '63884803a83e3f183fc489e5': 'LOW_MOOD_SELF_EFFICACY',
    '63884885a83e3f183fc48b42': 'LOW_MOOD_PURPOSE_AND_MEANING',
  },
  Relationships: {
    '6388493da83e3f183fc48d10': 'RELATIONSHIPS_RELATIONSHIP_EMOTION_REGULATION',
    '638849b5a83e3f183fc48e45':
      'RELATIONSHIPS_RELATIONSHIP_RELATIONSHIP_BUILDING',
    '63884abda83e3f183fc48eab': 'RELATIONSHIPS_RELATIONSHIP_MENTAL_WELLBEING',
  },
  'Self-confidence': {
    '63884785a83e3f183fc48988': 'SELF_CONFIDENCE_SELF_AWARENESS',
    '638848c8a83e3f183fc48b7b': 'SELF_CONFIDENCE_OPTIMISM',
    '63884901a83e3f183fc48d02': 'SELF_CONFIDENCE_RESILIENCE',
  },
  'Stress & burnout': {
    '6388493da83e3f183fc48d10': 'STRESS_BURNOUT_EMOTION_REGULATION',
    '63884abda83e3f183fc48eab': 'STRESS_BURNOUT_MENTAL_WELLBEING',
    '63884971a83e3f183fc48d1e': 'STRESS_BURNOUT_STRESS_MANAGEMENT',
  },
};

export const TraitIdMapping: {
  [key: string]: string;
} = {
  '63884706a83e3f183fc48975': 'GrowthMindset',
  '63884785a83e3f183fc48988': 'SelfAwareness',
  '63884803a83e3f183fc489e5': 'SelfEfficacy',
  '63884844a83e3f183fc48a18': 'GoalOrientation',
  '63884885a83e3f183fc48b42': 'Purpose',
  '638848c8a83e3f183fc48b7b': 'Optimism',
  '63884901a83e3f183fc48d02': 'Resilience',
  '6388493da83e3f183fc48d10': 'EmotionRegulation',
  '63884971a83e3f183fc48d1e': 'StressManagement',
  '638849b5a83e3f183fc48e45': 'RelationshipBuilding',
  '63884a4ea83e3f183fc48e85': 'EncouragingParticipation',
  '63884a81a83e3f183fc48e9d': 'WorkLifeBalance',
  '63884abda83e3f183fc48eab': 'MentalWellbeing',
};

export const PHQQuestions = ['QUESTION_1', 'QUESTION_2', 'QUESTION_3', 'QUESTION_4'];

// TODO check key with goal in response of api
