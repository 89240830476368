export const ProviderCancellationReasons: { [key: string]: string } = {
  CANCEL_REASON_PROVIDER_SCHEDULING_CONFLICT: '10',
  CANCEL_REASON_PROVIDER_PERSONAL: '11',
  CANCEL_REASON_PROVIDER_CLIENT_ASKED: '12',
  CANCEL_REASON_PROVIDER_CHANGE_DURATION: '13',
  CANCEL_REASON_PROVIDER_OOO: '15',
  CANCEL_REASON_PROVIDER_OTHERS: '14',

};

export enum CancellationModalContent {
  CANCELLATION_REASONS = 'cancellationReasons',
  WARNING_CONTENT = 'warningContent',
  LEAVE_NOTE_CONTENT = 'leaveNoteContent',
  RESCHEDULE_CONTENT = 'rescheduleContent',
  LATE_CANCELATION_METRIC = 'lateCancellationMetric',
  PENDING_INFO_CONTENT = 'cancelPendingStatus',
  IN_PERSON_TYPE = 'inPersonType',
}


export const CancellingPendingStatus = {
  NOTE: '',
  OTHER_REASON: 'Cancelling the meeting, because of pending state',
};