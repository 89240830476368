import * as React from 'react';
import './SideNavBar.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import intellectLogo from '../../../assets/IntellectLogo.svg';
import sidebarCloseIcon from '../../../assets/sidebar-close.svg';
import clientsIcon from '../../../assets/clients.svg';
import insightIcon from '../../../assets/union.svg';
import sessionsIcon from '../../../assets/sessions.svg';
import scheduleIcon from '../../../assets/schedule.svg';
import billingIcon from '../../../assets/billing.svg';
import settingsIcon from '../../../assets/msgSettings.svg';
import assignmentsIcon from '../../../assets/assignments.svg';
import sessionsSelectedIcon from '../../../assets/sessions-selected.svg';
import scheduleSelectedIcon from '../../../assets/schedule-selected.svg';
import clientsSelectedIcon from '../../../assets/clients-selected.svg';
import insightIconSelectedIcon from '../../../assets/union-selected.svg';
import billingSelectedIcon from '../../../assets/billing-selected.svg';
import settingsSelectedIcon from '../../../assets/msgSettings-selected.svg';
import assignmentsSelectedIcon from '../../../assets/assignments-selected.svg';
import logoutIcon from '../../../assets/logout.svg';
import Typography from '../../components/Typography';
import useAuth from '../../../utilities/hooks/useAuth';
import LanguageSwitcher from '../LanguageSwitcher';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';
import Button from '../../components/Button';
import { getCurrentLocale } from '../../../i18n';
import { externalUrls } from '../../../shared/Config';

interface NavBarItem {
  id: number;
  label: string;
  route: string;
  icons: {
    selected: string;
    notSelected: string;
  };
  instrumentation?: {
    eventName: string;
    eventAction: string;
    eventCategory: string;
    eventLabel: string;
    featureVersion?: string;
  };
}

const navBarItems: NavBarItem[] = [
  {
    id: 1,
    label: 'upcomingSessions',
    route: '/sessions',
    icons: {
      selected: sessionsSelectedIcon,
      notSelected: sessionsIcon,
    },
    instrumentation: {
      eventName: EventNames.upcomingSessions,
      eventAction: EventActions.click,
      eventCategory: EventCategories.upcomingSessions,
      eventLabel: 'upcoming_sessions',
      featureVersion: 'v1',
    },
  },
  {
    id: 2,
    label: 'schedule',
    route: '/schedule',
    icons: {
      selected: scheduleSelectedIcon,
      notSelected: scheduleIcon,
    },
    instrumentation: {
      eventName: EventNames.workingHours,
      eventAction: EventActions.click,
      eventCategory: EventCategories.schedule,
      eventLabel: 'schedule',
      featureVersion: 'v1',
    },
  },
  {
    id: 3,
    label: 'clients',
    route: '/clients',
    icons: {
      selected: clientsSelectedIcon,
      notSelected: clientsIcon,
    },
  },
  {
    id: 4,
    label: 'INSIGHTS',
    route: '/insights',
    icons: {
      selected: insightIconSelectedIcon,
      notSelected: insightIcon,
    },
  },
  {
    id: 5,
    label: 'assignments',
    route: '/assignments',
    icons: {
      selected: assignmentsSelectedIcon,
      notSelected: assignmentsIcon,
    },
  },
  {
    id: 6,
    label: 'billingInfo',
    route: '/billing',
    icons: {
      selected: billingSelectedIcon,
      notSelected: billingIcon,
    },
  },
  {
    id: 7,
    label: 'SETTINGS_TAB',
    route: '/settings',
    icons: {
      selected: settingsSelectedIcon,
      notSelected: settingsIcon,
    },
    instrumentation: {
      eventName: EventNames.settings,
      eventAction: EventActions.click,
      eventCategory: EventCategories.settings,
      eventLabel: 'settings',
      featureVersion: 'v1',
    },
  },
];

interface SideNavBarProps {
  collapsed: boolean;
  handleCloseSidebar: () => void;
}

function SideNavBar({ collapsed, handleCloseSidebar }: SideNavBarProps) {
  const [selectedNavBarItem, setSelectedNavBarItem] = React.useState(1);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentLanguage = getCurrentLocale();
  const { invalidateUser } = useAuth();
  const { track } = useTracking();

  React.useEffect(() => {
    const navbarItem = navBarItems.find(
      (item) => item.route === location.pathname,
    );
    if (navbarItem) {
      setSelectedNavBarItem(navbarItem.id);
    }
  }, [location]);

  const onNavBarItemClick = (
    id: number,
    route: string,
    instrumentation: NavBarItem['instrumentation'],
  ) => {
    setSelectedNavBarItem(id);
    navigate(route);
    handleCloseSidebar();
    if (instrumentation) {
      track(instrumentation.eventName, {
        eventAction: instrumentation.eventAction,
        eventCategory: instrumentation.eventCategory,
        eventLabel: instrumentation.eventLabel,
        featureVersion: instrumentation.featureVersion,
      });
    }
  };

  const onNavBarItemKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    navBarItemId: number,
  ) => {
    if (event.key === '13') {
      setSelectedNavBarItem(navBarItemId);
    }
  };

  const onLogoutClick = () => {
    invalidateUser();
  };

  const openClickedURL = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        aria-label="close sidebar"
        onClick={handleCloseSidebar}
        className={`open-sidebar-backdrop ${collapsed ? 'closed' : 'open'}`}
      />
      <div className={`side-navbar-container ${collapsed ? 'closed' : 'open'}`}>
        <div className="navbar-group">
          <div className="logo-container">
            <img src={intellectLogo} className="logo" alt="intellect logo" />
            {collapsed ? null : (
              <div
                className="close-sidebar-button"
                role="button"
                tabIndex={0}
                onClick={handleCloseSidebar}
              >
                <img src={sidebarCloseIcon} alt="close sidebar" />
              </div>
            )}
          </div>
          {navBarItems.map(
            ({ id, label, route, icons, instrumentation }, index) => {
              const isSelected = selectedNavBarItem === id;
              return (
                <motion.div
                  className={`navbar-item ${isSelected ? 'selected' : ''}`}
                  key={id}
                  initial={{ backgroundColor: '#F1F2F7' }}
                  whileHover={{ backgroundColor: '#E9EAEB' }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => onNavBarItemClick(id, route, instrumentation)}
                  onKeyDown={(event) => onNavBarItemKeyDown(event, id)}
                  tabIndex={index}
                  role="button"
                >
                  <img
                    src={isSelected ? icons.selected : icons.notSelected}
                    alt={label}
                    className="icon"
                  />
                  <Typography
                    color={isSelected ? 'primary' : 'secondary'}
                    size={12}
                    weight={isSelected ? '500' : '400'}
                  >
                    {t(label)}
                  </Typography>
                </motion.div>
              );
            },
          )}
          <section className="handbook-container">
            <Button
              label={t('PROVIDER_HANDBOOK')}
              variant="label"
              onClick={() =>
                openClickedURL(
                  currentLanguage === 'en'
                    ? externalUrls.providerHandbook.notionLinkEn
                    : externalUrls.providerHandbook.notionLinkJa,
                )
              }
              breakWord="keep-all"
            />
            <Button
              label={t('TALENT_LMS')}
              variant="label"
              onClick={() =>
                openClickedURL(
                  currentLanguage === 'en'
                    ? externalUrls.talentLMSCourse.talentLinkEn
                    : externalUrls.talentLMSCourse.talentLinkJa,
                )
              }
            />
          </section>
        </div>
        <div className="navbar-group">
          <div className="navbar-item">
            <LanguageSwitcher />
          </div>
          <div
            className="navbar-item"
            onClick={onLogoutClick}
            role="button"
            tabIndex={navBarItems.length + 1}
          >
            <img src={logoutIcon} alt="logout" className="icon" />
            <Typography color="error" size={12} weight="400">
              {lodash.capitalize(t('logout'))}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}

export default SideNavBar;
