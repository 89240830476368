/* eslint-disable @typescript-eslint/indent */
import { Message } from '@twilio/conversations';
import ActionTypes from '../../constant/ActionTypes';
import { IChatItem, IChatMessagePage } from '../../types/Chat';

const defaultState = {
  allChats: [],
  totalConversations: 0,
  selectedPage: 0,
};

const defaultAction = {
  type: '',
  payload: '',
};

interface IChatReducerState {
  allChats: Array<IChatItem>;
  totalConversations: number;
}

type Action = { type: string; payload: any };

export default (
  state: IChatReducerState = defaultState,
  action: Action = defaultAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CHAT.SET_CHANNEL_MESSAGES:
      return {
        ...state,
        allChats: [...state.allChats, payload],
      };

    case ActionTypes.CHAT.SET_TOTAL_CONVERSATIONS:
      return {
        ...state,
        totalConversations: payload,
      };

    case ActionTypes.CHAT.ADD_NEW_MESSAGE: {
      const newMessage = payload as Message;
      return {
        ...state,
        allChats: state.allChats.map((channel) =>
          channel.channelId === newMessage.conversation.sid
            ? {
                ...channel,
                messages: {
                  ...channel.messages,
                  items: [...channel.messages.items, newMessage],
                },
              }
            : channel,
        ),
      };
    }

    case ActionTypes.CHAT.PREPEND_SOCKET_MESSAGES: {
      const newMessages = payload as IChatMessagePage;
      const newMessagesChannel = newMessages?.items[0]?.conversationId;
      return {
        ...state,
        allChats: state.allChats.map((channel) =>
          channel.conversationId === newMessagesChannel
            ? {
                ...channel,
                messages: {
                  ...channel.messages,
                  items: [
                    ...newMessages.items.reverse(),
                    ...channel.messages.items,
                  ],
                  page: newMessages.page,
                },
              }
            : channel,
        ),
      };
    }

    case ActionTypes.CHAT.PREPEND_MESSAGES: {
      const newMessages = payload as IChatMessagePage;
      const newMessagesChannel = newMessages?.items[0]?.conversationId;
      return {
        ...state,
        allChats: state.allChats.map((channel) =>
          channel.conversationId === newMessagesChannel
            ? {
                ...channel,
                messages: {
                  ...channel.messages,
                  ...newMessages,
                  items: [...channel.messages.items, ...newMessages.items],
                  page: newMessages.page,
                },
              }
            : channel,
        ),
      };
    }

    case ActionTypes.CHAT.UPDATE_MESSAGE_CONSUMPTION_STATUS: {
      const { conversationId, count } = payload;
      return {
        ...state,
        allChats: state.allChats.map((channel) =>
          channel.conversationId === conversationId
            ? {
                ...channel,
                unConsumedMessageCount: count,
              }
            : channel,
        ),
      };
    }

    default:
      return state;
  }
};
