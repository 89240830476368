import { useNavigate } from 'react-router-dom';
import { StorageItems } from '../../../shared/constant/App';
import { IUser } from '../../../shared/types/response/user';
import { refreshApp } from '../../common/Path';
import {
  clearAppState,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from '../../common/Storage';

interface IUseAuthReturns {
  isLoggedIn: string;
  user: IUser;
  userToken: string;
  invalidateUser: () => void;
}

function clearCache() {
  const cacheItems = [StorageItems.USER_INFO, StorageItems.CHAT_TOKEN];
  cacheItems.forEach(cache => removeItemFromLocalStorage(cache));
}

function useAuth(): IUseAuthReturns {
  const navigate = useNavigate();
  const user = getItemFromLocalStorage(StorageItems.USER_INFO, 'object') as IUser;
  const isLoggedIn = user && user.id && user.token;

  const invalidateUser = () => {
    clearCache();
    navigate('/login');
    clearAppState();
    refreshApp(); // doing a refresh to clear the user sessions
  };

  return { isLoggedIn, user, userToken: user.token, invalidateUser };
}

export default useAuth;
