import * as React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, Row } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import './Table.scss';
import Loader from '../Loader';

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  onTableRowClick?: (data: Row<T>) => void;
  isLoading: boolean;
  noDataComponent?: React.ReactNode | string;
  height?: string;
  onSortClick?: (colName: string, sortDir: string | false) => void;
  manualSorting?: boolean;
}

function CustomTable<T extends object>({
  data,
  columns,
  onTableRowClick,
  isLoading,
  noDataComponent = 'no data found',
  height = 'auto',
  onSortClick,
  manualSorting = false,
}: ReactTableProps<T>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting,
  });
  const { t } = useTranslation();
  const ref = React.useRef<HTMLElement>(null);
  return (
    <article className="table-wrapper" style={{ height }} ref={ref}>
      <table width={table.getTotalSize()}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="table-header-row">
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="table-headers">
                  <Typography
                    size={12}
                    weight="500"
                    color="primary"
                    textAlign="left"
                  >
                    <span className="header-icon-wrapper">
                      {typeof header.column.columnDef.header === 'function'
                        ? header.column.columnDef?.header(header.getContext())
                        : t(header.column.columnDef.header as string)}
                      {header.column.getCanSort() && (
                        <span
                          onClick={(e) => {
                            header.column.getToggleSortingHandler()?.(e);
                            onSortClick?.(
                              header.id as string,
                              header.column.getNextSortingOrder(),
                            );
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            color={
                              header.column.getIsSorted() === 'asc'
                                ? '#1A2631'
                                : '#98A2B3'
                            }
                          />
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            color={
                              header.column.getIsSorted() === 'desc'
                                ? '#1A2631'
                                : '#98A2B3'
                            }
                          />
                        </span>
                      )}
                    </span>
                  </Typography>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={`table-data-row ${onTableRowClick && 'clickable'}`}
              onClick={() => onTableRowClick?.(row)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="table-data">
                  <Typography
                    color="swamp"
                    size={12}
                    weight="400"
                    textAlign="left"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Typography>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {!isLoading && !table.getRowModel().rows.length && (
        <section className="table-error-wrapper">{noDataComponent}</section>
      )}
      {isLoading && (
        <Loader
          useFullPage={false}
          dimension={ref.current?.getBoundingClientRect()}
        />
      )}
    </article>
  );
}

export default CustomTable;
