import * as React from 'react';
import './AssignToClientsPopup.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Popup from '../../components/Popup';
import TextBox from '../../components/TextBox';
import Typography from '../../components/Typography';
import searchIcon from '../../../assets/search.svg';
import Button from '../../components/Button';
import AssignClientListItem from '../AssignClientListItem';
import closeIcon from '../../../assets/close.svg';
import { IClientSummary } from '../../../shared/types/response/provider';
import { AssignSuccess } from '../../../shared/types/response/assignment';
import { useMutation } from '../../../utilities/hooks/useFetch';
import { assignToClients } from '../../../shared/redux/actions/assignment';
import useToast from '../../../utilities/hooks/useToast';
import NoResultsFound from '../NoResultsFound';
import useTracking from '../../../utilities/hooks/useTracking';
import { EventNames, EventActions, EventCategories } from '../../../shared/constant/Analytics';
import AssignmentNotePopup from '../AssignmentNotePopup';

interface AssignToClientsPopupProps {
  clientsData: IClientSummary[];
  assignmentType: 'internal' | 'custom';
  assignmentData: { entityId: string, type: string, language?: string };
  onClose: () => void;
}

function AssignToClientsPopup({ clientsData, assignmentType, assignmentData, onClose }: AssignToClientsPopupProps) {
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const { track } = useTracking();
  const [searchText, setSearchText] = React.useState<string>('');
  const [assignedClients, setAssignedClients] = React.useState<IClientSummary[]>([]);
  const [showNotesPopup, setShowNotesPopup] = React.useState<boolean>(false);

  const onAssignSuccess = (data: AssignSuccess) => {
    const toastMessages = [];
    const { assigned, alreadyAssigned } = data;
    if (assigned) {
      track(EventNames.providerWebAssignment, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.assignmentAssigned,
        eventLabel: 'assignment_assigned',
      });
      toastMessages.push(t('successfullyAssigned', { count: assigned }));
    }
    if (alreadyAssigned) {
      track(EventNames.providerWebAssignment, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.cancelledAssignment,
        eventLabel: 'cancelled_assignment',
      });
      toastMessages.push(t('similarAssignmentsDetected', { count: alreadyAssigned }));
    }

    toast.show({ message: toastMessages, closeAfter: 2000 });
  };

  const { mutate: sendAssignments } = useMutation((note: string) => dispatch(
    assignToClients(assignmentType, assignmentData, assignedClients.map((c) => c.userId), note),
  ), { onSuccess: onAssignSuccess });

  const onClientCheckBoxChange = (client: IClientSummary, isChecked: boolean) => {
    if (isChecked) {
      const newAssignedClients = [...assignedClients];
      newAssignedClients.push(client);
      setAssignedClients(newAssignedClients);
    } else {
      const newAssignedClients = assignedClients.filter(({ userId }) => userId !== client.userId);
      setAssignedClients(newAssignedClients);
    }
  };

  // some assignments don't need a note, so we bypass it and save without a note
  const isNotesRequiredForAssignment = () => {
    if (assignmentData.type === 'questionnaire') {
      return false;
    }

    return true;
  };

  const onAssignClick = () => {
    if (isNotesRequiredForAssignment()) {
      setShowNotesPopup(true);
    } else {
      sendAssignments();
      onClose();
    }
    if (assignmentType === 'custom') {
      track(EventNames.providerWebCustomAssignment, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.selectClients,
        eventLabel: 'select_clients',
      });
    }
  };

  const onAssignmentNotesClose = () => {
    sendAssignments();
    onClose();
  };

  const onAssignmentNotesSave = (note: string) => {
    sendAssignments(note);
    onClose();
  };

  const filteredClients = clientsData.filter((c) => c.user.friendlyName?.toLowerCase().includes(searchText.toLowerCase()));

  React.useEffect(() => {
    if (searchText && !filteredClients.length) {
      track(EventNames.providerWebAssignment, {
        eventAction: EventActions.click,
      });
    }
  }, [filteredClients]);

  return (
    <Popup
      withBackdrop
      backdropMode="glass"
      showCloseIcon={false}
      onClose={onClose}
      useDefaultPadding={false}
      show={!showNotesPopup}
    >
      <div className="assign-container">
        {showNotesPopup && (
          <AssignmentNotePopup
            onClose={onAssignmentNotesClose}
            onSave={onAssignmentNotesSave}
          />
        )}
        <img src={closeIcon} alt="close" className="close" onClick={onClose} />
        <div className="assign-headers">
          <Typography size={18} weight="500">
            {t('selectClients')}
          </Typography>
          <TextBox
            animate={false}
            variant="box-border"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            icon={searchIcon}
            placeholder={t('searchNames')}
          />
        </div>
        <div className="clients-list">
          {!filteredClients.length && (
            <div className="empty-results">
              <NoResultsFound
                captionCopy={
                  searchText && !filteredClients?.length
                    ? t('noResults')
                    : t('NO_CLIENTS_HEADER')
                }
                description={
                  searchText && !filteredClients?.length
                    ? t('pleaseCheckKeywords')
                    : t('NO_CLIENTS_SUBHEADER')
                }
              />
            </div>
          )}
          {filteredClients.map((client, index) => (
            <AssignClientListItem
              isChecked={assignedClients.some(
                (c) => c.userId === client.userId,
              )}
              key={`${client.userId}-${client.conversationId}`}
              onClientCheckBoxChange={onClientCheckBoxChange}
              clientDetails={client}
              index={index}
              isCheckBoxDisable={
                !client?.hasAppAccess ||
                (!!assignmentData.language &&
                  assignmentData.language !== client.language)
              }
            />
          ))}
        </div>
        <div className="footer-container">
          <Typography size={14} weight="400" color="secondary">
            {t('clientsSelected', { count: assignedClients.length })}
          </Typography>
          <Button
            label={t('assign')}
            variant="blue"
            fontSize={14}
            fontWeight="400"
            height="2.5rem"
            disabled={!assignedClients.length}
            onClick={() => onAssignClick()}
          />
        </div>
      </div>
    </Popup>
  );
}


export default AssignToClientsPopup;
