import * as React from 'react';
import './DatePickerPopper.scss';
import moment from 'moment';
import leftChevronIcon from '../../assets/left.svg';
import rightChevronIcon from '../../assets/right.svg';
import useCalender from '../../hooks/useCalender';
import { CalenderDay } from '../../types';
import Button from '../../../Button';
import TextBox from '../../../TextBox';
import Typography from '../../../Typography';
import { APIDateFormat } from '../../../../../utilities/common/Date';
import { defaultStyles } from '../../../../../configs/styles/defaultStyle';

interface DatePickerPopperProps {
  top: number;
  left: number;
  onSelectDate: (date: string) => void;
  onCancel: () => void;
  enabledDates?: string[];
  daysToDisable?: number;
}

function DatePickerPopper({
  top,
  left,
  onSelectDate,
  onCancel,
  enabledDates,
  daysToDisable,
}: DatePickerPopperProps) {
  const [popperPosition, setPopperPosition] = React.useState<{
    x: number;
    y: number;
  }>({ x: left, y: top });
  const [selectedDay, setSelectedDay] = React.useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = React.useState<string>(
    moment().format(APIDateFormat),
  );
  const popperRef = React.useRef<HTMLDivElement>(null);
  const { values } = useCalender(selectedMonth);
  const selectedMonthMoment = moment(selectedMonth, APIDateFormat);
  const selectedDayMoment =
    selectedDay && selectedMonthMoment.set('date', selectedDay);

  const navigateMonth = (action: 'next' | 'prev') => {
    if (action === 'next') {
      const newMonth = selectedMonthMoment
        .add(1, 'month')
        .format(APIDateFormat);
      setSelectedMonth(newMonth);
    } else if (action === 'prev') {
      const newMonth = selectedMonthMoment
        .subtract(1, 'month')
        .format(APIDateFormat);
      setSelectedMonth(newMonth);
    }
  };

  const onDayClick = (day: CalenderDay) => {
    if (day.indicator !== 'disabled') {
      setSelectedDay(day.day);
    }
  };

  const getDayFontColor = (day: CalenderDay) => {
    if (day.day === selectedDay && day.indicator !== 'disabled') {
      return defaultStyles.while.pureWhite;
    }

    if (day.indicator === 'disabled') {
      return defaultStyles.gray[500];
    }

    return defaultStyles.primaryColor;
  };

  const shouldDisableSelectButton = () => {
    if (!selectedDay) return true;
    if (daysToDisable) {
      return !!selectedDayMoment && selectedDayMoment.isBefore(moment().add(daysToDisable, 'day'), 'day');
    }
    if (selectedDayMoment && selectedDayMoment.isBefore(moment(), 'day')) {
      return true;
    }

    return false;
  };

  const getDayClassName = (calenderDay: CalenderDay) => {
    let className = 'calender-day';
    const { day, indicator } = calenderDay;
    const formattedDay = selectedMonthMoment
      .clone()
      .set('date', day)
      .format(APIDateFormat);

    if (enabledDates) {
      if (enabledDates.includes(formattedDay)) {
        className += ' enable-marker';
      }
    }

    if (day === selectedDay && indicator !== 'disabled') {
      className += ' selected';
    }

    return className;
  };

  React.useEffect(() => {
    setSelectedDay(null);
  }, [selectedMonth]);

  const onParentAnimationComplete = () => {
    // when popper is rendered, in devices with small resolution, some part of the popper will rendered off-screen
    // this will adjust the popper position based on device resolution when it rendered off-screen
    const padding = 16; // px
    const popperRect = popperRef.current?.getBoundingClientRect();
    if (popperRect) {
      const isPopperOffScreenHorizontal = popperRect.right > window.innerWidth;
      const isPopperOffScreenVertical = popperRect.bottom > window.innerHeight;
      if (isPopperOffScreenHorizontal) {
        const intersectionDelta = popperRect.right - window.innerWidth;
        const newXPosition = popperPosition.x - intersectionDelta - padding;
        setPopperPosition((prev) => ({ ...prev, x: newXPosition }));
      }

      if (isPopperOffScreenVertical) {
        const intersectionDelta = popperRect.bottom - window.innerHeight;
        const newYPosition = popperPosition.y - intersectionDelta - padding;
        setPopperPosition((prev) => ({ ...prev, y: newYPosition }));
      }
    }
  };

  React.useEffect(() => {
    onParentAnimationComplete();
  }, []);

  return (
    <div
      ref={popperRef}
      className="picker-popper"
      style={{ left: popperPosition.x, top: popperPosition.y }}
    >
      <div className="picker-navigation">
        <img
          className="navigation"
          src={leftChevronIcon}
          alt="previous month"
          onClick={() => navigateMonth('prev')}
        />
        <Typography size={14} weight="500">
          {selectedMonthMoment.format('MMMM YYYY')}
        </Typography>
        <img
          className="navigation"
          src={rightChevronIcon}
          alt="next month"
          onClick={() => navigateMonth('next')}
        />
      </div>
      <div className="calender-days">
        {moment.weekdaysShort().map((weekday) => (
          <div className="calender-day">
            <Typography size={14} weight="500">
              {weekday}
            </Typography>
          </div>
        ))}
        {values.map((day, index) => (
          <div
            role="button"
            tabIndex={index}
            className={getDayClassName(day)}
            onClick={() => onDayClick(day)}
          >
            <Typography size={14} weight="500" withColor={getDayFontColor(day)}>
              {day.day}
            </Typography>
          </div>
        ))}
      </div>
      <div className="selected-day">
        <TextBox
          value={
            selectedDayMoment
              ? selectedMonthMoment.format('MMM DD, YYYY - dddd')
              : ''
          }
          onChange={() => {}}
          variant="box-border"
        />
      </div>
      <div className="date-picker-action">
        <Button
          width="40%"
          label="Cancel"
          variant="secondary"
          onClick={onCancel}
        />
        <Button
          width="40%"
          label="Select"
          variant="blue"
          disabled={shouldDisableSelectButton()}
          onClick={() => {
            if (selectedDayMoment) {
              onSelectDate(selectedDayMoment.format(APIDateFormat));
              onCancel();
            }
          }}
        />
      </div>
    </div>
  );
}

export default DatePickerPopper;
