import { useMutation } from 'react-query';
import useToast from '../useToast';
import { sosRequestLogger } from '../../../shared/service/API/sosTracking';

function sosRequestTracker() {
  const toast = useToast();

  const { mutate: logSOSEvent, isLoading: isSOSLogLoading } = useMutation(
    (body: any) => sosRequestLogger(body),
    {
      onSuccess: (data) => {
        console.log('sos tracking', data);
      },
      onError: (errorData: Error) => {
        toast.show({ message: errorData.message });
      },
    },
  );
  return {
    logSOSEvent,
    isSOSLogLoading,
  };
}

export default sosRequestTracker;
