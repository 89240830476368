import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IFollowUpSessionsForm,
  IFollowUpSessionsFormSections,
} from '../../FollowUpSession.type';
import MultilevelDropdown from '../../../../components/MultilevelDropdown';
import {
  IMultiOption,
  IMultiOptionsData,
} from '../../../../components/MultilevelDropdown/types';
import './PresentingIssues.scss';
import Heading from '../../Heading';
import Typography from '../../../../components/Typography';

interface IPresentingIssues {
  primaryHeaderContent?: React.ReactNode;
  secondaryHeaderContent?: React.ReactNode;
  primaryOptions?: IMultiOptionsData[] | IMultiOption[];
  secondaryOptions?: IMultiOptionsData[] | IMultiOption[];
  primaryQuestion?: { id?: number; question: string };
  secondaryQuestion?: { id?: number; question: string };
  hideTag?: boolean;
}

function PresentingIssues({
  watch,
  setValue,
  secondaryHeaderContent,
  primaryHeaderContent,
  primaryOptions,
  secondaryOptions,
  primaryQuestion,
  secondaryQuestion,
  hideTag = false,
}: IFollowUpSessionsFormSections & IPresentingIssues) {
  const { t } = useTranslation();
  const handleChange =
    (key: keyof IFollowUpSessionsForm) =>
      (value: IMultiOption | IMultiOption[]) => {
        if (setValue) setValue(key, value as IMultiOptionsData);
      };
  return (
    <div className="presentingIssues">
      <section className="primary-issues">
        {primaryHeaderContent || (
          <Heading
            headerText={primaryQuestion?.question}
            hideTooltipChips
            toolTipID="-1"
          />
        )}
        <div>
          <MultilevelDropdown
            onChange={handleChange('primaryIssue')}
            multiDropValues={primaryOptions as IMultiOptionsData[]}
            isMultiSelect={false}
            defaultSelected={watch('primaryIssue') as IMultiOptionsData[]}
            placeholderText={t('SELECT')}
          />
        </div>
      </section>
      <section className="secondary-issues">
        {secondaryHeaderContent || (
          <Heading
          headerNode={
            <Typography size={14} weight="600">
              {secondaryQuestion?.question}
              <span className="subTextLimit">
                &nbsp;({t('CHOOSE_MAX_THREE')})
              </span>
            </Typography>
          }
          toolTipID="2"
          hideTooltipChips={hideTag}
        />
        ) }
        <MultilevelDropdown
          onChange={handleChange('secondaryIssue')}
          multiDropValues={secondaryOptions as IMultiOptionsData[]}
          defaultSelected={watch('secondaryIssue') as IMultiOptionsData[]}
          isMultiSelect
          placeholderText={t('SELECT')}
        />
      </section>
    </div>
  );
}

export default PresentingIssues;
