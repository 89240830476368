import * as React from 'react';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import './AppointmentsCard.scss';
import useToast from '../../../utilities/hooks/useToast';
import useCancellation from '../../../utilities/hooks/fetchHooks/cancellation';
import chevronRightIcon from '../../../assets/chevron-right.svg';
import cancelAppointmentsIllustration from '../../../assets/cancel-appointments.png';
import {
  IProviderDailyData,
  SessionData,
} from '../../../shared/types/response/provider';
import CancelAppointmentsPopup from '../CancelAppointmentsPopup';
import CancellationReasonsModal from '../CancellationReasonsModal';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import { APIDateFormat } from '../../../utilities/common/Date';
import { ICancellationModal } from '../CancellationReasonsModal/types';
import { useCancellationData } from '../../../utilities/hooks/useCancellationData/useCancellationData';

interface AppointmentsCardProps {
  meetings: IProviderDailyData['meetings'];
}

const containerAnimationVariants = {
  hover: {},
};

const imageAnimationVariants = {
  hover: {
    x: '10px',
  },
};

function AppointmentsCard({ meetings }: AppointmentsCardProps) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const selectedMeetingIdRef = React.useRef<string>('');
  const [hasCancelledAllMeetings, setCancelledAllMeetings] =
    React.useState<boolean>(false);
  const [showCancellationPopup, setShowCancellationPopup] =
    React.useState<boolean>(false);
  const [meetingsList, setMeetingsList] =
    React.useState<IProviderDailyData['meetings']>(meetings);
  const [sessionData, setSessionData] = React.useState<SessionData | null>(
    null,
  );
  const [isWithIn24Hrs, setIsWithin24Hrs] = React.useState<boolean>(false);
  const [isReschedule, setIsReschedule] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (sessionData === null) {
      if (isWithIn24Hrs) setIsWithin24Hrs(false);
      if (isReschedule) setIsReschedule(false);
    }
  }, [sessionData]);
  const onAppointmentPopupClose = (cancelledAll: boolean) => {
    if (cancelledAll) setCancelledAllMeetings(cancelledAll);
    else setShowCancellationPopup(false);
  };

  const toast = useToast();
  const handleCancellationPopupClose = (isFlowInCompleted: boolean = true) => {
    if (isFlowInCompleted) {
      track(EventNames.providerCancelSession, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.cancellationReason,
        eventLabel: 'close_button',
        featureVersion: 'v1',
      });
    }
    selectedMeetingIdRef.current = '';
    setShowCancellationPopup(true);
    setSessionData(null);
  };

  const handleCancellationMeetingSuccess = () => {
    const newList = meetingsList.filter(
      (meeting) => meeting.id !== selectedMeetingIdRef.current,
    );
    setMeetingsList(newList);
    toast.show({
      closeAfter: 3000000,
      message: t('CANCELLATION_SUCCESS', {
        clientName: sessionData?.friendlyName,
        date: moment(sessionData?.displayDate, APIDateFormat).format(
          'Do MMM, dddd',
        ),
        time: sessionData?.displayTime,
      }),
    });
    handleCancellationPopupClose(false);
  };
  const { mutate: cancelAppointment } = useCancellation({
    onSuccess: handleCancellationMeetingSuccess,
    onError: (error) => {
      toast.show({ message: error.message });
    },
  });

  const getSessionDataFromProviderMeetingList = (
    selectedMeetingId: string,
  ): SessionData | null => {
    if (meetingsList.length) {
      const session: any = meetingsList.find(
        ({ id: _id }) => selectedMeetingId === _id,
      );
      if (session) {
        const {
          participants,
          displayDate,
          displayTime,
          displayTimeEnd,
          id,
          status,
          type,
          scheduledStartTime,
          scheduledEndTime,
          meetingTimeZone,
          providerId,
          providerRole,
          room,
          roomId,
        } = session;
        const { friendlyName, userId, createdAt, coachingTier, tags } =
          participants[0];

        return {
          displayDate,
          displayTime,
          displayTimeEnd,
          friendlyName,
          userId: userId.toString(),
          meetingId: id,
          status,
          userCreatedAt: createdAt,
          coachingTier,
          tags,
          providerRole,
          type,
          scheduledStartTime,
          scheduledEndTime,
          providerId,
          meetingTimeZone,
          room,
          roomId,
        };
      }
      return null;
    }
    return null;
  };

  const getHoursDifference = (sdt: SessionData) => {
    const currentTimeInMillSec = Date.now();
    const epochTimeInMillSec = (Number(sdt.scheduledStartTime) || 0) * 1000;
    const currentTime = moment(currentTimeInMillSec);
    const epochTime = moment(epochTimeInMillSec);
    const differenceInHours = epochTime.diff(currentTime, 'hours');
    if (differenceInHours <= 24) {
      return true;
    }
    return false;
  };
  const handleMeetingId = (id: string) => {
    const std = getSessionDataFromProviderMeetingList(id);
    if (std) {
      selectedMeetingIdRef.current = id;
      setIsWithin24Hrs(() => getHoursDifference(std));
      setSessionData(() => std);
      track(EventNames.providerCancelSession, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.cancelSession,
        eventLabel: 'provider_cancel_session',
      });
      setShowCancellationPopup(false);
    } else {
      toast.show({
        message: t('UNABLE_TO_LOAD_DATA'),
      });
    }
  };

  const handleWarningModalCancel = (rescheduleState: boolean) => {
    setIsReschedule(rescheduleState);
    track(EventNames.providerCancelSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.confirmCancellation,
      eventLabel: rescheduleState
        ? 'reschedule_session'
        : 'confirm_cancellation',
      featureVersion: 'v1',
    });
  };

  const notifyNoCancelSuccess = () => {
    toast.show({
      message: t('USER_NOTIFICATION', {
        name: sessionData?.friendlyName,
      }),
    });
    handleCancellationPopupClose(false);
  };
  const notifyNoCancelError = (err: Error) => {
    toast.show({
      message: err?.message,
    });
  };
  const { notifyNoCancel } = useCancellationData({
    onSuccess: notifyNoCancelSuccess,
    onError: notifyNoCancelError,
  });

  const handleCloseRescheduleModal = () => {
    const newList = meetingsList.filter(
      (meeting) => meeting.id !== selectedMeetingIdRef.current,
    );
    setMeetingsList(newList);
    handleCancellationPopupClose(false);
  };

  const handleCancellationSubmit: ICancellationModal['onSubmit'] = (
    note,
    isNotify,
    type,
    otherReason,
  ) => {
    if (sessionData) {
      if (!isReschedule && !isNotify) {
        cancelAppointment({
          meetingId: sessionData.meetingId,
          note,
          type,
          otherReason,
        });
      }
      if (isNotify) {
        notifyNoCancel({
          userId: Number(sessionData?.userId),
          providerRole: sessionData?.providerRole,
          message: note,
        });
      }
      track(EventNames.providerCancelSession, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.providerDropNote,
        eventLabel: 'provider_drop_note_confirm',
        featureVersion: 'v1',
        eventSource: isReschedule ? 'reschedule' : 'cancel',
      });
    }
  };

  if (hasCancelledAllMeetings) {
    return null;
  }

  return (
    <motion.div
      className="appointments-card"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.5 }}
    >
      <CancelAppointmentsPopup
        show={showCancellationPopup}
        meetings={meetingsList}
        onClose={onAppointmentPopupClose}
        setSelectedMeetingId={handleMeetingId}
      />
      {sessionData && (
        <CancellationReasonsModal
          onClose={handleCancellationPopupClose}
          onSubmit={handleCancellationSubmit}
          within24Hrs={isWithIn24Hrs}
          onWarningCancel={handleWarningModalCancel}
          isReschedule={isReschedule}
          sessionData={sessionData}
          onCloseRescheduleModal={handleCloseRescheduleModal}
        />
      )}
      <div className="card-contents">
        <Typography size={14} weight="600">
          {t('CONFLICT_ALERT')}
        </Typography>
        <Typography withColor="#667085" size={12} weight="400">
          {t('CONFLICT_ALERT_BODY')}
        </Typography>
        <motion.div
          className="cancel-appointments"
          variants={containerAnimationVariants}
          whileHover="hover"
          onClick={() => setShowCancellationPopup(true)}
        >
          <Typography size={12} weight="500">
            {t('CANCEL_SESSION_CTA')}
          </Typography>
          <motion.img
            src={chevronRightIcon}
            alt="cancel appointments"
            variants={imageAnimationVariants}
          />
        </motion.div>
      </div>
      <img src={cancelAppointmentsIllustration} alt="cancel appointments" />
    </motion.div>
  );
}

export default AppointmentsCard;
