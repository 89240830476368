import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { IClientSummary } from '../../../shared/types/response/provider';
import queryIcon from '../../../assets/queryIcon.svg';
import {
  getProviderRole,
} from '../../../utilities/common/Provider';
import { APIDateFormat, getDisplayDate } from '../../../utilities/common/Date';
import { displayDateFormat } from '../../../utilities/common/Display';
import { getCurrentLocale } from '../../../i18n';
import TagsViewer from '../../app-components/TagsViewer';
import StatusViewer from '../../app-components/StatusViewer';
import { encodeCode } from '../../../utilities/common/Encryption';
import Typography from '../../components/Typography';

const currentLocale = getCurrentLocale();

export const clientsColDefinition: ColumnDef<IClientSummary>[] = [
  {
    header: 'name',
    accessorKey: 'user.friendlyName',
    sortingFn:'text',
    cell: (props) => (
      <>
        <span>{props.getValue()}</span>
        <Typography size={10} withColor='#636C73'>{encodeCode(props.row.original.userId)}</Typography>
      </>
    ),
  },
  {
    header: 'ORGANISATION',
    accessorKey: 'user.companyName',
    enableSorting: false,
  },
  {
    header: 'MATCHED_ON',
    accessorKey: 'createdAt',
    cell: (props) => (
      <span>
        {getDisplayDate({
          locale: currentLocale,
          translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
          format: { ja: 'MM,DD,YYYY,dddd', en: displayDateFormat },
          selectedDate: moment(props.getValue() as string, APIDateFormat),
        })}
      </span>
    ),
  },
  {
    header: 'lastSessionOn',
    accessorKey: 'lastSessionOn',
    enableSorting: true,
    cell: (props) => (
      <span>
        {props.getValue()
          ? getDisplayDate({
            locale: currentLocale,
            translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
            format: { ja: 'MM,DD,YYYY,dddd', en: displayDateFormat },
            selectedDate: moment(props.getValue()?.toString(), APIDateFormat),
          })
          : ''}
      </span>
    ),
  },
  {
    header: 'type',
    accessorKey: 'providerRole',
    enableSorting: false,
    cell: (props) => {
      const { t } = useTranslation();
      return <span>{t(getProviderRole(props.getValue() as string))}</span>;
    },
  },
  {
    header: 'tags',
    accessorKey: 'user.tags',
    enableSorting: false,
    cell: (props) => {
      const tags = props
        .getValue()
        ?.toString()
        ?.split(',')
        .filter((item) => item !== '');
      return tags?.length ? <TagsViewer key={props.cell.id} tagsData={tags} /> : <span />;
    },
  },
  {
    header: () => {
      const { t } = useTranslation();
      return (
        <>
          {t('status')}
          <span>
            <img
              data-tooltip-id="client-management-status-tooltip"
              src={queryIcon}
              alt="question-mark-icon"
              data-tooltip-html={t('STATUS_TOOLTIP_TEXT')}
            />
          </span>
          <ReactTooltip
            id="client-management-status-tooltip"
            place="right"
            data-html  
            className='status-tooltip'          
          />
        </>
      );
    },
    accessorKey: 'status',
    enableSorting: false,
    cell: (props) => <StatusViewer statusValue={props.getValue() as string} />,
  },
];
