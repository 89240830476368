import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Typography from '../../components/Typography';
import './Tags.scss';
import { ColorSet } from '../../../shared/types/App';
import { CssSize } from '../../components/BaseCard/BaseCard.d';

type TagVariants = 'normal' | 'blue' | 'pill' | 'disclaimer';

interface ITags {
  tags: string | string[];
  variant?: TagVariants;
  backgroundColor?: string;
  textColor?: string;
  textSize?: number;
  customClassName?: string;
  multiTagsStyle?: ColorSet[];
  icon?: string;
  toolTipText?: string;
  uniqueKey?: string;
  iconHeight?: CssSize;
  iconWidth?: CssSize;
  toolTipClass?: string;
  hideTooltip?: boolean;
}

const variantTextColorMap: Record<TagVariants, string> = {
  normal: '#121212',
  blue: '#2A68B6',
  pill: '#FFFFFF',
  disclaimer: '#C06A00',
};

function Tags({
  tags,
  variant = 'normal',
  backgroundColor,
  textColor,
  textSize,
  customClassName = '',
  multiTagsStyle = [],
  icon,
  toolTipText,
  uniqueKey,
  iconHeight = '13px',
  iconWidth = '14px',
  toolTipClass,
  hideTooltip = false,
}: ITags) {
  const tagItems = typeof tags === 'string' ? tags.split(',') : tags;
  const filteredTagItems = tagItems?.filter((tag) => tag !== '');
  const customStyle = (index: number) => {
    if (backgroundColor) {
      return { backgroundColor, fontColor: textColor };
    }
    if (multiTagsStyle.length) {
      return multiTagsStyle[index];
    }
    return {};
  };

  return (
    <div className="tags-container">
      {filteredTagItems?.map((tag, index) => (
        <div
          key={tag}
          className={`tag ${variant} ${customClassName}`}
          style={customStyle(index)}
        >
          <Typography
            size={textSize || 12}
            weight="600"
            withColor={
              customStyle(index)?.fontColor ||
              textColor ||
              variantTextColorMap[variant]
            }
          >
            {tag || ''}
          </Typography>
          {icon && (
            <span>
              <img
                data-tooltip-id={
                  toolTipText ? `toolTipUnsaved-${uniqueKey}` : 'noToolTIp'
                }
                height={iconHeight}
                width={iconWidth}
                src={icon}
                className="icon"
                alt="filter"
              />
            </span>
          )} 
          <ReactTooltip
            id={`toolTipUnsaved-${uniqueKey}`}
            place="right"
            content={toolTipText}
            hidden={!toolTipText || hideTooltip}
            className={toolTipClass}
          />
        </div>
      ))}
    </div>
  );
}

export default Tags;
