import styled from 'styled-components';
import { CssSize } from '../BaseCard/BaseCard.d';
import { FontWeights } from '../Typography/Typography';

interface InsightsDetailsCardContainerProps {
  width?: CssSize;
  height?: CssSize;
  background?: string;
}

export const InsightsDetailsCardContainer = styled.section<InsightsDetailsCardContainerProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin-bottom: 6rem;
`;

interface StyledSpacerProps {
  width?: CssSize;
  spacerHeight?: CssSize;
}
export const StyledSpacer = styled.div<StyledSpacerProps>`
  height: ${(props) => props.spacerHeight};
  width: ${(props) => props.width};
`;

interface StyledTextNoteProps {
  textNoteWidth?: CssSize;
  textNoteFontSize?: CssSize;
  textNoteWeight?: FontWeights;
  textNoteTextAlign: 'left' | 'center' | 'right';
}
export const StyledTextNote = styled.p<StyledTextNoteProps>`
  width: ${(props) => props.textNoteWidth};
  font-size: ${(props) => props.textNoteFontSize};
  font-weight: ${(props) => props.textNoteWeight};
  text-align: ${(props) => props.textNoteTextAlign};
  padding: 3px;
  margin: 0 10px;
`;

interface StyledDescriptionProps {
  descWidth?: CssSize;
  descHeight?: CssSize;
  descFontSize?: CssSize;
  descFontWeight?: FontWeights;
  descBackground?: string;
  descTextAlign: 'left' | 'center' | 'right';
}

export const StyledDescription = styled.section<StyledDescriptionProps>`
width: ${(props) => props.descWidth};
height: ${(props) => props.descHeight};
font-size: ${(props) => props.descFontSize};
font-weight: ${(props) => props.descFontWeight};
text-align: ${(props) => props.descTextAlign};
background: ${(props) => props.descBackground};
padding: 25px 7px;
`;