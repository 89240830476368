import * as React from 'react';
import './SelectBox.scss';

interface ISelectBox {
  children: React.ReactNode;
  onChange?: (value: string) => void;
  variant?: 'normal' | 'box';
  value: string;
  disabled?: boolean;
  placeholder?: string;
  onClick?: () => void;
}

function SelectBox({
  children,
  onChange,
  variant = 'normal',
  value,
  disabled = false,
  placeholder,
  onClick,
}: ISelectBox) {
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(event.target.value);
  };
  return (
    <div className="select-container">
      <select
        onChange={selectChange}
        onClick={onClick}
        className={variant}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      >
        {children}
      </select>
    </div>
  );
}

export default SelectBox;
