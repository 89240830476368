import * as React from 'react';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import useToast from '../../utilities/hooks/useToast';
import ChatBroker from './ChatBroker';
import useAuth from '../../utilities/hooks/useAuth';
import { MessageChannelTypes } from '../constants/CommonConstants';
import { IMessageSendType } from '../data-types/ChatBrokerTypes';
import { newMessageNotificationSound } from '../../utilities/common/Audio';

const ChatBrokerRef = new ChatBroker();

export function useChatBroker(onSuccess?: ()=>void) {
  const { isLoggedIn, user } = useAuth();
  const toast = useToast();
  const { t } = useTranslation();

  const { isLoading: enabled } = useQuery(
    ['conversationList'],
    ChatBrokerRef.getConversationList,
    {
      enabled: !!isLoggedIn,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  );
  const isNextMessageLoaded = React.useRef(false);
  const {
    isLoading,
    data,
    refetch: refetchLatestConversation,
  } = useQuery(
    ['conversationListManipulated'],
    ChatBrokerRef.getAllConversations,
    {
      enabled: !enabled,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: updateMsgConsumptionStatus } = useMutation(
    ({
      userId,
      channelType,
    }: {
      userId: string;
      channelType: MessageChannelTypes;
    }) => ChatBrokerRef.updateMessageConsumptionStatus(userId, channelType),
    {
      onSuccess: () => {
        refetchLatestConversation();
      },
    },
  );

  const { mutate: sendMessage } = useMutation(
    ({
      contentToSend,
      conversationId,
      channelType,
    }: {
      contentToSend: IMessageSendType;
      conversationId: string;
      channelType: MessageChannelTypes;
    }) => ChatBrokerRef.sendMessage(contentToSend, conversationId, channelType),
    {
      onSuccess,
      onError: () => {
        toast.show({ message: t('INTERNET_DISCONNECTED_ERROR'), toastVariant: 'error' });
      },
    },
  );

  // fetches individual user messages

  const { mutate: getSelectedUserMessages, isLoading: loading } = useMutation(
    ({
      selectedConversationId,
      channelId,
      pageNo,
      channelType,
      refetch,
    }: {
      selectedConversationId: string;
      channelId: string;
      pageNo: number;
      channelType: MessageChannelTypes;
      refetch?: boolean;
    }) =>
      ChatBrokerRef.getSelectedUserMessages(
        selectedConversationId,
        channelId,
        pageNo,
        channelType,
        refetch,
      ),
    {
      onSuccess: (_, variables) => {
        if (!variables?.refetch) isNextMessageLoaded.current = true;
      },
    },
  );

  React.useEffect(() => {
    if (isLoggedIn) {
      ChatBrokerRef.onSocketNewMessage((message) => {
        refetchLatestConversation();
        getSelectedUserMessages({
          selectedConversationId: '',
          channelId: '',
          pageNo: 1,
          channelType: MessageChannelTypes.TWILIO,
          refetch: true,
        });
        if (
          !(
            message.senderId.toString() === 'system' ||
            message.senderId.toString() === user.id
          )
        ) {
          newMessageNotificationSound.play();
        }
      });
    }
  }, [user, isLoggedIn]);

  return {
    allConversationList: data,
    isLoading: isLoading || enabled,
    unreadCount: ChatBrokerRef.unreadCount,
    liveChatData: {
      getSelectedUserMessages,
      isLoading: loading,
      updateMsgConsumptionStatus,
      selectedUserData: ChatBrokerRef.userMessages,
      getSelectedUserUnreadCount: ChatBrokerRef.getSelectedUserUnreadCount,
      isNextMessageLoaded,
    },
    sendMessage,
  };
}
