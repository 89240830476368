import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import TextBox from '../../components/TextBox';
import Typography from '../../components/Typography';
import searchIcon from '../../../assets/search.svg';
import useAuth from '../../../utilities/hooks/useAuth';
import Button from '../../components/Button';
import tickIcon from '../../../assets/tick.svg';
import './MeetingClientSelection.scss';
// import Loader from '../../components/Loader';

interface IClientData {
  user: {
    friendlyName: string;
  };
  userId: number;
  providerRole: string;
}

interface IMeetingSelectionProps {
  setCurrentStep: (step: number) => void;
  clientsList: Array<IClientData>;
  setSelectedClientId: (id: number) => void;
  selectedClientId: number;
  setSelectedProviderRole: (providerRole: string) => void;
  setSelectedClientName: (name: string) => void;
  // isLoading: boolean;
}

function MeetingClientSelection({
  setCurrentStep,
  clientsList,
  setSelectedClientId,
  selectedClientId,
  setSelectedProviderRole,
  setSelectedClientName,
}: // isLoading,
IMeetingSelectionProps) {
  const profilePlaceholderColors = [
    '#4C8387',
    '#955D29',
    '#DB6421',
    '#252525',
    '#A00000',
  ];
  // Reach hooks
  const navigate = useNavigate();

  // Custom Hooks
  const { t } = useTranslation();
  const { user } = useAuth();

  // Local State
  const [clientsSearchText, setClientsSearchText] = React.useState<string>('');
  const [isClientSelected, setIsClientSelected] = React.useState(false);
  // const [parentDimensions, setParentDimensions] = React.useState<DOMRect>();

  // Function
  const getSlelctedClientId = (
    selectedId: number,
    providerRole: string,
    name: string,
  ) => {
    setSelectedClientId(selectedId);
    setIsClientSelected(true);
    setSelectedClientName(name);
    setSelectedProviderRole(providerRole);
    if (selectedId === selectedClientId) {
      setSelectedClientId(0);
      setSelectedClientName('');
      setIsClientSelected(false);
      setSelectedProviderRole('');
    }
  };

  //* Function to get parent dimension
  const parentMeasuredRef = React.useCallback((node) => {
    if (node !== null) {
      // setParentDimensions(node.getBoundingClientRect());
    }
  }, []);

  // Component LifeCycle
  React.useEffect(() => {
    setSelectedClientId(0);
    setSelectedClientName('');
  }, []);

  // Search Filter for clients list
  let filteredClients = clientsList;
  if (clientsSearchText) {
    filteredClients = clientsList.filter((client) =>
      client?.user?.friendlyName
        ?.toLowerCase()
        .includes(clientsSearchText.toLowerCase()),
    );
  }

  return (
    <div className="client-selection-container" ref={parentMeasuredRef}>
      {/* <Loader useFullPage={false} dimension={parentDimensions} /> */}
      <div className="client-selection-message">
        <Typography size={24} weight="500" color="primary" textAlign="center">
          {t('clientBookingSession', { name: user.name })}
        </Typography>
      </div>
      <TextBox
        placeholder={t('searchClient')}
        icon={searchIcon}
        variant="box-border"
        animate={false}
        value={clientsSearchText}
        onChange={(e) => setClientsSearchText(e.target.value.toLowerCase())}
      />
      <div className="client-list-container">
        {filteredClients?.map((client, index) => (
          <motion.div
            key={client.userId}
            className="client-list selected"
            initial={{ backgroundColor: '#fff' }}
            whileHover={{ backgroundColor: '#E1E7EC' }}
            whileTap={{ scale: 0.85 }}
            transition={{ duration: 0.3 }}
            onClick={() =>
              getSlelctedClientId(client.userId, client.providerRole, client?.user?.friendlyName)
            }
          >
            <div className="cell-container">
              <div className="assign-client-details">
                <div
                  className="avatar"
                  style={{
                    backgroundColor:
                      profilePlaceholderColors[
                        index % profilePlaceholderColors.length
                      ],
                  }}
                >
                  <Typography size={10} weight="500" color="light">
                    {index}
                  </Typography>
                </div>
                <Typography size={12} weight="400" color="secondary">
                  {client?.user?.friendlyName}
                </Typography>
              </div>
              {selectedClientId === client.userId && (
                <img src={tickIcon} alt="Tick Mark" width={17} />
              )}
            </div>
          </motion.div>
        ))}
      </div>
      <div className="client-button-container">
        <Button
          label={t('CANCEL_CTA')}
          onClick={() => navigate(-1)}
          variant="secondary"
        />
        <Button
          label="Continue"
          onClick={() => setCurrentStep(2)}
          variant="blue"
          disabled={!isClientSelected}
        />
      </div>
    </div>
  );
}

export default MeetingClientSelection;
