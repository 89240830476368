import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BaseCard from '../../components/BaseCard';
import InsightsDetailsCard from '../../components/InsightsDetailsCard';
import useInsights from '../../../utilities/hooks/useInsights/useInsights';
import {
  InDepthCardDataType,
  BaseCardDataType,
  InsightDetailType,
} from './Insights.d';
import {
  StyledInsightWrapper,
  StyledInsightContentSection,
  StyledInsightFlexSection,
  StyledInsightOverviewSection,
} from './Insights.styled';
import Typography from '../../components/Typography';
import { StyledTextNote } from '../../components/InsightsDetailsCard/InsightsDetailsCard.styled';
import Loader from '../../components/Loader';
import {
  EventNames,
  EventActions,
  EventCategories,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';
import NewProviderInsights from '../../components/NewProviderInsights';

function isInsightDetailType(object: any): object is InsightDetailType {
  return object;
}

function Insights() {
  const { t } = useTranslation();
  const { track, trackDuration } = useTracking();
  const { insightData, isLoading, isNewProvider } = useInsights();

  React.useEffect(() => {
    trackDuration(EventNames.providerWebInsights);

    return () => {
      track(EventNames.providerWebInsights, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.providerWebInsights,
        eventLabel: 'provider_web_insights',
      });
    };
  }, []);

  return (
    <StyledInsightWrapper>
      {isLoading ? (
        <Loader useFullPage withBackdrop={false} />
      ) : (
        <>
          {/* Insight page header section */}
          <Typography weight="500" color="primary" size={24}>
            {t('INSIGHTS')}
          </Typography>

          {isNewProvider ? (
            <NewProviderInsights />
          ) : (
            <>
              {/* Insights page content section */}
              <StyledInsightContentSection width="100%">
                {/* Overview section */}
                <StyledInsightOverviewSection>
                  <StyledTextNote
                    textNoteFontSize="24px"
                    textNoteWidth="100%"
                    textNoteWeight="500"
                    textNoteTextAlign="center"
                  >
                    {t('OVERVIEW')}
                  </StyledTextNote>
                </StyledInsightOverviewSection>
                <StyledInsightFlexSection width="90%" flexGap="35px">
                  <StyledTextNote
                    textNoteFontSize="24px"
                    textNoteWidth="150px"
                    textNoteWeight="500"
                    textNoteTextAlign="left"
                  >
                    {t('LIFETIME')}
                  </StyledTextNote>

                  {isInsightDetailType(insightData) &&
                    insightData?.lifeTimeData?.map((data: BaseCardDataType) => (
                      <BaseCard
                        key={data.id}
                        cardValue={data.cardValue}
                        cardDescription={data.cardDescription}
                        fontSize={12}
                        fontWeight="500"
                        textAlign="center"
                        color="primary"
                        cardValueFontSize={24}
                        useTranslationForDescription
                      />
                    ))}
                </StyledInsightFlexSection>
                <StyledInsightFlexSection width="90%" flexGap="35px">
                  <StyledTextNote
                    textNoteFontSize="24px"
                    textNoteWidth="150px"
                    textNoteWeight="500"
                    textNoteTextAlign="left"
                  >
                    {t('SINCE_YTD')}
                  </StyledTextNote>
                  {isInsightDetailType(insightData) &&
                    insightData?.YTDData?.map((data: BaseCardDataType) => (
                      <BaseCard
                        key={data.id}
                        cardValue={data.cardValue}
                        cardDescription={data.cardDescription}
                        fontSize={12}
                        fontWeight="500"
                        textAlign="center"
                        color="primary"
                        cardValueFontSize={24}
                        useTranslationForDescription
                      />
                    ))}
                </StyledInsightFlexSection>
              </StyledInsightContentSection>

              {/* In Depth Look section */}
              <StyledInsightContentSection width="100%">
                <Typography size={24} textAlign="center" weight="500">
                  {t('IN_DEPTH_LOOK')}
                </Typography>
                <StyledInsightFlexSection width="90%" flexGap="17px">
                  {isInsightDetailType(insightData) &&
                    insightData?.inDepthCardData?.map(
                      (data: InDepthCardDataType) => (
                        <InsightsDetailsCard
                          key={data.id}
                          cardDescription={data.cardDescription}
                          cardValue={data.cardValue}
                          fontSize={12}
                          fontWeight="500"
                          textAlign="center"
                          cardValueFontSize={24}
                          color="primary"
                          insightInfo={data.insightInfo}
                          textNoteFontSize="8px"
                          textNoteWeight="400"
                          tableHeader={data.header}
                          baseTableData={data.tableData}
                          visibilityDesc={data?.visibilityCriteria}
                        />
                      ),
                    )}
                </StyledInsightFlexSection>
              </StyledInsightContentSection>
            </>
          )}
        </>
      )}
    </StyledInsightWrapper>
  );
}

export default Insights;
