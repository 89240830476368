import { getCurrentLocale } from '..';

export const getTranslation = (key: string, TKeys: Record<string, Record<string, string>>) => {
  const currentLanguage = getCurrentLocale();
  if (TKeys && TKeys[key]) {
    const { en: fallbackCopy, [currentLanguage]: copy } = TKeys[key];
    return copy || fallbackCopy || '';
  }

  return  '';
};