import * as React from 'react';
// import { RemoteTrackPublication } from 'twilio-video';
import './VideoRoomParticipantTrack.scss';

interface IVideoRoomParticipantProps {
  track: any;
  isLocal: boolean;
  previewMode?: boolean;
}

function VideoRoomParticipantTrack({ track, isLocal, previewMode }: IVideoRoomParticipantProps) {
  const trackElementRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (track !== null && track.attach) {
      const child = track.attach();
      trackElementRef.current?.classList.add(track.kind);
      trackElementRef.current?.appendChild(child);
    }
  }, [track]);

  return (
    <div className={`track ${isLocal ? `local ${previewMode ? 'preview-mode' : ''}` : 'remote'}`} ref={trackElementRef} />
  );
}

export default VideoRoomParticipantTrack;
