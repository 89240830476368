import * as React from 'react';
import './CaseNoteFormHeader.scss';
import { ICaseNoteHeader } from './types';
import Typography from '../Typography';

function CaseNoteFormHeader({
  headerText,
  diplayDate,
}: ICaseNoteHeader) {
  return (
    <article className="case-note-header-wrapper">
      <section className="session-container">
        <Typography size={28} weight="600">
          {headerText}
        </Typography>
      </section>
      <section className="session-date-wrapper">
        <Typography size={16} withColor="#909192">
          {diplayDate}
        </Typography>
      </section>
    </article>
  );
}

export default CaseNoteFormHeader;
