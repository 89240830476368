/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import './RiskAndIssuePresenter.scss';
import { IRiskAndIssueProps } from './types';
import {
  CaseNoteQuestionTypes,
  SOSNumbers,
} from '../../../shared/constant/CoachingCaseNotes';
import Tags from '../Tags';
import alertCircleIcon from '../../../assets/alert-circle.svg';
import RadioGroup from '../../components/RadioGroup';
import CareReferral from '../CareReferral';
import InformationModal from '../InformationModal';
import GroupTextEditor from '../../components/GroupTextEditor';
import { IMultiOptionsData } from '../../components/MultilevelDropdown/types';
import MultilevelDropdown from '../../components/MultilevelDropdown';
import { EventCategories } from '../../../shared/constant/Analytics';

function RiskAndIssuePresenter({
  caseNoteQuestionsData,
  control,
  riskCategoriesOptions,
  issuesOptions,
  suicidalAssessmentsOptions,
  showSucidalAssessmentEditor,
  showCareReferral,
  handleInformBtnClick,
  handleSOSClick,
  showInfoModal,
  infoModalOnClose,
  startQuestionDurationTracking,
  caseNoteEventTracking,
  showTags = true,
  riskBorder = true,
  showSOSSupport = false,
}: IRiskAndIssueProps) {
  const { t } = useTranslation();

  return (
    <article className="risk-issue-wrapper">
      <section
        className={`risk-wrapper ${riskBorder && 'risk-wrapper-border'}`}
      >
        <section className="header-info-container">
          <label className="form-label" htmlFor="riskAssessment">
            {
              caseNoteQuestionsData[CaseNoteQuestionTypes.RiskAssesment]
                .question
            }
          </label>
          {showTags && (
            <Tags
              variant="disclaimer"
              tags={t('SHARED_DISCLAIMER')}
              icon={alertCircleIcon}
              uniqueKey="casenote_disclaimer"
              toolTipText={t('DISCLAIMER_TEXT')}
              toolTipClass="customTooltip"
            />
          )}
        </section>
        <Controller
          name="riskAssessment"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <RadioGroup
              {...field}
              name="risk"
              values={riskCategoriesOptions}
              onChange={(_, selected) => {
                field.onChange({
                  ...selected,
                  questionId:
                    caseNoteQuestionsData[CaseNoteQuestionTypes.RiskAssesment]
                      .id,
                });
                caseNoteEventTracking?.(
                  caseNoteQuestionsData[
                    CaseNoteQuestionTypes.RiskAssesment
                  ].id.toString(),
                  EventCategories.questionId,
                );
              }}
              selectedValue={field.value?.value}
              customCss
              labelClass="labelClass"
              highlightOnSelect
              highlightColor="#FAFCFF"
            />
          )}
        />
        {showCareReferral && (
          <CareReferral
            desc={t('REFERRAL_TEXT')}
            icon={alertCircleIcon}
            iconHeight="16px"
            iconWidth="16px"
            fontSize={14}
            fontWeight="400"
            onClick={handleInformBtnClick}
            btnLabel={t('INFORM_CTA')}
            btnVariant="blue"
          />
        )}
        {showSOSSupport && (
          <CareReferral
            desc={t('SOS_CASE_NOTES_SUBTEXT')}
            icon={alertCircleIcon}
            iconHeight="16px"
            iconWidth="16px"
            fontSize={14}
            fontWeight="400"
            onClick={()=>handleSOSClick?.()}
            btnLabel={t('SOS')}
            btnVariant="sos"
            contentWidth='251px'
          />
        )}
        <InformationModal
          headerText={t('INTELLECT_SOS_NUMBER')}
          bodyText={t('SOS_DESC')}
          onClose={infoModalOnClose}
          show={showInfoModal}
          icon={alertCircleIcon}
          rightBtnLabel={SOSNumbers[0]}
          leftBtnLabel={SOSNumbers[1]}
          cursor="auto"
        />
        {showSucidalAssessmentEditor && (
          <Controller
            name="suicidalAssessment"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <GroupTextEditor
                {...field}
                editorInput={suicidalAssessmentsOptions}
                defaultValue={field.value?.id}
                onTextValueChange={(data) =>
                  field.onChange({
                    id: data,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.SuicidalAssesment
                      ].id,
                  })
                }
                onBlur={(data) =>
                  caseNoteEventTracking?.(data.id, EventCategories.questionId)
                }
                onFocus={() => startQuestionDurationTracking?.()}
              />
            )}
          />
        )}
      </section>
      <section className="issue-wrapper">
        <section className="primary-issue">
          <section className="header-info-container">
            <label className="form-label" htmlFor="primaryGoal">
              {
                caseNoteQuestionsData[CaseNoteQuestionTypes.PrimaryGoal]
                  .question
              }
            </label>
          </section>
          <Controller
            name="primaryGoal"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <MultilevelDropdown
                {...field}
                onChange={(val) => {
                  field.onChange({
                    ...val,
                    questionId:
                      caseNoteQuestionsData[CaseNoteQuestionTypes.PrimaryGoal]
                        .id,
                  });
                }}
                multiDropValues={issuesOptions as IMultiOptionsData[]}
                defaultSelected={field.value}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.PrimaryGoal
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
                onFocus={() => startQuestionDurationTracking?.()}
                placeholderText={t('SELECT')}
              />
            )}
          />
        </section>
        <section className="secondary-issue">
          <section className="header-info-container extra-padding">
            <label className="form-label" htmlFor="secondaryGoal">
              {
                caseNoteQuestionsData[CaseNoteQuestionTypes.SecondaryGoal]
                  .question
              }
              <span>&nbsp;({t('CHOOSE_MAX_THREE')})</span>
            </label>
            {showTags && (
              <Tags
                variant="disclaimer"
                tags={t('SHARED_DISCLAIMER')}
                icon={alertCircleIcon}
                uniqueKey="casenote_disclaimer"
                toolTipText={t('DISCLAIMER_TEXT')}
                toolTipClass="customTooltip"
              />
            )}
          </section>
          <Controller
            name="secondaryGoal"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <MultilevelDropdown
                {...field}
                onChange={(val) => {
                  field.onChange({
                    id: val,
                    questionId:
                      caseNoteQuestionsData[CaseNoteQuestionTypes.SecondaryGoal]
                        .id,
                  });
                }}
                multiDropValues={issuesOptions as IMultiOptionsData[]}
                isMultiSelect
                defaultSelected={field.value?.id as IMultiOptionsData[]}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.SecondaryGoal
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
                onFocus={() => startQuestionDurationTracking?.()}
                placeholderText={t('SELECT')}
              />
            )}
          />
        </section>
      </section>
    </article>
  );
}

export default RiskAndIssuePresenter;
