import { useLocation } from 'react-router-dom';

function usePaths() {
  const { pathname } = useLocation();
  return pathname
    .split('/')
    .filter((path) => path)
    .map(filteredPath => `/${filteredPath}`);
}

export default usePaths;
