import * as React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  availableLanguages,
  changeLanguage,
  getCurrentLocale,
} from '../../../i18n';
import RadioGroup from '../../components/RadioGroup';
import Typography from '../../components/Typography';
import './LanguageSwitcher.scss';
import ChangeLanguagePopup from '../ChangeLanguagePopup';
import chevronDownIcon from '../../../assets/chevron-down.svg';
import useToast from '../../../utilities/hooks/useToast';
import useOutsideClickHandler from '../../../utilities/hooks/useOutsideClickHandler';
import { getCaseNoteQuestions } from '../../../shared/redux/actions/provider';

function LanguageSwitcher() {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch();
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(() =>
    getCurrentLocale(),
  );
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const [showList, setShowList] = React.useState<boolean>(false);

  const onRadioChange = (value: string) => {
    setSelectedLanguage(value);
    setShowPopup(true);
    setShowList(false);
  };

  const refreshCacheWithLocales = () => {
    dispatch(getCaseNoteQuestions());
  };

  const onChangeLanguage = () => {
    changeLanguage(selectedLanguage);
    setShowPopup(false);
    refreshCacheWithLocales();
    toast.show({ message: t('successfullyChangedLang') });
  };

  const getSelectedLanguageLabel = () =>
    availableLanguages.find((l) => l.value === selectedLanguage)?.label || '';

  useOutsideClickHandler(parentRef, () => setShowList(false));

  const handleClosePopup = () => {
    setSelectedLanguage(getCurrentLocale());
    setShowPopup(false);
  };

  return (
    <div className="lang-switcher" ref={parentRef}>
      {showPopup && (
        <ChangeLanguagePopup
          onClose={() => handleClosePopup()}
          selectedLang={getSelectedLanguageLabel()}
          onChangeLanguage={onChangeLanguage}
        />
      )}
      <div
        role="button"
        tabIndex={0}
        className="action-btn"
        onClick={() => setShowList((prev) => !prev)}
      >
        <Typography withColor="#636C73" size={12} weight="400">
          {t('selectedLanguage', { lang: getCurrentLocale().toUpperCase() })}
        </Typography>
        <motion.img
          animate={showList ? { rotateZ: '180deg' } : {}}
          src={chevronDownIcon}
          alt="more"
        />
      </div>
      <AnimatePresence>
        {showList && (
          <motion.div
            className="lang-dropdown"
            initial={{ opacity: 0, translateY: '-200px' }}
            animate={{ opacity: 1, translateY: '-90px' }}
            exit={{ opacity: 0, translateY: '-200px' }}
            transition={{ duration: 0.4 }}
          >
              <RadioGroup
                name="languages"
                values={availableLanguages}
                onChange={(lang) => onRadioChange(lang)}
                selectedValue={selectedLanguage}
              />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default LanguageSwitcher;
