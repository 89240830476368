import * as React from 'react';

interface IUseAudioRecorderReturns {
  secondsElapsed: number;
  audioStream: MediaStream;
  recordingStarted: boolean;
  recorderInstance: MediaRecorder;
  audioBlob: Blob;
  audioUrl: string;
  startRecording: () => void;
  stopRecording: () => void;
}

function useAudioRecorder(): IUseAudioRecorderReturns {
  const [recorderState, setRecorderState] = React.useState<IUseAudioRecorderReturns>({} as IUseAudioRecorderReturns);

  const startRecording = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setRecorderState((prev) => ({
        ...prev,
        recordingStarted: true,
        audioStream,
      }));
    } catch (err) {
      console.log('Error occurred while getting audio device - ', err);
    }
  };

  const stopRecording = () => {
    if (recorderState.recorderInstance && recorderState.recorderInstance.state !== 'inactive') {
      recorderState.recorderInstance.stop();
      recorderState.recorderInstance.stream.getAudioTracks().forEach((track) => track.stop());
    }
  };
 
  React.useEffect(() => {
    if (recorderState.audioStream) {
      setRecorderState((prev) => ({ ...prev, recorderInstance: new MediaRecorder(prev.audioStream) }));
    }
  }, [recorderState.audioStream]);

  React.useEffect(() => {
    let dataChunks: BlobPart[] = [];
    const { recorderInstance } = recorderState;

    if (recorderInstance && recorderInstance.state === 'inactive') {
      recorderInstance.start();
    }

    if (recorderInstance) {
      recorderInstance.ondataavailable = (event: BlobEvent) => {
        dataChunks.push(event.data);
      };
  
      recorderInstance.onstop = () => {
        const audioBlob = new Blob(dataChunks, { type: 'audio/wav' });
        dataChunks = [];
        
        setRecorderState((prev) => {
          if (prev.recorderInstance) {
            return {
              ...prev,
              audioBlob,
              audioUrl: window.URL.createObjectURL(audioBlob),
            };
          }
  
          return prev;
        });
      };
    }

    return () => {
      if (recorderInstance) {
        recorderInstance.stream.getAudioTracks().forEach((track) => track.stop());
      }
    };

  }, [recorderState.recorderInstance]);

  return { ...recorderState, startRecording, stopRecording };

}

export default useAudioRecorder;

