import * as React from 'react';
import './Backdrop.scss';

interface IBackdrop {
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  mode?: BackdropMode;
}

export type BackdropMode = 'glass' | 'dark';


function Backdrop({ mode = 'dark', onClick = () => {} }: IBackdrop) {
  return (
    <div className={`backdrop-container ${mode}`} onClick={onClick} role="region" />
  );
}

export default Backdrop;