import * as React from 'react';
import './Typography.scss';

export type FontWeights = '400' | '500' | '600' | '700';
export type FontColors =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'solarized'
  | 'light'
  | 'white'
  | 'disabled'
  | 'green'
  | 'grey'
  | 'rhino'
  | 'swamp';
export type WhiteSpace = 'normal' | 'pre-line' | 'nowrap' | 'pre-wrap';

interface ITypographyProps {
  children: React.ReactNode;
  size: number;
  color?: FontColors;
  weight?: FontWeights;
  overFlowControl?: 'ellipsis' | 'clip';
  withColor?: string | null; // * to override theme colors for specific use cases
  breakWord?: 'break-all' | 'keep-all';
  textAlign?: 'left' | 'center' | 'right';
  withItalics?: boolean;
  whiteSpace?: WhiteSpace;
  width?: string;
  height?:string;
}

// TODO logic for fontsize -> rem instead of px
// TODO line height logic
// TODO display type -> inline, block, inline-block etc.
// TODO check performance whether the values need to memoized

function Typography({
  children,
  overFlowControl,
  withColor,
  textAlign,
  breakWord,
  weight = '400',
  size = 14,
  color = 'primary',
  withItalics = false,
  whiteSpace = 'normal',
  width,
  height,
}: ITypographyProps) {
  const buildStyle = () => {
    const styles: React.CSSProperties = {
      fontWeight: weight,
      fontSize: size,
      width,
      height,
    };

    if (overFlowControl) {
      styles.textOverflow = overFlowControl;
      styles.overflow = 'hidden';
    }

    if (withColor) {
      styles.color = withColor;
    }

    if (breakWord) {
      styles.wordBreak = breakWord;
    }

    if (textAlign) {
      styles.textAlign = textAlign;
    }

    if (withItalics) {
      styles.fontStyle = 'italic';
    }

    if (whiteSpace) {
      styles.whiteSpace = whiteSpace;
    }

    return styles;
  };
  return (
    <span style={buildStyle()} className={`typography ${color}`}>
      {children}
    </span>
  );
}

export default Typography;
