import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './GroupTextEditor.scss';
import { IGroupEditor, IEditorSelected } from './types';
import TextEditor from '../TextEditor';
import Typography from '../Typography';

function GroupTextEditor({
  editorInput,
  onTextValueChange,
  defaultValue,
  onFocus,
  onBlur,
}: IGroupEditor) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState<IEditorSelected[]>(
    [] as IEditorSelected[],
  );

  React.useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const onChange = (data: IEditorSelected, key: string) => {
    setSelected((prev) => [...prev.filter((item) => !(key in item)), data]);
    onTextValueChange([...selected.filter((item) => !(key in item)), data]);
  };

  return (
    <article className="group-editor-wrapper">
      {editorInput.map((question) => (
        <section key={question.value}>
          <section className="header-wrapper">
            <Typography size={16} weight="600" withColor="#363853">
              {question.label}
            </Typography>
          </section>
          <section className="group-text-editor-wrapper">
            <TextEditor
              onTextValueChange={(text: string) =>
                onChange({ [question.id]: text }, question.id)
              }
              defaultTemplate={
                selected.find((data) => question.id in data)?.[
                  question.id
                ] || ''
              }
              parametersValue={[]}
              maxCharacterLimit={5000}
              showMaxCharText={false}
              placeHolderText={t(`SUCIDAL_PROMPT_${question.value}`)}
              className="custom-style"
              onFocus={()=>onFocus?.(question)}
              onBlur={()=>onBlur?.(question)}
            />
          </section>
        </section>
      ))}
    </article>
  );
}

export default GroupTextEditor;
