import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import Typography from '../Typography';
import './Toast.scss';

interface ToastProps {
  message: string | string[];
  onClose: () => void;
  closeAfter?: number;
  toastVariant?: 'success' | 'error' | 'default'
}

function Toast({ message, onClose, closeAfter, toastVariant = 'default' }: ToastProps) {
  const { t } = useTranslation();

  React.useEffect(() => {
    if (closeAfter) {
      setTimeout(() => {
        onClose();
      }, closeAfter);
    }
  }, []);

  const toastMessages = typeof message === 'string' ? [message] : message;
  return (
    <motion.div
      animate={{
        scale: [0.5, 1, 1.03, 1],
      }}
      exit={{ transform: 'translateX()' }}
      className={`toast-container ${toastVariant}`}>
      <div className="label-container">
        {toastMessages.map((messageItem) => (
          <Typography color="light" size={12} weight="400" key={messageItem}>
            {messageItem}
          </Typography>
        ))}
      </div>
      <div
        className="toast-actions"
        onClick={onClose}
        role="button"
        tabIndex={0}
      >
        <Typography color="light" size={12} weight="600">
          {t('SUCCESSFULLY_SAVED_PROMPT_CTA')}
        </Typography>
      </div>
    </motion.div>
  );
}

export default Toast;
