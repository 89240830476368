import * as React from 'react';
import './ChatContactV2.scss';
import { motion } from 'framer-motion';
import Typography from '../../../../view/components/Typography';
import ChatAvatar from '../ChatAvatarV2';
import { getUnreadMessagesCountForFloatingChatButton } from '../../../../utilities/common/Display';

export interface IChatContactProps {
  name: string;
  message: string;
  messageConsumptionStatus: 'consumed' | 'not-consumed'; // not going with boolean as to support number based status in the future
  profileImage?: string;
  messagedTime: string;
  selected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  // channelType: string;
  messagesUnread: number;
}

// TODO extract profile image part into new component

function ChatContact({
  name,
  message,
  messageConsumptionStatus,
  profileImage,
  messagedTime,
  selected,
  onClick,
  messagesUnread,
}: IChatContactProps) {
  return (
    <motion.div
      className={`chat-contact-container ${selected ? 'selected' : ''}`}
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
    >
      <div className="left-pane">
        <ChatAvatar name={name} profileImage={profileImage} />
      </div>
      <div className="chat-main-content">
        <Typography weight="600" size={14} color="primary">
          {name}
        </Typography>
        <div className="message-container">
          <Typography
            overFlowControl="ellipsis"
            weight="400"
            size={12}
            whiteSpace="nowrap"
            color="primary"
          >
            {message}
          </Typography>
        </div>
      </div>
      <div className="chat-indication-container">
        <Typography color="solarized" weight="400" size={10}>
          {messagedTime}
        </Typography>
        <div className={`heartbeat-indicator ${messageConsumptionStatus}`}>
          {/* {messagesUnread} */}
          {messagesUnread
            ? getUnreadMessagesCountForFloatingChatButton(messagesUnread)
            : ''}
        </div>
      </div>
    </motion.div>
  );
}

export default ChatContact;