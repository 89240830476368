import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import lodash from 'lodash';
import Tags from '../../app-components/Tags';
import Typography from '../../components/Typography';
import './HelplineRecord.scss';
import infoIcon from '../../../assets/info.svg';
import UnorderedList from '../../components/UnorderedList';
import Button from '../../components/Button';
import TextArea from '../../components/TextArea';
import {
  scrollToBottom,
  scrollToTop,
} from '../../../utilities/common/UserExperience';
import {
  useHelplineNotes,
  useHelplineRecordDetails,
} from '../../../utilities/hooks/fetchHooks/helpline';
import { TriageLevelData } from '../../../shared/constant/Helpline';
import {
  displayDateFormat,
  displayJaDateFormat,
  displayJaTimeFormat,
  displayTimeFormat,
} from '../../../utilities/common/Display';
import useAuth from '../../../utilities/hooks/useAuth';
import {
  IDisplayDate,
  getTimeFromMilliSeconds,
} from '../../../utilities/common/Date';
import { getCurrentLocale } from '../../../i18n';

function HelplineRecord() {
  const { helplineRecordId } = useParams();
  const { t } = useTranslation();
  const { user } = useAuth();
  const [newComment, setNewComment] = React.useState<{
    showForm: boolean;
    note: string;
  }>({ showForm: false, note: '' });
  const { data: helplineRecordDetails, refetch: refetchHelplineDetails } =
    useHelplineRecordDetails(helplineRecordId || '');
  const { mutate: addHelplineNote } = useHelplineNotes({
    sessionId: helplineRecordId || '',
    onSuccess: () => {
      refetchHelplineDetails();
      setNewComment({ showForm: false, note: '' });
    },
  });
  const parentRef = React.useRef<HTMLDivElement>(null);

  const currentLocale = getCurrentLocale();
  const { client, caseNotes, risk, startTime, endTime, meeting } =
    helplineRecordDetails || {};
  const { email, location, name, organisation, phone } = client || {};
  const { name: providerName } = user;
  const callerNote = (caseNotes || [])[0];
  const tmf = currentLocale === 'ja' ? displayJaTimeFormat : displayDateFormat;
  const getSessionTimeRange = (delimiter: 'to' | '-') =>
    `${moment(startTime).format(tmf)} ${delimiter} ${moment(endTime).format(
      tmf,
    )}`;

  const onAddNewCommentClick = () => {
    setNewComment({ showForm: true, note: '' });
    scrollToBottom(parentRef, 'smooth');
  };

  const getCommentAuthorLabel = (addedBy: number) => {
    if (addedBy === Number(user.id)) {
      return 'You';
    }

    return 'Responder';
  };

  const getSessionLabel = () => {
    if (meeting) {
      return t('BOOKED_SESSION_FOR_CLIENT');
    }

    return 'You have not booked a session for your client';
  };

  React.useEffect(() => {
    scrollToTop();
  }, []);

  const getDisplayDate = ({
    locale,
    selectedDate,
    format,
    translatorResource = { ja: 'DISPLAY_DATE_WITH_MENTION' },
    splitWith = ',',
  }: IDisplayDate): string => {
    const formattedDate = moment(selectedDate).format(
      format[locale] || format.default || displayJaDateFormat,
    );
    const [month, date, year, day] = formattedDate.split(splitWith);
    if (translatorResource) {
      if (
        locale.includes('ja') &&
        (translatorResource?.ja || translatorResource?.common)
      ) {
        const ja = translatorResource?.ja || translatorResource?.common;
        return t(ja as string, { year, month, date, day });
      }
      if (
        locale.includes('en') &&
        (translatorResource?.en || translatorResource?.common)
      ) {
        const en = translatorResource?.en || translatorResource?.common;
        return t(en as string, { year, month, date, day });
      }
    }
    return formattedDate;
  };

  const latestSessionOn = React.useMemo(() => {
    if (startTime)
      return getDisplayDate({
        locale: currentLocale,
        selectedDate: startTime.toString(),
        format: { en: 'DD MMM', ja: displayJaDateFormat },
        translatorResource: { common: 'SESSION_ON' },
      });
    return '';
  }, [startTime]);
  const sessionScheduledOn = React.useMemo(() => {
    if (startTime)
      return getDisplayDate({
        locale: currentLocale,
        selectedDate: startTime.toString(),
        format: { en: displayDateFormat, ja: displayJaDateFormat },
        translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
      });
    return '';
  }, [startTime]);
  return (
    <div className="helpline-record" ref={parentRef}>
      <div className="record-details">
        <div className="top-section">
          <div className="top-left-container">
            <div className="client-avatar" style={{ backgroundColor: 'red' }}>
              <Typography size={14} weight="500" color="light">
                {name?.charAt(0)}
              </Typography>
            </div>
            <div className="basic-info">
              <Typography size={18} weight="500">
                {name}
              </Typography>
              <ul>
                <li>
                  <Typography size={12} weight="400">
                    {latestSessionOn || ''}
                  </Typography>
                </li>
                <li>
                  <Typography size={12} weight="400">
                    {getSessionTimeRange('to')}
                  </Typography>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="details-section">
          <div className="details-type-header">
            <Typography weight="500" size={14}>
              {t('CALLER_DETAILS')}
            </Typography>
          </div>
          <div className="details-section-row equal-spaced">
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('company')}
              </Typography>
              <Typography size={12} weight="400">
                {organisation || '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('PHONE_NUMBER')}
              </Typography>
              <Typography size={12} weight="400">
                {phone || '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('EMAIL')}
              </Typography>
              <Typography size={12} weight="400">
                {email || '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('LOCATION')}
              </Typography>
              <Typography size={12} weight="400">
                {location || '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('RISK_ASSESSMENT')}
              </Typography>
              {!lodash.isNil(risk) ? (
                <Tags
                  backgroundColor={TriageLevelData[risk]?.bgColor}
                  textColor={TriageLevelData[risk]?.fontColor}
                  tags={[TriageLevelData[risk]?.label]}
                />
              ) : (
                <Typography size={12} weight="400">
                  -
                </Typography>
              )}
            </div>
            <div className="details-item column-type" />
          </div>
          <div className="details-type-header">
            <Typography weight="500" size={14}>
              {t('RISK_ASSESSMENT')} {t('DETAILS')}
            </Typography>
          </div>
          <div className="details-section-row">
            <div className="full-width-item info">
              <img src={infoIcon} alt="info" />
              <Typography size={12} weight="400" color="secondary">
                {getSessionLabel()}
              </Typography>
            </div>
          </div>
          <div className="details-section-row equal-spaced">
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('COACH_NAME')}
              </Typography>
              <Typography size={12} weight="400">
                {providerName}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('BOOKING_SCHEDULED_ON')}
              </Typography>
              <Typography size={12} weight="400">
                {sessionScheduledOn}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('TIME_SLOT')}
              </Typography>
              {meeting ? (
                <Typography size={12} weight="400">
                  {`${getTimeFromMilliSeconds(
                    Number(meeting.scheduledStartTime),
                    currentLocale === 'ja'
                      ? displayJaTimeFormat
                      : displayTimeFormat,
                  )} - ${getTimeFromMilliSeconds(
                    Number(meeting.scheduledEndTime),
                    currentLocale === 'ja'
                      ? displayJaTimeFormat
                      : displayTimeFormat,
                  )}`}
                </Typography>
              ) : (
                <Typography size={12} weight="400">
                  -
                </Typography>
              )}
            </div>
            <div className="details-item column-type" />
          </div>
          <div className="details-type-header">
            <Typography weight="500" size={14}>
              {t('CALL_LOG_DETAILS')}
            </Typography>
          </div>
          <div className="details-section-row">
            <div className="full-width-item">
              <Typography
                size={14}
                weight="400"
                color="secondary"
                whiteSpace="pre-line"
              >
                {callerNote?.note}
              </Typography>
              <div className="footer-content">
                <UnorderedList
                  items={[
                    { label: t(getCommentAuthorLabel(callerNote?.addedBy)) },
                    {
                      label: getDisplayDate({
                        locale: currentLocale,
                        format: {
                          ja: displayJaDateFormat,
                          en: displayDateFormat,
                        },
                        selectedDate: callerNote?.updatedAt.toString(),
                        translatorResource: {
                          ja: 'DISPLAY_DATE_WITHOUT_MENTION',
                        },
                      }),
                    },
                    {
                      label: moment(callerNote?.updatedAt).format(
                        currentLocale.includes('ja')
                          ? displayJaTimeFormat
                          : displayTimeFormat,
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="comments-section">
        {caseNotes?.map(({ id, note, updatedAt, addedBy }, index) => {
          if (index === 0) return null;
          const callerUpdatedAt = moment(callerNote?.updatedAt).format(
            currentLocale.includes('ja')
              ? displayJaTimeFormat
              : displayTimeFormat,
          );
          const caseNotesUpdatedAt = getDisplayDate({
            locale: currentLocale,
            format: { ja: displayJaDateFormat, en: displayDateFormat },
            selectedDate: updatedAt.toString(),
            translatorResource: { ja: 'DISPLAY_DATE_WITHOUT_MENTION' },
          });
          return (
            <div className="comment-item" key={id}>
              <Typography
                size={14}
                weight="400"
                color="secondary"
                whiteSpace="pre-line"
              >
                {note}
              </Typography>
              <div className="footer-content">
                <UnorderedList
                  items={[
                    { label: t(getCommentAuthorLabel(addedBy)) },
                    { label: caseNotesUpdatedAt },
                    { label: callerUpdatedAt },
                  ]}
                />
              </div>
            </div>
          );
        })}
        {!!newComment.showForm && (
          <div className="new-comment">
            <TextArea
              value={newComment.note}
              placeholder="Add your comments here...."
              onChange={(value) =>
                setNewComment((prev) => ({ ...prev, note: value }))
              }
            />
          </div>
        )}
        {newComment.showForm ? (
          <div className="comments-action">
            <Button
              label={t('CANCEL_CTA')}
              onClick={() => setNewComment({ showForm: false, note: '' })}
              variant="secondary"
            />
            <Button
              label={t('SAVE_CTA')}
              onClick={() => addHelplineNote(newComment.note)}
              variant="blue"
            />
          </div>
        ) : (
          <div className="comments-action">
            <Button
              label={t('COMMENT')}
              onClick={onAddNewCommentClick}
              variant="secondary"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default HelplineRecord;
