import * as React from 'react';
import { createPortal } from 'react-dom';
import ToastContext, { ToastConfig } from '../../../shared/context/ToastContext';
import Toast from '../../../view/components/Toast';

type ToastProviderProps = React.PropsWithChildren<{}>;
const defaultToastConfig:ToastConfig = { message: '', closeAfter: 3000, toastVariant: 'default' };

function ToastProvider({ children }: ToastProviderProps) {
  const [isToastVisible, setToastVisible] = React.useState<boolean>(false);
  const [toastConfig, setToastConfig] = React.useState<ToastConfig>(defaultToastConfig);

  const show = (configs: Partial<ToastConfig>) => {
    setToastVisible(true);
    setToastConfig({ ...toastConfig, ...configs });
  };

  const onToastClose = () => {
    setToastConfig(defaultToastConfig);
    setToastVisible(false);
  };
  const toastContextValues = React.useMemo(() => ({ show, isToastVisible }), [isToastVisible]);

  return (
    <ToastContext.Provider value={toastContextValues}>
      {isToastVisible && (
        createPortal(
          <Toast
            message={toastConfig.message}
            closeAfter={toastConfig.closeAfter}
            onClose={onToastClose}
            toastVariant={toastConfig.toastVariant}
          />,
          document.getElementById('toast-portal') as HTMLDivElement,
        )
      )}
      {children}
    </ToastContext.Provider>
  );
}

export default ToastProvider;