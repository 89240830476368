import * as React from 'react';
import './GoToTop.scss';
import { motion } from 'framer-motion';
import upIcon from '../../../assets/up-arrow.svg';

interface GoToTopProps {
  scrollElementRef: React.RefObject<HTMLDivElement>;
  scrollThresholdToAppear: number;
}

function GoToTop({ scrollElementRef, scrollThresholdToAppear }: GoToTopProps) {
  const [showButton, setShowButton] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (scrollElementRef.current) {
      scrollElementRef.current.addEventListener('scroll', (event: Event) => {
        if ((event.target as Element).scrollTop >= scrollThresholdToAppear) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      });
    }
  }, [scrollElementRef]);

  const onButtonClick = () => {
    scrollElementRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <motion.div
      className="goto-top"
      whileHover={{ scale: 1.1 }}
      initial={{ opacity: 0 }}
      animate={showButton ? { opacity: 1 } : { opacity: 0 }}
      transition={{ duration: 0.5 }}
      onClick={onButtonClick}
    >
      <img src={upIcon} alt="go to top" />
    </motion.div>
  );
}

export default GoToTop;