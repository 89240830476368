/* eslint-disable react/prop-types */
import * as React from 'react';
import './Username.css';
import { useParticipantProperty } from '@daily-co/daily-react';

export default function Username({ id, isLocal, isScreenShare }) {
  const username = useParticipantProperty(id, 'user_name');

  return (
    <div className="username">
      {username || id} {isLocal && '(you)'} {isScreenShare && 'shared screen '}
    </div>
  );
}
