import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './SosClientList.scss';
import Popup from '../../components/Popup';
import { ISosClientList } from './types';
import Typography from '../../components/Typography';
import TextBox from '../../components/TextBox';
import searchIcon from '../../../assets/search.svg';
import Button from '../../components/Button';
import { IClientSummary } from '../../../shared/types/response/provider';
import NoResultsFound from '../NoResultsFound';
import AssignClientListItem from '../AssignClientListItem';
import {
  MessageChannelTypes,
  MessageSendType,
} from '../../../chat-module/constants/CommonConstants';
import { SOSUrls } from '../../../shared/constant/ExternalURLs';
import { useChatBroker } from '../../../chat-module/broker/useChatBroker';
import useToast from '../../../utilities/hooks/useToast';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import Loader from '../../components/Loader';
import sosRequestTracker from '../../../utilities/hooks/sosRequestTracker/sosRequestTracker';

function SosClientList({
  show,
  onClose,
  clientList,
  clientListLoading,
}: ISosClientList) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const toast = useToast();
  const { logSOSEvent } = sosRequestTracker();

  const [searchText, setSearchText] = React.useState<string>('');
  const [assignedClients, setAssignedClients] = React.useState<
  IClientSummary[]
  >([]);

  const filteredClients = clientList?.filter((c) =>
    c.user.friendlyName?.toLowerCase().includes(searchText.toLowerCase()),
  );

  const onCheckboxClick = (client: IClientSummary, isChecked: boolean) => {
    if (isChecked) {
      const newAssignedClients = [...assignedClients];
      newAssignedClients.push(client);
      setAssignedClients(newAssignedClients);
    } else {
      const newAssignedClients = assignedClients.filter(
        ({ userId }) => userId !== client.userId,
      );
      setAssignedClients(newAssignedClients);
    }
  };
  const onSOSSuccess = () => {
    const successMsg =
      assignedClients.length > 1
        ? t('SOS_NEW_SUCCESS_PROMPT_MULTIPLE')
        : t('SOS_NEW_SUCCESS_PROMPT_SINGLE');
    toast.show({ message: successMsg });
    onClose();
  };
  const { sendMessage } = useChatBroker(onSOSSuccess);

  const onConfirm = () => {
    track(EventNames.sosHelpLine, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.confirm,
      featureVersion: 'v1',
      eventLabel: 'confirm',
      eventSource: EventSource.quickTab,
      eventValue: assignedClients.length,
    });
    const logPayload = assignedClients.map((item) => ({
      userId: item.userId,
      providerId: item.providerId,
      providerRole: item.providerRole,
    }));
    logSOSEvent(logPayload);
    assignedClients.forEach((item) => {
      sendMessage({
        contentToSend: {
          data: 'This message contains required information for emergency purpose. Please click to get more information.',
          type: MessageSendType.DEEPLINK,
          deepLink: SOSUrls.emergencySOS,
        },
        conversationId: item.conversationId as string,
        channelType: item.channelType as MessageChannelTypes,
      });
    });
  };

  return (
    <Popup
      show={show}
      onClose={onClose}
      useDefaultPadding={false}
      withBackdrop
      backdropMode="glass"
    >
      <article className="sos-client-wrapper">
        <section className="header">
          <Typography size={18} weight="500" color="swamp">
            {t('SELECT_CLIENTS_SOS')}
          </Typography>
          <TextBox
            animate={false}
            variant="box-border"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            icon={searchIcon}
            placeholder={t('searchNames')}
          />
        </section>
        <section className="body">
          {clientListLoading && (
            <Loader
              useFullPage={false}
              dimension={{ height: 400, width: 500 } as DOMRect}
            />
          )}
          {!clientListLoading && !filteredClients?.length && (
            <div className="empty-results">
              <NoResultsFound
                captionCopy={
                  searchText && !filteredClients?.length
                    ? t('noResults')
                    : t('NO_CLIENTS_HEADER')
                }
                description={
                  searchText && !filteredClients?.length
                    ? t('pleaseCheckKeywords')
                    : t('NO_CLIENTS_SUBHEADER')
                }
                width="200px"
              />
            </div>
          )}
          {filteredClients?.map((client, index) => (
            <AssignClientListItem
              isChecked={assignedClients.some(
                (c) => c.userId === client.userId,
              )}
              key={`${client.userId}-${client.conversationId}`}
              onClientCheckBoxChange={onCheckboxClick}
              clientDetails={client}
              index={index}
              isCheckBoxDisable={false}
            />
          ))}
        </section>
        <section className="footer">
          <Typography size={14} weight="400" color="secondary">
            {t('clientsSelected', { count: assignedClients.length })}
          </Typography>
          <Button
            label={t('confirmButton')}
            variant="blue"
            fontSize={14}
            fontWeight="400"
            height="2.5rem"
            disabled={!assignedClients.length}
            onClick={onConfirm}
            minWidth="140px"
          />
        </section>
      </article>
    </Popup>
  );
}

export default SosClientList;
