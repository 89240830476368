import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChatAvatar from '../ChatAvatarV2';
import Typography from '../../../../view/components/Typography';
import Tags from '../../../../view/app-components/Tags';
import minimizeIcon from '../../../../assets/minimize.svg';
import { AllConversationListType } from '../../../data-types/ChatBrokerTypes';
import { getProviderRole } from '../../../../utilities/common/Provider';
import useLayout from '../../../../utilities/hooks/useLayout';
import './ChatHeader.scss';
import useTracking from '../../../../utilities/hooks/useTracking';
import { EventActions, EventCategories, EventNames } from '../../../../shared/constant/Analytics';
import Button from '../../../../view/components/Button';
import arrowIcon from '../../../../assets/slanting arrow.svg';
import { encodeCode } from '../../../../utilities/common/Encryption';

export interface ChatHeaderProps {
  userMetaData: AllConversationListType;
  isUserInfoVisible: Boolean;
}

function ChatHeader({ userMetaData, isUserInfoVisible }: ChatHeaderProps) {
  const { friendlyName, providerRole, tags, userId } = userMetaData;
  const { updateChatWindowSettings } = useLayout();
  const { t } = useTranslation();
  const { track } = useTracking();
  const navigate = useNavigate();

  return (
    <div className="chat-header">
      <div
        className={`user-info-container ${
          isUserInfoVisible ? 'hideUserInfo' : ''
        }`}
      >
        <ChatAvatar name={friendlyName} />
        <div className="user-info">
          <div className="user-tags-and-name">
            <Button
              label={friendlyName}
              fontSize={14}
              fontWeight="600"
              variant="raised"
              onClick={() => {
                navigate(`/clients/${providerRole}/${encodeCode(Number(userId))}`);
                track(EventNames.clientView, {
                  eventAction: EventActions.click,
                  eventCategory: EventCategories.clientView,
                  eventLabel: 'client_view',
                  featureVersion: 'v1',
                  eventSource: 'chat',
                });
              }}
              icon={arrowIcon}
              style={{ border: 'none', flexDirection: 'row-reverse' }}
              disableAnimation
              minWidth='fit-content'
              width='fit-content'
            />
            {tags && <Tags tags={tags} />}
          </div>
          <div className="user-type">
            <Typography size={10} weight="600" color="primary">
              {t(getProviderRole(providerRole))}
            </Typography>
          </div>
        </div>
      </div>
      <div className="toolbar-container">
        <div
          className="tool-item"
          role="button"
          tabIndex={0}
          onClick={() => {
            updateChatWindowSettings({ show: false });
            track(EventNames.messages, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.minimize,
              eventLabel: 'minimize',
              featureVersion: 'v1',
            });
          }}
        >
          <img src={minimizeIcon} alt="minimize" />
        </div>
      </div>
    </div>
  );
}

export default ChatHeader;
