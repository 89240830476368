import * as React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { getCurrentLocale } from '../../../i18n';
import './CustomTranslationEditor.scss';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  ICustomTabData,
  ICustomTranslatedMsgReq,
  ICustomTransltionEditor,
} from './types';
import Typography from '../Typography';
import Tabs from '../Tabs';
import Tab from '../Tab';
import TextEditor from '../TextEditor';
import TemplateHeader from '../MessageTemplateHeader';
import ConsentBanner from '../ConsentBanner';
import { maxCharacterLimit } from '../../../shared/constant/AutomatedChat';
import useTriggerSettings from '../../../utilities/hooks/useTriggerSettings/useTriggerSettings';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '../../../shared/constant/Analytics';
import { TabItem } from '../../../shared/context/TabsContext';

function CustomTranslationEditor({
  displayTranslationEditor,
  supportedLanguages,
  selectedTrigger,
  translatedMessages,
}: ICustomTransltionEditor) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [tabsData, setTabsData] = React.useState<ICustomTabData>(
    {} as ICustomTabData,
  );

  const currentLang = getCurrentLocale();
  const { updateDefaultMsgTemplate, msgConsentCheck } = useTriggerSettings(
    (data) => {
      setTabsData((prev: ICustomTabData) => ({
        ...prev,
        [data.languageId]: {
          ...prev[data.languageId],
          currentTemplate: decodeURI(data.template),
          savedTemplate: decodeURI(data.template),
        },
      }));
    },
  );
  const [isEditable, setIsEditable] = React.useState<boolean>(false);

  React.useEffect(() => {
    const tabsTemplates = _.cloneDeep(tabsData);
    supportedLanguages?.forEach((language) => {
      const [msg] = translatedMessages.filter(
        (msgData) => msgData?.languageId === language,
      );
      tabsTemplates[language] = {
        triggerId:
          msg?.triggerId?.toString() || selectedTrigger?.id?.toString(),
        savedTemplate:
          tabsData?.[language]?.savedTemplate || msg?.template || '',
        currentTemplate:
          tabsData?.[language]?.currentTemplate || msg?.template || '',
        languageId: language,
      };
    });
    setTabsData(tabsTemplates);
  }, [selectedTrigger, translatedMessages]);

  const isConsentDisabled = React.useMemo(
    () =>
      !Object.keys(tabsData).reduce(
        (acc, tabKey) => acc && !!tabsData[tabKey].savedTemplate.length,
        true,
      ),
    [tabsData, selectedTrigger],
  );

  const tabsName = React.useMemo(
    () =>
      supportedLanguages
        ?.filter((language) => language !== currentLang)
        ?.map((language) => ({
          key: language,
          label: t(language),
        })) || [],
    [supportedLanguages, currentLang],
  );

  const paramsValue = selectedTrigger?.settings?.params?.map((item) => ({
    id: item,
    display: item.replace('@{', '').replace('}', ''),
  }));

  const handleCustomTranslatedMsgSave = (data: ICustomTranslatedMsgReq) => {
    updateDefaultMsgTemplate({
      payload: {
        template: encodeURI(data?.currentTemplate),
        languageId: data?.languageId,
      },
      triggerId: data?.triggerId,
    });
    track(EventNames.automated_messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.translations,
      eventLabel: 'save',
      featureVersion: 'v1',
    });
  };

  const handleConsentCheck = (isChecked: boolean) => {
    msgConsentCheck({
      isActive: isChecked,
      triggerId: selectedTrigger?.id,
    });
    track(EventNames.automated_messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.translations,
      eventLabel: 'confirm_translation',
      featureVersion: 'v1',
    });
  };

  const handleTabChange = (selectedTab: TabItem) => {
    track(EventNames.automated_messages, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.translations,
      eventLabel: selectedTab.key,
      featureVersion: 'v1',
    });
  };

  if (!displayTranslationEditor) {
    return <section />;
  }

  if (!supportedLanguages?.length) {
    return (
      <section className="only-banner">
        <ConsentBanner
          label={t('MESSAGE_CONFIRM_LANGUAGE_CHECK_ONE_LANG')}
          defaultValue={!selectedTrigger?.customizationToDo} // in order to avoid filteration of isActive key
          onChange={handleConsentCheck}
          isConsentDisabled={false}
        />
      </section>
    );
  }

  return (
    <>
      <article className="custom-translation-editor-wrapper">
        <section className="custom-translation-editor-header">
          <Typography size={12} weight="500" color="rhino">
            {t('MESSAGES_ALL_LANGUAGES_HEADER')}
          </Typography>
        </section>
        <section className="custom-msg-editor-tabs-wrapper ">
          <Tabs tabs={tabsName} onTabChange={handleTabChange}>
            {tabsName.map((tabName) => (
              <Tab key={tabName.key} childrenFor={tabName.key}>
                <section
                  className={`translation-editor-container ${
                    isEditable && 'enabledEditor'
                  }`}
                >
                  <TemplateHeader
                    onSave={() => {
                      handleCustomTranslatedMsgSave(tabsData[tabName.key]);
                    }}
                    headerContent={t('CUSTOMISE_MESSAGE_HEADER')}
                    showSaveButton={
                      !!tabsData[tabName.key]?.currentTemplate.length &&
                      tabsData[tabName.key]?.currentTemplate !==
                        tabsData[tabName.key]?.savedTemplate
                    }
                  />

                  <TextEditor
                    onTextValueChange={(value) => {
                      setTabsData((prev: ICustomTabData) => ({
                        ...prev,
                        [tabName.key]: {
                          ...prev[tabName.key],
                          currentTemplate: value,
                        },
                      }));
                    }}
                    defaultTemplate={tabsData[tabName.key]?.currentTemplate}
                    parametersValue={paramsValue}
                    className="translation-editor"
                    maxCharacterLimit={maxCharacterLimit}
                    onBlur={() => {
                      setIsEditable(false);
                    }}
                    onFocus={() => {
                      setIsEditable(true);
                    }}
                  />
                </section>
              </Tab>
            ))}
          </Tabs>
        </section>
      </article>
      <ConsentBanner
        label={t('MESSAGE_CONFIRM_LANGUAGES_CHECK')}
        defaultValue={!selectedTrigger?.customizationToDo} // In order to avoid filteration of isActive key
        onChange={handleConsentCheck}
        isConsentDisabled={isConsentDisabled}
      />
    </>
  );
}

export default CustomTranslationEditor;
