import * as React from 'react';
import './VideoRoomControl.scss';
import { motion } from 'framer-motion';

interface IVideoRoomControlProps {
  color: 'primary' | 'error';
  icon: string;
  altText: string;
  onClick: () => void;
}

function VideoRoomControl({ color, icon, altText, onClick }: IVideoRoomControlProps) {
  return (
    <motion.div className={`room-control ${color}`} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} onClick={onClick}>
      <img src={icon} alt={altText} className="icon" />
    </motion.div>
  );
}

export default VideoRoomControl;