import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import './TagsViewer.scss';

interface ITagsViewer {
  tagsData?: Array<string>;
}

function TagsViewer({ tagsData }: ITagsViewer) {
  const { t } = useTranslation();
  const remainingTagCount = React.useMemo(() => {
    if (tagsData?.length && tagsData.length > 1) {
      return tagsData.length - 1;
    }
    return 0;
  }, [tagsData?.length]);

  const labelsArray = (tagsData?.[0] || '').split(' ');
  const displayText = labelsArray
    .reduce(
      (prev, current) => prev.concat(' ').concat(t(current.toUpperCase())),
      '',
    )
    .trim();
  return (
    <article className="tag-viewer-wrapper">
      <section
        data-tooltip-id="tags-tooltip"
        data-tooltip-content={tagsData?.join()}
        className="tags-content-wrapper"
      >
        <span className="tag-name-container">{displayText}</span>
        {remainingTagCount > 0 && (
          <span className="tag-count-container">
            {` +${remainingTagCount}`}
          </span>
        )}
      </section>
      <ReactTooltip
        id="tags-tooltip"
        className="tag-viewer-tooltip"
      />
    </article>
  );
}
export default TagsViewer;
