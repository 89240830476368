/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { ICareIntakeFormInputs } from './types';
import {
  CaseNoteQuestionTypes,
  Recommendations,
} from '../../../../shared/constant/CoachingCaseNotes';
import TextEditor from '../../../components/TextEditor';
import Tags from '../../Tags';
import alertCircleIcon from '../../../../assets/alert-circle.svg';
import CheckboxGroup from '../../../components/CheckboxGroup';
import '../CareIntake.scss';
import RadioGroup from '../../../components/RadioGroup';
import CareReferral from '../../CareReferral';
import { externalUrls } from '../../../../shared/Config';
import {
  ICaseNoteResp,
  ICasenoteQuestionData,
  IOptions,
  IRecommendations,
} from '../../CaseNotesV2/types';
import { EventCategories } from '../../../../shared/constant/Analytics';
import arrowIcon from '../../../../assets/slanting arrow.svg';

function FormPart2({
  caseNoteQuestionsData,
  control,
  watchCareRecommendations,
  showAdditionalSupportEditor,
  recommendationCategoriesOptions,
  sharedFollowUpCategoriesOptions,
  startQuestionDurationTracking,
  caseNoteEventTracking,
}: {
  caseNoteQuestionsData: ICasenoteQuestionData;
  control: Control<ICareIntakeFormInputs, any>;
  newCaseNoteAnswers: ICaseNoteResp | undefined;
  watchCareRecommendations: IRecommendations;
  showAdditionalSupportEditor: boolean;
  recommendationCategoriesOptions: IOptions[];
  sharedFollowUpCategoriesOptions: IOptions[];
  startQuestionDurationTracking?: () => void;
  caseNoteEventTracking?: (label: string, category?: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <article className="main-form-wrapper">
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor='clientGoalsForTherapy'>
            {
              caseNoteQuestionsData[CaseNoteQuestionTypes.ClientGoalsForTherapy]
                ?.question
            }
          </label>
        </section>
        <Controller
          name="clientGoalsForTherapy"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.ClientGoalsForTherapy
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.ClientGoalsForTherapy}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.ClientGoalsForTherapy
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor='formulationAndTreatment'>
            {
              caseNoteQuestionsData[
                CaseNoteQuestionTypes.FormulationAndTreatment
              ]?.question
            }
          </label>
          <Tags
            variant="disclaimer"
            tags={t('SHARED_DISCLAIMER')}
            icon={alertCircleIcon}
            uniqueKey="casenote_disclaimer"
            toolTipText={t('DISCLAIMER_TEXT')}
            toolTipClass="customTooltip"
          />
        </section>
        <Controller
          name="formulationAndTreatment"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.FormulationAndTreatment
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t(
                  `HISTORY_PROMPT_${CaseNoteQuestionTypes.FormulationAndTreatment}`,
                )}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.FormulationAndTreatment
                    ].id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="selection-wrapper">
        <section className="header-info-container">
          <label className="form-label" htmlFor='recommendations'>
            {
              caseNoteQuestionsData[CaseNoteQuestionTypes.Recommendations]
                ?.question
            }
          </label>
          <Tags
            variant="disclaimer"
            tags={t('SHARED_DISCLAIMER')}
            icon={alertCircleIcon}
            uniqueKey="casenote_disclaimer"
            toolTipText={t('DISCLAIMER_TEXT')}
            toolTipClass="customTooltip"
          />
        </section>
        <Controller
          name="recommendations"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <CheckboxGroup
              {...field}
              options={recommendationCategoriesOptions}
              defaultSelected={field?.value?.id}
              onChange={(selected) => {
                field.onChange({
                  id: selected,
                  questionId:
                    caseNoteQuestionsData[CaseNoteQuestionTypes.Recommendations]
                      .id,
                });
                caseNoteEventTracking?.(
                  caseNoteQuestionsData[
                    CaseNoteQuestionTypes.Recommendations
                  ].id.toString(),
                  EventCategories.questionId,
                );
              }}
            />
          )}
        />
        {watchCareRecommendations?.id.find(
          (item) => item.value === Recommendations.Referral,
        ) && (
          <CareReferral
            desc={t('REFERRAL_INSTRUCTION')}
            icon={alertCircleIcon}
            iconHeight="16px"
            iconWidth="16px"
            fontSize={14}
            fontWeight="700"
            onClick={() =>
              window.open(
                externalUrls.referral.typeformLink,
                '_blank',
                'noreferrer',
              )
            }
            btnLabel= {t('REFERRAL_CTA')}
            btnLabelIcon={arrowIcon}
          />
        )}
        {showAdditionalSupportEditor && (
          <Controller
            name="additionalSupport"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <section className="editor-wrapper">
                <TextEditor
                  {...field}
                  onTextValueChange={field.onChange}
                  defaultTemplate={field.value || ''}
                  parametersValue={[]}
                  maxCharacterLimit={5000}
                  showMaxCharText={false}
                  placeHolderText={t('RECOMMENDATION_PLACEHOLDER')}
                  className="custom-editor-style"
                  onFocus={startQuestionDurationTracking}
                  onBlur={() => caseNoteEventTracking?.('additional_support')}
                />
              </section>
            )}
          />
        )}
      </section>
      <section className="selection-wrapper">
        <section className="shared-followup">
          <label className="form-label followup" htmlFor='sharedFollowUpPlan'>
            {
              caseNoteQuestionsData[CaseNoteQuestionTypes.SharedFollowUpPlan]
                ?.question
            }
          </label>
          <Controller
            name="sharedFollowUpPlan"
            control={control}
            render={({ field: { ref, ...field } }) => (
              <RadioGroup
                {...field}
                name="shared-follow-up"
                values={sharedFollowUpCategoriesOptions}
                onChange={(_, selected) => {
                  field.onChange({
                    ...selected,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.SharedFollowUpPlan
                      ].id,
                  });
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.SharedFollowUpPlan
                    ].id.toString(),
                    EventCategories.questionId,
                  );
                }}
                selectedValue={field.value?.value}
                customCss
              />
            )}
          />
        </section>
      </section>
      <section className="care-history-textarea">
        <label className="form-label" htmlFor='followUpPlan'>
          {caseNoteQuestionsData[CaseNoteQuestionTypes.FollowUpPlan]?.question}
        </label>
        <Controller
          name="followUpPlan"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[CaseNoteQuestionTypes.FollowUpPlan]
                        ?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={5000}
                showMaxCharText={false}
                placeHolderText={t('FOLLOW_UP_PLACEHOLDER')}
                className="custom-editor-style"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.FollowUpPlan
                    ]?.id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
      <section className="care-history-textarea">
        <section className="header-info-container extra-padding">
          <label className="form-label" htmlFor='additonalSessionRecommended'>
            {
              caseNoteQuestionsData[
                CaseNoteQuestionTypes.AdditonalSessionRecommended
              ]?.question
            }
          </label>
          <Tags
            variant="disclaimer"
            tags={t('SHARED_DISCLAIMER')}
            icon={alertCircleIcon}
            uniqueKey="casenote_disclaimer"
            toolTipText={t('DISCLAIMER_TEXT')}
            toolTipClass="customTooltip"
          />
        </section>
        <Controller
          name="additonalSessionRecommended"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <section className="editor-wrapper">
              <TextEditor
                {...field}
                onTextValueChange={(note) =>
                  field.onChange({
                    id: note,
                    questionId:
                      caseNoteQuestionsData[
                        CaseNoteQuestionTypes.AdditonalSessionRecommended
                      ]?.id,
                  })
                }
                defaultTemplate={field.value?.id || ''}
                parametersValue={[]}
                maxCharacterLimit={3}
                showMaxCharText={false}
                placeHolderText={t('ADDITIONAL_RECOMMENDATION_PLACEHOLDER')}
                className="custom-editor-height"
                inputType="number"
                onFocus={startQuestionDurationTracking}
                onBlur={() =>
                  caseNoteEventTracking?.(
                    caseNoteQuestionsData[
                      CaseNoteQuestionTypes.AdditonalSessionRecommended
                    ]?.id.toString(),
                    EventCategories.questionId,
                  )
                }
              />
            </section>
          )}
        />
      </section>
    </article>
  );
}

export default FormPart2;
