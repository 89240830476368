import { RawAxiosRequestHeaders } from 'axios';
import NetworkClient from '../Network';
import { beautifyUrl } from '../../../utilities/common/Path';
import Config from '../../Config';
import isTokenExpired from '../../../utilities/hooks/irisHooks/isIrisTokenExpired';
import {
  addItemToLocalStorage,
  getItemFromLocalStorage,
} from '../../../utilities/common/Storage';
import { StorageItems } from '../../constant/App';
import {
  IIrisConversation,
  IIrisMessage,
  ISendMessageTypes,
} from '../../types/response/provider';
// import {
//   addSocketListener,
//   isSocketConnected,
// } from '../../../utilities/socket';
// import { IrisSocketEventTypes } from '../../types/helpline';
// import { IChatMessagePage } from '../../types/Chat';
// // eslint-disable-next-line import/no-cycle
// import { getMessages } from '../../redux/actions/chat';

const client = new NetworkClient();
export const getIrisAccessToken = async (accessToken: string) => {
  const response = await client.doGet(Config.api.iris.getIrisAccessToken, {
    accessToken,
  });
  if (response && response.data) {
    addItemToLocalStorage('irisExpiryTime', response.data.exp);
    addItemToLocalStorage('irisAccessToken', response.data.token);
    return response.data;
  }

  throw new Error('Error');
};

export const getIrisMessages = (conversationId: string, page: string) =>
  new Promise<IIrisMessage[]>((resolve, reject) => {
    const irisAccessToken = getItemFromLocalStorage(
      'irisAccessToken',
      'string',
    );
    const getNewMessages = async () => {
      const headers: RawAxiosRequestHeaders = {
        'Content-Type': 'application/json',
        Authorization: irisAccessToken.toString(),
      };
      const response = await client.doGet(
        beautifyUrl(Config.api.iris.getIrisMessages, [conversationId, page]),
        headers,
      );
      if (response && response.data) {
        // if (page === '1') {
        //   const slicedResponse = response.data.slice(1);
        //   resolve(slicedResponse);
        // }
        resolve(response.data);
      }
      reject(new Error('Unable to fetch data'));
    };
    const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
    const { token }: any = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    );
    const isIrisTokenExpired = isTokenExpired(Number(expToken));
    if (isIrisTokenExpired) {
      getIrisAccessToken(token);
      getNewMessages();
    } else {
      getNewMessages();
    }
  });

export const getIrisConversations = async (irisToken: string) => {
  const getConversations = async () => {
    const response = await client.doGet(
      beautifyUrl(Config.api.iris.getirisConversations, [irisToken]),
    );
    if (response && response.data) {
      return response.data;
    }
    throw new Error('Error');
  };
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  const { token }: any = getItemFromLocalStorage(
    StorageItems.USER_INFO,
    'object',
  );
  const isIrisTokenExpired = isTokenExpired(Number(expToken));
  if (isIrisTokenExpired) {
    getIrisAccessToken(token);
  } else {
    getConversations();
  }
};

export const sendIrisMessage = async ({
  data,
  conversationId,
}: ISendMessageTypes) => {
  const irisAccessToken = getItemFromLocalStorage('irisAccessToken', 'string');
  const sendMessage = async () => {
    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      Authorization: irisAccessToken.toString(),
    };
    const response = await client.doPost(
      beautifyUrl(Config.api.iris.sendIrisMessage, [conversationId]),
      data,
      headers,
    );
    if (response && response.data) {
      return response.data;
    }
    throw new Error('Error');
  };
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  const isIrisTokenExpired = isTokenExpired(Number(expToken));
  if (isIrisTokenExpired) {
    const { token }: any = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    );
    getIrisAccessToken(token);
    sendMessage();
  } else {
    sendMessage();
  }
};

export const getProviderConversationList = async () =>
  new Promise<IIrisConversation[]>((resolve, reject) => {
    const irisAccessToken = getItemFromLocalStorage(
      'irisAccessToken',
      'string',
    );
    const getIrisConversationList = async () => {
      const headers: RawAxiosRequestHeaders = {
        'Content-Type': 'application/json',
        Authorization: irisAccessToken.toString(),
      };
      const response = await client.doGet(
        Config.api.iris.getIrisProviderConversations,
        headers,
      );
      if (response && response.data) {
        resolve(response.data);
      }
      reject();
    };
    const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
    const { token }: any = getItemFromLocalStorage(
      StorageItems.USER_INFO,
      'object',
    );
    const isIrisTokenExpired = isTokenExpired(Number(expToken));
    if (isIrisTokenExpired) {
      getIrisAccessToken(token);
      getIrisConversationList();
    } else {
      getIrisConversationList();
    }
  });

export const deleteIrisMessage = async (
  messageId: string,
  // conversationId: string,
) => {
  const { token }: any = getItemFromLocalStorage(
    StorageItems.USER_INFO,
    'object',
  );
  const irisAccessToken = getItemFromLocalStorage('irisAccessToken', 'string');
  const deleteMessage = async () => {
    const headers: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      Authorization: irisAccessToken.toString(),
    };
    const response = await client.doPost(
      beautifyUrl(Config.api.iris.deleteIrisMessage, [messageId]),
      headers,
    );
    if (response && response.data) {
      return response.data;
    }
    throw new Error('Error');
  };
  const expToken = getItemFromLocalStorage('irisExpiryTime', 'string');
  const isIrisTokenExpired = isTokenExpired(Number(expToken));
  if (isIrisTokenExpired) {
    getIrisAccessToken(token);
    deleteMessage();
  } else {
    deleteMessage();
  }
};
