import * as React from 'react';
import './FloatingWidget.scss';
import { motion } from 'framer-motion';
import useLayout from '../../../utilities/hooks/useLayout';
import { IElementPosition } from '../../../shared/types/App';

interface IFloatingWidget {
  children: React.ReactNode,
  canDrag?: boolean;
  initialPosition?: IElementPosition;
}

function FloatingWidget({ children, canDrag = false, initialPosition }: IFloatingWidget) {
  const { layoutRef } = useLayout();
  return (
    <motion.div className="floating-widget-container"
      drag={canDrag} dragConstraints={layoutRef} style={initialPosition || {}}>
      {children}
    </motion.div>
  );
}

export default FloatingWidget;