import styled from 'styled-components';
import { CssSize } from './BaseCard.d';

interface BaseCardContainerProps {
  width?: CssSize;
  height?: CssSize;
  background?: string;
  padding?: CssSize;
}
export const BaseCardContainer = styled.section<BaseCardContainerProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
