import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer, RootState } from '../reducers/rootReducer';
import { loadAppState, saveAppState } from '../../../utilities/common/Storage';

const persistedState = loadAppState();

export default function configureStore(initialState = {}) {
  const store = createStore(
    rootReducer,
    persistedState || initialState,
    composeWithDevTools(applyMiddleware(thunk)),
  );

  store.subscribe(() => {
    const state: RootState = store.getState();
    const stateSliceToSave: RootState = {
      app: { meeting: state.app.meeting },
    };

    saveAppState(stateSliceToSave);
  });

  return store;
}
