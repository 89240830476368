import * as React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import './RecommendedAssignmentCard.scss';
import Typography from '../Typography';
import Tags from '../../app-components/Tags';
import Button from '../Button';
import { IRecommendedAssignmentCardsDetails } from './types';
import { AssignmentTypes } from '../../../shared/types/response/assignment';
import { ColorSet } from '../../../shared/types/App';

const tagColors: Record<AssignmentTypes | 'default', ColorSet> = {
  course: {
    fontColor: '#2A68B6',
    backgroundColor: '#D7E8FC',
  },
  questionnaire: {
    fontColor: '#663B22',
    backgroundColor: '#FEDDAD',
  },
  rescue: {
    fontColor: '#008044',
    backgroundColor: '#CCEEDE',
  },
  default: {
    fontColor: '#2A68B6',
    backgroundColor: '#D7E8FC',
  },
};

function RecommendedAssignmentCard({
  imgSrc,
  tags,
  handleAssign,
  title,
  customClassname = '',
  type,
}: IRecommendedAssignmentCardsDetails) {
  const { t } = useTranslation();

  const getTagsStyling = () =>
    tags.map(() => {
      let key: AssignmentTypes | 'default' = 'default';
      if (type in tagColors) {
        key = type as AssignmentTypes;
      }
      return tagColors[key];
    });

  return (
    <motion.article className={`assignment-card-container ${customClassname}`} whileHover={{ scale: 1.025 }}>
      <section className="assignment-card-img">
        <img src={imgSrc} alt="assignment-img" />
      </section>
      <section className="assignment-card-content">
        <section className="assignment-card-title">
          <Typography size={12} weight="700" width="124px">
            {title}
          </Typography>
        </section>
        <Tags
          tags={tags}
          multiTagsStyle={getTagsStyling()}
          textSize={8}
          customClassName="tags-style"
        />
        <section className="assignment-card-button">
          <Button
            width="85px"
            minWidth="85px"
            minHeight="20px"
            height="20px"
            variant="blue"
            fontSize={12}
            fontWeight="500"
            label={t('assign')}
            onClick={handleAssign}
          />
        </section>
      </section>
    </motion.article>
  );
}

export default RecommendedAssignmentCard;
