/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  CategoryScale,
  Chart,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from 'chart.js';

import LineChart from './LineChart';
import { ChartTypes } from './chart';
import GaugeChart, { IGaugeChart } from './GaugeChart';
import { CssSize } from '../BaseCard/BaseCard.d';

interface IWidget<T> {
  type: ChartTypes;
  chartData?: T;
  props?: any;
  isLoading?: boolean;
  width?: CssSize;
  height?: CssSize;
  gaugeChartProps?: IGaugeChart;
}
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, [
  Tooltip,
]);

export default function ChartWidget<T>({
  isLoading,
  type,
  chartData,
  props,
  height,
  width,
  gaugeChartProps,
}: IWidget<T>) {
  if (isLoading) {
    return <div>loading</div>;
  }
  switch (type) {
    case 'LineChart':
      return (
        <LineChart
          data={chartData}
          props={props}
          height={height}
          width={width}
        />
      );
    case 'GaugeChart':
      if (gaugeChartProps) {
        return <GaugeChart {...gaugeChartProps} />;
      }
      throw Error('provide gaugeChart props');

    default:
      return <div>Chart type {type} not supported</div>;
  }
}
