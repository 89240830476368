/* eslint-disable @typescript-eslint/indent */
import { Message } from '@twilio/conversations';
import Config from '../../Config';
import NetworkClient from '../../service/Network';
import { IProviderConversations } from '../../types/response/provider';
import {
  IChatItem,
  IChatMessagePage,
  IConversationsMetadata,
  IMessageItem,
  IrisMediaContentTypes,
} from '../../types/Chat';
import { addItemToLocalStorage } from '../../../utilities/common/Storage';
import { StorageItems } from '../../constant/App';
import ActionTypes from '../../constant/ActionTypes';
import Client from '../../service/Chat';
import { beautifyUrl } from '../../../utilities/common/Path';
import {
  getIrisMessages,
  getProviderConversationList,
} from '../../service/API/iris';
import { MessageChannelTypes } from '../../constant/Chat';

const client = new NetworkClient();
const chatClientInstance = new Client();
const noOfMessagesPerPage = 10;

export const getTokenAndChannelData =
  () => async (dispatch: Function, getState: Function) => {
    const conversationsMetadata: Partial<IConversationsMetadata> = {};
    const clientsData: IConversationsMetadata['userData'] = [];
    const currentState = getState();

    return new Promise((resolve, reject) => {
      client
        .doPost(Config.api.chat.conversations, {})
        .then(async (response: IProviderConversations) => {
          const responseData = response.data;
          const token = responseData.chatTokens[0];
          responseData.conversations.forEach(async (conversation) => {
            clientsData.push({
              channelId: conversation.channelExternalId,
              conversationId: conversation.conversationId,
              userId: conversation.userId,
              friendlyName: conversation.user?.friendlyName,
              providerRole: conversation.providerRole,
              tags: conversation.user.tags,
              channelType: conversation.channelType,
            });
          });
          conversationsMetadata.userData = clientsData;
          conversationsMetadata.clientToken = token;

          dispatch({
            type: ActionTypes.CHAT.SET_TOTAL_CONVERSATIONS,
            payload: responseData.conversations.length,
          });

          // TODO try out progressive building to enhance performance (+ generator pattern)

          // addItemToLocalStorage(StorageItems.CHAT_TOKEN, token);

          if (
            currentState.chat &&
            currentState.chat.allChats &&
            currentState.chat.allChats.length !==
              responseData.conversations.length
          ) {
            const chatClient = await chatClientInstance.getClient();
            await Promise.all(
              conversationsMetadata.userData.map(async (userData) => {
                const {
                  channelId,
                  userId,
                  friendlyName,
                  providerRole,
                  conversationId,
                  channelType,
                } = userData;

                if (chatClient && channelType === MessageChannelTypes.TWILIO) {
                  const channelObj = await chatClient.getConversationBySid(
                    channelId,
                  ); // TODO confirm channel joining status ?
                  let unConsumedMessageCount: number | null = 0;
                  try {
                    unConsumedMessageCount =
                      await channelObj.getUnreadMessagesCount();
                  } catch (error) {
                    console.log(
                      'error occurred while getting unread count: ',
                      error,
                    );
                  }

                  const initialChannelMessages = await channelObj
                    .getMessages(noOfMessagesPerPage)
                    .catch((error) =>
                      console.log(
                        `Error occurred while fetching messages: ${error.message}`,
                      ),
                    );

                  const channelMessageInstance = await {
                    channelId,
                    conversationId,
                    userId,
                    friendlyName,
                    providerRole,
                    channelType,
                    unConsumedMessageCount: unConsumedMessageCount || 0,
                    messages: initialChannelMessages,
                  };
                  dispatch({
                    type: 'SET_CHANNEL_MESSAGES',
                    payload: await channelMessageInstance,
                  });
                } else if (channelType === MessageChannelTypes.IRIS) {
                  const data = await getProviderConversationList();
                  const filteredData = await data?.filter(
                    (item) => item.id === userData.conversationId,
                  );
                  const irisitems: IMessageItem[] = [];
                  filteredData?.map(async (message) => {
                    if (
                      message.lastMessage.message === '' ||
                      message.lastMessage.message === 'Assignment ✍️'
                    ) {
                      const fetchedMessages = await getIrisMessages(
                        message.id,
                        '1',
                      );
                      const messageAttachments = fetchedMessages[0];
                      const timestampString =
                        message.lastMessage !== null
                          ? message?.lastMessage.createdAt
                          : '';
                      const date = new Date(timestampString);
                      let irisMessage = '';
                      let irisBody = '';
                      let assignmentId = '';
                      let media = null;
                      if (message.lastMessage !== null) {
                        if (message.lastMessage.message !== '') {
                          irisMessage = message.lastMessage.message;
                        } else if (message.lastMessage.message === '') {
                          irisMessage = messageAttachments.message || '';
                        }
                      } else {
                        irisMessage = '';
                      }
                      if (message.lastMessage !== null) {
                        if (message.lastMessage.message !== '') {
                          irisBody = message.lastMessage.message;
                        } else if (message.lastMessage.message === '') {
                          if (messageAttachments.attachments.length > 0) {
                            irisBody =
                              messageAttachments.attachments[0].content;
                          } else {
                            irisBody = messageAttachments.message;
                          }
                        }
                      } else {
                        irisBody = '';
                      }
                      if (message.lastMessage.message === '') {
                        media = {
                          contentType:
                            IrisMediaContentTypes[
                              messageAttachments.attachments[0].type
                            ],
                          url: messageAttachments.attachments[0].content,
                          getContentTemporaryUrl: () =>
                            messageAttachments.attachments[0].content,
                        };
                      } else {
                        media = null;
                      }
                      if (
                        message.lastMessage.message === 'Assignment ✍️' &&
                        messageAttachments?.attachments?.[0]?.type === 4 &&
                        messageAttachments?.attachments?.[0]?.content
                      ) {
                        try {
                          const jsonData = JSON.parse(
                            messageAttachments.attachments[0].content,
                          );
                          assignmentId = jsonData?.assignmentId;
                          // jsonData is a JSON object
                        } catch (error) {
                          console.log('~ error:', error);
                          // Handle the error condition
                        }
                        // const data = JSON.parse(message.attachments[0].content);
                      }
                      irisitems.push({
                        conversationId,
                        state: {
                          message: irisMessage,
                          body: irisBody,
                          author:
                            message.lastMessage !== null
                              ? message.lastMessage.senderId.toString()
                              : '',
                          sid: message.lastMessage.id,
                          timestamp:
                            message.lastMessage !== null
                              ? date
                              : new Date(messageAttachments.createdAt),
                          type:
                            message.lastMessage.message === ''
                              ? 'media'
                              : 'text',
                          index: 9999,
                          media,
                          attributes: {
                            messageType: 'assignment',
                            assignmentId,
                          },
                        },
                      });
                    } else {
                      const timestampString =
                        message.lastMessage !== null
                          ? message?.lastMessage.createdAt
                          : '';
                      const date = new Date(timestampString);
                      irisitems.push({
                        conversationId: message.id,
                        state: {
                          message:
                            message.lastMessage !== null
                              ? message.lastMessage.message
                              : '',
                          body:
                            message.lastMessage !== null
                              ? message.lastMessage.message
                              : '',
                          author:
                            message.lastMessage !== null
                              ? message.lastMessage.senderId.toString()
                              : '',
                          sid: message.lastMessage.id,
                          timestamp:
                            message.lastMessage !== null ? date : new Date(),
                          type: 'text',
                          index: 9999,
                          media: null,
                        },
                      });
                    }
                  });

                  const irisChatMessage: IChatItem = {
                    channelId,
                    channelType,
                    conversationId,
                    userId,
                    friendlyName,
                    providerRole,
                    messages: {
                      hasNextPage: false,
                      hasPrevPage: false,
                      nextPage: (page: string) => {
                        getIrisMessages(userData.conversationId, page);
                      },
                      prevPage: () => {},
                      items: await irisitems.reverse(),
                    },
                    tags: '',
                    unConsumedMessageCount:
                      filteredData[0]?.participants[0]?.unreadCount,
                  };

                  const channelMessageInstance = {
                    channelId,
                    channelType,
                    conversationId,
                    userId,
                    friendlyName,
                    providerRole,
                    unConsumedMessageCount:
                      filteredData[0]?.participants[0]?.unreadCount,
                    messages: irisChatMessage.messages,
                  };
                  dispatch({
                    type: 'SET_CHANNEL_MESSAGES',
                    payload: channelMessageInstance,
                  });
                }
              }),
            );

            resolve(conversationsMetadata);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          console.error(
            'Error occurred while fetching provider conversations - ',
            error,
          );
          reject(error);
        });
    });
  };

export const setUnconsumedMessageCount =
  (conversationId: string, count: number) => (dispatch: Function) => {
    dispatch({
      type: 'UPDATE_MESSAGE_CONSUMPTION_STATUS',
      payload: { conversationId, count },
    });
  };

export const addNewMessage =
  (
    message: Message,
    selectedChannelId: string | undefined,
    chatWindowVisible: boolean,
  ) =>
  // eslint-disable-next-line @typescript-eslint/indent
  (dispatch: Function) => {
    const newMessageChannelId = message.conversation.sid;
    dispatch({ type: 'ADD_NEW_MESSAGE', payload: message });
    if (!(chatWindowVisible && newMessageChannelId === selectedChannelId)) {
      // message consumption status should only be updated in all the case except the chat window is visible -
      // and interacts with that channel
      dispatch({
        type: 'UPDATE_MESSAGE_CONSUMPTION_STATUS',
        payload: { channelId: newMessageChannelId, count: 1 },
      });
    }
  };

export const getMessages =
  (newMessages: IChatMessagePage) => (dispatch: Function) => {
    dispatch({
      type: 'PREPEND_MESSAGES',
      payload: newMessages,
    });
  };

export const getSocketMessages =
  (newMessages: IChatMessagePage) => (dispatch: Function) => {
    dispatch({
      type: 'PREPEND_SOCKET_MESSAGES',
      payload: newMessages,
    });
  };

export const getChatToken = () => (dispatch: Function) =>
  client
    .doPost(Config.api.chat.conversations, {})
    .then((response: IProviderConversations) => {
      if (
        response &&
        response.data &&
        response.data.chatTokens &&
        response.data.chatTokens[0]
      ) {
        const chatToken = response.data.chatTokens[0];
        addItemToLocalStorage(StorageItems.CHAT_TOKEN, chatToken);
        dispatch({
          type: ActionTypes.APP.SET_CHAT_TOKEN,
          payload: response.data.chatTokens[0],
        });
      }
    })
    .catch((error) =>
      console.log(
        `Error occurred while fetching conversations: ${error.message}`,
      ),
    );

export const setChatMessagesConsumed = (channelId: string) => () =>
  client.doPost(
    beautifyUrl(Config.api.chat.setChatMessageConsumed, [channelId]),
    {},
  );
