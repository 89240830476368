import * as React from 'react';
import './ClientDetail.scss';
import moment from 'moment';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import lodash from 'lodash';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import Collapsible from '../../components/Collapsible';
import useLayout from '../../../utilities/hooks/useLayout';
import {
  IClientSummary,
  IProviderClient,
  ClientMeetingData,
} from '../../../shared/types/response/provider';
import { getProviderRole } from '../../../utilities/common/Provider';
import {
  getClientData as _getClientData,
  getCaseNoteData as _getCaseNoteData,
} from '../../../shared/redux/actions/provider';
import {
  displayDateFormat,
  displayJaDateFormat,
  getClientCredits,
} from '../../../utilities/common/Display';
import SideDrawer from '../../components/SideDrawer';
import CaseNotes from '../CaseNotes';
import { Page } from '../../../shared/types/common';
import Tags from '../Tags';
import CaseNotesListItem from '../CaseNotesListItem';
import { ICaseNoteAnswer } from '../../../shared/types/response/CaseNote';
import { APIDateFormat, getDisplayDate } from '../../../utilities/common/Date';
import useTracking from '../../../utilities/hooks/useTracking';
import { getCaseNoteFormType } from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import { CaseNoteFormType } from '../../../shared/constant/CoachingCaseNotes';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import NotFound from '../../screens/NotFound';
import CareCaseNotes from '../CareCaseNotes';
import CareIntake from '../CareIntake';
import SideDrawerContext, {
  ISideDrawerSetting,
} from '../../../shared/context/SideDrawerContext';
import { getCurrentLocale } from '../../../i18n';
import { encodeCode } from '../../../utilities/common/Encryption';

interface IClientDetail {
  clientSummary: IClientSummary;
  clientData: IProviderClient;
  /** case note list returned by API only contains written case notes,
   * sometimes we need to pass case notes which should be written as new
   */
  unsavedCaseNotes?: ClientMeetingData[];
  getClientData: (
    clientId: string,
    page: number,
    providerRole: string,
  ) => Promise<Page>;
  getCaseNoteData: (meetingId: string) => Promise<Array<ICaseNoteAnswer>>;
  isInSession?: boolean;
}

function ClientDetail({
  clientSummary,
  getClientData,
  clientData,
  getCaseNoteData,
  unsavedCaseNotes = [],
  isInSession = false,
}: IClientDetail) {
  const { updateChatWindowSettings } = useLayout();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { track } = useTracking();
  const [showCaseNotesDrawer, setShowCaseNotesDrawer] =
    React.useState<boolean>(false);
  const [selectedCaseNote, setSelectedCaseNote] =
    React.useState<ClientMeetingData>();
  const [filteredUnsavedCaseNotes, setFilteredUnsavedCaseNotes] =
    React.useState<ClientMeetingData[]>([]);
  const [sideDrawerSettings, setSideDrawerSettings] =
    React.useState<ISideDrawerSetting>({
      beforeHideHandler: () => false,
    });

  const updateSideDrawerSettings = (
    sideDrawerSetting: Partial<ISideDrawerSetting>,
  ) => {
    setSideDrawerSettings((prev) => ({ ...prev, ...sideDrawerSetting }));
  };
  const { caseNotes, credits, totalMeetings, recentMeeting, meetingCount } = clientData;
  const { providerRole, user, userId } = clientSummary;
  const { createdAt, friendlyName, tags } = user;
  React.useEffect(() => {
    const initializeClientDetails = async () => {
      if (clientSummary && providerRole) {
        const initialPage = 1;
        getClientData(userId?.toString(), initialPage, providerRole);

        await Promise.all(
          unsavedCaseNotes.map(async (caseNote) => {
            const hasAnswers = await (
              await getCaseNoteData(caseNote.meetingId)
            ).length;
            if (!hasAnswers) {
              setFilteredUnsavedCaseNotes((prev) => [...prev, caseNote]);
            }
          }),
        );
      }
    };

    initializeClientDetails();
  }, [clientSummary, providerRole]);

  React.useEffect(() => {
    if (selectedCaseNote && JSON.stringify(selectedCaseNote) !== '{}') {
      setShowCaseNotesDrawer(true);
    }
  }, [selectedCaseNote]);

  React.useEffect(() => {
    if (!lodash.isEmpty(clientData) && providerRole) {
      const { page } = clientData;
      if (page.hasNextPage) {
        getClientData(userId?.toString(), page.nextPage, providerRole);
      }
    }
  }, [clientData, providerRole]);

  const { fetchCasenoteFormType, isFormTypeLoading } = getCaseNoteFormType(
    (data, meetingData) => {
      if (
        data.form === CaseNoteFormType.V3 ||
        data.form === CaseNoteFormType.V4
      ) {
        navigate(`/carenotes/${encodeCode(userId)}/${meetingData.meetingId}`);
      } else if (data.form === CaseNoteFormType.V1) {
        navigate(`/coachingnotes/${encodeCode(userId)}/${meetingData.meetingId}`);
      } else {
        setSelectedCaseNote({
          clientId: encodeCode(userId),
          meetingId: meetingData.meetingId,
          meetingDate: meetingData.meetingDate,
          formType: data.form as CaseNoteFormType,
        });
      }
    },
  );

  const onViewCaseNote = (meetingId: string, meetingDate: string) => {
    fetchCasenoteFormType({ meetingId, meetingDate, isInSession });
    track(EventNames.view_session_case_notes, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.view_session_case_notes,
      eventLabel: 'view_session_case_notes',
      featureVersion: 'v1',
      eventValue: {
        meetingId,
        userId,
      },
      eventSource: pathname.includes('room') ? EventSource.meeting : EventSource.scheduleSession,
    });
  };

  const hideCaseNotesDrawer = () => {
    setSelectedCaseNote({} as ClientMeetingData);
    setShowCaseNotesDrawer(false);
    track(EventNames.view_session_case_notes, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.close,
      eventLabel: 'close',
      featureVersion: 'v1',
    });
  };

  const sideDrawerContextValue = React.useMemo(
    () => ({
      beforeHideHandler: sideDrawerSettings.beforeHideHandler,
      updateSideDrawerSettings,
      hideHandler:hideCaseNotesDrawer,
    }),
    [sideDrawerSettings],
  );
  
  const getInSessionCaseNotes = (formType: CaseNoteFormType) => {
    switch (formType) {
      case CaseNoteFormType.V6:
        return (
          <CareIntake
            isInSessionForm
            formType={formType}
            selectedClientId={selectedCaseNote?.clientId}
            selectedMeetingId={selectedCaseNote?.meetingId}
          />
        );
      case CaseNoteFormType.v0:
        return <CaseNotes mode="edit" client={selectedCaseNote as ClientMeetingData} />;
      // @ts-ignore
      case CaseNoteFormType.V5:
      case CaseNoteFormType.V7:
        return <CareCaseNotes
                formType={selectedCaseNote?.formType as CaseNoteFormType}
                showDrawer={showCaseNotesDrawer} 
                selectedClientId={selectedCaseNote?.clientId}
                selectedMeetingId={selectedCaseNote?.meetingId}
                onClose={hideCaseNotesDrawer}
                inSession
                />;
      default:
        return <NotFound />;
    }
  };
  const canShowCaseNotesForV5V7 = React.useMemo(() => {
    if (selectedCaseNote && selectedCaseNote?.formType) {
      return [CaseNoteFormType.V5, CaseNoteFormType.V7].includes(selectedCaseNote.formType);
    }
    return false;
  }, 
  [selectedCaseNote]);

  const currentLocale = getCurrentLocale();
  const joinedOn = getDisplayDate({
    locale: currentLocale,
    selectedDate: createdAt as string,
    format: { ja: displayJaDateFormat, en: 'DD MMM' },
    translatorResource: { ja: 'joinedOn', en: 'joinedOn' },
  });
  
  return (
    <div className="client-details">
      {
        canShowCaseNotesForV5V7 && showCaseNotesDrawer && getInSessionCaseNotes(selectedCaseNote?.formType as CaseNoteFormType)
      }
      <SideDrawerContext.Provider value={sideDrawerContextValue}>
        <SideDrawer
          show={showCaseNotesDrawer && !canShowCaseNotesForV5V7}
          hideHandler={hideCaseNotesDrawer}
          className={selectedCaseNote?.formType === '0' ? '' : 'in-session-no-followup' }
        >
          {showCaseNotesDrawer &&
            !!selectedCaseNote &&
            // <CaseNotes mode="edit" client={selectedCaseNote} />
            getInSessionCaseNotes(
              selectedCaseNote.formType as CaseNoteFormType,
            )}
        </SideDrawer>
      </SideDrawerContext.Provider>
      <div className="details-container">
        <div className="basic-details">
          <div className="client-name-and-tags">
            <Typography size={24} weight="600">
              {friendlyName}
            </Typography>
            {tags && <Tags tags={tags} />}
          </div>
          <Typography size={14} weight="400">
            {joinedOn}
          </Typography>
          <div className="client-type">
            <Typography size={12} weight="600">
              {t(getProviderRole(providerRole))}
            </Typography>
          </div>
        </div>
        <div className="sessions-details">
          <div className="detail-item">
            <Typography size={14} weight="400" color="solarized">
              {t('lastSessionOn')}
            </Typography>
            <Typography size={14} weight="600">
              {recentMeeting
                ? moment(recentMeeting.scheduledDate, APIDateFormat).format(
                  displayDateFormat,
                )
                : t('noSessions')}
            </Typography>
          </div>
          <div className="detail-item">
            <Typography size={14} weight="400" color="solarized">
              {t('sessionsAttended')}
            </Typography>
            <Typography size={14} weight="600">
              {totalMeetings}
            </Typography>
          </div>
          <div className="detail-item">
            <Typography size={14} weight="400" color="solarized">
              {t('creditsLeft')}
            </Typography>
            <Typography size={14} weight="600">
              {credits &&
                t(getClientCredits(credits), { count: credits.credit })}
            </Typography>
          </div>
        </div>
        <Button
          label={t('viewMessages')}
          variant="primary"
          onClick={() => {
            updateChatWindowSettings({ show: true, initialUser: userId });
            track(EventNames.messages, {
              eventAction: EventActions.load,
              eventCategory: EventCategories.viewMessages,
              eventLabel: 'view_messages',
              featureVersion: 'v1',
              eventSource: EventSource.scheduleSession,
            });
          }}
        />
      </div>
      <div className="elaborated-details">
        <Collapsible collapsedMinHeight="40" initial="expanded">
          <div className="collapsible-items-header">
            <Typography weight="600" size={14}>
              {t('caseNotes')}
            </Typography>
            <Typography weight="400" size={14} color="solarized">
              {' '}
              ({meetingCount})
            </Typography>
          </div>
          <div className="case-notes">
            {!!filteredUnsavedCaseNotes.length &&
              filteredUnsavedCaseNotes.map((caseNote) => {
                const { meetingId, meetingDate } = caseNote;
                return (
                  <CaseNotesListItem
                    key={meetingId}
                    meetingId={meetingId}
                    scheduledDate={meetingDate}
                    onViewCaseNote={() =>
                      onViewCaseNote(meetingId, meetingDate)
                    }
                    isDisable={isFormTypeLoading}
                  />
                );
              })}
            {caseNotes &&
              caseNotes.map((caseNote) => {
                const {
                  meetingId,
                  meeting: { displayDate },
                } = caseNote;
                return (
                  <CaseNotesListItem
                    key={meetingId}
                    meetingId={meetingId}
                    scheduledDate={displayDate}
                    onViewCaseNote={() =>
                      onViewCaseNote(meetingId, displayDate)
                    }
                  />
                );
              })}
          </div>
        </Collapsible>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  clientData: state.provider.selectedClient,
});

const mapDispatchToProps = (dispatch: Function) => ({
  getClientData: (clientId: string, page: number, providerRole: string) =>
    dispatch(_getClientData(clientId, page, providerRole)),
  getCaseNoteData: (meetingId: string) => dispatch(_getCaseNoteData(meetingId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetail);
