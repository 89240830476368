import moment from 'moment';
import { setDefaultLocale as setDatePickerLocale } from 'react-datepicker';
import { APP_DEFAULT_LANGUAGE, StorageItems } from '../shared/constant/App';
import { addItemToLocalStorage, getItemFromLocalStorage } from '../utilities/common/Storage';
import i18nInstance from './config';
import 'moment/locale/ja';

export const initializeLocalization = () => {
  import('./config');
};

export const changeLanguage = (locale: string) => {
  addItemToLocalStorage(StorageItems.APP_LANGUAGE, locale);
  i18nInstance.changeLanguage(locale);
  moment.locale(locale);
  setDatePickerLocale(locale);
};

export const getCurrentLocale = () => (getItemFromLocalStorage(StorageItems.APP_LANGUAGE, 'string') || APP_DEFAULT_LANGUAGE) as string;

export const availableLanguages = [
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'ja',
    label: 'Japanese',
  },
];

export const setAppLanguage = () => {
  const currentLang = getCurrentLocale();
  changeLanguage(currentLang);
};