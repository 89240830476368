/*eslint-disable */
import * as React from 'react';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import './ScheduledSessions.scss';
import {
  ClientMeetingData,
  IClientSummary,
  IMeetingStatus,
  IMeetingSummary,
  LeaveData,
  SessionData,
} from '../../../shared/types/response/provider';
import { APIDateFormat, APIDisplayTimeFormat, OldChatContactFormat, getDisplayDate } from '../../../utilities/common/Date';
import { isSessionCompleted } from '../../../utilities/common/Provider';
import { displayJaDateFormat } from '../../../utilities/common/Display';
import { saveHoliday as _saveHoliday } from '../../../shared/redux/actions/provider';
import {
  initializeMeeting as _initializeMeeting,
  resetMeeting as _resetMeeting,
} from '../../../shared/redux/actions/app';
import {
  InPersonBannerStateToMeetingStatusMap,
  InPersonBannerStates,
  InPersonBannerValues,
  MeetingStatus,
  SessionStatus,
} from '../../../shared/constant/Common';
import CaseNotes from '../CaseNotes';
import SideDrawer from '../../components/SideDrawer';
import ClientDetail from '../ClientDetails';
import { VideoRoomSDKs } from '../../../shared/constant/App';
import useMeeting from '../VideoRoom/hooks/useMeetingJoin';
import useToast from '../../../utilities/hooks/useToast';
import Loader from '../../components/Loader';
import {
  getDailyCoUrlFromRoomId,
  getInPersonMeetingState,
} from '../../../utilities/common/Meeting';
import KebabMenu from '../../components/KebabMenu';
import useCancellation from '../../../utilities/hooks/fetchHooks/cancellation';
import Tags from '../Tags';
import clockIcon from '../../../assets/clock.svg';
import locationIcon from '../../../assets/location.svg';
import {
  StatusValues,
  googleMapLocationLink,
} from '../../../shared/constant/ProviderBooking';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';
import CaseNote from '../CaseNotesV2';
import CancellationReasonsModal from '../CancellationReasonsModal';
import { useCancellationData } from '../../../utilities/hooks/useCancellationData/useCancellationData';
import { getCurrentLocale } from '../../../i18n';
import Card from '../../components/Card';
import InformationModal from '../InformationModal';
import useInpersonInfo from '../../../utilities/hooks/useInPersonInfo/useInpersonInfo';
import arrowIcon from '../../../assets/slanting arrow.svg';
import { encodeCode } from '../../../utilities/common/Encryption';
import useOutOfOffice from '../../../utilities/hooks/useOutOfOffice/useOutOfOffice';

interface IScheduledSessions {
  sessions: SessionData[];
  selectedDay: string;
  meeting: IMeetingStatus;
  initializeMeeting: (data: ClientMeetingData) => void;
  resetMeeting: () => void;
  refetchSessions: () => void;
  leaves: LeaveData[];
}

const filterByDay = (
  allSessions: SessionData[] | LeaveData[],
  selectedDay: string,
) => {
  const formattedSelectedDay = moment(selectedDay).format(APIDateFormat);
  return allSessions?.filter(
    (session) => session.displayDate === formattedSelectedDay,
  );
};

function ScheduledSessions({
  sessions,
  selectedDay,
  meeting,
  initializeMeeting,
  resetMeeting,
  refetchSessions,
  leaves,
}: IScheduledSessions) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { track } = useTracking();
  const toast = useToast();
  const { isOnLeave: leaveInfo, fetchOnLeave, leaveCheckLoading } = useOutOfOffice();
  const { status: meetingStatus, data } = meeting || {};
  const [showNewCaseNote, setShowNewCaseNote] = React.useState<boolean>(false);
  const [selectedClient, setSelectedClient] =
    React.useState<IClientSummary | null>(null);
  const [selectedMeeting, setSelectedMeeting] =
    React.useState<IMeetingSummary | null>(null);
  const [kebabMenuContext, setKebabMenuContext] =
    React.useState<SessionData | null>(null);
  const [isWithin24Hrs, setIsWithin24Hrs] = React.useState<boolean>(false);
  const [isReschedule, setIsReschedule] = React.useState<boolean>(false);
  const [showRescheduleInPersonInfoModal, setShowRescheduleInPersonInfoModal] =
    React.useState<boolean>(false);
  const [inpersonWarning, setInpersonWarning] = React.useState<{
    showWarning:boolean,
    currentMeetingStatus?:string,
    currentMeetingId?:string,
    scheduledStartTime?: string,
  }>({
    showWarning: false,
  });
  const onUpdateInPersonStatusSuccess = React.useCallback(()=>{
    if(inpersonWarning.currentMeetingStatus===InPersonBannerStateToMeetingStatusMap.noShow){
        toast.show({ message:t('NO_SHOW_SUCCESS') });
    }
    if(inpersonWarning.currentMeetingStatus===InPersonBannerStateToMeetingStatusMap.stop){
        toast.show({ message:t('MEETING_COMPLETE') });
    }
    refetchSessions()
  },[inpersonWarning.currentMeetingStatus])
  const { updateInPersonStatus, inPersonStatusUpdated } = useInpersonInfo(
    '',
    false,
    onUpdateInPersonStatusSuccess
  );
  const filteredSessions = React.useMemo(()=>(
    filterByDay(sessions, selectedDay) as SessionData[]
  ).filter((session) =>
    moment.unix(Number(session.scheduledEndTime)).isAfter(moment()),
  ),[sessions,selectedDay])
  const filteredLeaves = filterByDay(leaves, selectedDay) as LeaveData[];

  const onCancellationPopupClose = (isFlowIncompleted: boolean = true) => {
    if (isFlowIncompleted) {
      track(EventNames.providerCancelSession, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.cancellationReason,
        eventLabel: 'close_button',
        featureVersion: 'v1',
      });
    }
    setKebabMenuContext(null);
    setIsWithin24Hrs(false);
    setIsReschedule(false);
  };

  const { isLoading, mutate: joinMeeting } = useMeeting({
    onSuccess: (meetingData) => {
      const dailyCoUrl = getDailyCoUrlFromRoomId(
        meetingData?.meeting?.locationRef || '',
      );
      navigate(
        `/rooms/${VideoRoomSDKs.dailyCo}/${meetingData.meeting.id}/?roomUrl=${dailyCoUrl}`,
      );
    },
    onError: (error: Error) => {
      toast.show({ message: error.message });
    },
  });

  const { mutate: cancelAppointment, isLoading: isCancellingSession } =
    useCancellation({
      onSuccess: () => {
        toast.show({
          closeAfter: 3000000,
          message: t('CANCELLATION_SUCCESS', {
            clientName: kebabMenuContext?.friendlyName,
            date: moment(kebabMenuContext?.displayDate, APIDateFormat).format(
              'Do MMM, dddd',
            ),
            time: kebabMenuContext?.displayTime,
          }),
        });
        refetchSessions();
      },
      onError: (error) => {
        toast.show({ message: error.message });
      },
    });

  const onEnterRoomClick = (sessionData: SessionData) => {
    if (!isSessionCompleted(sessionData.status)) {
      const {
        userCreatedAt,
        userId,
        providerRole,
        tags,
        friendlyName,
        meetingId,
        displayDate,
        type,
      } = sessionData;
      track(EventNames.providerDashboard, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.meetingEnter,
        eventLabel: 'meeting_enter',
        featureVersion: 'v1',
      });
      const meetingData: ClientMeetingData = {
        meetingId,
        providerRole,
        tags,
        friendlyName,
        userCreatedAt,
        clientId: userId,
        meetingDate: displayDate,
      };
      initializeMeeting(meetingData);

      // * join validation for dailyCo should happens before entering the room
      if (type === VideoRoomSDKs.dailyCo) {
        joinMeeting({ meetingId, channel: VideoRoomSDKs.dailyCo });
      } else {
        navigate(`/rooms/${type}/${sessionData.meetingId}`);
      }
    }
  };

  const onCaseNotesClose = () => {
    resetMeeting();
    setShowNewCaseNote(false);
  };

  React.useEffect(() => {
    if (meetingStatus === MeetingStatus.COMPLETED) {
      setShowNewCaseNote(true);
    } else {
      setShowNewCaseNote(false);
    }
  }, [meeting]);
  React.useEffect(()=>{
    fetchOnLeave(moment(selectedDay).format(APIDateFormat));
  },[selectedDay]) 

  const onCaseNoteView = (sessionData: SessionData) => {
    const {
      userCreatedAt,
      userId,
      providerRole,
      tags,
      friendlyName,
      meetingId,
      displayDate,
    } = sessionData;

    const clientSummary: IClientSummary = {
      providerRole,
      userId: Number(userId),
      user: {
        friendlyName,
        tags,
        createdAt: userCreatedAt,
      },
    };

    const meetingSummary: IMeetingSummary = {
      meetingId,
      scheduledDate: displayDate,
    };

    setSelectedClient(clientSummary);
    setSelectedMeeting(meetingSummary);
    track(EventNames.view_session_case_notes, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.view_session_case_notes,
      eventLabel: 'view_session_case_notes',
      featureVersion: 'v1',
      eventValue: {
        meetingId,
        userId,
      },
      eventSource: EventSource.scheduleSession,
    });
  };

  const getUnsavedCaseNotes = () => {
    const { meetingId, scheduledDate } = selectedMeeting || {};
    const unsavedCaseNotes: ClientMeetingData[] = [];
    const currentMeetingCaseNote: ClientMeetingData = {
      clientId: selectedClient?.userId?.toString() || '',
      meetingId: meetingId || '',
      meetingDate: scheduledDate || '',
    };

    unsavedCaseNotes.push(currentMeetingCaseNote);

    // * returning empty array as API is not yet ready to support editing future case notes.
    return [];
  };

  const shouldDisableCancellation = (
    scheduledStartTime: string,
    meetingTimeZone: string,
  ) => {
    const todayMoment = moment();
    const dateMoment = moment
      .utc(Number(scheduledStartTime) * 1000)
      .tz(meetingTimeZone);
    return dateMoment.isBefore(todayMoment);
  };

  const getHoursDifference = (meetingDetails: any) => {
    const currentTimeMillis = Date.now();
    const epochTimeMillis = (meetingDetails?.scheduledStartTime || 0) * 1000;
    const currentTime = moment(currentTimeMillis);
    const epochTime = moment(epochTimeMillis);
    const differenceInHours = epochTime.diff(currentTime, 'hours');
    if (differenceInHours <= 24) {
      setIsWithin24Hrs(true);
    }
  };

  const handleKebabMenuClick = (sessionData: SessionData) => {
    if (sessionData.type === VideoRoomSDKs.f2f) {
      setShowRescheduleInPersonInfoModal(true);
    } else {
      setKebabMenuContext(sessionData);
      getHoursDifference(sessionData);
    }
    track(EventNames.providerCancelSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.cancelSession,
      eventLabel: 'provider_cancel_session',
    });
  };

  const handleWarningModalCancel = (rescheduleState: boolean) => {
    setIsReschedule(rescheduleState);
    track(EventNames.providerCancelSession, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.confirmCancellation,
      eventLabel: rescheduleState
        ? 'reschedule_session'
        : 'confirm_cancellation',
      featureVersion: 'v1',
    });
  };

  const notifyNoCancelSuccess = () => {
    toast.show({
      message: t('USER_NOTIFICATION', {
        name: kebabMenuContext?.friendlyName,
      }),
    });
  };
  const notifyNoCancelError = (err: Error) => {
    toast.show({
      message: err?.message,
    });
  };
  const { notifyNoCancel } = useCancellationData({
    onSuccess: notifyNoCancelSuccess,
    onError: notifyNoCancelError,
  });

  const currentLocale = getCurrentLocale();

  const inPersonClickEventTracking = (
    label: string,
    category: string = EventCategories.viewIntakeForm,
  ) => {
    track(EventNames.inPersonSession, {
      eventAction: EventActions.click,
      eventCategory: category,
      eventLabel: label,
      featureVersion: 'v1',
    });
  };

  const handleF2FSessionButtonClick = (
    status: string,
    type: string,
    scheduledStartTime: string,
    scheduledEndTime: string,
    mapLink: string,
    meetingId: string,
  ) => {
    const inPersonState = getInPersonMeetingState({
      enable: true,
      singleSessionData: {
        type,
        scheduledEndTime,
        scheduledStartTime,
        status,
      },
    });
    if (inPersonState === InPersonBannerStates.unknown) {
      window.open(mapLink, '_blank', 'noopener');
      return;
    }
    if (inPersonState === InPersonBannerStates.start) {
      inPersonClickEventTracking(inPersonState, inPersonState);
      updateInPersonStatus({
        meetingId,
        meetingStatus: InPersonBannerStateToMeetingStatusMap.start,
      });
      return
    }
    if (inPersonState === InPersonBannerStates.noShow) {
      inPersonClickEventTracking(inPersonState, inPersonState);
      setInpersonWarning({
        currentMeetingStatus: InPersonBannerStateToMeetingStatusMap.noShow,
        currentMeetingId:  meetingId as string,
        showWarning: true,
        scheduledStartTime: scheduledStartTime,

      });
      return
    }
    if (inPersonState === InPersonBannerStates.stop) {
      inPersonClickEventTracking(inPersonState, inPersonState);
      setInpersonWarning({
        currentMeetingStatus: InPersonBannerStateToMeetingStatusMap.stop,
        currentMeetingId:  meetingId as string,
        showWarning: true,
        scheduledStartTime: scheduledStartTime,
      });
      return
    }
  };

  const getF2FButtonLabel = (
    status: string,
    type: string,
    scheduledStartTime: string,
    scheduledEndTime: string,
  ) => {
    const inPersonState = getInPersonMeetingState({
      enable: true,
      singleSessionData: {
        type,
        scheduledEndTime,
        scheduledStartTime,
        status,
      },
    });
    if (inPersonState === InPersonBannerStates.unknown) {
      return t('GET_DIRECTIONS');
    }
    return t(InPersonBannerValues[inPersonState].btnLabel);
  };

  // workaround to show no show pop up on refresh/ pageload
  React.useEffect(() => {
    if (filteredSessions.length) {
      const noShowSession = filteredSessions.filter((session) => {
        const inpersonCurrentState = getInPersonMeetingState({
          enable: true,
          singleSessionData: {
            type: session?.type,
            scheduledEndTime: session?.scheduledEndTime,
            scheduledStartTime: session?.scheduledStartTime,
            status: session?.status,
          },
        });
        return inpersonCurrentState === InPersonBannerStates.noShow;
      });
      if (noShowSession.length) { 
        setInpersonWarning({
        currentMeetingStatus: InPersonBannerStateToMeetingStatusMap.noShow,
        currentMeetingId:  noShowSession[0].meetingId as string,
        showWarning: true,
        scheduledStartTime: noShowSession[0].scheduledStartTime,
      });
      }else{
        setInpersonWarning({showWarning: false})
      }
    }
  }, [filteredSessions]);

  // todo to get that selcted client data
  const handleClientNameClick = ({ clientId, role } : { clientId: string; role: string; }) =>{
    navigate(`/clients/${role}/${encodeCode(Number(clientId))}`);
    track(EventNames.clientView, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.clientView,
      eventLabel: 'client_view',
      featureVersion: 'v1',
      eventSource: 'upcoming_sessions'
    });
  }

  return (
    <div className="scheduled-sessions-container">
      {(isLoading || isCancellingSession) && <Loader useFullPage />}
      {
        // this compomponent was calling the casenotes even after the call ends hence commenting
        /* {!!showNewCaseNote && !!data && !!data.clientId && (
        <SideDrawer show={showNewCaseNote} hideHandler={onCaseNotesClose}>
          <CaseNotes mode="edit" client={data} />
        </SideDrawer>
      )} */
      }
      <SideDrawer
        show={Boolean(selectedClient?.userId)}
        hideHandler={() => setSelectedClient(null)}
      >
        {selectedClient?.userId && (
          <ClientDetail
            unsavedCaseNotes={getUnsavedCaseNotes()}
            clientSummary={selectedClient}
          />
        )}
      </SideDrawer>
      {kebabMenuContext && (
        // <Popup withBackdrop onClose={onCancellationPopupClose}>
        //   <CancelSessionConfirmation
        //     meetingId={kebabMenuContext.meetingId}
        //     onClose={onCancellationPopupClose}
        //     onConfirm={(meetingId, note) => {
        //       cancelAppointment({ meetingId, note });
        //       onCancellationPopupClose();
        //     }}
        //   />
        // </Popup> // todo to be removed in future
        <CancellationReasonsModal
          onClose={onCancellationPopupClose}
          onSubmit={(note, isNotify, type, otherReason) => {
            if (!isReschedule && !isNotify) {
              cancelAppointment({
                meetingId: kebabMenuContext.meetingId,
                note,
                type,
                otherReason,
              });
            }
            if (isNotify) {
              notifyNoCancel({
                userId: Number(kebabMenuContext?.userId),
                providerRole: kebabMenuContext?.providerRole,
                message: note,
              });
            }
            onCancellationPopupClose(false);
            track(EventNames.providerCancelSession, {
              eventAction: EventActions.click,
              eventCategory: EventCategories.providerDropNote,
              eventLabel: 'provider_drop_note_confirm',
              featureVersion: 'v1',
              eventSource: isReschedule ? 'reschedule' : 'cancel',
            });
          }}
          within24Hrs={isWithin24Hrs}
          sessionData={kebabMenuContext}
          onWarningCancel={handleWarningModalCancel}
          isReschedule={isReschedule}
        />
      )}
      <div className="header">
        <Typography size={20} weight="600" color="primary">
          {t('scheduledSessionsOn')}
        </Typography>
          <Typography size={16} weight="600" color="solarized">
            {getDisplayDate({
              locale: currentLocale,
              selectedDate: selectedDay,
              format: {
                ja: displayJaDateFormat,
                en: 'MMM DD, YYYY, dddd',
              },
            })}
          </Typography>
      </div>
      <div className="scheduled-agenda">
        {!!filteredSessions.length &&
          filteredSessions.map((item) => {
            const {
              status,
              friendlyName,
              meetingId,
              displayTime,
              displayTimeEnd,
              userId,
              meetingTimeZone,
              tags,
              scheduledStartTime,
              scheduledEndTime,
              locationRef,
              type,
              room,
              providerRole,
            } = item;
            // TODO need to check with Inst type
            const dispTime: Record<string, string> = {
              ja: `${moment(+scheduledStartTime * 1000).format(
                'HH:mm',
              )} - ${moment(+scheduledEndTime * 1000).format('HH:mm')}`,
              en: `${displayTime} - ${displayTimeEnd}`,
            };
            const tenure = dispTime[getCurrentLocale()];
            const isCompletedSession = isSessionCompleted(status);
            return (
              <div className="item" key={`${userId}-${meetingId}`}>
                <div className="flex justify-between">
                  <div className="info-container mt-2">
                    <Button
                      label={friendlyName}
                      fontSize={14}
                      fontWeight='600'
                      variant='raised'
                      onClick={()=>handleClientNameClick({clientId: userId, role: providerRole})}
                      icon={arrowIcon}
                      style={{border:'none', flexDirection:'row-reverse'}}
                      disableAnimation
                    />
                    <div className="time-clock-container">
                      <img src={clockIcon} alt="clock" className="clock-icon" />
                      <Typography size={12} weight="400" color="secondary">
                        {tenure}
                      </Typography>
                    </div>
                    {type === VideoRoomSDKs.f2f && room && (
                      <div className="location-info-container">
                        <img src={locationIcon} alt="location-pin-icon" />
                        <Typography size={12} weight="400" color="secondary">
                          {room.name}@{room.location.name}
                        </Typography>
                      </div>
                    )}
                    <div className="tag-container">
                      <Tags
                        textSize={10}
                        tags={
                          type === VideoRoomSDKs.f2f
                            ? t('INPERSON')
                            : t('VIRTUAL')
                        }
                        backgroundColor={
                          type === VideoRoomSDKs.f2f ? '#d1e6fa' : '#dbbfe5'
                        }
                      />
                      <Tags
                        textSize={10}
                        tags={
                          status === StatusValues.isPending
                            ? t('pending')
                            : t('confirmed')
                        }
                        backgroundColor="#DDE3E9"
                        textColor="#47515A"
                      />
                      {tags !== '' && (
                        <Tags
                          textSize={10}
                          tags={tags as string}
                          // backgroundColor={colors?.backgroundColor}
                          // textColor={colors?.fontColor}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="action-container">
                  <Button
                    width="100%"
                    label={t('viewCaseNote')}
                    onClick={() => onCaseNoteView(item)}
                    variant="secondary"
                    disabled={false}
                  />
                  {type === VideoRoomSDKs.f2f ? (
                    <Button
                      width="100%"
                      label={getF2FButtonLabel(
                        status,
                        type,
                        scheduledStartTime,
                        scheduledEndTime,
                      )}
                      onClick={() =>
                        handleF2FSessionButtonClick(
                          status,
                          type,
                          scheduledStartTime,
                          scheduledEndTime,
                          `${googleMapLocationLink}/${room?.location.latitude},${room?.location.longitude}`,
                          meetingId,
                        )
                      }
                      variant="primary"
                      backgroundColor={getF2FButtonLabel(
                        status,
                        type,
                        scheduledStartTime,
                        scheduledEndTime,
                      ) === t('END_SESSION_CTA') ? '#2A68B6' : ''}
                      disabled={
                        isCompletedSession || status === StatusValues.isPending
                      }
                    />
                  ) : (
                    <Button
                      width="100%"
                      label={
                        !isCompletedSession ? t('enterRoom') : t('sessionEnded')
                      }
                      onClick={() => onEnterRoomClick(item)}
                      variant="primary"
                      disabled={
                        isCompletedSession || status === StatusValues.isPending
                      }
                    />
                  )}

                  <KebabMenu
                    options={[
                      {
                        label: t('EDIT_SESSION_CTA'),
                        value: 'cancel-session',
                        disabled: shouldDisableCancellation(
                          scheduledStartTime,
                          meetingTimeZone,
                        ),
                        onClick: () => handleKebabMenuClick(item),
                      },
                    ]}
                  />
                </div>
              </div>
            );
          })}
        {!!filteredLeaves.length && !leaveCheckLoading && (
          <div className="card-wrapper">
            <Card
              show={!!filteredLeaves.length}
              startingLeaveDate={`${moment
                .unix(Number(leaveInfo?.leave.from))
                .format(`${OldChatContactFormat} - ${APIDisplayTimeFormat}`)}`}
              enditingLeaveDate={`${moment
                .unix(Number(leaveInfo?.leave.to))
                .format(`${OldChatContactFormat} - ${APIDisplayTimeFormat}`)}`}
            />
          </div>
        )}
        {!filteredSessions.length && !filteredLeaves.length && (
          <div className="empty-container">
            <Typography color="solarized" weight="600" size={14}>
              {t('youDontHaveAnyAppointments')}
            </Typography>
          </div>
        )}
      </div>

      <InformationModal
        headerText=""
        bodyText={t('CANCEL_INPERSON')}
        onClose={() => setShowRescheduleInPersonInfoModal(false)}
        show={showRescheduleInPersonInfoModal}
        leftBtnLabel=""
        rightBtnLabel={t('CANCEL_INPERSON_CTA')}
        contentFontSize={16}
        modalVariant="single-cta"
        borderRadius="100px"
        rightBtnClick={() => setShowRescheduleInPersonInfoModal(false)}
      />
      <InformationModal
        headerText={
          inpersonWarning.currentMeetingStatus === SessionStatus.completed
            ? t('IS_SESSION_OVER')
            : t('NO_SHOW_HEADER')
        }
        bodyText={
          inpersonWarning.currentMeetingStatus === SessionStatus.completed
            ? t('IN_PERSON_SUBTEXT')
            : t('NO_SHOW_INFO')
        }
        onClose={() => {
          setInpersonWarning({ showWarning: false });
        }}
        show={inpersonWarning.showWarning}
        rightBtnLabel={
          inpersonWarning.currentMeetingStatus === SessionStatus.completed
            ? t('MARK_SESSION_COMPLETE')
            : t('MARK_AS_NO_SHOW_CTA')
        }
        leftBtnLabel=""
        customBtnContainerStyle="custom-style"
        borderRadius="100px"
        fontSize={14}
        fontWeight="600"
        width="100%"
        height="45px"
        headerFontSize={24}
        contentFontSize={16}
        modalVariant="single-cta"
        rightBtnClick={() => {
          track(EventNames.inPersonSession, {
            eventAction: EventActions.click,
            eventCategory:
              inpersonWarning.currentMeetingStatus ===
                InPersonBannerStates.completed &&
              EventCategories.endSessionConfirm,
            eventLabel: 'end_session_confirm',
            featureVersion: 'v1',
            eventSource: EventSource.upcomingSessions,
            eventValue: moment
            .unix(Number(inpersonWarning?.scheduledStartTime)).diff(moment()),
          });
          updateInPersonStatus({
            meetingStatus: inpersonWarning.currentMeetingStatus as string,
            meetingId: inpersonWarning?.currentMeetingId || '',
          });
          setInpersonWarning({ showWarning: false });
        }}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => ({ meeting: state.app.meeting });

const mapDispatchToProps = (dispatch: Function) => ({
  saveHoliday: (date: string, leaveSlots: number[][]) =>
    dispatch(_saveHoliday(date, leaveSlots)),
  initializeMeeting: (data: ClientMeetingData) =>
    dispatch(_initializeMeeting(data)),
  resetMeeting: () => dispatch(_resetMeeting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledSessions);
