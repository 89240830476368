import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EventNames, EventActions, EventCategories } from '../../../shared/constant/Analytics';
import useTracking from '../../../utilities/hooks/useTracking';
import Button from '../../components/Button';
import Popup from '../../components/Popup';
import TextArea from '../../components/TextArea';
import Typography from '../../components/Typography';
import './AssignmentNotePopup.scss';

interface AssignmentNotePopupProps {
  onSave: (note: string) => void;
  onClose: () => void;
}

function AssignmentNotePopup({ onSave, onClose }: AssignmentNotePopupProps) {
  const { t } = useTranslation();
  const { track } = useTracking();
  const [note, setNote] = React.useState<string>('');

  const onSendAssignmentClick = () => {
    track(EventNames.providerWebCustomAssignment, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.sendAssignment,
      eventLabel: 'send_assignment_button',
    });
    onSave(note);
  };

  return (
    <Popup
      withBackdrop
      useDefaultPadding={false}
      backdropMode="glass"
      onClose={() => onClose()}
    >
      <div className="assignment-note">
        <div className="notes-contents">
          <div className="note-header">
            <Typography size={18} weight="500">
              {t('ADD_NOTE')}
            </Typography>
          </div>
          <div className="note-enter">
            <Typography size={12} weight="400">
              {t('NOTES_TITLE')}
            </Typography>
            <div className="note-field">
              <TextArea
                value={note}
                onChange={(value) => setNote(value)}
                placeholder={t('PLACEHOLDER_NOTE')}
                showCharacterCounter
                characterLimit={560}
              />
            </div>
          </div>
        </div>
        <div className="note-confirmation">
          <Button
            width="100%"
            height="40px"
            variant="primary"
            label={t('SEND_ASSIGNMENT_CTA')}
            onClick={() => onSendAssignmentClick()}
          />
        </div>
      </div>
    </Popup>
  );
}

export default AssignmentNotePopup;

