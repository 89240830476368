import { useMutation } from 'react-query';
import { saveSlots } from '../../../shared/redux/actions/provider';
import {
  IProviderDaySlot,
  InPersonClinic,
} from '../../../shared/types/response/provider';
import { transformSlotsToWeekDay } from '../../common/Date';
import useToast from '../useToast';

export const useUpdateSlots = ({
  refreshData,
}: {
  refreshData: () => void;
}) => {
  const toast = useToast();

  return useMutation({
    mutationFn: ({
      slotMap,
      clinicsList,
    }: {
      slotMap: Record<string, IProviderDaySlot>;
      clinicsList: InPersonClinic[];
    }) => {
      const body = transformSlotsToWeekDay(slotMap, clinicsList);
      return saveSlots(body);
    },
    onSuccess: (data) => {
      if (data.error) {
        toast.show({ message: data.error.message });
      }
      refreshData();
    },
  });
};
