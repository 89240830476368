import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

export const getClientsV3 = async (
  page: number,
  filters: { field: string; value: string | string[] }[],
  sort: { field: string; direction: string }[],
) => {
  const response = await client.doPost(Config.api.chat.conversationsV3, {
    page,
    filters,
    sort,
  });
  if (response && response.data) {
    return response.data;
  }

  throw new Error('Unable to get clients list, please try again!');
};

export const getClientCompanies = async () => {
  const response = await client.doGet(Config.api.clients.companies);
  if (response && response.data) {
    return response.data.companies.map((company: string) => ({
      label: company || '',
      key: company || '',
    }));
  }

  throw new Error('Unable to get client companies list, please try again!');
};
