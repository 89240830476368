import * as React from 'react';
import { DailyCall } from '@daily-co/daily-js';
import Typography from '../../../../../components/Typography';
import './VideoEffects.scss';
import { BlurOff, BlurOn, ImageIcon } from '../Tray/Icons';

interface VideoEffect {
  label: string;
  type: 'background-blur' | 'background-image' | 'none';
  value: string;
  icon: Function;
}

const availableEffects: VideoEffect[] = [
  {
    icon: BlurOff,
    label: 'Remove effects',
    type: 'none',
    value: '0',
  },
  {
    icon: BlurOn,
    label: 'Soft Blur',
    type: 'background-blur',
    value: '0.5',
  },
  {
    icon: BlurOn,
    label: 'Hard Blur',
    type: 'background-blur',
    value: '0.9',
  },
  {
    icon: ImageIcon,
    label: 'Background 1',
    type: 'background-image',
    value:
      'https://d3imeoj536zhqj.cloudfront.net/images/uploads/Intellect+Zoom+Background+(1).png',
  },
  {
    icon: ImageIcon,
    label: 'Background 2',
    type: 'background-image',
    value:
      'https://d3imeoj536zhqj.cloudfront.net/images/uploads/Intellect%20Zoom%20Background_2.png',
  },
];

interface VideoEffectsProps {
  callObject: DailyCall | null;
}

function VideoEffects({ callObject }: VideoEffectsProps) {
  const onEffectsClick = (selectedEffect: VideoEffect) => {
    const { type, value } = selectedEffect;
    switch (type) {
      case 'background-blur':
        callObject?.updateInputSettings({
          video: {
            processor: {
              type: 'background-blur',
              config: { strength: Number(value) },
            },
          },
        });
        break;

      case 'background-image':
        callObject?.updateInputSettings({
          video: {
            processor: {
              type: 'background-image',
              config: {
                source: value,
              },
            },
          },
        });
        break;
      case 'none':
        callObject?.updateInputSettings({
          video: {
            processor: {
              type: 'none',
            },
          },
        });
        break;
      default:
        console.log('effect not found');
    }
  };

  return (
    <div className="video-effects">
      <div className="effects-header">
        <Typography size={16} weight="500">
          Apply Effects
        </Typography>
      </div>
      <div className="effects-listing">
        {availableEffects.map((effect, index) => (
          <div
            role="button"
            tabIndex={index}
            className="effect-item"
            onClick={() => onEffectsClick(effect)}
          >
            {effect.icon()}
            <Typography size={12} weight="400">
              {effect.label}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoEffects;
