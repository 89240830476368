import { Client as TwilioChatClient } from '@twilio/conversations';
import Config from '../../Config';
import { IProviderConversations } from '../../types/response/provider';
import NetworkClient from '../Network';

class Client {

  client: TwilioChatClient | null;

  networkClient: NetworkClient;

  constructor() {
    this.client = null;
    this.networkClient = new NetworkClient();
  }

  getNewChatToken = async (): Promise<string> => {
    const response: IProviderConversations = await this.networkClient.doPost(Config.api.chat.conversations, {});
    if (response && response.data && response.data.chatTokens && response.data.chatTokens[0]) {
      return response.data.chatTokens[0];
    }

    return '';
  };

  refreshToken = async () => {
    const newToken = await this.getNewChatToken();
    this.client?.updateToken(newToken);
  };

  getClient = async (): Promise<TwilioChatClient | null> => {
    if (this.client) return this.client;
    const newToken = await this.getNewChatToken();
    if (newToken) {
      this.client = await new TwilioChatClient(newToken);
      this.client.addListener('tokenAboutToExpire', this.refreshToken);
      this.client.addListener('tokenExpired', this.refreshToken);
      return this.client;
    }

    return null;
  };
}

export default Client;