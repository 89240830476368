import styled from 'styled-components';
import { CssSize } from '../../components/BaseCard/BaseCard.d';

export const StyledInsightWrapper = styled.article`
  height: 100%;
  width: auto;
  margin: 30px;
`;

interface StyledInsightContentSectionProps {
  width?: CssSize;
}
export const StyledInsightContentSection = styled.section<StyledInsightContentSectionProps>`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #e9eaeb;
  padding: 20px 0;
`;
export const StyledInsightOverviewSection = styled.section`
  width: 100%;
  position: relative;
`;
export const StyledAside = styled.aside`
  position: absolute;
  right: 0;
  top: 26px;
  font-size: 12px;
  font-weight: 400;
`;
interface StyledInsightFlexSectionProps {
  width?: CssSize;
  flexGap?: CssSize;
}
export const StyledInsightFlexSection = styled.section<StyledInsightFlexSectionProps>`
  width: ${(props) => props.width};
  margin-top: 38px;
  margin-bottom: 21px;
  display: flex;
  flex-direction: row;
  gap: ${(prop) => prop.flexGap};
  align-items: center;
  justify-content: center;
`;
