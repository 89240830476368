import mixpanel from 'mixpanel-browser';
import { EventProperties } from '../../../shared/constant/Analytics';
import { isProduction } from '../../common/Path';

export type TrackFunction = (
  event: string,
  properties: Record<string, string>,
) => void;

function useTracking() {
  const track = (
    event: string = '',
    properties: Record<keyof typeof EventProperties, string> | {} = {},
  ) => {
    mixpanel.track(event, properties);

    if (!isProduction()) {
      console.log('event', event, properties);
    }
  };

  const trackDuration = (event: string) => {
    mixpanel.time_event(event);
  };

  const initializeUser = (userId: string) => {
    mixpanel.identify(userId);
  };

  const setUserProperties = (
    properties: Record<string, string>,
    setMode: 'once' | 'overwrite' | 'append' = 'once',
  ) => {
    switch (setMode) {
      case 'once':
        mixpanel.people.set_once(properties);
        break;

      case 'overwrite':
        mixpanel.people.set(properties);
        break;

      case 'append':
        mixpanel.people.append(properties);
        break;

      default:
        mixpanel.people.set_once(properties);
    }
  };

  return {
    track,
    trackDuration,
    user: { initialize: initializeUser, setProperties: setUserProperties },
  };
}

export default useTracking;
