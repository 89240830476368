import * as React from 'react';

export default function useUnload(
  condition: boolean,
  beforeUnloadHandler?: (event: BeforeUnloadEvent) => void,
) {
  React.useEffect(() => {
    function handleUnload(event: BeforeUnloadEvent) {
      event.preventDefault();
      /* eslint-disable-next-line */
      event.returnValue = true;
      beforeUnloadHandler?.(event);
    }
    if (condition) {
      window.addEventListener('beforeunload', handleUnload);
    } else {
      window.removeEventListener('beforeunload', handleUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [beforeUnloadHandler, condition]);
}
