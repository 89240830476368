import lodash from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { getCurrentLocale } from '../../../i18n';
import { getTranslation } from '../../../i18n/utilities';
import { assignmentsFilterAnalyticsValuesMap, EventActions, EventCategories, EventNames } from '../../../shared/constant/Analytics';
import { getAssignments, getAssignmentConfig } from '../../../shared/redux/actions/assignment';
import { getClientsForAssignment } from '../../../shared/redux/actions/provider';
import { Assignment, AssignmentConfigItem, AssignmentConfigs, AssignmentPage, FilterValues } from '../../../shared/types/response/assignment';
import useDebounce from '../../../utilities/hooks/useDebounce';
import { useInfiniteFetch, useFetch } from '../../../utilities/hooks/useFetch';
import useTracking from '../../../utilities/hooks/useTracking';
import Loader from '../../components/Loader';
import MultiSelect from '../../components/MultiSelect';
import TextBox from '../../components/TextBox';
import AssignmentCard from '../AssignmentCard';
import AssignToClientsPopup from '../AssignToClientsPopup';
import GoToTop from '../GoToTop';
import NoResultsFound from '../NoResultsFound';
import searchIcon from '../../../assets/search.svg';
import './InternalAssignments.scss';
import { IClientSummary } from '../../../shared/types/response/provider';

interface InternalAssignmentsProps {
  onCreateCustomAssignmentClick: () => void;
}

function InternalAssignments({ onCreateCustomAssignmentClick }: InternalAssignmentsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { track } = useTracking();
  const [searchText, setSearchText] = React.useState<string>('');
  const [selectedAssignment, setSelectedAssignment] = React.useState<Assignment | null>(null);
  const [filterValues, setFilterValues] = React.useState<FilterValues>({ languages: [], categories: [], topics: [] });
  const lastElementRef = React.useRef<HTMLDivElement>(null);
  const assignmentListingElementRef = React.useRef<HTMLDivElement>(null);
  const lastElementObserver = React.useRef<IntersectionObserver>();
  const debouncedSearchText = useDebounce(searchText, 1000);

  const { data, fetchNextPage, isLoading, isFetchingNextPage } = useInfiniteFetch(
    ['assignments', filterValues.categories.join(','), filterValues.languages.join(','), filterValues.topics.join(','), debouncedSearchText],
    ({ pageParam = 0 }) => dispatch(getAssignments(pageParam, filterValues, searchText)),
    {
      getNextPageParam: (currentPage: AssignmentPage<Assignment>) => {
        const { nbPages, page } = currentPage;
        if (page < nbPages - 1) {
          return page + 1;
        }

        return undefined;
      },
    },
  );

  const { data: clientsData } = useFetch<IClientSummary[]>(['clients'], () => dispatch(getClientsForAssignment()), { initialData: [] });
  const { data: assignmentsConfigData } = useFetch<AssignmentConfigs>(
    ['assignment-config'],
    () => dispatch(getAssignmentConfig()),
    {
      initialData: {
        categories: {},
        languages: {},
        topics: {},
        translationKeys: {},
      } as AssignmentConfigs,
    },
  );
  const isAssignmentFilterResultsEmpty = !isLoading && lodash.isArray(data?.pages[0]) && !data?.pages[0].length;
  const { categories, languages, topics, translationKeys } = (assignmentsConfigData || {}) as AssignmentConfigs;
  React.useEffect(() => {
    lastElementObserver.current = new IntersectionObserver(async (entries) => {
      const lastAssignmentCardEntry = entries[0];
      if (lastAssignmentCardEntry.isIntersecting) {
        fetchNextPage();
      }
    }, { threshold: 1 });

    const element = lastElementRef.current;
    const observer = lastElementObserver.current;

    if (element) {
      observer?.observe(element);
    }

    return () => {
      if (element) {
        observer?.unobserve(element);
      }
    };

  }, [data]);

  React.useEffect(() => {
    track(EventNames.providerWebAssignment, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.viewAssignments,
      eventLabel: 'view_assignments',
    });
  }, []);

  const onAssignButtonClick = (assignment: Assignment) => {
    setSelectedAssignment(assignment);
  };

  const onAssignPopupClose = () => {
    setSelectedAssignment(null);
  };

  const getTranslatedOptionsForFilters = (filter: AssignmentConfigItem) =>
    (filter.values || []).map(({ key, label }) => ({ key, label: getTranslation(label, translationKeys) }));

  const updateFilters = (appliedFilters: string[], source: 'languages' | 'categories' | 'topics') => {
    setFilterValues((prev) => ({ ...prev, [source]: appliedFilters }));
    track(EventNames.providerWebAssignment, {
      eventAction: EventActions.click,
      eventCategory: assignmentsFilterAnalyticsValuesMap[source],
      eventLabel: assignmentsFilterAnalyticsValuesMap[source],
    });
  };

  return (
    <div className="assignments-section">
      {selectedAssignment && (
        <AssignToClientsPopup
          assignmentType="internal"
          assignmentData={{
            entityId: selectedAssignment.entityId,
            type: selectedAssignment.type,
            language: selectedAssignment.language,
          }}
          clientsData={clientsData || []}
          onClose={onAssignPopupClose}
        />
      )}
      {(isLoading || isFetchingNextPage) && (
        <Loader useFullPage withBackdrop={false} />
      )}
      <div className="filters-and-search">
        <div className="filters">
          <MultiSelect
            placeholder={getTranslation(languages.title, translationKeys)}
            options={getTranslatedOptionsForFilters(languages)}
            onClose={(filters) => updateFilters(filters, 'languages')}
            initialValues={[getCurrentLocale()]}
          />
          <MultiSelect
            placeholder={getTranslation(categories.title, translationKeys)}
            options={getTranslatedOptionsForFilters(categories)}
            onClose={(filters) => updateFilters(filters, 'categories')}
          />
          <MultiSelect
            placeholder={getTranslation(topics.title, translationKeys)}
            options={getTranslatedOptionsForFilters(topics)}
            onClose={(filters) => updateFilters(filters, 'topics')}
          />
        </div>
        <div className="search">
          <TextBox
            variant="box-border"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            icon={searchIcon}
            placeholder={t('searchFor')}
          />
        </div>
      </div>
      <div className="assignments-listing" ref={assignmentListingElementRef}>
        {!!isAssignmentFilterResultsEmpty && (
          <div className="empty-assignments">
            <NoResultsFound
              captionCopy={t('noResultsAvailable')}
              description={t('pleaseCheckFilter')}
              ctaLabel={t('NO_EXTERNAL_RESOURCE_CTA')}
              ctaCallback={() => onCreateCustomAssignmentClick()}

            />
          </div>
        )}
        <AnimatePresence>
          {data?.pages.map((group) => (
            <React.Fragment key={group.page}>
              {(group.assignments || []).map((assignment: Assignment, index: number) => (
                <AssignmentCard<Assignment>
                  index={index}
                  key={assignment.objectID}
                  assignmentData={assignment}
                  onAssignClick={onAssignButtonClick} />
              ))}
            </React.Fragment>
          ),
          )}
        </AnimatePresence>
        <div className="bottom-element" ref={lastElementRef} />
      </div>
      <div className="goto-top-container">
        <GoToTop
          scrollThresholdToAppear={200}
          scrollElementRef={assignmentListingElementRef}
        />
      </div>
    </div>
  );
}

export default InternalAssignments;
