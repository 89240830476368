import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLocale } from '../../../i18n';
import {
  enableCustomMessages,
  getAutomatedMessageTriggers,
  getCustomMessagesList,
  updateAutomatedMessageTemplate,
  updateAutomatedMessageTrigger,
} from '../../../shared/service/API/settings';
import { useFetch, useMutation } from '../useFetch';
import {
  CustomMsgListResType,
  TriggerDetailsAPIRespType,
  UpdateTemplateRequestType,
  UpdateTemplateResType,
  UpdateTriggerRequestType,
  UpdateTriggerRespType,
} from '../../../view/screens/Settings/types';
import useToast from '../useToast';


function useTriggerSettings(
  updateDefaultMsgOnSuccess?: (data: UpdateTemplateResType) => void,
) {
  const { t } = useTranslation();
  const toast = useToast();
  const currentLang = getCurrentLocale();
  const [triggerStatus, setTriggerStatus] =
    React.useState<UpdateTriggerRespType>({} as UpdateTriggerRespType);
  const [customMsgList, setCustomMsgList] = React.useState<CustomMsgListResType[]>([] as CustomMsgListResType[]);

  const {
    data: triggersData,
    isLoading,
    refetch: refetchTriggerData,
  } = useFetch<TriggerDetailsAPIRespType>(
    ['getAutomatedMessageTriggers', currentLang],
    getAutomatedMessageTriggers,
    {
      refetchOnWindowFocus: false,
    },
  );
  const { mutate: updateTriggerStatus, isLoading: isTriggerStatusUpdated } =
    useMutation(
      (body: UpdateTriggerRequestType) => updateAutomatedMessageTrigger(body),
      {
        onSuccess: (data) => {
          setTriggerStatus(data?.data);
          refetchTriggerData();
        },
        onError: (errorData) => {
          toast.show({ message: errorData.message });
        },
      },
    );

  const {
    mutate: updateDefaultMsgTemplate,
    isLoading: isDefaultTemplateUpdated,
  } = useMutation(
    ({
      payload,
      triggerId,
    }: {
      payload: UpdateTemplateRequestType;
      triggerId: string;
    }) => updateAutomatedMessageTemplate(payload, triggerId),
    {
      onSuccess: (data) => {
        if (data?.success) {
          updateDefaultMsgOnSuccess?.(data?.data);
          refetchTriggerData();
          toast.show({
            message: t('SUCCESSFULLY_SAVED_PROMPT', {
              language: t(data?.data?.languageId),
            }),
          });
        }
      },
      onError: (errorData) => {
        toast.show({ message: errorData.message });
      },
    },
  );

  const { mutate: getCustomMsgList, isLoading: isCustomMsgListLoading } =
    useMutation((triggerId: string) => getCustomMessagesList(triggerId), {
      onSuccess: (data) => {
        setCustomMsgList(data?.messages);
      },
      onError: (errorData) => {
        toast.show({ message: errorData.message });
      },
    });

  const { mutate: msgConsentCheck, isLoading: isMsgConsentLoading } =
    useMutation(
      ({ isActive, triggerId }: { isActive: boolean; triggerId: string }) =>
        enableCustomMessages({ isActive, triggerId }),
      {
        onSuccess: (data) => {
          refetchTriggerData();
          console.log(data);
        },
        onError: (errorData) => {
          toast.show({ message: errorData.message });
        },
      },
    );
  return {
    triggersData,
    isLoading,
    refetchTriggerData,
    updateTriggerStatus,
    isTriggerStatusUpdated,
    triggerStatus,
    updateDefaultMsgTemplate,
    isDefaultTemplateUpdated,
    getCustomMsgList,
    isCustomMsgListLoading,
    customMsgList,
    msgConsentCheck,
    isMsgConsentLoading,
  };
}

export default useTriggerSettings;
