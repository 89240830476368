import * as React from 'react';

export interface ToastConfig {
  message: string | string[];
  closeAfter: number;
  toastVariant?: 'success' | 'error' | 'default';
}

export type ShowFunction = (configs: Partial<ToastConfig>) => void;

interface ToastContextValues {
  show: ShowFunction;
  isToastVisible: boolean;
}

const ToastContext = React.createContext<ToastContextValues>({} as ToastContextValues);

export default ToastContext;
