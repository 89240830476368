import { CssSize } from '../../view/components/BaseCard/BaseCard.d';

export enum PaginationCurrentPagePosition {
  START = 'START',
  MIDDLE = 'MIDDLE',
  END = 'END',
}

export enum ProviderRole {
  COACHING = '0',
  CLINICAL = '1',
  HELPLINE = '2',
  CARENAVIGATOR = '4',
  NUTRITIONIST = '5',
  FITNESS = '6',
  FINANCIAL = '7',
  EXECUTIVE = '8',
  LEGAL = '9',
  CAREOFFSITE = '10',
}

export enum MeetingStatus {
  NOT_STARTED = 'not-started',
  STARTED = 'started',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
}

export enum SessionStatus {
  scheduled = '0',
  inprogress = '1',
  completed = '2',
  cancelled = '3',
  noshow = '4',
  empty = '5',
  lastmincancelled = '6',
  providernoshow = '7',
  usernoshow = '8',
  requesttoschedule = '9',
  requestcancelled = '10',
}

export const InPersonBannerValues: {
  [key: string]: {
    bannerContent: string;
    bannerColor: string;
    btnLabel: string;
    btnColor: string;
    btnVariant: 'primary' | 'secondary' | 'raised' | 'caution' | 'blue' | 'save' | 'inperson-default' | 'inperson-complete';
    displayBtn: boolean;
    btnWidth?: CssSize;
  };
} = {
  start: {
    bannerContent: 'SESSION_START_CONFIRMATION',
    bannerColor: '#f6f6f9',
    btnLabel: 'BEGIN_SESSION_CTA',
    btnColor: '#263E58',
    btnVariant: 'inperson-default',
    displayBtn: true,
    btnWidth: '137px',
  },
  noShow: {
    bannerContent: 'NO_SHOW_CONTENT',
    bannerColor: '#f6f6f9',
    btnLabel: 'MARK_AS_NO_SHOW_CTA',
    btnColor: '#263E58',
    btnVariant: 'inperson-default',
    displayBtn: true,
    btnWidth: '196px',
  },
  stop: {
    bannerContent: 'END_SESSION_CONTENT',
    bannerColor: '#f6f6f9',
    btnLabel: 'END_SESSION_CTA',
    btnColor: '#2A68B6',
    btnVariant: 'inperson-default',
    displayBtn: true,
    btnWidth: '196px',
  },
  completed: {
    bannerContent: 'COMPLETED_SESSION_CONTENT',
    bannerColor: '#F1FFF2',
    btnLabel: 'COMPLETED_SESSION_CTA',
    btnColor: '#F1FFF2',
    btnVariant: 'inperson-complete',
    displayBtn: true,
    btnWidth: '210px',
  },
  noAction: {
    bannerContent: 'NO_ACTION_CONTENT',
    bannerColor: '#FFF5F5',
    btnLabel: '',
    btnColor: '',
    btnVariant: 'primary',
    displayBtn: false,
  },
};

export enum InPersonBannerStates {
  start = 'start',
  noShow = 'noShow',
  stop = 'stop',
  completed = 'completed',
  noAction = 'noAction',
  unknown = 'unknown',
}

export const InPersonBannerStateToMeetingStatusMap: { [key: string]: SessionStatus; } = {
  start: SessionStatus.inprogress,
  noShow: SessionStatus.usernoshow,
  stop: SessionStatus.completed,
};  

export enum ProviderAttributes {
  PROFESSIONAL_BACKGROUND = 0,
  AREA_OF_SPECIALTY = 1,
  MY_APPROACH = 2,
}

export enum ClientCreditTiers {
  CREDIT_POOL = '1',
  LIMITED_CREDIT = '0',
}

export enum SessionType {
  VIRTUAL = '0',
  INPERSON = '1',
}

export const dayOfWeekNumbers = ['0', '1', '2', '3', '4', '5', '6', '7'];
