import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import angleLeftIcon from '../../../assets/angle-left.png';
import angleRightIcon from '../../../assets/angle-right.png';
import quoteIcon from '../../../assets/quote.png';
import Typography from '../../components/Typography';
import './Introduction.scss';

function IntroductionScreen() {
  const { t } = useTranslation();
  return (
    <section className="intro-container">
      <motion.section
        className="circle-fade"
        initial={{ right: '40%' }}
        animate={{ right: '35%' }}
        transition={{
          duration: '3',
          repeat: Infinity,
          repeatType: 'reverse',
        }}
      />
      <section className="content-container">
        <motion.img
          src={quoteIcon}
          alt="quote"
          className="quote-icon"
          initial={{ translateY: '-10px' }}
          animate={{ translateY: '0px' }}
          transition={{
            duration: '1',
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
        <section className="intro">
          <Typography color="light" weight="400" size={15}>
            {t('intellectDescription')}
          </Typography>
        </section>
        <img src={angleRightIcon} className="right-angle" alt="right angle" />
        <img src={angleLeftIcon} alt="left angle" />
      </section>
      <section className="rect-arrow" />
    </section>
  );
}

export default IntroductionScreen;
