/* eslint-disable*/
import BaseClient from './BaseClient';
import {
  ConversationListDataType,
  IrisChatRespTypes,
  IConversationsMetadata,
  NewMessageType,
  SelectedUserMessagesType,
} from '../data-types/ClientTypes';
import {
  getIrisMessages,
  getIrisProviderConversationList,
  sendIrisMessage,
} from '../services/API/Iris';
import {
  MessageChannelTypes,
  MessageSendType,
} from '../constants/CommonConstants';
import { IMessageSendType, IOnNewMessage } from '../data-types/ChatBrokerTypes';
import IrisSocket, { SocketMessage } from '../utils/socket';
import { IrisSocketEventsType } from '../constants/IrisConstants';
import { setChatMessagesConsumed } from '../services/API/CommonConversation';

export default class IrisClient extends BaseClient {
  private userMessages: SelectedUserMessagesType;

  private onNewMessage: IOnNewMessage;

  private socket: IrisSocket | null;

  constructor() {
    super();
    this.userMessages = {
      isNext: true,
      messages: [],
    } as SelectedUserMessagesType;
    this.onNewMessage = () => {};
    this.socket = null;
    this.setupSocket = this.setupSocket.bind(this);
  }

  setupSocket() {
    this.socket = new IrisSocket();
    this.socket.addSocketListener(
      IrisSocketEventsType.NEW_MESSAGE,
      (message: SocketMessage) => {
        const newMsg: NewMessageType = {
          createdAt: new Date(),
          id: message.id,
          senderId: message.senderId,
          message: message.message,
          attachments: message.attachments,
          deleted: false,
          conversationId: message.conversationId,
        };
        this.onNewMessage(newMsg);
      },
    );
  }

  onSocketNewMessage(callBack: IOnNewMessage) {
    this.onNewMessage = callBack;
  }

  async getConversationList(
    _conversationList: IConversationsMetadata['userData'],
  ): Promise<ConversationListDataType[]> {
    if (!this.socket) {
      this.setupSocket();
    }

    let resp = await getIrisProviderConversationList(this.apiClient);
    resp = resp?.data?.map((conversation: IrisChatRespTypes) => ({
      conversationId: conversation?.id,
      unreadCount: conversation?.participants?.[0]?.unreadCount,
      lastMessageContent: conversation?.lastMessage?.message,
      lastMsgCreatedAt: new Date(conversation?.lastMessage?.createdAt),
      channelType: MessageChannelTypes.IRIS,
      attachmentType: conversation?.attachments?.[0]?.type,
    }));

    return resp;
  }

  // fetch messages of selected Iris user

  async getSelectedUserMessages(
    conversationId: string,
    page: number,
  ): Promise<SelectedUserMessagesType> {
    if (page === 1) {
      this.userMessages = {
        isNext: true,
        messages: [],
      } as SelectedUserMessagesType;
    }
    if (!this.userMessages.isNext) {
      return this.userMessages;
    }

    const resp = await getIrisMessages(
      this.apiClient,
      conversationId,
      page.toString(),
    );
    this.userMessages.messages.push(...resp.data);
    this.userMessages.isNext = resp.data.length < 10 ? false : true; // work around for not having is next key in api
    return this.userMessages;
  }

  // sending Iris messages

  async sendMessage(
    contentToSend: IMessageSendType,
    conversationId: string,
  ): Promise<string> {
    const formData = new FormData();
    if (contentToSend.type === MessageSendType.TEXT) {
      formData.append('message', contentToSend.data);
    } else if (contentToSend.type === MessageSendType.AUDIO) {
      const contentType = 'audio/x-wav';
      const blob = new Blob([contentToSend.data], { type: contentType });
      formData.append('message', contentToSend?.body || '');
      formData.append('attachments', blob);
    } else if (contentToSend.type === MessageSendType.IMAGE) {
      formData.append('message', contentToSend?.body || '');
      formData.append('attachments', contentToSend.data);
    }
    else if ( contentToSend.type === MessageSendType.DEEPLINK){
      formData.append('message', `${contentToSend.data} ${contentToSend?.deepLink || ""}`)
    }
    else if (contentToSend.type === MessageSendType.PDF) {
      formData.append('message', contentToSend?.body || '');
      formData.append('attachments', contentToSend.data);
    }
    const resp = await sendIrisMessage(this.apiClient, {
      data: formData,
      conversationId,
    });
    return resp;
  }
  async updateMessageConsumptionStatus(
    conversationId: string,
  ): Promise<boolean> {
    const resp = await setChatMessagesConsumed(conversationId);
    return resp.success;
  }
}
