/*eslint-disable */
import * as React from 'react';
import './CheckboxGroup.scss';
import { ICheckboxGroup, ICheckboxOptions } from './types';
import { isEqual } from 'lodash';

function CheckboxGroup({
  options,
  disabledList = [],
  onChange = (data: ICheckboxOptions[]) => {},
  defaultSelected,
}: ICheckboxGroup) {
  const [selected, setSelected] = React.useState<ICheckboxOptions[]>([]);

  React.useEffect(() => {
    if (defaultSelected) {
      const selectedOptionsIds = defaultSelected.map((item) => item?.id);
      setSelected(
        options.filter((item) => selectedOptionsIds.includes(item?.id)),
      );
    }
  }, [defaultSelected]);

  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    option: ICheckboxOptions,
  ) => {
    // Prevent the click event from propagating
    e.stopPropagation();

    if (e.target.checked) {
      setSelected((prev) => [...prev, option]);
      onChange([...selected, option]);
    } else {
      const tmp = selected.filter((item) => item.label !== e.target.value);
      setSelected(tmp);
      onChange(tmp);
    }
  };

  return (
    <article className="checkbox-wrapper">
      {options.map((option) => (
        <section key={option.label}>
          <input
            type="checkbox"
            value={option.label}
            onChange={(e) => {
              onInputChange(e, option);
            }}
            checked={!!selected.find((item) => isEqual(item, option))}
          />
          <label>{option.label}</label>
        </section>
      ))}
    </article>
  );
}

export default CheckboxGroup;
