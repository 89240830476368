import { Chart, ArcElement, Tooltip, Legend, Plugin } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import * as React from 'react';

type Percentage = `${string}%`;
type IGaugeChartDisplayTextProps = {
  currentValue: number;
  totalValue: number;
};
export interface IGaugeChart {
  backColor?: string;
  topColor?: string;
  currentMarkerColor?: string;
  arcThickness?: Percentage;
  arcBorderRadius?: number;
  textFontSize?: string;
  textColor?: string;
  textFontFamily?: string;
  totalValue: number;
  currentValue: number;
  markerPrecision?: number;
  markerColor?: string;
  markerRadius?: number;
  showMarker?: boolean;
  getDisplayText?: (param?: IGaugeChartDisplayTextProps) => string;
}
const getCutOutValue = (arcThickness: Percentage): Percentage => {
  const arch = 100 - Number(arcThickness.replace('%', ''));
  return `${arch}%`;
};
export default function GaugeChart({
  backColor = '#F3F3F3',
  topColor = '#ffff008f',
  currentMarkerColor = 'yellow',
  arcThickness = '10%',
  textFontSize = '50px',
  textColor = '#242424',
  textFontFamily = 'Poppins',
  arcBorderRadius = 0,
  markerPrecision = 0.5,
  markerColor = 'grey',
  markerRadius = 2,
  showMarker = true,
  totalValue,
  currentValue,
  getDisplayText,
}: IGaugeChart) {
  Chart.register(ArcElement, Tooltip, Legend);

  const markers = React.useMemo(() => {
    const arr = [];
    for (let i = markerPrecision; i < totalValue; i += markerPrecision) {
      arr.push(i);
    }
    return arr;
  }, [markerPrecision, totalValue]);
  const showCurrentMarker = React.useMemo(() => {
    if (currentValue === 0 || currentValue === totalValue) {
      return false;
    }
    return true;
  }, [currentValue, totalValue]);

  const chartData = {
    labels: ['on', 'off'],
    datasets: [
      {
        data: [currentValue, totalValue - currentValue],
        backgroundColor: [topColor, backColor],
        borderRadius: arcBorderRadius,
        hoverBackgroundColor: [topColor, backColor],
      },
    ],
  };
  const options = {
    rotation: 270, // start angle in degrees
    circumference: 180, // sweep angle in degrees
    cutout: getCutOutValue(arcThickness),
    aspectRatio: 1.8,
    maintainAspectRatio: true,
    responsive: true,
    hoverBorderColor: 'white',

    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };
  const gaugeChartText: Plugin<'doughnut'> = {
    id: 'gaugeChartText',
    afterDatasetDraw(chart) {
      const { ctx } = chart;
      const dataSetMeta = chart.getDatasetMeta(0);
      ctx.save();
      function textLabel(
        text: string,
        x: number,
        y: number,
        fontSize: string,
        textColorStyle: string,
        textBaseLine: CanvasTextBaseline,
        textAlign: CanvasTextAlign,
      ) {
        ctx.font = `bold ${fontSize} ${textFontFamily}`;
        ctx.fillStyle = textColorStyle;
        ctx.textBaseline = textBaseLine;
        ctx.textAlign = textAlign;
        ctx.fillText(text, x, y);
      }
      const xCenter = dataSetMeta.data?.[0]?.x;
      const yCenter = dataSetMeta.data?.[0]?.y;
      let chartText = `${currentValue}/${totalValue}`;
      if (getDisplayText) {
        chartText = getDisplayText({ currentValue, totalValue });
      }
      textLabel(
        chartText,
        xCenter,
        yCenter,
        textFontSize,
        textColor,
        'bottom',
        'center',
      );
    },
  };
  const gaugeChartPoint: Plugin<'doughnut'> = {
    id: 'gaugeChartPoint',
    afterDatasetDraw(chart) {
      const { ctx } = chart;
      const dataSetMeta = chart.getDatasetMeta(0);
      ctx.save();
      const xCenter = dataSetMeta.data?.[0]?.x;
      const yCenter = dataSetMeta.data?.[0]?.y;
      const outerRadius = (dataSetMeta.data?.[0] as any)?.outerRadius;
      const innerRadius = (dataSetMeta.data?.[0] as any)?.innerRadius;
      const circumference = dataSetMeta.data?.reduce(
        (acc, val: any) => acc + val.circumference,
        0,
      );
      const midPoint = outerRadius - innerRadius;
      const rotationAngle = circumference / Math.PI / totalValue;
      ctx.translate(xCenter, yCenter);

      function createCircle(
        rotation: number,
        strokeStyle: string,
        fillStyle: string,
        lineWidth: number,
        x: number,
        y: number,
        radius: number,
      ) {
        ctx.rotate(rotation);
        ctx.beginPath();
        ctx.strokeStyle = strokeStyle;
        ctx.fillStyle = fillStyle;
        ctx.lineWidth = lineWidth;
        ctx.arc(x, y, radius, 0, Math.PI * 2, false);
        ctx.fill();
        ctx.stroke();
        ctx.rotate(-rotation);
      }
      // create current marker
      if (showCurrentMarker) {
        createCircle(
          Math.PI * (rotationAngle * currentValue + 1.5),
          currentMarkerColor,
          'white',
          midPoint / 2,
          0,
          0 - (outerRadius - midPoint / 2),
          midPoint / 2,
        );
      }
      if (showMarker) {
        markers.forEach((val) => {
          createCircle(
            Math.PI * (rotationAngle * val + 1.5),
            markerColor,
            markerColor,
            0.5,
            0,
            0 - (yCenter - midPoint * 2.5),
            markerRadius,
          );
        });
      }

      ctx.restore();
    },
  };
  return (
    <Doughnut
      data={chartData}
      options={options}
      plugins={[gaugeChartText, gaugeChartPoint]}
    />
  );
}
