import Config from '../../Config';
import NetworkClient from '../Network';

const client = new NetworkClient();

export const errorMessage = {
  generic: 'Unable to load the insights data, please try again!',
};

export const getInsightsDetails = async () => {
  const response = await client.doGet(Config.api.insights.getInsightsDetails);
  if (response && response.success) {
    return response;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};

export const getInsightAssignmentDetails = async () => {
  const response = await client.doGet(Config.api.insights.getInsightAssignmentDetails);
  if (response && response.success) {
    return response;
  }
  throw new Error(response.data.error?.message || errorMessage.generic);
};
