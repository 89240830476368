import * as React from 'react';
import './TextBox.scss';
import { AnimatePresence, motion, TargetAndTransition } from 'framer-motion';
import validationSuccessIcon from '../../../assets/blue-tick.svg';
import validationFailIcon from '../../../assets/close-red.svg';
import { fade } from '../../../configs/animations/variants';
import Typography from '../Typography';

interface ITextBox {
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
  icon?: string,
  animate?: boolean,
  variant?: 'normal' | 'box' | 'solarized' | 'box-border',
  type?: 'text' | 'password';
  disabled?: boolean;
  showValidationMark?: boolean;
  isValidInput?: boolean;
  errorMessage?: string;
}

const variantBGColorMap = {
  normal: '#fff',
  box: '#F1F1F5',
  solarized: ' #E6EDF2',
  'box-border': '#FBFCFE',
};

function TextBox({
  value,
  onChange,
  errorMessage = '',
  placeholder = '',
  icon = '',
  animate = true,
  variant = 'normal',
  type = 'text',
  disabled = false,
  showValidationMark = false,
  isValidInput = true,
}: ITextBox) {
  const getFocusAnimations = (): TargetAndTransition => {
    let animations: TargetAndTransition = {};
    if (animate) {
      animations = { ...animations, scaleX: 1.1, translateX: -20 };
    }

    if (variant === 'box-border') {
      animations = { ...animations, border: '1px solid #263E58' };
    }

    return animations;
  };

  return (
    <div className="textbox-container">
      <div className="textbox-action-container">
        <motion.input
          type={type}
          className={variant}
          onChange={onChange}
          value={value}
          whileHover={variant === 'box-border' ? { border: '1px solid #263E58' } : {}}
          whileFocus={getFocusAnimations()}
          placeholder={placeholder}
          disabled={disabled}
          style={
            icon ?
              {
                background: `${variantBGColorMap[variant]} url(${icon}) no-repeat scroll 13px 50%`,
                paddingLeft: '40px',
              }
              : {
                backgroundColor: variantBGColorMap[variant],
                paddingLeft: '20px',
              }
          }
        />
        <AnimatePresence>
          {showValidationMark && (
            <motion.img
              className="validation-success"
              src={isValidInput ? validationSuccessIcon : validationFailIcon}
              alt="validation success"
              variants={fade}
              initial="out"
              animate="in"
              exit="out"
            />
          )}
        </AnimatePresence>
      </div>
      {errorMessage && (
        <motion.div
          className="error-message-container"
          variants={fade}
          initial="out"
          animate="in"
          exit="out"
        >
          <Typography size={12} weight="400" color="error">
            {errorMessage}
          </Typography>
        </motion.div>
      )}
    </div>
  );
}

export default TextBox;