import {
  fetchActiveSessionDuringOutOfOffice,
  fetchLeaveStatus,
  markOutOfOffice,
  removeOutOfOffice,
} from '../../../shared/service/API/outOfOffice';
import useToast from '../useToast';
import { IActiveSessionPayload, IAddOOODates, ILeaveCheckRes } from './types';
import { useMutation } from '../useFetch';

function useOutOfOffice() {
  const toast = useToast();

  const { data: isOnLeave, isLoading: leaveCheckLoading, mutate:fetchOnLeave } =
    useMutation( (date:string) => fetchLeaveStatus(date), {
    });

  const {
    mutate: fetchActiveSessions,
    isLoading: isFetchSessionLoading,
    data: activeSessionDetails,
  } = useMutation(
    ({ fromDate, toDate }: IActiveSessionPayload) =>
      fetchActiveSessionDuringOutOfOffice(fromDate, toDate),
    {
      onSuccess: (data) => {
        console.log('sess', data);
      },
      onError: (errorData: Error) => {
        toast.show({ message: errorData.message });
      },
    },
  );

  return {
    isOnLeave:isOnLeave as ILeaveCheckRes,
    leaveCheckLoading,
    fetchOnLeave,
    fetchActiveSessions,
    isFetchSessionLoading,
    activeSessionDetails,
  };
}

export function addOutOfOfficeDates(onSuccess: () => void) {
  const toast = useToast();
  const { mutate: addLeaveDates, isLoading: isAddLeaveLoading } = useMutation(
    (body: IAddOOODates) => markOutOfOffice(body),
    {
      onSuccess,
      onError: (errorData: Error) => {
        toast.show({ message: errorData.message });
      },
    },
  );
  return {
    addLeaveDates,
    isAddLeaveLoading,
  };
}

export function removeOutOfOfficeDate(onSuccess: () => void) {
  const toast = useToast();
  const { mutate: removeLeaveDates, isLoading: isRemoveLeaveLoading } = useMutation(
    (body: IAddOOODates) => removeOutOfOffice(body),
    {
      onSuccess,
      onError: (errorData: Error) => {
        toast.show({ message: errorData.message });
      },
    },
  );
  return {
    removeLeaveDates,
    isRemoveLeaveLoading,
  };
}

export default useOutOfOffice;
