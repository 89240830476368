import * as React from 'react';

export interface ISideDrawerSetting {
  beforeHideHandler: () => boolean;
}

export interface ISideDrawerContext {
  beforeHideHandler: () => boolean;
  updateSideDrawerSettings: (
    sideDrawerSetting: Partial<ISideDrawerSetting>,
  ) => void;
  hideHandler:()=>void
}
// beforeHideHandler will  execute before hideHandler od side bar and block execution when it returns true
const SideDrawerContext = React.createContext<ISideDrawerContext>(
  {} as ISideDrawerContext,
);

export default SideDrawerContext;
