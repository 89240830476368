import * as React from 'react';
import './ClientDetails.scss';
import { motion } from 'framer-motion';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import lodash from 'lodash';
import Typography from '../../components/Typography';
import Tags from '../../app-components/Tags';
import Button from '../../components/Button';
import { getClientData as _getClientData } from '../../../shared/redux/actions/provider';
import {
  ClientMeetingData,
  IProviderClient,
} from '../../../shared/types/response/provider';
import {
  convertClientTagsToRender,
  getAssignmentProgressData,
  getClientStatus,
  getProviderRole,
  progressTypes,
} from '../../../utilities/common/Provider';
import { Page } from '../../../shared/types/common';
import { IDisplayDate } from '../../../utilities/common/Date';
import {
  displayDateFormat,
  displayTimeFormat,
  getClientCredits,
  getImageUri,
  getTruncatedMeetingId,
  displayJaDateFormat,
  displayJaTimeFormat,
} from '../../../utilities/common/Display';
import {
  useFetch,
  useMutation,
  usePrefetch,
} from '../../../utilities/hooks/useFetch';
import {
  getClientAssignments,
  getInsightsReport,
  sendAssignmentReminder,
} from '../../../shared/redux/actions/assignment';
import Loader from '../../components/Loader';
import { getTranslation } from '../../../i18n/utilities';
import useLayout from '../../../utilities/hooks/useLayout';
import SideDrawer from '../../components/SideDrawer';
import CaseNotes from '../../app-components/CaseNotes';
import useToast from '../../../utilities/hooks/useToast';
import useTracking from '../../../utilities/hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
  EventSource,
} from '../../../shared/constant/Analytics';
import { useHelplineRecords } from '../../../utilities/hooks/fetchHooks/helpline';
import UnorderedList from '../../components/UnorderedList';
import calenderIcon from '../../../assets/calender-inline.svg';
import clockIcon from '../../../assets/clock.svg';
import { PageAnimationDuration } from '../../../configs/animations/constants';
import {
  buttonVariants,
  ButtonVariantTypes,
} from '../../../configs/animations/variants';
import SuccessPopup from '../../components/SuccessPopup';
import SendSOSPopup from '../../app-components/SendSOSPopup';
import Popup from '../../components/Popup';
import MeetingSlotSelection from '../../app-components/MeetingSlotSelection';
import { useProviderOpenSlots } from '../../../utilities/hooks/fetchHooks/provider';
import useAuth from '../../../utilities/hooks/useAuth';
import { getCaseNoteFormType } from '../../../utilities/hooks/useCaseNotesDetails/useCaseNotesDetails';
import { CaseNoteFormType } from '../../../shared/constant/CoachingCaseNotes';
import { getCurrentLocale } from '../../../i18n';
import useClientDetails from '../../../utilities/hooks/useClientDetails/useClientDetails';
import copyIcon from '../../../assets/CopyIcon.svg';
import decodeCode from '../../../utilities/common/Encryption';
import { unMatchedStatusCode } from '../ClientsV2/constants';

// * reusing few old client details logic along with new logic (connect() + useDispatch())
// TODO extract components
interface ClientDetailsProps {
  clientFullData?: IProviderClient;
  getClientData?: (
    clientId: string,
    page: number,
    providerRole: string,
  ) => Promise<Page>;
}

// interface LocationState {
//   clientData: IClientSummary;
//   index: number;
// }

const profilePlaceholderColors = [
  '#4C8387',
  '#955D29',
  '#DB6421',
  '#252525',
  '#A00000',
];
function ClientDetails({ clientFullData, getClientData }: ClientDetailsProps) {
  const providerId = useAuth().user?.id;
  const navigate = useNavigate();
  const { clientId, serviceType } = useParams();
  const decodedClientId = decodeCode(clientId as string).toString();
  const { clientProfiledata, clientProfileLoading } = useClientDetails({
    clientId: decodedClientId,
    serviceType,
  });
  // const { state } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const { track } = useTracking();
  const { updateChatWindowSettings } = useLayout();
  const [sosStatus, setSosStatus] = React.useState<{
    showSOSPopup: boolean;
    showSOSSuccessPopup: boolean;
  }>({ showSOSPopup: false, showSOSSuccessPopup: false });
  const [selectedCaseNote, setSelectedCaseNote] =
    React.useState<ClientMeetingData | null>(null);
  const [reminderSentAssignments, setReminderSentAssignments] = React.useState<
  string[]
  >([]);
  const [isPopUp, setIsPopUp] = React.useState(false);
  // const { clientData, index } = (state || {}) as LocationState;
  const { user, userId, status, providerRole, lastSessionDuration, isHold } =
    clientProfiledata || {};
  const { friendlyName, createdAt, companyName, tags, concernArea } =
    user || {};
  const { caseNotes, credits, recentMeeting, totalMeetings, sessionBooked } =
    clientFullData || {};
  const clientStatus = getClientStatus(status || '');
  const profilePlaceholderColor =
    profilePlaceholderColors[userId % profilePlaceholderColors.length];
  const renderableTags = convertClientTagsToRender(tags);
  const currentLocale = getCurrentLocale();
  const { data: helplineRecords } = useHelplineRecords(decodedClientId);
  const timeFmt =
    currentLocale === 'ja' ? displayJaTimeFormat : displayTimeFormat;
  const { data: assignmentProgressData, isLoading } = useFetch(
    ['client', userId],
    () => dispatch(getClientAssignments(userId.toString())),
    {
      initialData: [],
    },
  );

  //* Query to get selected client slot
  const { data: providerOpenSlots } = useProviderOpenSlots(
    providerId,
    providerRole as string,
    decodedClientId,
  );

  usePrefetch(['client-reports', decodedClientId], () =>
    dispatch(getInsightsReport(decodedClientId?.toString())),
  );

  const { mutate: sendReminder } = useMutation(
    (assignmentId: string) =>
      dispatch(sendAssignmentReminder(userId?.toString(), assignmentId)),
    {
      onSuccess: (response: string, assignmentId: string) => {
        if (response === 'success') {
          setReminderSentAssignments((prev) => [...prev, assignmentId]);
          toast.show({ message: t('sentReminder') });
        }
      },
      onError: (error: Error) => {
        toast.show({ message: error.message });
      },
    },
  );

  const onSendReminderClick = (id: string) => {
    sendReminder(id);
    track(EventNames.clientView, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.sendReminder,
      eventLabel: 'send_reminder',
      featureVersion: 'v1',
    });
  };
  const getDisplayDate = ({
    locale,
    selectedDate,
    format,
    translatorResource = { ja: 'DISPLAY_DATE_WITH_MENTION' },
    splitWith = ',',
  }: IDisplayDate): string => {
    const formattedDate = moment(selectedDate).format(
      format[locale] || format.default || displayJaDateFormat,
    );
    const [month, date, year, day] = formattedDate.split(splitWith);
    if (locale.includes('ja') && translatorResource?.ja) {
      return t(translatorResource.ja, { year, month, date, day });
    }
    if (locale.includes('en') && translatorResource?.en) {
      return t(translatorResource.en, { year, month, date, day });
    }
    return formattedDate;
  };

  const joinedOn = getDisplayDate({
    locale: currentLocale,
    selectedDate: createdAt as string,
    format: { ja: displayJaDateFormat, en: 'DD MMM' },
    translatorResource: { ja: 'joinedOn', en: 'joinedOn' },
  });
  const onSOSClose = (isSOSSuccessful: boolean) => {
    if (isSOSSuccessful) {
      setSosStatus((prev) => ({
        ...prev,
        showSOSPopup: false,
        showSOSSuccessPopup: true,
      }));
    } else {
      setSosStatus((prev) => ({ ...prev, showSOSPopup: false }));
    }
    track(EventNames.sosHelpLine, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.close,
      featureVersion: 'v1',
      eventLabel: 'close',
      eventSource: EventSource.clientView,
    });
  };

  //* Function to show book a session popup
  const onBookSessionClick = () => {
    if (isHold) {
      toast.show({
        message: t('matchedWithClinical', { clientName: friendlyName }),
      });
    } else {
      track(EventNames.clientView, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.bookSession,
        eventLabel: 'book_session',
        featureVersion: 'v1',
      });
      setIsPopUp(true);
    }
  };
  // Component Life Cycle
  React.useEffect(() => {
    const initializeClientDetails = async () => {
      if (decodedClientId && providerRole) {
        const initialPage = 1;
        getClientData?.(decodedClientId, initialPage, providerRole);
      }
    };

    initializeClientDetails();
  }, [decodedClientId, providerRole]);

  React.useEffect(() => {
    if (clientFullData && clientFullData.page && providerRole) {
      const { page } = clientFullData;
      if (page.hasNextPage) {
        getClientData?.(userId?.toString(), page.nextPage, providerRole);
      }
    }
  }, [clientFullData, providerRole]);

  const { fetchCasenoteFormType, isFormTypeLoading } = getCaseNoteFormType(
    (data, variables) => {
      if (data.form === CaseNoteFormType.v0) {
        setSelectedCaseNote({
          clientId: userId?.toString(),
          meetingId: variables.meetingId,
          meetingDate: variables.meetingDate,
        });
      } else if (data.form === CaseNoteFormType.V1) {
        navigate(`/coachingnotes/${clientId}/${variables.meetingId}`);
      } else {
        navigate(`/carenotes/${clientId}/${variables.meetingId}`);
      }
    },
  );
  const handleDetailsClick = (meetingId: string, meetingDate: string) => {
    track(EventNames.clientView, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.view_session_case_notes,
      eventLabel: 'view_session_case_notes',
      featureVersion: 'v1',
      eventSource: EventSource.clientView,
    });
    fetchCasenoteFormType({ meetingId, meetingDate });
  };

  const handleCopyIconClick = () => {
    navigator.clipboard.writeText(clientId as string);
    toast.show({ message: t('COPIED_CLIPBOARD') });
    track(EventNames.clientView, {
      eventAction: EventActions.click,
      eventCategory: EventCategories.caseIdCopied,
      eventLabel: 'case_id_copied',
      featureVersion: 'v1',
      eventSource: clientId,
    });
  };

  if (isFormTypeLoading || clientProfileLoading) {
    return <Loader useFullPage />;
  }
  return (
    <motion.div
      className="client-details-full"
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ duration: PageAnimationDuration }}
    >
      {isLoading && <Loader useFullPage withBackdrop={false} />}
      <SideDrawer
        show={Boolean(selectedCaseNote)}
        hideHandler={() => setSelectedCaseNote(null)}
      >
        {selectedCaseNote && (
          <CaseNotes mode="edit" client={selectedCaseNote} />
        )}
      </SideDrawer>
      {sosStatus.showSOSPopup && (
        <SendSOSPopup
          clientData={clientProfiledata}
          onCancel={(isSOSSuccessful) => onSOSClose(isSOSSuccessful)}
          trackingData={{ source: EventSource.session }}
        />
      )}
      {sosStatus.showSOSSuccessPopup && (
        <SuccessPopup
          heading="SOS Helpline"
          description="Helpline details have been sent to your client via chat."
          onClose={() =>
            setSosStatus((prev) => ({ ...prev, showSOSSuccessPopup: false }))
          }
        />
      )}
      <div className="general-details">
        <div className="top-section">
          <div className="top-left-container">
            <div
              className="client-avatar"
              style={{ backgroundColor: profilePlaceholderColor }}
            >
              <Typography size={14} weight="500" color="light">
                {friendlyName?.charAt(0)}
              </Typography>
            </div>
            <div className="basic-info">
              <Typography size={18} weight="500">
                {friendlyName}
              </Typography>
              <section className="case-id-wrapper">
                <section className="case-id-copy-wrapper">
                  <Typography size={12}>
                    {' '}
                    {t('CASE_ID', { clientId })}
                  </Typography>
                  <img
                    src={copyIcon}
                    alt="copy-icon"
                    onClick={handleCopyIconClick}
                  />
                </section>
                <ul>
                  <li>
                    <Typography size={12} weight="400">
                      {joinedOn}
                    </Typography>
                  </li>
                  <li>
                    <Typography size={12} weight="400">
                      {t(getProviderRole(providerRole))}
                    </Typography>
                  </li>
                  <li>
                    {clientStatus && (
                      <Tags
                        textSize={10}
                        tags={[t(clientStatus.label)]}
                        backgroundColor={clientStatus.backgroundColor}
                        textColor={clientStatus.textColor}
                      />
                    )}
                  </li>
                </ul>
              </section>
            </div>
          </div>
          {status === unMatchedStatusCode ? null : (
            <div className="top-right-container">
              <Button
                width="100%"
                variant="secondary"
                label={t('VIEW_PERSONAL_INSIGHTS')}
                onClick={() => {
                  navigate(`/clients/${clientId}/insights`, {
                    state: clientProfiledata,
                  });
                  track(EventNames.clientView, {
                    eventAction: EventActions.click,
                    eventCategory: EventCategories.viewPersonalInsightResults,
                    eventLabel: 'view_personal_insights_results',
                    featureVersion: 'v1',
                    eventValue: clientId,
                  });
                }}
              />
              <Button
                variant="secondary"
                label={t('messages')}
                // Disable button if "No App Access" tag exists
                onClick={() =>
                  updateChatWindowSettings({
                    show: true,
                    initialUser: userId,
                  })
                }
              />
              <motion.div
                className="sos-button"
                variants={buttonVariants}
                whileTap={ButtonVariantTypes.tap}
                whileHover={ButtonVariantTypes.hover}
                onClick={() => {
                  setSosStatus({
                    showSOSPopup: true,
                    showSOSSuccessPopup: false,
                  });
                  track(EventNames.sosHelpLine, {
                    eventAction: EventActions.click,
                    eventCategory: EventCategories.sosHelpLine,
                    eventLabel: 'sos_helpline',
                    featureVersion: 'v1',
                    eventSource: EventSource.clientView,
                  });
                }}
              >
                <span className="text-[#FCFBF8] font-semibold text-xs tracking-wider">
                  {t('SOS')}
                </span>
                {/* <Typography color="light" size={12} weight="600">
                SOS
              </Typography> */}
              </motion.div>
            </div>
          )}
        </div>
        <div className="details-section">
          <div className="details-type-header">
            <Typography weight="500" size={14}>
              {t('personalDetails')}
            </Typography>
          </div>
          <div className="details-section-row equal-spaced">
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('company')}
              </Typography>
              <Typography size={12} weight="400">
                {companyName || '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('gender')}
              </Typography>
              <Typography size={12} weight="400">
                -
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('age')}
              </Typography>
              <Typography size={12} weight="400">
                -
              </Typography>
            </div>
            <div className="details-item column-type" />
          </div>
          <div className="details-section-row">
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('tags')}
              </Typography>
              {renderableTags.length ? (
                renderableTags.map(({ label, colors }) => (
                  <Tags
                    textSize={10}
                    tags={t(
                      label
                        .split(' ')
                        .reduce(
                          (prev, current) =>
                            prev.concat(' ').concat(t(current.toUpperCase())),
                          '',
                        )
                        .trim(),
                    )}
                    backgroundColor={colors?.backgroundColor}
                    textColor={colors?.fontColor}
                  />
                ))
              ) : (
                <Typography size={12} weight="400">
                  -
                </Typography>
              )}
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('goals')}
              </Typography>
              {concernArea?.length ? (
                <Tags
                  textSize={10}
                  tags={concernArea.map((goal) => goal.areaExpertise)}
                />
              ) : (
                <Typography size={12} weight="400">
                  -
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className="details-section">
          <div className="details-type-header">
            <Typography weight="500" size={14}>
              {t('coachingDetails')}
            </Typography>
          </div>
          <div className="details-section-row equal-spaced">
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('creditsLeft')}
              </Typography>
              <Typography size={12} weight="400">
                {credits && !lodash.isNil(credits?.credit)
                  ? t(getClientCredits(credits), { count: credits.credit })
                  : '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('noOfSessions')}
              </Typography>
              <Typography size={12} weight="400">
                {sessionBooked || '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('sessionsAttended')}
              </Typography>
              <Typography size={12} weight="400">
                {totalMeetings || '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('lastSessionOn')}
              </Typography>
              <Typography size={12} weight="400">
                {recentMeeting?.scheduledDate
                  ? getDisplayDate({
                    locale: currentLocale,
                    selectedDate: recentMeeting.scheduledDate,
                    format: {
                      en: displayDateFormat,
                      ja: displayJaDateFormat,
                    },
                    translatorResource: {
                      ja: 'DISPLAY_DATE_WITHOUT_MENTION',
                    },
                  })
                  : '-'}
              </Typography>
            </div>
            <div className="details-item column-type">
              <Typography size={12} weight="400" color="secondary">
                {t('lastSessionDuration')}
              </Typography>
              <Typography size={12} weight="400">
                {lastSessionDuration
                  ? t('minutes', { count: Number(lastSessionDuration) / 60 })
                  : '-'}
              </Typography>
            </div>
          </div>
          <div className="session-booking-container">
            <div className="booking-text-container">
              <Typography size={16} weight="500" color="primary">
                {t('bookSessionDescription')}
              </Typography>
            </div>
            {status === unMatchedStatusCode ? null : (
              <Button
                width="20%"
                height="40px"
                label={t('bookSession')}
                variant="blue"
                onClick={() => onBookSessionClick()}
              />
            )}
          </div>
          {isPopUp && (
            <Popup
              withBackdrop
              useDefaultPadding={false}
              backdropMode="glass"
              onClose={() => setIsPopUp(false)}
            >
              <div>
                <MeetingSlotSelection
                  isPopUp={isPopUp}
                  selectedClientId={userId}
                  providerOpenSlots={providerOpenSlots}
                  setIsPopUp={setIsPopUp}
                  providerRole={providerRole as string}
                  name={user?.friendlyName as string}
                />
              </div>
            </Popup>
          )}
        </div>
      </div>
      <div className="other-details-section">
        <Typography size={18} weight="500">
          {t('caseNotes')}
        </Typography>
        <div className="list-details case-notes">
          <div className="listing-header">
            <Typography size={12} weight="500">
              {t('LOGS')}
            </Typography>
            <Typography size={12} weight="500">
              {t('action')}
            </Typography>
          </div>
          <div className="listing-container">
            {caseNotes?.length ? (
              caseNotes?.map(({ meetingId, meeting }) => (
                <div className="case-note-item" key={meetingId}>
                  <div className="side-section">
                    <Typography size={12} weight="400" color="secondary">
                      {getDisplayDate({
                        locale: currentLocale,
                        selectedDate: meeting.displayDate,
                        format: { en: 'MMM D, YYYY', ja: displayJaDateFormat },
                        translatorResource: {
                          ja: 'DISPLAY_DATE_WITHOUT_MENTION',
                        },
                      })}
                    </Typography>
                    <Typography size={12} weight="400" color="primary">
                      {getTruncatedMeetingId(meetingId)}
                    </Typography>
                  </div>
                  <div className="side-section">
                    <Button
                      label={t('details')}
                      onClick={() =>
                        handleDetailsClick(meetingId, meeting.scheduledDate)
                      }
                      variant="secondary"
                      disabled={isFormTypeLoading}
                      disableAnimation={isFormTypeLoading}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-case-notes">
                <Typography size={14} weight="500" color="secondary">
                  {t('noCaseNotes')}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="other-details-section">
        <Typography size={18} weight="500">
          {t('HELPLINE_RECORDS')}
        </Typography>
        <div className="list-details case-notes">
          <div className="listing-header">
            <Typography size={12} weight="500">
              {t('LOGS')}
            </Typography>
            <Typography size={12} weight="500">
              {t('action')}
            </Typography>
          </div>
          <div className="listing-container">
            {helplineRecords?.length ? (
              helplineRecords?.map(({ id, startTime, endTime }, i) => (
                <div className="case-note-item" key={id}>
                  <div className="side-section">
                    <Typography size={16} weight="500" color="primary">
                      {`Call ${i + 1}`}
                    </Typography>
                    <UnorderedList
                      labelColor="secondary"
                      items={[
                        {
                          icon: calenderIcon,
                          label: `${moment(startTime).format(
                            displayDateFormat,
                          )}`,
                        },
                        {
                          icon: clockIcon,
                          label: `${moment(startTime).format(
                            timeFmt,
                          )} - ${moment(endTime || startTime).format(timeFmt)}`,
                        },
                      ]}
                    />
                  </div>
                  <div className="side-section">
                    <Button
                      label={t('view')}
                      onClick={() => {
                        navigate(`/clients/${clientId}/helpline-records/${id}`);
                      }}
                      variant="secondary"
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-case-notes">
                <Typography size={14} weight="500" color="secondary">
                  {t('NO_HELPLINE_RECORDS')}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="other-details-section">
        <Typography size={18} weight="500">
          {t('assignments')}
        </Typography>
        <div className="list-details assignment-progress">
          {assignmentProgressData?.length ? (
            assignmentProgressData.map(
              ({
                _id,
                status: assignmentStatus,
                title,
                translationKeys,
                mainImage,
                referenceType,
              }) => {
                const isCustomAssignment = referenceType === 'custom';
                const { color, cta, label } = getAssignmentProgressData(
                  assignmentStatus as keyof typeof progressTypes,
                );
                return (
                  <div className="assignment-progress-item" key={_id}>
                    <div className="left-section">
                      <img src={getImageUri(mainImage)} alt="assignment" />
                      <div className="assignment-details">
                        <Typography
                          size={12}
                          weight="500"
                          breakWord="break-all"
                        >
                          {!isCustomAssignment
                            ? getTranslation(title, translationKeys)
                            : title}
                        </Typography>
                        {status === unMatchedStatusCode ? null : (
                          <Typography withColor={color} size={12} weight="500">
                            {t(label)}
                          </Typography>
                        )}
                      </div>
                    </div>
                    {cta && status !== unMatchedStatusCode && (
                      <Button
                        label={t('remindCTA')}
                        variant="secondary"
                        disabled={reminderSentAssignments.includes(_id)}
                        onClick={() => onSendReminderClick(_id)}
                      />
                    )}
                  </div>
                );
              },
            )
          ) : (
            <div className="assignment-progress-item empty">
              <Typography size={14} weight="500" color="secondary">
                {t('noAssignments')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
}

const mapStateToProps = (state: any) => ({
  clientFullData: state.provider.selectedClient,
});

const mapDispatchToProps = (dispatch: Function) => ({
  getClientData: (clientId: string, page: number, providerRole: string) =>
    dispatch(_getClientData(clientId, page, providerRole)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
