import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getClientStatus } from '../../../utilities/common/Provider';
import Tags from '../Tags';
import './StatusViewer.scss';

interface IStatusViewer {
  statusValue: string;
}

function StatusViewer({ statusValue }: IStatusViewer) {
  const { t } = useTranslation();
  return (
    <section className="status-wrapper">
      <Tags
        textSize={10}
        tags={[t(getClientStatus(statusValue).label)]}
        backgroundColor={getClientStatus(statusValue).backgroundColor}
        textColor={getClientStatus(statusValue).textColor}
      />
    </section>
  );
}

export default StatusViewer;
