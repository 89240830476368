import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import './TemplateHeader.scss';
import Typography from '../Typography';
import editPen from '../../../assets/editPen.svg';
import Button from '../Button';

interface ITemplateHeaderProps {
  onSave: () => void;
  showSaveButton?: boolean;
  headerContent: string;
}

function TemplateHeader({
  onSave,
  showSaveButton = true,
  headerContent,
}: ITemplateHeaderProps) {
  const { t } = useTranslation();
  return (
    <motion.article
      className="template-header-container"
      initial={{ y: -50, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 0.3 },
      }}
      exit={{
        y: -50,
        opacity: 0,
        transition: { duration: 0.3 },
      }}
    >
      <section className="left-header-wrapper">
        <Typography whiteSpace="pre-wrap" size={12} weight="500" color="rhino">
          {headerContent}
        </Typography>
        <img src={editPen} alt="edit-icon" />
      </section>
      <section className="right-header-wrapper">
        {showSaveButton && (
          <Button
            label={t('SAVE_CTA')}
            variant="save"
            minWidth="63px"
            fontSize={12}
            fontWeight="700"
            width="63px"
            onClick={onSave}
          />
        )}
      </section>
    </motion.article>
  );
}

export default TemplateHeader;
