import { useTranslation } from 'react-i18next';
import { useFetch, useMutation } from '../useFetch';
import {
  getOptions,
  getNewCaseNotesAnswers,
  getNewCaseNotesQuestions,
  saveCaseNotes,
  fetchCaseNoteFormType,
  getInSessionCaseNotesAnswers,
} from '../../../shared/service/API/caseNotes';
import {
  ICaseNoteQuestionResp,
  ICaseNoteResp,
  IOptions,
  ISaveCaseNotesReqType,
} from '../../../shared/types/CaseNotes';
import useToast from '../useToast';
import { CaseNoteFormType, CaseNoteQuestionTypes } from '../../../shared/constant/CoachingCaseNotes';
import { ICaseNoteFormTypeResp } from '../../../view/app-components/CaseNotesV2/types';

function useCaseNotesDetails({
  meetingId,
  afterAddingCaseNotes,
  formType,
}: {
  meetingId: string;
  afterAddingCaseNotes?: () => void;
  formType?: CaseNoteFormType
}) {
  const { t } = useTranslation();
  const toast = useToast();
  let fetchAnswersApi = getNewCaseNotesAnswers;
  if (formType && [CaseNoteFormType.V5, CaseNoteFormType.V7, CaseNoteFormType.V6].includes(formType)) {
    fetchAnswersApi = getInSessionCaseNotesAnswers;
  }
  const { data: newCaseNoteAnswers, isLoading: isCaseNoteAnswersLoading, refetch: refetchCaseNotesAnswers } =
    useFetch<ICaseNoteResp>(
      ['newCaseNoteAnswers', meetingId, formType],
      () => fetchAnswersApi(meetingId as string),
      {
        refetchOnWindowFocus: false,
      },
    );
  const { data: newCaseNoteQuestions, isLoading: isQuestionaireLoading, refetch: refetchCaseNotesQuestions } =
    useFetch<ICaseNoteQuestionResp[]>(
      ['newCaseNoteQuestionaire', meetingId],
      () => getNewCaseNotesQuestions(newCaseNoteAnswers?.form as string),
      {
        refetchOnWindowFocus: false,
        enabled: !isCaseNoteAnswersLoading,
      },
    );

  const { mutate: saveNewcaseNotes, isLoading: isSaveNewcaseNotesLoading, isSuccess: hasSuccessfullySaved } =
    useMutation((body: ISaveCaseNotesReqType) => saveCaseNotes(body), {
      onSuccess: () => {
        toast.show({ message: t('SUCCESS_SAVE_PROMPT') });
        afterAddingCaseNotes?.();
      },
      onError: (errorData) => {
        toast.show({ message: errorData.message });
      },
    });

  return {
    newCaseNoteQuestions,
    isQuestionaireLoading,
    newCaseNoteAnswers,
    isCaseNoteAnswersLoading,
    saveNewcaseNotes,
    isSaveNewcaseNotesLoading,
    hasSuccessfullySaved,
    refetchCaseNotesAnswers, 
    refetchCaseNotesQuestions,
  };
}
export function getNewCaseNoteOptions(questionType: string, formType: string) {
  const { data, isLoading, refetch } = useFetch<any>(
    [`newCaseNoteOptions${questionType}`, questionType, formType],
    () => getOptions(questionType, formType),
    {
      refetchOnWindowFocus: false,
      select: ([optionData]) => (optionData?.children?.map((item: any) => ({
        label: item.question,
        value: item.type || item.id,
        id: item.id,
        ...(item.children && {
          children: item.children.map((child: any) => ({
            label: child.question,
            value: child.type || child.id,
            id: child.id,
          })),
        }),
      }))), 
    },
  );
  // const optionValues = React.useMemo<IOptions[]>(() => {
  //   const [optionData] = data || [];
  //   if (!isLoading && optionData) {
  //     return 
  //   }
  //   return undefined;
  // }, [data, isLoading]);
  return {
    data,
    modifiedOptionData: data as IOptions[],
    isLoading,
    refetch,
  };
}
// need to check for optimizing with req props
// need to modify as (_ requiredOptions?: Array<CaseNoteQuestionTypes>,) => req.reduce(() =>{}, [isRequiredOptLoading, {opt1, opt2, opt3... opt[req.length-1] }])
export function getAllCaseNoteOptions(formType: string) {
  // primary presenting options

  const {
    modifiedOptionData: responseToInterventionAndTreatmentOptions,
    isLoading: isResponseToInterventionAndTreatmentLoading,
  } = getNewCaseNoteOptions(
    CaseNoteQuestionTypes.ResponseToInterventionTreatment.toString(),
    formType,
  );

  const {
    modifiedOptionData: riskCategoriesOptions,
    isLoading: isRiskOptionsLoading,
  } = getNewCaseNoteOptions(
    CaseNoteQuestionTypes.RiskAssesment.toString(),
    formType,
  );

  // intervention and Modality Options
  const {
    modifiedOptionData: techniqueCategoriesOptions,
    isLoading: isTechniqueOptionsLoading,
  } = getNewCaseNoteOptions(
    CaseNoteQuestionTypes.InterventionsAndModality.toString(),
    formType,
  );
  const {
    modifiedOptionData: progressCategoriesOptions,
    isLoading: isProgressCategoriesOptionsLoading,
  } = getNewCaseNoteOptions(
    CaseNoteQuestionTypes.ProgressTowardsGoals.toString(),
    formType,
  );
  const {
    modifiedOptionData: recommendationCategoriesOptions,
    isLoading: isRecommendationCategoriesOptionsLoading,
  } = getNewCaseNoteOptions(
    CaseNoteQuestionTypes.Recommendations.toString(),
    formType,
  );
  const {
    modifiedOptionData: sharedFollowUpCategoriesOptions,
    isLoading: isSharedFollowUpOptionsLoading,
  } = getNewCaseNoteOptions(
    CaseNoteQuestionTypes.SharedFollowUpPlan.toString(),
    formType,
  );
  // primary & secondary Options loading
  const {
    modifiedOptionData: issuesOptions,
    isLoading: isIssueOptionsLoading,
  } = getNewCaseNoteOptions(
    CaseNoteQuestionTypes.SecondaryGoal.toString(),
    formType,
  );

  const {
    modifiedOptionData: suicidalAssessmentsOptions,
    isLoading: isSuicidalAssessmentsOptionsLoading,
  } = getNewCaseNoteOptions(
    CaseNoteQuestionTypes.SuicidalAssesment.toString(),
    formType,
  );

  return {
    isOptionsLoading:
      isRiskOptionsLoading ||
      isTechniqueOptionsLoading ||
      isProgressCategoriesOptionsLoading ||
      isRecommendationCategoriesOptionsLoading ||
      isSharedFollowUpOptionsLoading ||
      isIssueOptionsLoading ||
      isSuicidalAssessmentsOptionsLoading ||
      isResponseToInterventionAndTreatmentLoading,
    riskCategoriesOptions,
    techniqueCategoriesOptions,
    progressCategoriesOptions,
    recommendationCategoriesOptions,
    sharedFollowUpCategoriesOptions,
    issuesOptions,
    suicidalAssessmentsOptions,
    responseToInterventionAndTreatmentOptions,
  };
}

export function getCaseNoteFormType(
  onSuccess: (
    data: ICaseNoteFormTypeResp,
    variables: { meetingId: string; meetingDate: string },
  ) => void,
) {
  const toast = useToast();
  const { mutate: fetchCasenoteFormType, isLoading: isFormTypeLoading } =
    useMutation(
      (payload: { meetingId: string; meetingDate: string, isInSession: boolean }) =>
        fetchCaseNoteFormType(payload.meetingId, payload.isInSession),
      {
        onSuccess,
        onError: (errorData) => {
          toast.show({ message: errorData.message });
        },
      },
    );

  return {
    fetchCasenoteFormType,
    isFormTypeLoading,
  };
}
export default useCaseNotesDetails;
