import * as React from 'react';
import { IWarningModal } from './types';
import Button from '../../../../components/Button';
import Typography from '../../../../components/Typography';
import './WarningModal.scss';

function WarningModal({
  onCancel,
  onConfirm,
  header,
  subHeader,
  cancelBtnText,
  confirmBtnText,
  className = '',
}: IWarningModal) {
  return (
    <article className={`cancellation-warning-wrapper ${className}`}>
      <section className="cancellation-warning-header">
        <Typography
          size={24}
          weight="600"
          withColor="#101828"
          textAlign="center"
        >
          {header}
        </Typography>
      </section>
      {subHeader && (
        <section className="cancellation-warning-msg">
          <Typography size={16} color="secondary" textAlign="center">
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: subHeader }} />
          </Typography>
        </section>
      )}
      <section className="cancellation-warning-body">
        <Button
          label={cancelBtnText}
          variant="secondary"
          onClick={onCancel}
          width="100%"
          height="48px"
          fontSize={14}
        />
        <Button
          label={confirmBtnText}
          variant="blue"
          onClick={onConfirm}
          width="100%"
          height="48px"
          fontSize={14}
        />
      </section>
    </article>
  );
}

export default WarningModal;
