import moment from 'moment';
import * as React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { APIDateFormat } from '../../../utilities/common/Date';
import { getProviderRole } from '../../../utilities/common/Provider';
import { useBillingDetails } from '../../../utilities/hooks/fetchHooks/billing';
import Typography from '../../components/Typography';
import UnorderedList from '../../components/UnorderedList';
import './BillingDetails.scss';
import { PageAnimationDuration } from '../../../configs/animations/constants';

const breakdownHeaders = ['CLIENT_NAME', 'SESSION_TYPE', 'TIME_SLOT', 'TOTAL_PAY'];

function BillingDetails() {
  const { t } = useTranslation();
  const { billingDay: billingDayFromParam } = useParams();
  const billingDay = billingDayFromParam || '';

  const { data } = useBillingDetails(billingDay);
  const { billingDetails, summary } = data || {};
  const currentBill = (billingDetails || {})[billingDay];
  const currentSummary = (summary || {})[billingDay];

  const { duration, sessions } = currentSummary || {};

  return (
    <motion.div
      className="billing-details"
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 100, opacity: 0 }}
      transition={{ duration: PageAnimationDuration }}
    >
      <div className="breakdown-container">
        <div className="breakdown-header">
          <Typography size={18} weight="500">
            {t('BILLING_DETAILS')}
          </Typography>
          <Typography size={12} weight="400">
            {moment(billingDayFromParam, APIDateFormat).format(
              'MMMM DD ddd, YYYY',
            )}
          </Typography>
        </div>
        <div className="breakdowns">
          <Typography size={14} weight="500">
          {t('SESSION_DETAILS')}
          </Typography>
          <div className="breakdown-details">
            {breakdownHeaders.map((heading) => (
              <Typography
                size={12}
                weight="400"
                color="secondary"
                key={heading}
              >
                {t(heading)}
              </Typography>
            ))}
            {(currentBill || []).map(
              ({ participants, providerRole, displayTime, displayTimeEnd, payment }) => (
                <>
                  <Typography size={12} weight="400">
                    {participants?.[0]?.friendlyName}
                  </Typography>
                  <Typography size={12} weight="400">
                    {t(getProviderRole(providerRole))}
                  </Typography>
                  <Typography size={12} weight="400">
                    {`${displayTime} - ${displayTimeEnd}`}
                  </Typography>
                  <Typography size={12} weight="400"> 
                    {payment || 0} 
                  </Typography>
                </>
              ),
            )}
          </div>
        </div>
        <div className="total-breakdowns">
          <Typography size={20} weight="500">
            {t('TOTAL_BREAKDOWN')}
          </Typography>
          <UnorderedList
            items={[
              { label: t('SESSIONS', { count: sessions }) },
              { label: t('HOURS', { count: Number((duration / 60).toFixed(2)) }) },
            ]}
          />
        </div>
      </div>
    </motion.div>
  );
}

export default BillingDetails;
