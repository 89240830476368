import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../Typography';
import sendIcon from '../../../assets/send-arrow.svg';
import './TemplateFooter.scss';
import ToggleSwitch from '../ToggleSwitch';
import { IMessageTemplateProps } from '../../screens/Settings/types';

function TemplateFooter({
  selectedTrigger,
  handleToggle,
}: IMessageTemplateProps) {
  const { t } = useTranslation();
  return (
    <article className="msg-template-footer">
      <section className="footer-content-left">
        <section className="left-icon">
          <img src={sendIcon} alt="arrow-icon" />
        </section>
        <section className="left-content">
          <Typography size={12} weight="500" color="rhino">
            {t('ACTIVATE_AUTOMATED_MESSAGES_HEADER')}
          </Typography>
          <Typography size={12} color="grey">
            {t('ACTIVATE_AUTOMATED_MESSAGES_DESC')}
          </Typography>
        </section>
      </section>
      <section className="footer-content-right">
        <ToggleSwitch
          initialValue={selectedTrigger.customEnabled}
          onChange={(isChecked) => {
            if (
              Object.keys(selectedTrigger).length > 0 &&
              isChecked !== selectedTrigger.customEnabled
            ) {
              // handle onchange on start
              handleToggle({ ...selectedTrigger, customEnabled: isChecked });
            }
          }}
          id={selectedTrigger.id}
          // disabled={!selectedTrigger.canToggle}
        />
      </section>
    </article>
  );
}

export default TemplateFooter;
