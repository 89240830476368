import * as React from 'react';
import './DatePickerInput.scss';
import calenderIcon from '../../assets/calender.svg';

interface DatePickerInputProps {
  onClick?: Function;
  value: string;
}

function DatePickerInput(
  { onClick, value }: DatePickerInputProps,
  ref: React.Ref<HTMLDivElement> | null,
) {
  return (
    <div
      className="date-picker-input"
      ref={ref}
      onClick={() => onClick?.()}
      role="button"
      tabIndex={0}
    >
      {value}
      <img src={calenderIcon} alt="edit" className="icon" />
    </div>
  );
}

export default React.forwardRef(DatePickerInput);
