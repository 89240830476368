import * as React from 'react';
import Typography from '../Typography';
import { FontColors } from '../Typography/Typography';
import './UnorderedList.scss';

interface UnorderedListProps {
  items: {
    icon?: string;
    label: string;
  }[];
  labelColor?: FontColors;
}

function UnorderedList({ items, labelColor = 'primary' }: UnorderedListProps) {
  return (
    <div className="unordered-list">
      <ul>
        {items.map(({ label, icon }) => (
          <li key={label}>
            {icon && (
              <img src={icon} alt="" />
            )}
            <Typography size={12} weight="400" color={labelColor}>
              {label}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default UnorderedList;
