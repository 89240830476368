import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { VideoRoomSDKs } from '../../../../shared/constant/App';
import { getDailyCoUrlFromRoomId } from '../../../../utilities/common/Meeting';
import useToast from '../../../../utilities/hooks/useToast';
import Button from '../../../../view/components/Button';
import Loader from '../../../../view/components/Loader';
import Typography from '../../../../view/components/Typography';
import useMeeting from '../../../../view/app-components/VideoRoom/hooks/useMeetingJoin';
import './CtaMessageItem.scss';

interface IAssignmentMessageItemProps {
  ctaContent: string;
  message: string;
  source: string;
  updateChatWindowSettings: (settings: any) => void;
}

function CtaMessageItem({
  ctaContent,
  message,
  source,
  updateChatWindowSettings,
}: IAssignmentMessageItemProps) {
  // Initial Values
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  // Functions
  // * Function to get the image uri
  const parsedMessage = JSON.parse(ctaContent);
  const { messageType, meetingId, type } = parsedMessage || {};

  const { isLoading, mutate: joinMeeting } = useMeeting({
    onSuccess: (meetingData) => {
      const dailyCoUrl = getDailyCoUrlFromRoomId(
        meetingData?.meeting?.locationRef || '',
      );
      navigate(
        `/rooms/${VideoRoomSDKs.dailyCo}/${meetingData.meeting.id}/?roomUrl=${dailyCoUrl}`,
      );
      updateChatWindowSettings({ show: false });
    },
    onError: (error: Error) => {
      toast.show({ message: error.message });
    },
  });

  const joinSession = () => {
    if (type === VideoRoomSDKs.dailyCo) {
      joinMeeting({ meetingId, channel: VideoRoomSDKs.dailyCo });
    } else {
      navigate(`/rooms/${type}/${meetingId}`);
      updateChatWindowSettings({ show: false });
    }
  };
  return (
    <div
      className={`cta-assignment-message italic rounded-md ${
        source === 'outbound' ? 'text-white' : 'text-white'
      }`}
    >
      {isLoading && <Loader useFullPage />}
      <div className="assignment-msg-contents">
        <Typography
          weight="400"
          size={12}
          withItalics
          withColor={source === 'outbound' ? '#fff' : '#48515A'}
        >
          {message}
        </Typography>
      </div>
      {messageType === 'join' && (
        <Button
          width="100%"
          variant={source === 'outbound' ? 'blue' : 'primary'}
          label={t('joinSession')}
          onClick={() => joinSession()}
          disabled={moment().unix() > parsedMessage?.validity}
        />
      )}
    </div>
  );
}

export default CtaMessageItem;
