import React, { useState, useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import {
  useParticipantIds,
  useScreenShare,
  useLocalSessionId,
  useDailyEvent,
  DailyAudio,
} from '@daily-co/daily-react';

import './Call.css';
import Tile from '../Tile/Tile';
import UserMediaError from '../UserMediaError/UserMediaError';
import Typography from '../../../../../components/Typography';

export default function Call(props) {
  /* If a participant runs into a getUserMedia() error, we need to warn them. */
  const [getUserMediaError, setGetUserMediaError] = useState(false);

  /* We can use the useDailyEvent() hook to listen for daily-js events. Here's a full list
   * of all events: https://docs.daily.co/reference/daily-js/events */
  useDailyEvent(
    'camera-error',
    useCallback((e) => {
      Sentry.captureException(e.error.msg);
      setGetUserMediaError(true);
    }, []),
  );
  useDailyEvent(
    'error',
    (event)=>{
      /* eslint react/prop-types: 0 */
      props?.onError?.(event.errorMsg);
    },
  );

  /* This is for displaying remote participants: this includes other humans, but also screen shares. */
  const { screens } = useScreenShare();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  /* This is for displaying our self-view. */
  const sessionId = useLocalSessionId();
  const isAlone = useMemo(
    () => remoteParticipantIds?.length < 1 || screens?.length < 1,
    [remoteParticipantIds, screens],
  );

  const renderCallScreen = () => (
    <div className="call">
      <div
        className={`participant-container ${
          screens?.length && 'screen-shared'
        }`}
      >
        {!!remoteParticipantIds?.length &&
          remoteParticipantIds.map((id) => (
            <Tile
              key={id}
              id={id}
              viewType={screens?.length ? 'tile' : 'full'}
              isScreenShare={!!screens?.length}
            />
          ))}
        {!remoteParticipantIds?.length && (
          <div className="info-box">
            <Typography color="light" size={14}>
              Waiting for other participant
            </Typography>
          </div>
        )}
        {sessionId && (
          <Tile
            id={sessionId}
            isLocal
            isAlone={isAlone}
            viewType="tile"
            isScreenShare={!!screens?.length}
          />
        )}
      </div>

      {!!screens?.length &&
        screens.map((screen) => (
          <Tile key={screen.screenId} id={screen.session_id} isScreenShare viewType='screen'/>
        ))}

      <DailyAudio />
      {/* {remoteParticipantIds?.length > 0 || screens?.length > 0 ? (
        <>
          {remoteParticipantIds.map((id) => (
            <Tile key={id} id={id} viewType='full' />
          ))}
          {screens.map((screen) => (
            <Tile key={screen.screenId} id={screen.session_id} isScreenShare />
          ))}
          <DailyAudio />
        </>
      ) : (
        // When there are no remote participants or screen shares
        <div className='info-box'>
          <Typography color='light' size={14}>Waiting for other
            participant</Typography>
        </div>
      )} */}
    </div>
  );

  return getUserMediaError ? <UserMediaError /> : renderCallScreen();
}
