import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from './utilities/hooks/useAuth';
import useTracking from './utilities/hooks/useTracking';
import { setUserId } from './utilities/monitoring';

function ProtectedRoutes() {
  const { user, isLoggedIn } = useAuth();
  const { user: userProfile } = useTracking();

  const setupUserProfileForAnalytics = () => {
    const { id, createdAt, updatedAt, memberships } = user;
    const { organisationTeam } = memberships[0];
    const { organisationId } = organisationTeam;

    userProfile.initialize(id);
    userProfile.setProperties({
      userId: id,
      created: createdAt,
      updated: updatedAt,
      organisation: organisationId.toString(),
    });
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      setupUserProfileForAnalytics();
      setUserId(user.id);
    }
  }, []);

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
}

export default ProtectedRoutes;