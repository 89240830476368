import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '../../components/Typography';
import Button from '../../components/Button';
import './LateCancellationPercentage.scss';
import { ILateCancellationMetrics } from './types';

function LateCancellationPercentage({
  latePercentageColorCode,
  latePercentage,
  cancelSessionNote,
  onCancelSession,
  onRescheduleSession,
  header,
  subHeader,
  children,
}: ILateCancellationMetrics) {
  const { t } = useTranslation();
  const handleCancel = () => {
    onCancelSession();
  };

  const handleReschedule = () => {
    onRescheduleSession();
  };
  return (
    <article className="late-percentage-wrapper">
      <div className="late-percentage-body">
        <section className="late-percentage-heading">
          <Typography size={22} weight="600" withColor="#101828">
            {header}
          </Typography>
          <Typography size={15} withColor="#636C73">
            <div className="sub-header">{subHeader}</div>
          </Typography>
        </section>
        <section className="late-percentage-metric">
          {latePercentage ? (
            <Typography
              size={22}
              withColor={latePercentageColorCode}
              weight="700"
            >
              {latePercentage}
            </Typography>
          ) : (
            children
          )}
        </section>
        {cancelSessionNote && (
          <section className="late-cancelled-session-note">
            <Typography size={15} withColor="#636C73">
              {cancelSessionNote}
            </Typography>
          </section>
        )}
        <section className="late-percentage-actions">
          <Button
            label={t('continue')}
            variant="secondary"
            onClick={handleCancel}
            width="100%"
            height="48px"
            fontSize={14}
          />
          <Button
            label={t('RESCHEDULE_SESSION')}
            variant="blue"
            onClick={handleReschedule}
            width="100%"
            height="48px"
            fontSize={14}
          />
        </section>
      </div>
    </article>
  );
}

export default LateCancellationPercentage;
