import { AxiosResponse } from 'axios';
import { StorageItems } from '../../shared/constant/App';
import { gotoRoute } from '../common/Path';
import { removeItemFromLocalStorage } from '../common/Storage';

const onResponseFulfilled = (response: AxiosResponse) => response;
// console.log('%c Response: ', 'background: #0f0; color: #fff', response);

const onResponseRejected = (error: any) => {
  if (error.response && error.response?.status === 401) {
    // Cookie.remove('user');
    // store.dispatch(UserActions.setLogoutSucess());
  } else if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    // store.dispatch(
    //   AppActions.setErrorMessage(error.response.data.error.message)
    // );
  }
  if (error.response?.status === 403) {
    removeItemFromLocalStorage(StorageItems.USER_INFO);
    gotoRoute('/login');
  }
  if (!error.response && typeof error?.message === 'string') {
    // eslint-disable-next-line
    return Promise.reject({ data: { error: { message: error?.message } } });
  }
  return Promise.reject(error.response);
};

export { onResponseFulfilled, onResponseRejected };
