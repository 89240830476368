import React from 'react';
import TextBox from '../../components/TextBox';
import './AccordianDropDown.scss';
import searchIcon from '../../../assets/search.svg';
import downArrow from '../../../assets/down-arrow.svg';
import rightArrow from '../../../assets/right-arrow.svg';
// import IClientDataprops from '../../'

interface ICaseNotesProps {
  caseNoteQuestions: any;
  setSelectedValue: (value: string) => void;
  setIsDropdownSelected: (value: boolean) => void;
  parentRef: any;
}

interface ICaseNoteQuestion {
  id: number;
  question: string;
  children: [
    {
      children: [{ question: string }];
    },
  ];
}

function AccordianDropDown({
  caseNoteQuestions,
  setSelectedValue,
  setIsDropdownSelected,
  parentRef,
}: ICaseNotesProps) {
  // Local state
  const [searchText, setSearchText] = React.useState('');
  const [selectedQuestionId, setSelectedQuestionId] = React.useState(Number);
  const [filteredQuestions, setFilteredQuestions] = React.useState([]);
  const searchedQuestionArray: any = [];

  // Functions
  // * Function set and remove the selected question id
  const handleSelectedQuestion = (id: number) => {
    if (selectedQuestionId === id) {
      setSelectedQuestionId(0);
    } else {
      setSelectedQuestionId(id);
    }
  };

  const handleSelectedOption = (question: string) => {
    setSelectedValue(question?.replace(/\([^)]*\)/g, ''));
    setIsDropdownSelected(false);
  };

  // * Function to filter the questions based on search text
  const filterQuestions = (questions: ICaseNoteQuestion) => {
    const filteredQuestion: Array<[{ question: string }]> =
      questions.children.map((question: any) => {
        const filteredSubQuestion = question.children.filter(
          (subQuestion: any) =>
            subQuestion.question
              .toLowerCase()
              .includes(searchText.toLowerCase()),
        );
        return filteredSubQuestion;
      });
    setFilteredQuestions(filteredQuestion as never[]);
  };

  // Component Life Cycle
  //* UseEffect to call filtereQuestions function on change of searchText
  React.useEffect(() => {
    if (searchText) {
      filterQuestions(caseNoteQuestions?.[1]);
    }
  }, [searchText]);

  //* UseEffect to push filteredQuestions to an array
  React.useEffect(() => {
    if (filteredQuestions.length > 0) {
      filteredQuestions.map((question: any) =>
        question.map((subQuestion: any) => {
          if (subQuestion.question) {
            return subQuestion.question;
          }
          return searchedQuestionArray;
        }),
      );
    }
  }, [filteredQuestions]);

  return (
    <div className="accordion-dropdown-container" ref={parentRef}>
      <div className="dropdown-search">
        <TextBox
          animate={false}
          variant="box-border"
          placeholder="Search Presenting issues"
          icon={searchIcon}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      {searchText
        ? filteredQuestions.map((question: Array<[{ question: string }]>) =>
          question.map((subQuestion:any) => (
              <div className="accordion-content-container">
                <div
                  className="issue-container"
                  onClick={() => handleSelectedOption(subQuestion.question)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="issue-value-text">
                    {subQuestion.question.replace(/\([^)]*\)/g, '')}
                  </div>
                  <div className="issue-subheading">
                    {subQuestion.question.match(/\(([^)]+)\)/g)}
                  </div>
                </div>
              </div>
          )),
        )
        : caseNoteQuestions?.[1]?.children?.map((question: any) => (
            <div className="accordion-content-container">
              <div
                className="presenting-issue-heading"
                onClick={() => handleSelectedQuestion(question.id)}
                tabIndex={0}
                role="button"
              >
                <span className="presenting-issue-heading-text">
                  {question.question}
                </span>
                {selectedQuestionId === question.id ? (
                  <img src={downArrow} alt="Down Arrow" />
                ) : (
                  <img src={rightArrow} alt="arrow" />
                )}
              </div>
              {selectedQuestionId === question.id &&
                question.children.map((subQuestion: any) => (
                  <div
                    className="issue-container"
                    onClick={() => handleSelectedOption(subQuestion.question)}
                    role="button"
                    tabIndex={0}
                  >
                    <div className="issue-value-text">
                      {subQuestion.question.replace(/\([^)]*\)/g, '')}
                    </div>
                    <div className="issue-subheading">
                      {subQuestion.question.match(/\(([^)]+)\)/g)}
                    </div>
                  </div>
                ))}
            </div>
        ))}
    </div>
  );
}

export default AccordianDropDown;
