import * as React from 'react';
import './MediaViewer.scss';
import { motion } from 'framer-motion';
import closeIcon from '../../../../assets/close-white.svg';
import useMediaPreview from '../../../../utilities/hooks/useMediaPreview';

interface IMediaViewerProps {
  mediaUrl: string;
}

function MediaViewer({ mediaUrl }: IMediaViewerProps) {
  const { showPreview } = useMediaPreview();
  return (
    <motion.div className="media-viewer" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <img src={closeIcon} className="close" onClick={() => showPreview(null)} alt="close" />
      <img className="media" src={mediaUrl} alt="media file" />
    </motion.div>
  );
}

export default MediaViewer;